export default function Setting({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath={color}>
                <path
                    d="M10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.3517 8.66249L15.4376 6.45416L16.6667 4.99999L15.0001 3.33332L13.5542 4.56916L11.2984 3.64166L10.7792 1.66666H9.15091L8.62425 3.66749L6.42008 4.59666L5.00008 3.33332L3.33341 4.99999L4.54425 6.49082L3.64425 8.70499L1.66675 9.16666V10.8333L3.66758 11.3792L4.59675 13.5833L3.33341 15L5.00008 16.6667L6.49258 15.45L8.66425 16.3433L9.16675 18.3333H10.8334L11.3367 16.3442L13.5459 15.4292C13.9142 15.6925 15.0001 16.6667 15.0001 16.6667L16.6667 15L15.4301 13.5417L16.3451 11.3317L18.3334 10.815V9.16666L16.3517 8.66249Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_75_1458">
                    <rect width={size} height={size} fill={color}
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
