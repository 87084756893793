import { FC, useEffect, useState } from 'react';
import Button from "../../components/form/Button";
import { useNavigate } from "react-router-dom";
import Trash from "../../img/icon/Trash";
import { $deleteAvatars, $getListAvatars } from "../../api/requests/avatars";
import RoleUsers from "../../mixins/role";
import { MobileAchievementsItems } from "../../components/achievements/MobileAchievementsItems";
import { AchievementsItems } from "../../components/achievements/AchievementsItems";
import Pen from "../../img/icon/Pen";

const AvatarsPage: FC = () => {
    const isMobile = window.innerWidth < 465
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('avatars', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('avatars', 'update');

    const headerBtn = [ 'Все', 'Мужские', 'Женские' ];

    const [ list, setList ] = useState<any[]>([]);

    const [ filterType, setFilterType ] = useState(0);

    const handleDelete = (itemToRemove: any) => {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteAvatars(itemToRemove.id).then(res => {
            if (!res.id) return;

            const updatedItems = list.filter((list: any) => list !== itemToRemove);

            setList(updatedItems);
        })
    }

    const handleFilter = (gender: any) => {
        setFilterType(gender);
    };

    const filteredItems = list.filter((item: any) => {
        return filterType === 0 || item.gender === filterType;
    });

    useEffect(() => init(), [])

    function init() {
        $getListAvatars().then(res => {
            if (!res.length) return;

            setList(res)
        })
    }

    return (
        <div className="game list-wrapper">
            <div className="booking__header">
                <h1>Аватары</h1>
            </div>
            <div className="game-main">
                <div className="game__header">
                    <div className="game__header__left">
                        { headerBtn.map((title: string, index: number) => (
                            <Button
                                key={ index }
                                className={ `btn btn-primary ${ (index) === filterType ? 'achievements__header-active' : 'achievements__header-nonActive' }` }
                                text={ title }
                                onClick={ () => (handleFilter(index)) }
                            />
                        )) }
                    </div>

                    {isCreateByRole && (
                        <div className='game__header-right'>
                            <Button
                                className={ 'btn btn-primary' }
                                text={ 'Создание аватара' }
                                onClick={ () => (navigate('create')) }
                            />
                        </div>
                    )}
                </div>

                <div className="game__content">
                    {!!filteredItems.length && filteredItems.map((item, idx) => (
                        <div className="game__content__pro"
                             key={ `game-item-list-pro-${ idx }` }
                        >
                            <div className="game__content__pro__item">
                                <img src={ item.image }/>

                                <div className="game__content__pro__item__hour d-flex justify-content-between">
                                    {/*<p>{ item.title }</p>*/}

                                    {isUpdateByRole && (
                                        <div className="game__content__pro__item__hour__timer">
                                            <div className="game__content__pro__item__delete">
                                                <Button
                                                    className={ 'btn btn-primary' }
                                                    text={isMobile ? <Pen/> : 'Редактировать' }
                                                    onClick={ () => {
                                                        navigate('edit', {
                                                            state: {
                                                                current: 'edit',
                                                                data: item
                                                            },
                                                        })
                                                    } }
                                                />

                                                <Button
                                                    className={ 'btn btn-danger' }
                                                    text={ <Trash/> }
                                                    onClick={ () => handleDelete(item) }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        // <div className="col-6 col-sm-6 col-xl-4"
                        //      key={ `person-item-list-${ idx }` }
                        // >
                        //     { isMobile ? (
                        //             <MobileAchievementsItems
                        //                 isShowEditBtn={ isUpdateByRole }
                        //                 onDelete={ () => (handleDelete(item)) }
                        //                 itemInfo={ item }
                        //                 isShowCounts={ false }
                        //             />
                        //         ) :
                        //         (
                        //             <AchievementsItems
                        //                 isShowEditBtn={ isUpdateByRole }
                        //                 onDelete={ () => (handleDelete(item)) }
                        //                 itemInfo={ item }
                        //                 isShowCounts={ false }
                        //             />
                        //         ) }
                        // </div>
                    )) }
                </div>

                {!filteredItems.length && (
                    <div className="program__block__item__appellation">
                        <p>
                            Список аватаров пуст
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AvatarsPage;
