import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import TableContainer from "../../components/form/TableContainer";
import PlusBorder from "../../img/icon/PlusBorder";
import ArrowLeft from "../../img/icon/ArrowLeft";
import SeasonsModals from "./SeasonModal";
import { $deleteSeasons, $getSeasons } from "../../api/requests/seasons";
import DateOperations from "../../mixins/date-operation";
import RoleUsers from "../../mixins/role";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

const SeasonsPage: FC = () => {
    const dateOperations = new DateOperations();
    const roleUsers = new RoleUsers();

    const userData = useSelector(GetCurrentUserData);

    const isCreateByRole = roleUsers.getModelRoleByType('seasons', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('seasons', 'update');

    const statusList = [
        {
            text: 'Завершен',
            value: -1,
        },
        {
            text: 'Активный',
            value: 0,
        },
        {
            text: 'В ожидании',
            value: 1,
        },
    ];

    const headerTable = [
        {
            key: 'from',
            title: 'Дата начала сезона',
        },
        {
            key: 'to',
            title: 'Дата конца сезона',
        },
        {
            key: 'statuses',
            title: 'Статус сезона',
        }
    ];

    const [ list, setList ] = useState<any[]>([]);
    const [ isModal, setIsModal ] = useState(false);
    const [ selectItem, setSelectItem ] = useState<any>(null);

    const getChangeSeasonItem = (item: any): any => {
        const findStatus = statusList.find(i => i.value === item.status);

        return {
            ...item,
            from: dateOperations.getFormattedDateByISO(item.date_from.slice(0, 10)),
            to: dateOperations.getFormattedDateByISO(item.date_to.slice(0, 10)),
            statuses: findStatus ? findStatus.text : ''
        }
    }

    useEffect(() => init(), []);

    function init() {
        $getSeasons().then(res => {
            if (!res.length) return;

            setList(res.map(i => getChangeSeasonItem(i)));
        })
    }

    function onClickAddNew(isEdit: boolean, item?: any) {
        setIsModal(true);

        if (isEdit) onLeftClickTrContent(item)
    }

    function onLeftClickTrContent(item: any) {
        if (!isUpdateByRole || !userData?.staffCenter.isMain) return;

        setSelectItem(item)
        setIsModal(true)
    }

    function onClickDelete(itemToDelete: any,) {
        if (!itemToDelete.id) return;

        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteSeasons(itemToDelete.id).then(res => {
            if (!res.id) return;

            const updatedItems = list.filter(item => item.id !== itemToDelete.id);

            setList(updatedItems);
            setIsModal(false);
        });
    }

    return (
        <div className={ 'season__wrap' }>
            <div className="computers list-wrappers">
                <div className="season__wrap-main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 computers-style">
                                <div className="booking__header">
                                    <h1>Сезоны</h1>
                                </div>
                                <div className="computers__header">
                                    <div className="computers__header-right ml-auto">
                                        {(isCreateByRole && userData?.staffCenter?.isMain) && (
                                            <Button
                                                className={ 'btn btn-primary' }
                                                onClick={ onClickAddNew }
                                                leftIcon={ (
                                                    <PlusBorder/>
                                                ) }
                                                text={ 'Добавить' }
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="computers__content users__content">
                                    <div className="seasons__content__table">
                                        <TableContainer
                                            header={ headerTable }
                                            content={ list }
                                            setData={setList}
                                            onLeftClickTrContent={ onLeftClickTrContent }
                                            isLastRightText={ false }
                                        />
                                    </div>
                                </div>

                                <SeasonsModals
                                    isShow={ isModal }
                                    setIsShow={ () => {
                                        setIsModal(false);
                                        setSelectItem(null);

                                        init();
                                    } }
                                    closeModal={ () => {
                                        setIsModal(false);
                                        setSelectItem(null);
                                    } }
                                    selectItem={ selectItem }
                                    onClickDelete={ () => onClickDelete(selectItem) }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SeasonsPage;

