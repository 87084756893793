import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { failureNotify, successNotify } from "../../notifications";
import { $authSign } from "../../api/requests/auth";
import { AuthSignBodyResultData, AuthSignData } from "../../api/requests/auth/interface";
import { $getCurrentUser } from "../../api/requests/staff";

export const getCurrentUser = createAsyncThunk(
    "user/getCurrentUser",
    async () => {
        return $getCurrentUser().then(response => {
            return (response) as AuthSignBodyResultData;
        })
    }
);

export const login = createAsyncThunk(
    "user/login",
    async (data: AuthSignData, {rejectWithValue}) => {
        return $authSign(data, {returnData: false}).then(response => {
            if (!response.result.access_token) {
                console.log(response.message, 'error profile');

                if (response && response.message) {
                    failureNotify(response.message);
                    return rejectWithValue(response.message);
                }

                return rejectWithValue(response);
            }

            Cookies.set('token', response.result.access_token);

            successNotify('Вы успешно авторизованы');

            return (response.result) as AuthSignBodyResultData;
        })
    }
);

export const selectClub = createAsyncThunk(
    "user/selected",
    async (data: any, {rejectWithValue}) => {
        return $authSign(data, {returnData: false}).then(response => {
            if (!response.result.access_token) {
                console.log(response.message, 'error profile');

                if (response && response.message) {
                    failureNotify(response.message);
                    return rejectWithValue(response.message);
                }

                return rejectWithValue(response);
            }

            Cookies.set('token', response.result.access_token);

            successNotify('Вы успешно авторизованы');

            return (response.result) as AuthSignBodyResultData;
        })
    }
);



