import { useEffect, useState } from "react";
import Chart from "../form/Chart";
import DatePickerField from "../form/DatePickerField";
import Button from "../form/Button";
import DateOperations from "../../mixins/date-operation";
import { $getStatisticRevenue } from "../../api/requests/reports";
import { FilterDate } from "./filter-date/FilterDate";
import { FilterDateSecond } from "./filter-date/FilterDateSecond";

export const OverviewReport = () => {
    const isMobile = window.innerWidth <= 465
    const dateOperation = new DateOperations();

    const [ dateStart, setDateStart ] = useState<Date>(new Date());
    const [ dateEnd, setDateEnd ] = useState<Date>(new Date());

    const [ isLengthList, setIsLengthList ] = useState<boolean>(false);

    const [ headerBlocks, setHeaderBlocks ] = useState([
        {
            key: 'first',
            title: 'Наличные',
            firstValue: null,
            secondValue: null
        },
        {
            key: 'second',
            title: 'Безналичные',
            firstValue: null,
            secondValue: null
        },
        {
            key: 'third',
            title: 'Итого',
            value: null
        }
    ]);

    const [ listRevenue, setListRevenue ] = useState<any[]>([]);

    const [ listDatePeriods, setListDatePeriods ] = useState<any[]>([]);

    const [ listOrderAmount, setListOrderAmount ] = useState<number[]>([])

    const onChangeResponseItem = (item: any): any => {
        return {
            key: item.k,
            title: 'Наличные',
            firstValue: null,
            secondValue: null
        }
    }

    useEffect(() => init(), []);

    function init() {
        const requestObj = {
            fromDate: dateOperation.getISODateTimeByObj(dateStart),
            toDate: dateOperation.getISODateTimeByObj(dateEnd),
        }

        setIsLengthList(false);

        $getStatisticRevenue(requestObj).then(res => {
            if (!res.length) return setIsLengthList(true);

            const amountList = res.map(i => i.amount);

            setListRevenue(amountList)
            setListDatePeriods(res.map(i => {
                return {
                    title: '',
                    subTitle: i.date
                }
            }))
            // setHeaderBlocks(res.map(i => i && onChangeResponseItem(i)))
        })
    }

    return (
        <div className='reports__overview'>
            <FilterDateSecond
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                init={init}/>

            { !isLengthList
                ? (
                    <div className='reports__overview-content'>
                        <div className='reports__occupancy-chart'>
                            <h1 className='reports__overview-title'>Выручка за выбраный период</h1>


                            <Chart
                                tension={ 0.4 }
                                labels={ listDatePeriods }
                                datasets={ listRevenue }
                                overview={true}
                            />
                        </div>
                    </div>
                )
                : (
                    <div className='reports__overview-notFound'>
                        <h1 className='reports__overview-header-title'>Выручка за выбраный период</h1>
                        <p className='reports__overview-notFound-title'> За выбранный период данных нет.</p>
                    </div>
                )
            }
        </div>
    )
}
