import React, { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useLocation, useNavigate } from "react-router-dom";
import CardClubForm from "../../components/clubs/CardClubForm";
import CardClubRight from "../../components/clubs/CardClubRight";
import DialogContainer from "../../components/dialog/DialogContainer";
import { CardCoords } from "../../components/clubs/CardCoords";
import { $createItemCenter, $deleteCentral, $getCentral, $updateCentral } from "../../api/requests/center";
import { getCurrentUser } from "../../redux/actions/userActions";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import RoleUsers from "../../mixins/role";

const ClubsAddPage: FC = () => {
    const isMobile = window.innerWidth < 465
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const userData = useSelector(GetCurrentUserData);

    const roleUsers = new RoleUsers();
    const isDeleteByRole = roleUsers.getModelRoleByType('center', 'delete');
    const checkStatusUser = (userData?.role === 1) ? true : (userData?.status === 2);

    const [placeMarkCoords, setPlaceMarkCoords] = useState<Number[]>([55.7516925824441,37.620753161609024]);
    const [phone, setPhone] = useState<string>('');
    const [isSave, setIsSave] = useState<boolean>(false);

    const state: any = location.state || {};
    const isEditPage = state.current === 'edit';
    const [dataProps, setDataProps] = useState(state.data);

    const [isOpenDialog, setIsOpenDialog] = useState(false);

    useEffect(() => {
        if (dataProps) {
            const stringArray = dataProps.coords ? dataProps.coords.split(',') : ['0', ' 0'];

            setPlaceMarkCoords(stringArray.map(parseFloat));
            setPhone(dataProps.phone);

            $getCentral(dataProps.id).then(res => {
                if (!res.id) return;

                setDataProps(res)
            })
        }
    }, [state.data])

    function onSaveItem(item: any) {
        setIsSave(false);

        const itemUpdate = {
            ...item,
            coords: placeMarkCoords.join(', '),
            phone
        }

        if (isEditPage) return onEditSave(itemUpdate);

        onCreateSave(itemUpdate)
    }

    function onEditSave(item: any) {
        $updateCentral(dataProps.id, item).then(res => {
            if (!res.id) return;

            onClickHome();
        })
    }

    function onCreateSave(item: any) {
        $createItemCenter(item).then(res => {
            if (!res.id) return;

            onClickHome();
        })
    }

    function onDeleteItem() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteCentral(dataProps.id).then(res => {
            if (!res.id) return;

            onClickHome();
        })
    }

    function onClickHome() {
        navigate("/non/game");

        dispatch(getCurrentUser());
    }

    function onClickSave () {
        setIsSave(true);
    }

    return (
        <div className="clubs list-wrapper">
            <div className="container-fluid">
                <div className="row d-flex">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="club__header club__add__header">
                            <Button
                                text={!isMobile &&  'Назад' }
                                leftIcon={ (
                                    <ArrowLeft
                                        color={'#3582F6'}
                                    />
                                )}
                                onClick={ onClickHome }
                                className={ 'btn btn-icon mb-3' }
                            />

                            <h4 className={'club__add__header__title'}>Карточка центра</h4>
                        </div>

                        <div className="club__add__content">
                            <p>Данные центра</p>

                            <CardClubForm
                                data={dataProps}
                                onSave={onSaveItem}
                                onDelete={onDeleteItem}
                                isEdit={isEditPage}
                                isSave={isSave}
                            />
                        </div>

                    </div>

                    <div className="col-lg-4 col-md-6 col-12">
                        <CardCoords
                            phone={phone}
                            setPhone={setPhone}
                            placeMarkCoords={placeMarkCoords}
                            setPlaceMarkCoords={setPlaceMarkCoords}
                        />
                    </div>

                    <div className="col-lg-4 col-md-6 col-12">
                        <CardClubRight/>

                        <div className="club__add__content-btn club__add__content-btn-mobile">
                            { (isEditPage && isDeleteByRole && checkStatusUser) && (
                                <Button
                                    text={ 'Удалить' }
                                    onClick={ onDeleteItem }
                                    className={ 'btn btn-danger w-100 justify-content-center' }
                                    disabled={userData?.staffCenter?.isMain}
                                />
                            ) }

                            <Button
                                text={ 'Сохранить' }
                                onClick={ onClickSave }
                                className={ 'btn btn-primary w-100 justify-content-center' }
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/*<DialogContainer*/}
            {/*    isOpen={isOpenDialog}*/}
            {/*    closeModal={() => setIsOpenDialog(false)}*/}
            {/*    isShowCloseBtn={false}*/}
            {/*    widthProps={471}*/}
            {/*>*/}
            {/*    <div className="buy-info">*/}
            {/*        <div className="buy-info-icon">*/}
            {/*            <span>Trial</span>*/}
            {/*        </div>*/}

            {/*        <div className="buy-info-header">*/}
            {/*            <h4>30 дней бесплатно!</h4>*/}
            {/*        </div>*/}

            {/*        <div className="buy-info-text">*/}
            {/*            У вас активирована максимальная подписка со*/}
            {/*            всеми модулями в течение 30 дней. Потом нужно*/}
            {/*            выбрать подходящую платную подписку или перейти*/}
            {/*            на бесплатную.*/}
            {/*        </div>*/}

            {/*        <div className="buy-info-btn">*/}
            {/*            <Button*/}
            {/*                text={'Поздравляем!'}*/}
            {/*                onClick={() => setIsOpenDialog(false)}*/}
            {/*                className={'btn btn-primary w-100 justify-content-center'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</DialogContainer>*/}
        </div>
    );
}

export default ClubsAddPage;

