import { FC, useEffect, useRef, useState } from "react";
import Logs from "../../img/icon/Logs";
import Chevron from "../../img/icon/Chevron";
import Modal from "react-modal";
import DateOperations from "../../mixins/date-operation";
import Arrow from "../../img/icon/Arrow";
import { $getNotificationList } from "../../api/requests/notification";

interface NotificationProps {
    onClose: () => void
    isOpen: boolean
}

export const Notification: FC<NotificationProps> = (
    {
        isOpen,
        onClose
    }
) => {
    const dateOperations = new DateOperations();

    const [ notificationItems, setNotificationItems ] = useState<any[]>([
        {
            image: <Logs/>,
            isActive: true,
            title: 'logs',
            text: 'Новый лог',
            sendAt: '2023-09-23T14:36:06.663Z',
            type: 0,
            deviceType: 2
        },
        {
            image: <Logs/>,
            isActive: true,
            title: 'logs',
            text: 'Новый лог',
            sendAt: '2023-09-26T19:36:06.663Z',
            type: 0,
            deviceType: 2
        },
        {
            image: <Logs/>,
            isActive: true,
            title: 'logs',
            text: 'Новый лог',
            sendAt: '4200',
            type: 0,
            deviceType: 2
        },
        {
            image: <Logs/>,
            isActive: true,
            title: 'logs',
            text: 'Новый лог',
            sendAt: '4200',
            type: 0,
            deviceType: 2
        },
        {
            image: <Logs/>,
            isActive: true,
            title: 'logs',
            text: 'Новый лог',
            sendAt: '4200',
            type: 0,
            deviceType: 2
        },
        {
            image: <Logs/>,
            isActive: true,
            title: 'logs',
            text: 'Новый лог',
            sendAt: '4200',
            type: 0,
            deviceType: 2
        }
    ]);

    const popupRef = useRef<HTMLDivElement>(null);

    const [ isExpanded, setIsExpanded ] = useState(false);
    const visibleItems = isExpanded ? notificationItems.length : 5;

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        const bodyOverflow = document.body.style.overflow;

        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow = bodyOverflow;
        };
    }, [ isOpen ]);

    // useEffect(() => init(), []);

    function init() {
        $getNotificationList().then(res => {
            if (!res.result) return;

            setNotificationItems(res.result);
        })
    }

    return (
        <Modal
            appElement={ [] }
            className={ `notification ${ isExpanded ? '-expanded' : '-closed' }` }
            overlayClassName={ 'notification__container' }
            isOpen={ isOpen }
        >
            <div
                ref={ popupRef }
                className='notification-block'
            >
                <div className={ `notification-block__top ${ isExpanded ? '-expanded' : '-closed' }` }>
                    { notificationItems.slice(0, visibleItems).map((item, index) => (
                        <div key={ index } className='notification-block__item'>
                            <div className='notification-block__left'>
                                <picture>{ item.image }</picture>

                                <p className='notification-block__item-text'>{ item.text }</p>
                            </div>

                            <p className='notification-block__item-date'>
                                { dateOperations.getFormattedDateByISO(dateOperations.getDate(item.sendAt)) }
                            </p>
                        </div>
                    )) }
                </div>

                <div onClick={ toggleExpansion }
                     className={ `${ isExpanded ? 'notification-btn__expanded ' : ' notification-btn' }` }
                >
                    { !isExpanded
                        ? <Chevron
                            size={ 30 }
                            color={ 'rgba(255, 255, 255, 0.5)' }/>
                        : <Arrow
                            color={ 'rgba(255, 255, 255, 0.5)' }
                            size={ 30 }
                        />
                    }
                </div>
            </div>
        </Modal>
    )
}
