import { FC, useEffect, useState } from "react";
import TableContainer from "../../components/form/TableContainer";
import EditRatingDialog from "../../components/reports/EditRatingDialog";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useNavigate } from "react-router-dom";
import FilterSelect from "../../components/FilterSelect";
import { withDebounce } from "../../functions";
import { $getSearchCity } from "../../api/requests/reports";
import { $getStatisticCity } from "../../api/requests/rating-city";
import FilterSelectByCity from "../../components/form/FilterSelectByCity";

const RatingByCityClubs: FC = () => {
    const navigate = useNavigate();

    const tableHeader = [
        {
            key: 'name',
            title: 'Города'
        },
        {
            key: 'summaryHours',
            title: 'Сумма всех часов за игры'
        },
        {
            key: 'summaryPointsHours',
            title: 'Кол-во баллов за сумму часов'
        },
        {
            key: 'averageHours',
            title: 'Среднее время часов за игру'
        },
        {
            key: 'averagePointsHours',
            title: 'Кол-во баллов за среднее время часов за игру'
        },
        {
            key: 'percentAttendance',
            title: 'Процент посещаемости от всех пользователей'
        },
        {
            key: 'percentPointsAttendance',
            title: 'Кол-во баллов за процент посещаемости'
        },
    ];

    const [ tableContent, setTableContent ] = useState<any[]>([]);
    const [ filterTableContent, setFilterTableContent ] = useState<any[]>([]);

    const [ isShowEditModal, setIsShowEditModal ] = useState(false);
    const [ selectItem, setSelectItem ] = useState(null);

    const [ cityList, setCityList ] = useState<any[]>([]);

    useEffect(() => init(), []);

    function init() {
        onChangeSearchCityList();

        getStatisticList();
    }

    function getStatisticList(item?: any) {
        $getStatisticCity({ cityId: item?.id }).then(res => {
            if (!res || !res.length) return;

            const updateList = res.map(i => ({
                ...i,
                name: i.city?.name
            }))

            setTableContent(updateList)
            setFilterTableContent(updateList)
        })
    }

    function onCLickEditItem(item: any) {
        setIsShowEditModal(true);
        setSelectItem(item);
    }

    function onClickOpenCreate() {
        setIsShowEditModal(true);
    }

    function onClickHome() {
        navigate("/non/game");
    }

    function onChangeCityList(item: any) {
        setSelectItem(item)

        getStatisticList(item)
    }

    function onChangeCitySearch(value: string) {
        if (!value) return;

        withDebounce(() => onChangeSearchCityList(value))
    }

    function onChangeSearchCityList(search: string = '') {
        $getSearchCity({ search }).then(res => {
            if (!res || !res.length) return;

            setCityList(res.map(i => ({
                ...i,
                label: i.name,
                value: i.id
            })))
        })
    }

    function onChangeContentList(item: any) {
        let updateList = [...tableContent];

        if (item) updateList = updateList.filter(i => i.city.id === item?.id);

        setFilterTableContent(updateList)
    }

    return (
        <div className="clubs list-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="club__header">
                            <Button
                                text={'На главную'}
                                leftIcon={(
                                    <ArrowLeft
                                        color={'#3582F6'}
                                    />
                                )}
                                onClick={onClickHome}
                                className={'btn btn-icon'}
                            />

                            <div className={'club__header-block'}>
                                <h4>Рейтинг по городам</h4>

                                <Button
                                    text={'Добавить город'}
                                    onClick={onClickOpenCreate}
                                    className={'btn btn-primary'}
                                />
                            </div>

                            <div className={'club__header-block'}>
                                <FilterSelectByCity
                                    placeholder='Фильтр по городу'
                                    setSelectItem={ (item) => {
                                        setSelectItem(item)
                                        onChangeContentList(item)
                                    } }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className='reports__occupancy'>
                            <div className='reports__occupancy-table'>
                                <TableContainer
                                    header={ tableHeader }
                                    content={ filterTableContent }
                                    setData={ (list) => {
                                        setTableContent(list)
                                        setFilterTableContent(list)
                                    } }
                                    onLeftClickTrContent={onCLickEditItem}
                                />
                            </div>

                            {isShowEditModal && (
                                <EditRatingDialog
                                    cityList={cityList}
                                    item={selectItem}
                                    isShow={isShowEditModal}
                                    setIsShow={(item: any) => {
                                        setIsShowEditModal(false);
                                        setSelectItem(null);

                                        init();
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RatingByCityClubs;
