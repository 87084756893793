import {IApiHandlerParams} from "../../interface";
import api from "../../../api";
import {
    NewsCenterDataGet,
    CategoryCenterData,
    NewsCategoryCenterDataGet,
    NewsCategoryModel,
    NewsModel
} from "./interface";
import {NewsModelCenter,CategoryModelCenter} from "./interface";
import { AchievementPageRequestData } from "../achievement/interface";

export const $createNews = (data: NewsModelCenter, handler?: IApiHandlerParams): Promise<NewsModel> => api
    .post('/center/news/single', data, {
        handler
    })

export const $updateNews = (id: string, data: NewsModelCenter, handler?: IApiHandlerParams): Promise<NewsModel> => api
    .put('/center/news/single/' +id, data, {
        handler
    })

export const $deleteNews = (id: string, handler?: IApiHandlerParams): Promise<NewsModel> => api
    .delete('/center/news/single/' + id,{
        handler
    })

export const $getNewsById = (id: string, handler?: IApiHandlerParams): Promise<NewsModel> => api
    .get('/center/news/single/' + id,{
        handler
    })

export const $getNews = (handler?: IApiHandlerParams): Promise<NewsCenterDataGet> => api
    .get('/center/news',{
        handler
    })

export const $createCategoryNews = (data: CategoryModelCenter, handler?: IApiHandlerParams): Promise<NewsCategoryModel> => api
    .post('/center/news/category/single', data, {
        handler
    })

export const $updateCategoryNews = (id: string, data: CategoryModelCenter, handler?: IApiHandlerParams): Promise<NewsCategoryModel> => api
    .put('/center/news/category/single/' + id, data, {
        handler
    })

export const $deleteCategoryNews = (id: string, handler?: IApiHandlerParams): Promise<NewsCategoryModel> => api
    .delete('/center/news/category/single/' + id,{
        handler
    })

export const $getCategoryNewsById = (id: string, params: CategoryModelCenter, handler?: IApiHandlerParams): Promise<CategoryCenterData> => api
    .get('/center/news/category/single/' + id,{
        request: {params},
        handler
    })

export const $getCategoryNews = (params?: AchievementPageRequestData, handler?: IApiHandlerParams): Promise<NewsCategoryCenterDataGet> => api
    .get('/center/news/category',{
        request: {params},
        handler
    })

export const $sendNotification = (id: string, handler?: IApiHandlerParams): Promise<NewsCategoryModel> => api
    .post('/center/news/single/' + id, {}, {
        handler
    })
