import React, { FC, useState } from "react";
import TextField from "../form/TextField";
import DialogContainer from "../dialog/DialogContainer";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import { $getListZone } from "../../api/requests/zone";
import ImageField from "../form/ImageField";
import FilterSelect from "../FilterSelect";
import ImageToBase64 from "../../mixins/image-to-base64";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

interface CreateGroupDialogProps {
    isShow: boolean
    setIsShow: (item?: any) => void
    type: string
}

const CreateGroupDialog: FC<CreateGroupDialogProps> = (
    {
        isShow,
        setIsShow,
        type
    }
) => {
    const imageToBase64 = new ImageToBase64();

    const isAdmin = useSelector(GetCurrentUserData)?.role === 1;

    const [ name, setName ] = useState('');
    const [ position, setPosition ] = useState('');
    const [ monthValue, setMonthValue ] = useState<any>(null);
    const [ image, setImage ] = useState<any>(null);

    const [ valueDevice, setValueDevice ] = useState<any>(null);

    const [ isLoadingSave, setIsLoadingSave ] = useState<boolean>(false);

    const dateMonthList = [
        {
            label: 'Январь',
            value: 1
        },
        {
            label: 'Февраль',
            value: 2
        },
        {
            label: 'Март',
            value: 3
        },
        {
            label: 'Апрель',
            value: 4
        },
        {
            label: 'Май',
            value: 5
        },
        {
            label: 'Июнь',
            value: 6
        },
        {
            label: 'Июль',
            value: 7
        },
        {
            label: 'Август',
            value: 8
        },
        {
            label: 'Сентябрь',
            value: 9
        },
        {
            label: 'Октябрь',
            value: 10
        },
        {
            label: 'Ноябрь',
            value: 11
        },
        {
            label: 'Декабрь',
            value: 12
        },
    ];

    const typeDevice = [
        { value: 0, label: 'ПК', type: 'comp' },
        { value: 1, label: 'Консоль', type: 'console' },
        { value: 2, label: 'VR', type: 'vr' },
        { value: 3, label: 'Спортивные зоны', type: 'open-area' }
    ];

    const typeSportList = [
        { value: 3, label: 'Мини-футбол' },
        { value: 4, label: 'Стритбол' },
        { value: 5, label: 'Зона воркаут' },
    ];

    const isFormValid = () => {
        return !name.length;
    }

    function onClickSave() {
        if (isFormValid()) return;

        setIsLoadingSave(true);

        const findTypeItem = typeDevice.find(i => i.type === type)

        const typeValue = findTypeItem ? ((findTypeItem.value === 3) ? +valueDevice.value : findTypeItem.value) : 0

        const requestData = {
            name,
            // position: +position,
            type: typeValue,
            months: monthValue && monthValue.map((i: any) => i.value),
            image: imageToBase64.getImageCheckValid(image)
        }

        $getListZone(requestData).then(i => {
            setIsLoadingSave(false);

            if (!i) return;

            resetForm();
            setIsShow(true);
        })
    }

    function resetForm() {
        setName('');
        setImage(null);
        setMonthValue(null);
        setValueDevice(null);
        setPosition('');
    }

    return (
        <DialogContainer
            isOpen={ isShow }
            closeModal={ () => {
                setIsShow();
                resetForm();
            }}
            label={ 'Создание' }
            closeIcon={ <Close/> }
        >
            {isAdmin && (
                <div className="edit__stocks__right-content">
                    <ImageField
                        label={ '150x150' }
                        upload={ image }
                        setUpload={ setImage }
                    />
                </div>
            )}

            <div className={ 'create__group' }>
                <TextField
                    label={ 'Название' }
                    imgRight={ (
                        <p>{ `${ name.length }/100` }</p>
                    ) }
                    value={ name }
                    onChangeValue={ setName }
                    maxLength={ 100 }
                />

                {/*<TextField*/}
                {/*    type={'number'}*/}
                {/*    label={ 'Позиция' }*/}
                {/*    value={ position }*/}
                {/*    onChangeValue={ setPosition }*/}
                {/*/>*/}

                {(type === 'open-area') && (
                    <FilterSelect
                        placeholder={'Тип'}
                        value={valueDevice}
                        options={typeSportList}
                        className={'mb-3'}
                        onChange={(val) => setValueDevice(val)}
                    />
                )}

                <div>
                    <FilterSelect
                        isMulti
                        className={ '' }
                        placeholder={ 'Какие месяцы доступны' }
                        value={ monthValue }
                        options={ dateMonthList }
                        onChange={ setMonthValue }
                    />
                </div>

                <Button
                    loading={ isLoadingSave }
                    text={ 'Сохранить' }
                    onClick={ onClickSave }
                    className={ 'btn btn-primary w-100 justify-content-center' }
                />
            </div>
        </DialogContainer>
    )
}

export default CreateGroupDialog;
