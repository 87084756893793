import React, { FC, useEffect, useState } from "react";
import ArrowLeft from "../../img/icon/ArrowLeft";
import Button from "../../components/form/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../../img/icon/Info";
import TextField from "../../components/form/TextField";
import { ToggleBlock } from "../../components/form/ToggleBlock";
import TransformObjArray from "../../mixins/transform-obj-array";
import FilterSelect from "../../components/FilterSelect";
import ImageField from "../../components/form/ImageField";
import { $notificationCreate, $notificationDelete, $notificationUpdate } from "../../api/requests/notification";
import DatePickerField from "../../components/form/DatePickerField";
import DateOperations from "../../mixins/date-operation";
import ImageToBase64 from "../../mixins/image-to-base64";

const NotificationAddPage: FC = () => {
    const isMobile = window.innerWidth <= 465;
    const location = useLocation();
    const navigate = useNavigate();

    const transformObjArray = new TransformObjArray();
    const dateOperations = new DateOperations();
    const imageToBase64 = new ImageToBase64();

    const state: any = location.state;
    const isEditPageNotification = state?.current === 'edit';

    const editedData = state?.data || {};

    const [ image, setImage ] = useState<any>(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const deviceType = [
        {
            label: 'iOS',
            value: 0
        },
        {
            label: 'Android',
            value: 1
        },
        {
            label: 'Все',
            value: 2
        },
    ];

    const statusNotification = [
        {
            label: 'В ожидании',
            value: 0
        },
        {
            label: 'В процессе',
            value: 1
        },
        {
            label: 'Завершен',
            value: 2
        },
        {
            label: 'Прерван',
            value: -1
        }
    ];

    const typeList = [
        {
            label: 'Пуш',
            value: 0
        },
        {
            label: 'SMS',
            value: 1
        },
        {
            label: 'Всплывающее окно',
            value: 2
        },
        {
            label: 'Банер',
            value: -1
        }
    ];

    const [ list, setList ] = useState<any[]>([
        {
            key: 'title',
            placeholder: 'Заголовок',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            field: true
        },
        {
            key: 'text',
            placeholder: 'Описание',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            field: true
        },
        {
            key: 'sendAt',
            placeholder: 'Дата и время отправки',
            value: null,
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            date: true,
            className: 'mb-3'
        },
        {
            key: 'deviceType',
            placeholder: 'Тип устройства',
            options: deviceType,
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            select: true,
            className: 'mb-3'
        },
        {
            key: 'status',
            placeholder: 'Статус отправки уведомлений',
            options: statusNotification,
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            select: true,
            className: 'mb-3',
            hide: true,
            disabled: true
        },
        {
            key: 'type',
            placeholder: 'Тип уведомления',
            options: typeList,
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(value, key),
            select: true,
            className: 'mb-3'
        },
        {
            key: 'isActive',
            title: 'Статус',
            value: true,
            setValue: (
                value: boolean,
                key: string
            ) => onChangeSetValue(value, key),
            checkbox: true
        }
    ]);

    const getDateByUtc = (dateString: string) => {
        const date = new Date(dateString);

        const userTimezoneOffset = new Date().getTimezoneOffset();

        return new Date(date.getTime() + userTimezoneOffset * 60 * 1000);
    }

    useEffect(() => init(), []);

    function init() {
        const listUpdate = [ ...list ].map(i => {
            if (editedData && Object.keys(editedData).includes(i.key)) {
                i.value = editedData[i.key];

                if (i.options) i.value = i.options.find((_i: any) => _i.value === editedData[i.key]);

                if (i.key === 'status') i.hide = false
                if (i.key === 'sendAt') i.value = getDateByUtc(editedData[i.key]);
            }

            return i;
        });

        if (editedData.image) setImage(editedData.image);

        setList(listUpdate)
    }

    function onChangeSetValue(
        value: string | boolean,
        key: string
    ) {
        const listUpdates = [ ...list ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setList(listUpdates)
    }

    function onClickBack() {
        navigate('/notifications')
    }

    function onClickEnter() {
        setIsLoading(true);

        const date = transformObjArray.getRequestObj(list).sendAt

        const requestObj = {
            ...transformObjArray.getRequestObj(list),
            centerId: null,
            sendAt: (date instanceof Date)
                ? dateOperations.getISODateTimeByObj(date)
                : date,
            image: imageToBase64.getImageCheckValid(image),
            status: transformObjArray.getRequestObj(list).status || 1
        }

        if (isEditPageNotification) return onEditData(requestObj);

        onCreateSave(requestObj);
    }

    function onCreateSave(request: any) {
        $notificationCreate(request).then(res => {
            setIsLoading(false);

            if (!res.id) return;
            onClickBack();
        })
    }

    function onEditData(request: any) {
        if (!editedData.id) return setIsLoading(false);

        $notificationUpdate(editedData.id, request).then(res => {
            setIsLoading(false);

            if (!res.id) return;

            onClickBack();
        })
    }

    function onClickDelete() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        if (!editedData.id) return onClickBack();

        $notificationDelete(editedData.id).then(res => onClickBack());
    }

    return (
        <div className="discipline__content">
            <div className="discipline__content__back">
                <Button
                    className={ 'btn' }
                    leftIcon={ (
                        <ArrowLeft color={ '#3582F6' } />
                    ) }
                    text={ !isMobile && 'Назад' }
                    onClick={ onClickBack }
                />

                { isMobile && (
                    <h3>
                        { isEditPageNotification ? 'Редактирование' : 'Создание' } уведомления
                    </h3>
                ) }
            </div>

            <div className="discipline__content__form">
                <div className="discipline__content__form__left">
                    { isMobile ? (
                            <h4 className={'discipline__content__form__left__mobile__text'}>Данные</h4>
                        ) :
                        (
                            <h3>{ isEditPageNotification ? 'Редактирование' : 'Создание' } уведомления</h3>
                        )
                    }

                    { list.map((
                        item,
                        idx
                    ) => (
                        <div className="discipline__content__form__left__block"
                             key={ `discipline__content__form__left__block${ idx }` }
                        >
                            { item.field && (
                                <TextField
                                    placeholder={ item.placeholder }
                                    value={ item.value }
                                    onChangeValue={ (value) => item.setValue(value, item.key) }
                                />
                            ) }

                            { item.checkbox && (
                                <ToggleBlock
                                    desc={ item.title }
                                    isChecked={ item.value }
                                    handleChange={ (i) => item.setValue(i, item.key) }
                                />
                            ) }

                            { (item.select && !item.hide) && (
                                <FilterSelect
                                    className={ item.className }
                                    placeholder={ item.placeholder }
                                    value={ item.value }
                                    options={ item.options }
                                    onChange={ (value) => item.setValue(value, item.key) }
                                    disabled={ item.disabled }
                                />
                            ) }

                            { item.date && (
                                <>
                                    <DatePickerField
                                        className={ item.className }
                                        isIcon={ true }
                                        isTimeSelect={ true }
                                        placeholder={ item.placeholder }
                                        startDate={ item.value }
                                        onChangeDate={ (date) => item.setValue(date, item.key) }
                                    />
                                </>
                            ) }
                        </div>
                    )) }

                    <div className="discipline__content__form__left__bottom d-flex">
                        { isEditPageNotification && (
                            <Button
                                className={ 'btn btn-danger' }
                                text={ 'Удалить' }
                                onClick={ onClickDelete }
                            />
                        ) }

                        <Button
                            loading={ isLoading }
                            className={ 'btn btn-primary' }
                            text={ isEditPageNotification ? 'Обновить' : 'Создать' }
                            onClick={ onClickEnter }
                        />
                    </div>
                </div>

                <div className="discipline__content__form__right">
                    <div className="discipline__content__form__right__text">
                        { isMobile ? (
                                <h4 className={'discipline__content__form__left__mobile__text'}>Обложка</h4>
                            ) :
                            (
                                <h3>Обложка 540х540</h3>
                            )
                        }

                        {/*<Info*/ }
                        {/*    size={ 16 }*/ }
                        {/*    color={ '#3582F6' }*/ }
                        {/*/>*/ }
                    </div>

                    <div className="discipline__content__form__right__file">
                        <ImageField
                            label={ '512х512' }
                            upload={ image }
                            setUpload={ setImage }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotificationAddPage;

