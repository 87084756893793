import api from '../../../api';
import {IApiHandlerParams} from "../../interface";
import {
    UserModelLink,
    CreateUserData,
    ListUsersRequestParams,
    UpdateUserData, ListUsersData, StaffCenterSelf, CenterSelfDataResult,
} from "./interface";

export const $getCurrentUser = (handler?: IApiHandlerParams): Promise<UserModelLink> => api
    .get('/center/staff/self', {
        handler
    })

export const $getListUsers = (params?: ListUsersData, handler?: IApiHandlerParams): Promise<CenterSelfDataResult> => api
    .get('/center/staff', {
        request: {params},
        handler
    })

export const $getSelfStaffList = (handler?: IApiHandlerParams): Promise<Array<UserModelLink>> => api
    .get('/center/staff/list', {
        handler
    })

export const $createUser = (data: CreateUserData, handler?: IApiHandlerParams): Promise<UserModelLink> => api
    .post('/center/staff', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })

export const $getPersonUser = (id: string, handler?: IApiHandlerParams): Promise<UserModelLink> => api
    .get('/center/staff/single/' + id, {
        handler
    })

export const $updatePersonUser = (id: string, data: UpdateUserData, handler?: IApiHandlerParams): Promise<UserModelLink> => api
    .put('/center/staff/single/' + id, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })

export const $deletePersonUser = (id: string, handler?: IApiHandlerParams): Promise<UserModelLink> => api
    .delete('/center/staff/single/' + id, {
        // mock: require('./mocks/error.delete.json'),
        handler
    })

export const $selectCenterStaff = (id: string, handler?: IApiHandlerParams): Promise<UserModelLink> => api
    .get('/center/staff/select/' + id, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })
