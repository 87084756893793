export default function Discipline({ color = 'white', size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.17596 13.241L17.1933 3.22363M7.17596 13.241H4.81894L2.46191 15.598H4.81894V17.9551L7.17596 15.598V13.241ZM17.1933 3.22363H14.8362M17.1933 3.22363V5.58065"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M12.8245 13.241L2.80713 3.22363M12.8245 13.241H15.1815L17.5386 15.598H15.1815V17.9551L12.8245 15.598V13.241ZM2.80713 3.22363H5.16415M2.80713 3.22363V5.58065"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}


