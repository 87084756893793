import { FC, useEffect, useRef, useState } from 'react';
import PlusBorder from "../../img/icon/PlusBorder";
import Calendar from "../../img/icon/Calendar";
import PopupBlock from "./PopupBlock";
import Notification from "../../img/icon/Notification";
import List from "../../img/icon/List";
import WordComplete from "../../img/icon/WordComplete";
import Coins from "../../img/icon/Coins";
import Shift from "../../img/icon/Shift";
import ClosedWindow from "../../img/icon/ClosedWindow";
import Electro from "../../img/icon/Electro";
import ElectroOff from "../../img/icon/ElectroOff";
import Comment from "../../img/icon/Comment";
import Setting from "../../img/icon/Setting";
import ElectroOn from "../../img/icon/ElectroOn";
import Exit from "../../img/icon/Exit";
import SmartShe from "../../img/icon/SmartShe";
import PeopleDouble from "../../img/icon/PeopleDouble";
import Unavailable from "../../img/icon/Unavailable";
import Pen from "../../img/icon/Pen";
import Reload from "../../img/icon/Reload";
import Execute from "../../img/icon/Execute";
import {
    $deleteDevice,
    $requestCommandByDevice,
    $startActionMaintenance,
    $startSession,
    $stopSession
} from "../../api/requests/device";
import { CommandDevice } from "../../api/requests/device/interface";
import { useAppDispatch } from "../../redux/store";
import { changeDeviceItem } from "../../redux/slices/deviceSlice";
import RoleUsers from "../../mixins/role";

interface PopupProps {
    x: number;
    y: number;
    isTop?: boolean;
    onClose: () => void;
    data: any;
    setUpdateList: () => void;
    onOpenSelectTariff: () => void;
}

const PopupContainer: FC<PopupProps> = (
    {
        x,
        y,
        isTop,
        onClose,
        data,
        setUpdateList,
        onOpenSelectTariff
    }
    ) => {
    const dispatch = useAppDispatch();
    const popupRef = useRef(null);

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('fitZone', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('fitZone', 'update');
    const isDeleteByRole = roleUsers.getModelRoleByType('fitZone', 'delete');

    const isDisabledByRole = !isCreateByRole || !isUpdateByRole || !isDeleteByRole;

    const [isHovered, setIsHovered] = useState<number | null>(null);
    const [yPos, setYPos] = useState<number>(y);

    const list = [
        {
            key: selectTariff,
            icon: <Calendar/>,
            name: 'Выбрать пакет',
            color: '#FFFFFF',
            disabled: ((data?.status !== 1) || isDisabledByRole),
        },
        {
            icon: <Calendar/>,
            name: 'Бронирование',
            color: '#FFFFFF',
            content: [
                {
                    key: 'booking',
                    icon: <PlusBorder color={'#3582F6'}/>,
                    name: 'Забронировать',
                    color: '#3582F6',
                },
                {
                    key: 'bookingList',
                    icon: <List/>,
                    name: 'Список бронирований',
                    color: '#FFFFFF',
                },
                {
                    key: 'bookingComplete',
                    icon: <WordComplete color={'#70D584'}/>,
                    name: 'Подтвердить бронь',
                    color: '#70D584',
                }
            ],
            disabled: true,
            hide: true
        },
        {
            icon: <Coins/>,
            name: 'Штраф',
            color: '#FFFFFF',
            content: [
                {
                    key: '',
                    icon: <PlusBorder color={'#3582F6'}/>,
                    name: 'Забронировать',
                    color: '#3582F6',
                },
                {
                    key: '',
                    icon: <List/>,
                    name: 'Список бронирований',
                    color: '#FFFFFF',
                },
                {
                    key: '',
                    icon: <WordComplete color={'#70D584'}/>,
                    name: 'Подтвердить бронь',
                    color: '#70D584',
                }
            ],
            disabled: true,
            hide: true
        },
        {
            key: '',
            icon: <Shift width={20}/>,
            name: 'Смена места',
            color: '#FFFFFF',
            disabled: true,
            hide: true
        },
        {
            key: closeSession,
            icon: <ClosedWindow color={'#FF5858'}/>,
            name: 'Завершить сеанс',
            color: '#FF5858',
            disabled: ((data?.status !== 0) || isDisabledByRole)
        },
        {
            key: '',
            icon: <Notification size={20}/>,
            name: 'Уведомление',
            color: '#FFFFFF',
            line: true,
            disabled: true,
            hide: true
        },
        {
            icon: <Electro/>,
            name: 'Электропитание',
            color: '#FFFFFF',
            hide: data?.type !== 0,
            content: [
                {
                    key: (item: any) => changeServiceCommand(item, CommandDevice.RUN),
                    icon: <ElectroOn color={'#70D584'}/>,
                    name: 'Включить',
                    color: '#70D584',
                    disabled: data?.isOnline || isDisabledByRole
                },
                {
                    key: (item: any) => changeServiceCommand(item, CommandDevice.SHUTDOWN),
                    icon: <ElectroOff color={'#FF5858'} width={20} height={20}/>,
                    name: 'Выключить',
                    color: '#FF5858',
                    disabled: !data?.isOnline || isDisabledByRole
                },
                {
                    key: (item: any) => changeServiceCommand(item, CommandDevice.REBOOT),
                    icon: <Reload size={20}/>,
                    name: 'Перезагрузить',
                    disabled: !data?.isOnline || isDisabledByRole
                }
            ]
        },
        {
            key: '',
            icon: <Comment/>,
            name: 'Комментарии',
            color: '#FFFFFF',
            disabled: true,
            hide: true
        },
        {
            icon: <Setting/>,
            name: 'Управление',
            color: '#FFFFFF',
            content: [
                {
                    key: startActionMaintenance,
                    icon: <ElectroOn color={data.status === -2 ? '#FF5858' : '#70D584'}/>,
                    name: data.status === -2 ? 'Выкл. режим обслуживания' : 'Вкл. режим обслуживания',
                    color: data.status === -2 ? '#FF5858' : '#70D584',
                    disabled: ((data.status === 0) || isDisabledByRole),
                    hide: data?.type !== 0,
                },
                {
                    key: (item: any) => changeServiceCommand(item, CommandDevice.LOGOFF),
                    icon: <Exit color={'#FF5858'}/>,
                    name: 'Выйти из системы',
                    line: true,
                    disabled: ((data?.status !== -2) || isDisabledByRole),
                    hide: data?.type !== 0,
                },
                {
                    key: '',
                    icon: <SmartShe/>,
                    name: 'Отправить логи',
                    disabled: true,
                    hide: data?.type !== 0,
                },
                {
                    key: '',
                    icon: <Execute/>,
                    name: 'Выполнить',
                    line: true,
                    disabled: true,
                    hide: data?.type !== 0,
                },
                {
                    key: (item: any) => changeServiceCommand(item, CommandDevice.ALLOW_ADMIN_MODE),
                    icon: <PeopleDouble/>,
                    name: 'Высокий доступ',
                    disabled: ((data?.status !== -2) || isDisabledByRole),
                    hide: data?.type !== 0,
                },
                {
                    key: (item: any) => changeServiceCommand(item, CommandDevice.DISABLE_SHELL),
                    icon: <Unavailable color={'#FF5858'}/>,
                    name: 'Отключить шелл',
                    disabled: ((data?.status !== -2) || isDisabledByRole),
                    hide: data?.type !== 0,
                },
                {
                    key: onClickEditDevice,
                    icon: <Pen color={'#3582F6'}/>,
                    name: 'Редактировать',
                    line: true,
                    disabled: ((data.status === 0) || !isUpdateByRole)
                },
                {
                    key: onClickDeleteDevice,
                    icon: <WordComplete color={'#FF5858'}/>,
                    name: 'Удалить',
                    disabled: ((data.status === 0) || !isDeleteByRole)
                }
            ]
        }
    ]

    const handleHover = (idx: number) => {
        setIsHovered(idx);
    };

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !(popupRef.current as any).contains(event.target as Node)
            ) {
                onClose();
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => document.removeEventListener('click', handleDocumentClick);
    }, []);

    useEffect(() => {
        if (x && y && popupRef.current && isTop) {
            setYPos(y - (popupRef.current as any).offsetHeight - 5)
        }
    }, [])

    function onClickItem(item: any) {
        if (item.disabled || !item.key) return;

        item.key(data)
    }

    function closeSession(item: any) {
        $stopSession(item.id).then(res => {
            if (!res.id) return;

            setUpdateList()
        })
    }

    function onClickEditDevice(item: any) {
        dispatch(changeDeviceItem({
            ...item,
            name: item.name.props.children[0].props.children,
            user: item.user.props.children.props.children,
        }))
    }

    function onClickDeleteDevice(item: any) {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteDevice(item.id).then(res => {
            setUpdateList()
        })
    }

    function selectTariff(item: any) {
        onOpenSelectTariff()
    }

    function changeServiceCommand(item: any, key: CommandDevice) {
        $requestCommandByDevice({
            deviceId: item.id,
            action: key
        }).then((res) => {
            setUpdateList()
        })
    }

    function startActionMaintenance(item: any) {
        $startActionMaintenance({
            deviceId: item.id,
            inService: data.status !== -2
        }).then(res => {
            setUpdateList()
        })
    }

    return (
        <div
            ref={popupRef}
            style={{
                left: x,
                top: yPos
            }}
            className={'popup__container'}
        >
            {list.map((item, idx) => {

                if (item.hide) return null;

                return (
                    <div key={`popup__block-${idx}`}>
                        <PopupBlock
                            item={item}
                            idx={idx}
                            onMouseEnter={(idx: number) => handleHover(idx)}
                            isHovered={isHovered}
                            onClickItem={onClickItem}
                        />
                    </div>
                )
            })}
        </div>
    );
};

export default PopupContainer;
