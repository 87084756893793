import { createTheme, Match, SingleEliminationBracket, SVGViewer } from '@g-loot/react-tournament-brackets';
import { useWindowSize } from "@uidotdev/usehooks";
import { FC, MouseEvent } from "react";
import { Participant } from "@g-loot/react-tournament-brackets/dist/src/types";

const WhiteTheme: any = createTheme({
    textColor: {main: '#FFFFFF', highlighted: '#FFFFFF', dark: '#FFFFFF'},
    matchBackground: {wonColor: '#3582F6', lostColor: '#FF5858'},
    score: {
        background: {wonColor: '#3582F6', lostColor: '#FF5858'},
        text: {highlightedWonColor: '#FFFFFF', highlightedLostColor: '#FFFFFF'},
    },
    border: {
        color: 'transparent',
        highlightedColor: '#ffffff0d',
    },
    roundHeader: {backgroundColor: '#da96c6', fontColor: '#FFFFFF'},
    connectorColor: '#4B4C50',
    connectorColorHighlight: '#3582F6',
    svgBackground: 'transparent',
});

interface CustomMatchBracketProps {
    list: MatchesItem[]
    onClickTop?: (item: any, event: MouseEvent<HTMLDivElement>, match?: any) => void
    onClickBottom?: (item: any, event: MouseEvent<HTMLDivElement>, match?: any) => void
    winner: any;
}

export interface MatchesItem {
    id: number | string;
    href?: string;
    name?: string;
    nextMatchId: number | string | null;
    nextLooserMatchId?: number | string;
    tournamentRoundText?: string;
    startTime: string;
    state: 'PLAYED' | 'NO_SHOW' | 'WALK_OVER' | 'NO_PARTY' | string;
    participants: Participant[];

    [key: string]: any;
}

export const CustomMatchBracket: FC<CustomMatchBracketProps> = (
    {
        list,
        onClickTop,
        onClickBottom,
        winner
    }
) => {

    const matches: any = list

    const sizes = () => {
        const size = useWindowSize();

        if (size.width && size.height) {
            const finalWidth = Math.max(size.width - 270, 500);
            const finalHeight = Math.max(size.height, 500);

            return {width: finalWidth, height: finalHeight}
        }

        return {width: 0, height: 0}
    };

    return (
        <SingleEliminationBracket
            matches={ matches }
            // matchComponent={ Match }
            theme={ WhiteTheme }
            options={ {
                style: {
                    width: 175,
                    boxHeight: 83,
                    canvasPadding: 50,
                    spaceBetweenRows: 0,
                    connectorColor: WhiteTheme.connectorColor,
                    connectorColorHighlight: WhiteTheme.connectorColorHighlight,
                    roundHeader: {
                        backgroundColor: WhiteTheme.roundHeader.backgroundColor,
                        fontColor: WhiteTheme.roundHeader.fontColor,
                        fontSize: 16,
                        marginBottom: 15,
                        roundTextGenerator: (currentRoundNumber: number, roundsTotalNumber: number) => `Тур ${currentRoundNumber}`
                    },
                    // roundSeparatorWidth: 0,
                    lineInfo: {
                        // separation: 0
                        homeVisitorSpread: 0
                    },
                    // horizontalOffset: 0
                },
            } }
            svgWrapper={ ({children, ...props}) => (
                <div className={'tournaments__grid__tour__container'}>
                    <SVGViewer
                        background={ WhiteTheme.svgBackground }
                        SVGBackground={ WhiteTheme.svgBackground }
                        width={ sizes().width }
                        height={ sizes().height }
                        detectWheel={false}
                        tool={'none'}
                        { ...props }
                    >
                        { children }
                    </SVGViewer>

                    {(winner && winner.id) && (
                        <div
                            // onMouseEnter={ () => onMouseEnter(winner.participants[0].id) }
                            onClick={(event) => onClickTop && onClickTop(winner.participants[0], event)}
                            className={'tournaments__grid__tour__container__winner'}
                        >
                            <div>{ winner.participants[0].title }</div>
                        </div>
                    )}
                </div>
            ) }
            matchComponent={ (
                {
                    match,
                    onMatchClick,
                    onPartyClick,
                    onMouseEnter,
                    onMouseLeave,
                    topParty,
                    bottomParty,
                    topWon,
                    bottomWon,
                    topHovered,
                    bottomHovered,
                    topText,
                    bottomText,
                    connectorColor,
                    computedStyles,
                    teamNameFallback,
                    resultFallback,
                }
            ) => {
                // console.log({
                //     match, //{id: 1, name: '', nextMatchId: 5, nextLooserMatchId: 3, tournamentRoundText: '1'}
                //     topParty,// {id: 'c016cb2a-fdd9-4c40-a81f-0cc6bdf4b9cc', resultText: '', isWinner: true, status: null, name: 'giacomo123'}
                //     bottomParty,// {id: '9ea9ce1a-4794-4553-856c-9a3620c0531b', resultText: '', isWinner: false, status: null, name: 'Ant'}
                //     topWon,// true
                //     bottomWon,// false
                //     topHovered,// false
                //     bottomHovered,// false
                //     topText,// ''
                //     bottomText,// ''
                //     connectorColor,// '#4B4C50'
                //     computedStyles, // {width: 175, boxHeight: 117, canvasPadding: 50, spaceBetweenColumns: 50, spaceBetweenRows: 0}
                // })

                return (
                    <div
                        className={'tournaments__grid__tour__block__team'}
                    >
                        <div
                            onMouseEnter={ () => onMouseEnter(topParty.id) }
                            onClick={(event) => {
                                onClickTop && onClickTop(topParty, event, match)
                            }}
                            style={ {
                                display: 'flex',
                                backgroundColor: topWon
                                    ? WhiteTheme.matchBackground.wonColor
                                    : (topParty.isWaiting
                                        ? 'rgb(52 53 57)'
                                        : WhiteTheme.matchBackground.lostColor)
                            } }
                            className={'tournaments__grid__tour__block__team-first'}
                        >
                            <div>{ topParty.title || teamNameFallback }</div>

                            <div>{ topParty.resultText ?? resultFallback(topParty) }</div>
                        </div>

                        <div
                            onMouseEnter={ () => onMouseEnter(bottomParty.id) }
                            onClick={(event) => onClickBottom && onClickBottom(bottomParty, event, match)}
                            style={ {
                                display: 'flex',
                                backgroundColor: bottomWon
                                    ? WhiteTheme.matchBackground.wonColor
                                    : (bottomParty.isWaiting
                                        ? 'rgb(52 53 57)'
                                        : WhiteTheme.matchBackground.lostColor)
                            } }
                            className={'tournaments__grid__tour__block__team-first tournaments__grid__tour__block__team-two'}
                        >
                            <div>{ bottomParty.title || teamNameFallback }</div>

                            <div>{ bottomParty.resultText ?? resultFallback(topParty) }</div>
                        </div>
                    </div>
                )
            } }
        />
    );
};
