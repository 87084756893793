export default function SmartShe({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M16.6663 10.8332V4.79067C16.6664 4.72489 16.6535 4.65975 16.6283 4.59897C16.6031 4.5382 16.5662 4.48299 16.5197 4.4365L13.8963 1.81317C13.8027 1.71938 13.6756 1.66662 13.543 1.6665H3.83301C3.7004 1.6665 3.57322 1.71918 3.47945 1.81295C3.38569 1.90672 3.33301 2.0339 3.33301 2.1665V17.8332C3.33301 17.9658 3.38569 18.093 3.47945 18.1867C3.57322 18.2805 3.7004 18.3332 3.83301 18.3332H11.6663"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2" d="M13.333 1.6665V4.49984C13.333 4.63245 13.3857 4.75962 13.4795 4.85339C13.5732 4.94716 13.7004 4.99984 13.833 4.99984H16.6663M13.333 15.8332H18.333M18.333 15.8332L15.833 13.3332M18.333 15.8332L15.833 18.3332"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>

    );
}


