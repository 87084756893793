import { FC } from "react";
import Warning from "../../img/icon/Warning";
import Pen from "../../img/icon/Pen";
import DateOperations from "../../mixins/date-operation";

interface LogItemProps{
    item: any
}
export const LogItem: FC <LogItemProps> = ({
    item
}) => {
    const dateOperations = new DateOperations();

    return (
        <div className='logs__item'>
            <div className='logs__item-header'>
                <div className='logs__item-header-left'>
                    {item.text && (
                        <div className='logs__item-header-desc'>
                            {/*{item.type === 0 && (*/}
                            {/*    <Warning*/}
                            {/*        color='#70D584'*/}
                            {/*        size={20}*/}
                            {/*    />*/}
                            {/*)}*/}

                            {/*{item.type === 1 && (*/}
                            {/*    <Pen*/}
                            {/*        color='#3582F6'*/}
                            {/*        width={20}*/}
                            {/*        height={20}*/}
                            {/*    />*/}
                            {/*)}*/}

                            <p style={{color : ('#3582F6')}}>
                                {item.text}
                            </p>
                        </div>
                    )}

                    <div className='logs__item-header-desc logs__item-header-date'>
                        <p> {dateOperations.getDate(item.date)}</p>

                        <p>{dateOperations.getISOTimeByDateObj(new Date(item.date))}</p>
                    </div>

                    {item.device && (
                        <div className='logs__item-header-desc'>
                            {item.device}
                        </div>
                    )}
                </div>

                <p className='logs__item-header-right'>{item.user}</p>
            </div>

            <div className='logs__item-content'>
                {item.desc}
            </div>
        </div>
    )
}
