import { FC, useEffect, useRef, useState } from 'react';
import PlusBorder from "../../img/icon/PlusBorder";
import Arrow from "../../img/icon/Arrow";
import Calendar from "../../img/icon/Calendar";

interface PopupProps {
    item: any
    idx: number
    onMouseEnter?: (idx: number) => void
    isHovered?: number | null
    onClickItem: (item: any) => void
}

const PopupBlock: FC<PopupProps> = (
    {
        item,
        idx,
        onMouseEnter,
        isHovered,
        onClickItem
    }
) => {
    return (
        <div
            className={`popup__block${(isHovered === idx) ? ' hovered' : ''}${(item.disabled) ? ' disabled' : ''}`}
            style={{
                color: item.color,
                borderTop: item.line ? '1px solid rgba(255, 255, 255, 0.15)' : 'none'
            }}
            onMouseEnter={() => {
                if (!item.content) return;

                onMouseEnter && onMouseEnter(idx)
            }}
            onClick={() => onClickItem(item)}
        >
            <div className="popup__block-icon">
                { item.icon }
            </div>

            <div className="popup__block-name">
                { item.name }
            </div>

            {item.content && (
                <>
                    <div className="popup__block-right-icon">
                        <Arrow/>
                    </div>

                    <div className="popup__container popup__block-right">
                        {!item.disabled && item.content.map((_item: any, _idx: number) => {

                            if (_item.hide) return null;

                            return (
                                <PopupBlock
                                    key={`popup-block-1-${_idx}`}
                                    item={_item}
                                    idx={_idx}
                                    onClickItem={onClickItem}
                                />
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default PopupBlock;
