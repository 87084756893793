import { FC, useEffect, useState, } from 'react';
import Button from "../../components/form/Button";
import {useNavigate} from "react-router-dom";
import { $getListApp } from "../../api/requests/app";
import RoleUsers from "../../mixins/role";

const ProgramPage: FC = () => {
    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('apps', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('apps', 'update');

    const [isProgram, setIsProgram] = useState(false);

    const [list, setList] = useState<any[]>([]);
    const [gameList, setGameList] = useState<any[]>([]);
    const [programList, setProgramList] = useState<any[]>([]);

    const navigate = useNavigate();

    useEffect(() => init(), [])
    useEffect(() => {
        if (gameList.length || programList.length) {
            if (isProgram) setList(programList)
            else setList(gameList)
        }
    }, [isProgram])

    function init() {
        $getListApp().then(res => {
            if (!res) return;

            setList(res)
            setGameList(res)
        })
    }

    function onClickChangePage(isProgram: boolean) {
        setIsProgram(isProgram)
    }

    function onClickAdd() {
        navigate(isProgram ? 'addPrograms' : 'AddGame');
    }

    function onClickEdit(item: any) {
        if (!isUpdateByRole) return;

        navigate(isProgram ? 'addPrograms' : 'AddGame', {
            state: {
                current: 'edit',
                data: item
            },
        });
    }

    return (
        <div className="program__block__item">
            <div className="booking__header">
                <h1>Игры</h1>
            </div>
            <div className="program__block__item__header">
                <Button
                    className={`btn btn-link ${isProgram ? '' : 'active'}`}
                    text={'Игры'}
                    onClick={() => onClickChangePage(false)}
                />

                {/*<Button*/}
                {/*    className={`btn btn-link ml-0 ${isProgram ? 'active' : ''}`}*/}
                {/*    text={'Программы'}*/}
                {/*    onClick={() => onClickChangePage(true)}*/}
                {/*/>*/}

                {isCreateByRole && (
                    <div className="program__block__item__header-last">
                        <Button
                            className={'btn btn-primary ml-auto justify-content-center'}
                            text={`Добавить ${isProgram ? 'программу' : 'игру'}`}
                            onClick={onClickAdd}
                        />
                    </div>
                )}
            </div>

            {!list.length && (
                <div className="program__block__item__appellation">
                    <p>
                       Список игр пуст
                    </p>
                </div>
            )}

            <div className={'program__block__item__list'}>
                {!!list.length && list.map((item, idx) => (
                    <div
                        className="program__block__item__list__image"
                        key={`program__block__item__list-image-${idx}`}
                    >
                        <div
                            className="program__block__item__list__image__btn"
                            onClick={() => onClickEdit(item)}
                        >
                            <img src={item.image || 'https://hb.bizmrg.com/ss-static/3360/noNUd0EI6FWT%D1%81%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202023-07-10%20%D0%B2%2020.37.18.png'} />

                            <span>{item.name}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProgramPage;
