import { FC } from "react";
import FileField from "./FileField";

interface ImageFieldProps {
    upload: File | string | null
    setUpload: (file: string) => void
    label?: string
}

const ImageField: FC<ImageFieldProps> = (
    {
        upload,
        setUpload,
        label
    }
) => {

    const handleImageUpload = (file: File) => {
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === 'string') {
                    setUpload(reader.result);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <FileField
            label={ label }
            upload={ upload }
            setUpload={ handleImageUpload }
        />
    )
}

export default ImageField;
