import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { userInitialStateType } from "../../types/user";
import { getCurrentUser, login } from "../actions/userActions";
import { RootState } from "../store";

const initialState: userInitialStateType = {
    loading: false,
    data: null,
    error: null,
    success: false,
    dataCenter: null,
    reloadPage: false
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: (state) => {
            state.loading = false;
            state.data = null;
            state.error = null;
            state.success = false;
        },
        changeUser: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        changeCenter: (state, action: PayloadAction<any>) => {
            state.dataCenter = action.payload;
        },
        changeReloadPage: (state, action: PayloadAction<any>) => {
            state.reloadPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        })
        builder.addCase(getCurrentUser.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
        builder.addCase(getCurrentUser.rejected, (state, action) => {
            state.loading = false
            state.data = null;
            state.error = action.payload
        })
        builder.addCase(login.pending, (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
            state.success = false;
        })
        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
            state.success = true;
        })
        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.data = null;
            state.error = action.payload;
            state.success = false;
        })
    },
});
export const { changeUser, changeCenter, changeReloadPage, resetUser } = userSlice.actions;

export const GetCurrentUserData = (state: RootState) => state.currentUser.data;
export const GetCurrentCenterData = (state: RootState) => state.currentUser.dataCenter;
export const GetCurrentReloadPageData = (state: RootState) => state.currentUser.reloadPage;

export default userSlice.reducer;
