import React, { FC, } from "react";
import Button from "../form/Button";
import { useNavigate } from "react-router-dom";

const PersonBtn: FC = () => {
    const navigate = useNavigate();

    const handleChange = () => {
        navigate("/person/create");
    }

    return (
        <div className="person_btn__item">
            <Button
                text={ 'Добавить сотрудника' }
                className={ 'btn btn-primary' }
                onClick={ handleChange }
            />
        </div>
    );
}

export default PersonBtn;
