export default function Telegram({color= "white", width = 25, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 25 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector"
                  d="M9.91718 13.1814L9.52018 18.7654C10.0882 18.7654 10.3342 18.5214 10.6292 18.2284L13.2922 15.6834L18.8102 19.7244C19.8222 20.2884 20.5352 19.9914 20.8082 18.7934L24.4302 1.82141L24.4312 1.82041C24.7522 0.324405 23.8902 -0.260595 22.9042 0.106406L1.61418 8.25741C0.161178 8.82141 0.183178 9.63141 1.36718 9.99841L6.81018 11.6914L19.4532 3.78041C20.0482 3.38641 20.5892 3.60441 20.1442 3.99841L9.91718 13.1814Z"
                  fill={color}
            />
        </svg>
    );
}
