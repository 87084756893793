import { FC } from "react";
import Button from "../../components/form/Button";
import Trash from "../../img/icon/Trash";

interface DeleteDisciplineProps {
    onDelete?: () => void,
}

const DeleteDiscipline: FC<DeleteDisciplineProps> = (
    {
        onDelete
    }

) => {


    return (
        <Button
            text={ <Trash/> }
            className={ 'btn' }
            onClick={onDelete}
        />
    )

}

export default DeleteDiscipline;

