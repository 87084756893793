export default function TimerSecond({color = "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M7.83255 1.66797H12.8326M4.49922 5.83464L16.1659 17.918M10.3326 8.33464V11.668M5.49088 7.08464C4.31547 8.32756 3.66283 9.97477 3.668 11.6855C3.67317 13.3961 4.33576 15.0394 5.51867 16.2752C6.70158 17.511 8.31429 18.2448 10.0231 18.3247C11.7319 18.4046 13.4061 17.8246 14.6992 16.7046M16.5159 14.168C17.0018 12.9688 17.1282 11.6541 16.8798 10.3843C16.6314 9.11449 16.0188 7.94441 15.1168 7.01677C14.2148 6.08913 13.0623 5.44402 11.7999 5.16013C10.5376 4.87624 9.21987 4.96583 8.00755 5.41797"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>

    );
}
