import { FC, useEffect, useState } from "react";
import TableContainer from "../../components/form/TableContainer";
import EditRatingDialog from "../../components/reports/EditRatingDialog";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useNavigate } from "react-router-dom";
import FilterSelect from "../../components/FilterSelect";
import { withDebounce } from "../../functions";
import { $getSearchCity } from "../../api/requests/reports";
import { $getStatisticCity } from "../../api/requests/rating-city";

interface FilterSelectByCityProps {
    placeholder: string
    setSelectItem: (value: any) => void
    selectItem?: any
}

const FilterSelectByCity: FC<FilterSelectByCityProps> = (
    {
        placeholder,
        setSelectItem,
        selectItem
    }
) => {
    const [ cityList, setCityList ] = useState<any[]>([]);

    useEffect(() => init(), []);

    function init() {
        onChangeSearchCityList();
    }

    function onChangeCityList(item: any) {
        setSelectItem(item)
    }

    function onChangeCitySearch(value: string) {
        if (!value) return;

        withDebounce(() => onChangeSearchCityList(value))
    }

    function onChangeSearchCityList(search: string = '') {
        $getSearchCity({ search }).then(res => {
            if (!res || !res.length) return;

            setCityList(res.map(i => ({
                ...i,
                label: i.name,
                value: i.id
            })))
        })
    }

    return (
        <FilterSelect
            value={ selectItem }
            placeholder={placeholder}
            options={ cityList }
            onChange={ onChangeCityList }
            onChangeInput={ onChangeCitySearch }
            className={`reports__filter-selection searchable`}
            isSearchable
            isClearable
        />
    )
}

export default FilterSelectByCity;
