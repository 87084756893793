export default function Mail({color = "white", colorSecond= "#3665CB", width = 25, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 25 20`} fill="none">
            <path
                d="M1.33789 16.1396V3.86049C1.33789 2.62748 2.33744 1.62793 3.57045 1.62793H21.4309C22.6639 1.62793 23.6635 2.62748 23.6635 3.86049V16.1396C23.6635 17.3726 22.6639 18.3721 21.4309 18.3721H3.57045C2.33744 18.3721 1.33789 17.3726 1.33789 16.1396Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.56977 2.46543C2.79914 2.46543 2.17442 3.09015 2.17442 3.86078V16.1399C2.17442 16.9105 2.79914 17.5352 3.56977 17.5352H21.4302C22.2009 17.5352 22.8256 16.9105 22.8256 16.1399V3.86078C22.8256 3.09015 22.2009 2.46543 21.4302 2.46543H3.56977ZM0.5 3.86078C0.5 2.1654 1.87438 0.791016 3.56977 0.791016H21.4302C23.1256 0.791016 24.5 2.1654 24.5 3.86078V16.1399C24.5 17.8352 23.1256 19.2096 21.4302 19.2096H3.56977C1.87438 19.2096 0.5 17.8352 0.5 16.1399V3.86078Z"
                fill={color}
            />
            <path
                filRrule="evenodd"
                clipRule="evenodd"
                d="M3.06317 6.41213C3.22708 6.06203 3.65892 5.90436 4.02773 6.05996L12.4011 9.59269C12.4641 9.61926 12.5359 9.61926 12.5989 9.59269L20.9723 6.05996C21.3411 5.90436 21.7729 6.06203 21.9368 6.41213C22.1007 6.76222 21.9347 7.17217 21.5659 7.32777L13.1925 10.8605C12.7516 11.0465 12.2484 11.0465 11.8075 10.8605L3.43415 7.32777C3.06535 7.17217 2.89925 6.76222 3.06317 6.41213Z"
                fill={colorSecond}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.56556 10.4234C9.48714 10.3883 9.3952 10.3958 9.32403 10.443L4.1464 13.8756C3.81443 14.0957 3.35925 14.016 3.12974 13.6977C2.90022 13.3793 2.98328 12.9429 3.31526 12.7228L8.49288 9.29014C8.99113 8.95982 9.63472 8.90777 10.1836 9.15341L12.397 10.1439C12.4623 10.1731 12.5377 10.1731 12.603 10.1439L14.8164 9.15341C15.3653 8.90777 16.0089 8.95982 16.5071 9.29014L21.6847 12.7228C22.0167 12.9429 22.0998 13.3793 21.8703 13.6977C21.6408 14.016 21.1856 14.0957 20.8536 13.8756L15.676 10.443C15.6048 10.3958 15.5129 10.3883 15.4344 10.4234L13.2211 11.4139C12.764 11.6185 12.236 11.6185 11.7789 11.4139L9.56556 10.4234Z"
                fill={colorSecond}
            />
        </svg>
    );
}
