
export default function ArrowLeft({ color = "white", width = 20, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0  20 20`} fill="none">
            <path d="M17.5 10.0003H2.5M2.5 10.0003L9.58333 2.91699M2.5 10.0003L9.58333 17.0837"
                  stroke={color}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
        </svg>
    );
}
