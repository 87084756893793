import { IApiHandlerParams } from "../../interface";
import api from "../../../api";
import { CreateLogModel, PageUsers } from "./interface";

export const $getLogsList = (params?: PageUsers, handler?: IApiHandlerParams): Promise<CreateLogModel> => api
    .get('/log', {
        request: {params},
        handler
    })

