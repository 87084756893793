import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import {AuthSignBodyData, AuthSignData, AuthSmsCodeData} from "./interface";

export const $authSign = (data: AuthSignData, handler?: IApiHandlerParams): Promise<AuthSignBodyData> => api
  .post('/auth/sign-in', data,{
    // mock: require('./mocks/complete.form.calculate.json'),
    handler
  })

export const $authGetSms = (data: AuthSmsCodeData, handler?: IApiHandlerParams): Promise<any> => api
  .post('/auth/send-sms', data,{
    // mock: require('./mocks/complete.form.calculate.json'),
    handler
  })
