import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import {
    CenterTournamentsData,
    CenterTournamentsDataModel,
    CenterTournamentsRequestData,
    CenterTournamentsListResponseData,
    DashboardTournamentCreate,
    CenterTournamentsRequestListData,
    TournamentRequestModel,
    DashboardTournamentUpdateTeamStatus,
    TournamentRoundSubModel,
    DashboardTournamentSetTeamStatus,
    DashboardTournamentStart, DashboardTournamentUpdateRequestStatus, prizeSeatsPost
} from "./interface";

export const $getTournamentsList = (params?: CenterTournamentsData, handler?: IApiHandlerParams): Promise<CenterTournamentsListResponseData> => api
    .get('/tournament', {
        request: {params},
        handler
    });

export const $getTournamentsGridList = (id: string, handler?: IApiHandlerParams): Promise<CenterTournamentsDataModel> => api
    .get('/tournament/grid/' + id, {
        handler
    });

export const $createTournaments = (data: DashboardTournamentCreate, handler?: IApiHandlerParams): Promise<CenterTournamentsDataModel> => api
    .post('/tournament/single', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $getTournamentsListById = (id: string, handler?: IApiHandlerParams): Promise<CenterTournamentsDataModel> => api
    .get('/tournament/single/' + id, {
        handler
    });

export const $updateTournaments = (id: string, data: DashboardTournamentCreate, handler?: IApiHandlerParams): Promise<CenterTournamentsDataModel> => api
    .put('/tournament/single/' + id, data, {
        handler
    });

export const $deleteTournaments = (id: string, handler?: IApiHandlerParams): Promise<CenterTournamentsDataModel> => api
    .delete('/tournament/single/' + id, {
        handler
    });

export const $getTournamentsRequestList = (params?: CenterTournamentsRequestData, handler?: IApiHandlerParams): Promise<CenterTournamentsRequestListData> => api
    .get('/tournament-request', {
        request: {params},
        handler
    });

export const $updateStatusRequestTournaments = (id: string, data: DashboardTournamentUpdateRequestStatus, handler?: IApiHandlerParams): Promise<TournamentRequestModel> => api
    .put('/tournament-request/single/' + id, data, {
        handler
    });

export const $setTeamTournament = (data: DashboardTournamentSetTeamStatus, handler?: IApiHandlerParams): Promise<TournamentRoundSubModel> => api
    .post('/tournament/team/set', data,{
        handler
    });

export const $updateTeamTournament = (data: DashboardTournamentUpdateTeamStatus, handler?: IApiHandlerParams): Promise<TournamentRoundSubModel> => api
    .post('/tournament/team/update', data,{
        handler
    });

export const $startTournament = (data: DashboardTournamentStart, handler?: IApiHandlerParams): Promise<CenterTournamentsDataModel> => api
    .post('/tournament/start', data,{
        handler
    });

export const $stopTournament = (data: DashboardTournamentStart, handler?: IApiHandlerParams): Promise<CenterTournamentsDataModel> => api
    .post('/tournament/stop', data,{
        handler
    });


export const $getListAwards = (id: string, data: any, handler?: IApiHandlerParams): Promise<any> => api
    .post('/tournament/awards/' + id, data,{
        handler
    });

export const $getListPrize = (id: string, handler?: IApiHandlerParams): Promise<any> => api
    .get('/tournament/prizeSeats/' + id,{
        handler
    });

export const $updateListPrize = (data: prizeSeatsPost, handler?: IApiHandlerParams): Promise<any> => api
    .post('/tournament/prizeSeats/set', data,{
        handler
    });
