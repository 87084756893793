import axios, {AxiosResponse} from "axios";
import {
    IApiHandlerParams,
    IApiMethods,
    IRequestConfig,
    TApiResponse,
    TRequestMethods,
    TRequestParams,
} from "./interface";
import {
    activateLoading,
    getFullConfig,
    getRequestUrl,
    handleErrorResponse,
    hideLoading,
    requestHandler
} from "./handler";
import "./requester-setup";


const handleRequest = (v: Promise<AxiosResponse<TApiResponse>>, config?: IApiHandlerParams): Promise<TApiResponse> =>
    new Promise<TApiResponse>(resolve =>
        setTimeout(() => resolve(v
                .then(r => requestHandler(r, config))
                .catch(r => handleErrorResponse(config, r))
                .finally(() => hideLoading(config))),
            (config && config.delay) || 0
        )
    );

const getAxiosResponseWithMockData = async (data: TApiResponse): Promise<{
    headers: {};
    data: any;
    statusText: string;
    config: {};
    status: number
}> => ({
    data,
    status: 200,
    statusText: 'OK',
    headers: {},
    config: {},
});

const apiMethods: IApiMethods = {
    get(endpoint: string, config?: IRequestConfig): Promise<TApiResponse> {
        return this.factory("get", endpoint, config)();
    },

    post(
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory("post", endpoint, config, body)();
    },

    put(
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory("put", endpoint, config, body)();
    },

    patch(
        endpoint: string,
        body?: any,
        config?: IRequestConfig
    ): Promise<TApiResponse> {
        return this.factory("patch", endpoint, config, body)();
    },

    delete(endpoint: string, config?: IRequestConfig): Promise<TApiResponse> {
        return this.factory("delete", endpoint, config)();
    },

    factory(
        method: TRequestMethods,
        endpoint: string,
        config?: IRequestConfig,
        body?: any
    ): () => Promise<TApiResponse> {
        const params: any[] = [getRequestUrl(config) + endpoint];

        body && params.push(body);

        params.push(config?.request);

        return () => this.request(method, params, config?.handler as any, config?.mock);
    },

    // @ts-ignore
    request(
        method: TRequestMethods,
        params: any[],
        config?: IApiHandlerParams,
        mock?: any
    ): Promise<TApiResponse> {
        activateLoading(config);

        if (mock) {
            console.table({
                MOCK_REQUEST: {
                    URL: params[0],
                    REQUEST: params[1],
                    RESPONSE: mock,
                    METHOD: method,
                },
            });
        }

        //@ts-ignore
        return handleRequest(mock
                ? getAxiosResponseWithMockData(mock)
                : axios[method].apply(axios, params as TRequestParams) as Promise<AxiosResponse<TApiResponse>>,
            getFullConfig(config)
        );
    },
};

export default apiMethods;
