import React, { FC } from "react";
import Button from "../form/Button";
import Trash from "../../img/icon/Trash";
import { useNavigate } from "react-router-dom";
import Coins from "../../img/icon/Coins";
import image from '../../img/add-media-image (1) 1 (1).svg'
import Pen from "../../img/icon/Pen";

interface MobileAchievementsItemsProps {
    itemInfo: any
    onDelete: () => void
    isShowCounts?: boolean
    isShowEditBtn?: boolean
}

type DeepObject = Record<string, unknown>;

export const MobileAchievementsItems: FC<MobileAchievementsItemsProps> = (
    {
        itemInfo,
        onDelete,
        isShowCounts = true,
        isShowEditBtn
    }
) => {
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate('edit', {
            state: {
                current: 'edit',
                data: removeNestedObjects(itemInfo)
            },
        })
    }

    function removeNestedObjects(input: DeepObject): DeepObject {
        const result: DeepObject = {};

        for (const key in input) {
            if (input.hasOwnProperty(key) && typeof input[key] !== 'object') {
                result[key] = input[key];
            }
        }

        return result;
    }

    return (
        <div className='achievements__item'>
            <div className='achievements__item-content'>
                {/*<p className='achievements__item-desc'>*/}
                {/*    { itemInfo.description }*/}
                {/*</p>*/}

                <div className={'achievements__item-img'}>
                    <img src={ itemInfo.image ||  image} className='achievements__item-img'/>
                    { isShowCounts && (
                        <div className='achievements__item-point'>
                            <Coins color='#F9D448'/>

                            { itemInfo.text }
                        </div>
                    ) }
                </div>

                <div className='achievements__item-header'>
                    <h1 className='achievements__title achievements__item-title'>
                        { itemInfo.title }
                    </h1>
                </div>

                { itemInfo.subText && (
                    <div className={'mt-3 achievements__item-subtitle'}>
                        <p>{itemInfo.subText}</p>
                    </div>
                ) }

                {isShowEditBtn && (
                    <div className='achievements__item-btns'>
                        <Button
                            className={ 'btn btn-primary' }
                            text={ <Pen /> }
                            onClick={ handleEdit }
                        />

                        <div className='achievements__item-delete' onClick={ onDelete }>
                            <Trash/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
