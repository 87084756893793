import { FC, useEffect, useState } from "react";
import Pen from "../../img/icon/Pen";
import PersonInfo from "./PersonInfo";
import Button from "../form/Button";
import { useNavigate } from "react-router-dom";
import { UserModelLink } from "../../api/requests/staff/interface";
import RoleUsers from "../../mixins/role";
import DateOperations from "../../mixins/date-operation";
import { useAppDispatch } from "../../redux/store";
import { savePersonData } from "../../redux/slices/personSlice";
import Token from "../../img/icon/Token";

interface PersonItemsProps {
    personInfo: UserModelLink
    isShowEditPerson: boolean
}

const PersonItems: FC<PersonItemsProps> = (
    {
        personInfo,
        isShowEditPerson
    }
) => {

    const navigate = useNavigate();
    const roleUsers = new RoleUsers();
    const dateOperation = new DateOperations();
    const dispatch = useAppDispatch();

    const [listPerson, setListPerson] = useState([
        {
            key: 'status',
            title: 'Статус',
            text: '',
            color: '#70D584',
        },
        {
            key: 'email',
            title: 'E-mail',
            text: '',
            color: '',
        },
        {
            key: 'phone',
            title: 'Телефон',
            text: '',
            color: '',
        },
        {
            key: 'createdAt',
            title: 'Дата создания',
            text: '',
            color: '',
        }
    ]);

    useEffect(() => init(), []);

    function init() {
        if (personInfo) {
            setListPerson((list) => {
                return list.map(i => getChangeItemPerson(i))
            })
        }
    }

    function getChangeItemPerson (item: any) {
        if (item.key === 'status') return {
            ...item,
            text: personInfo.status && roleUsers.getNameStatusUser(personInfo.status) || '-'
        }

        if (item.key === 'phone') return {
            ...item,
            text: personInfo.phone && `+${personInfo.phone}` || '-'
        }

        if (item.key === 'createdAt') return {
            ...item,
            text: personInfo.createdAt && dateOperation.getFormattedDateByISO(personInfo.createdAt.slice(0, 10)) || '-'
        }

        if (item.key === 'email') return {
            ...item,
            text: personInfo.email || '-'
        }
    }

    function onClickEdit() {
        dispatch(savePersonData(personInfo))

        navigate(`/person/edit/${personInfo.id}`);
    }

    return (
        <div className="person__items__block">
            <div className="person__items__block__item">
                <div className="person__items__img">
                    <img src={personInfo.image || '/img/logo/logo-2.png'} alt=""/>
                </div>

                <div className="person__items__block__name">
                    <div className="person__items__block__elem">
                        <p>{ `${personInfo.surname} ${personInfo.name} ${personInfo.lastName}` }</p>

                        {isShowEditPerson && (
                            <Button
                                className="btn"
                                text={ <Pen
                                    color={ '#3582F6' }
                                /> }
                                onClick={onClickEdit}
                            />
                        )}
                    </div>

                    <div className="person__items__block__manager">
                        <span>{ roleUsers.getNameRoleUser(personInfo.role) }</span>
                    </div>
                </div>
            </div>

            <div className="person__items__block__section">
                { listPerson.map(({title, text, color}, idx) => (
                    <div className="col-6 person__items__block__fix"
                         key={`person-item-block-${idx}`}
                    >
                        <div className="person__items__block__body">
                            <PersonInfo
                                title={ title }
                                text={ text }
                                color={ color }
                            />
                        </div>
                    </div>
                )) }
            </div>
        </div>
    );
}

export default PersonItems;
