import { FC, useEffect, useState } from "react";
import ArrowLeft from "../../img/icon/ArrowLeft";
import Button from "../../components/form/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../../img/icon/Info";
import TextField from "../../components/form/TextField";
import { ToggleBlock } from "../../components/form/ToggleBlock";
import TransformObjArray from "../../mixins/transform-obj-array";
import FilterSelect from "../../components/FilterSelect";
import ImageField from "../../components/form/ImageField";
import { $avatarsCreate, $updateAvatars } from "../../api/requests/avatars";
import ImageToBase64 from "../../mixins/image-to-base64";
import FormGroupItem from "../../components/form/FormGroupItem";

const AvatarsAddPage: FC = () => {
    const isMobile = window.innerWidth < 465
    const location = useLocation();
    const navigate = useNavigate();

    const imageToBase64 = new ImageToBase64();

    const state: any = location.state;
    const isEditPageDiscipline = state?.current === 'edit';

    const editedData = state?.data || {};

    const transformObjArray = new TransformObjArray();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ image, setImage ] = useState<any>(null);

    const [ list, setList ] = useState<any[]>([
        {
            key: 'title',
            placeholder: 'Название',
            value: '1',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            field: true,
            required: true,
        },
        {
            key: 'type',
            placeholder: 'Категория',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            list: [
                {value: 0, label: 'Фиджитал'},
                {value: 1, label: 'Спорт'},
                {value: 2, label: 'Киберспорт'}
            ],
            select: true,
            required: true,
        },
        {
            key: 'gender',
            placeholder: 'Пол',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            list: [
                {value: 1, label: 'Мужской'},
                {value: 2, label: 'Женский'}
            ],
            select: true,
            required: true,
        },
        {
            key: 'isActive',
            title: 'Статус',
            value: true,
            setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
            checkbox: true,
            required: false,
        }
    ]);

    useEffect(() => init(), []);

    function init() {
        const listUpdate = [ ...list ].map(i => {
            if (editedData && Object.keys(editedData).includes(i.key)) {
                i.value = editedData[i.key];

                if (i.list) i.value = i.list.find((_i: any) => _i.value === editedData[i.key])
            }

            return i;
        });

        setImage(editedData.image);
        setList(listUpdate);
    }

    function onChangeSetValue(value: string | boolean, key: string) {
        const listUpdates = [ ...list ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setList(listUpdates)
    }

    function onClickBack() {
        navigate('/avatars')
    }

    function onClickEnter() {
        const requestData = {
            ...transformObjArray.getRequestObj(list),
            image: imageToBase64.getImageCheckValid(image)
        };

        if (transformObjArray.formValidateByRequired(requestData, list))
            return console.log(transformObjArray.formValidateByRequired(requestData, list));

        setIsLoading(true);

        if (isEditPageDiscipline) return onEditData(requestData);

        onCreateSave(requestData);
    }

    function onCreateSave(request: any) {
        $avatarsCreate(request).then(res => {
            setIsLoading(false);

            if (!res) return;
            onClickBack();
        })
    }

    function onEditData(request: any) {
        if (!editedData.id) return setIsLoading(false);

        $updateAvatars(editedData.id, request).then(res => {
            setIsLoading(false);

            if (!res) return;
            onClickBack();
        })
    }

    return (
        <div className="discipline__content">
            <div className="discipline__content__back">
                <Button
                    className={ 'btn' }
                    leftIcon={ (
                        <ArrowLeft color={ '#3582F6' }/>
                    ) }
                    text={!isMobile &&  'Назад' }
                    onClick={ onClickBack }
                />

                {isMobile && (
                    <h3>{ isEditPageDiscipline ? 'Редактирование' : 'Создание' } аватара</h3>
                )}
            </div>

            <div className="discipline__content__form">
                <div className="discipline__content__form__left">
                    {!isMobile && (
                        <h3>{ isEditPageDiscipline ? 'Редактирование' : 'Создание' } аватара</h3>
                    )}

                    { list.map((item, idx) => (
                        <div className="discipline__content__form__left__block"
                             key={ `discipline__content__form__left__block${ idx }` }
                        >
                            <FormGroupItem
                                item={item}
                            />
                        </div>
                    )) }

                    <div className="discipline__content__form__left__bottom">
                        <Button
                            loading={ isLoading }
                            className={ 'btn btn-primary' }
                            text={ 'Сохранить' }
                            onClick={ onClickEnter }
                        />
                    </div>
                </div>

                <div className="discipline__content__form__right">
                    <div className="discipline__content__form__right__text">
                        <h3>Обложка 540х540</h3>

                        {/*<Info*/}
                        {/*    size={ 16 }*/}
                        {/*    color={ '#3582F6' }*/}
                        {/*/>*/}
                    </div>

                    <div className="discipline__content__form__right__file">
                        <ImageField
                            label={ 'Картинка' }
                            upload={ image }
                            setUpload={ setImage }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AvatarsAddPage;

