import {useEffect, useRef, useState} from "react";
import ScrollContainer from "./ScrollContainer";
import PopupDialog from "../dialog/PopupDialog";
import BookingPopup from "../booking/BookingPopup";
import DateOperations from "../../mixins/date-operation";
import Token from "../../img/icon/Token";

export interface Props {
    dateWork: WorkDate
    list: ListData[]
    onClickPropsAdd: (item: ListData, isEdit?: boolean) => void
    selectDate: Date
}

export interface WorkDate {
    start: string
    end: string
}

export interface ListData {
    name: string
    items: any[]
}

const CalendarBlock = (
    {
        dateWork,
        list,
        onClickPropsAdd,
        selectDate
    }: Props) => {

    const dateOperation = new DateOperations();

    const [dateWorkList, setDateWorkList] = useState<string[]>([]);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [isHovered, setIsHovered] = useState(false);
    const [eventPopup, setEventPopup] = useState<MouseEvent | null>(null);

    const time = useRef(null);
    const blockAdd = useRef(null);

    const timeToHour = (time: string) => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return hours
    };

    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [fontSizeLocal, setFontSizeLocal] = useState(16);

    const [listPopup, setListPopup] = useState({
        title: 'Забронированно'
    })

    const getDateByFormat = (date: Date): string => {
        return date.toISOString().split('T')[0]
    }

    const getIsShowCurrentTime = (): boolean => {
        return (currentTime === 0) ? (getDateByFormat(selectDate) !== getDateByFormat(new Date())) : false;
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (screenWidth >= 1800) {
            const pixelsToAdd = Math.floor((screenWidth - 1800) / 100);
            setFontSizeLocal(16 + pixelsToAdd);
        } else {
            setFontSizeLocal(16);
        }
    }, [screenWidth]);


    useEffect(() => {
        const dateStart = timeToHour(dateWork.start);
        const dateEnd = timeToHour(dateWork.end);

        const listTime: string[] = []

        for (let i = dateStart; i <= dateEnd; i++) {
            if (i < 10) listTime.push(`0${i}:00`, `0${i}:30`)
            else if (i === dateEnd) listTime.push(`${i}:00`)
            else listTime.push(`${i}:00`, `${i}:30`)
        }

        setDateWorkList(listTime);
    }, []);

    useEffect(() => {
        if (dateWorkList.length) {
            const updateCurrentTime = () => {
                const currentDate = new Date(); // '2024-05-18T05:00:00.000Z'
                const hours = String(currentDate.getHours()).padStart(2, '0');
                const minutes = String(currentDate.getMinutes()).padStart(2, '0');
                const newTime = `${hours}:${minutes}:00`;

                if (+hours < 5) return setCurrentTime(time.current && (time.current as any).clientWidth || 0);

                if (+hours >= 5 && +hours < 10) return setCurrentTime(0);

                setCurrentTime(getDateStart(newTime));
            };

            updateCurrentTime();

            const intervalId = setInterval(updateCurrentTime, 60000);

            return () => clearInterval(intervalId);
        }
    }, [dateWorkList, list]);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            const container = blockAdd.current;

            if (container) {
                const containerRect = (container as HTMLElement).getBoundingClientRect();
                const newX = e.clientX - containerRect.left - 50;
                const newY = e.clientY - containerRect.top - 50;

                setPosition({
                    x: Math.max(0, Math.min(newX, containerRect.width - 24 - 73)),
                    y: Math.max(0, Math.min(newY, containerRect.height - 24 - 73)),
                });
            }
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        if (getDateByFormat(selectDate) !== getDateByFormat(new Date())) setCurrentTime(0);
    }, [list])

    function getDateStart(startTime: string): number {
        const dateTime = startTime.slice(0, 5);
        const dateFindIdx = dateWorkList.indexOf(dateTime);

        const containerWidth = time.current && (time.current as any).offsetWidth || 0;
        const currentPaddingPx = 36 / 16 * fontSizeLocal

        const positionInPixels = getPositionOfTime(dateWorkList, (containerWidth - currentPaddingPx), dateFindIdx);

        if (dateFindIdx !== -1) {
            // Если время найдено в списке, вычисляем позицию без добавления недостающих минут
            return positionInPixels;
        } else {
            // Время не найдено в списке, найдем ближайший временной блок
            const closeTimeObj = findClosestTime(dateTime, dateWorkList)

            const closestTime = closeTimeObj.closestTime;
            const closestTimeIdx = dateWorkList.indexOf(closestTime);

            const currentPaddingPx = 36 / 16 * fontSizeLocal

            // Вычисляем позицию для ближайшего временного блока
            const closestPosition = getPositionOfTime(dateWorkList, containerWidth - currentPaddingPx, closestTimeIdx);

            // Вычисляем количество минут между ближайшим временным блоком и startTime
            const minutesDiff = calculateMinutesDifference(closestTime, startTime);

            // Добавляем нехватающие минуты к позиции ближайшего временного блока
            return closeTimeObj.isCurrentMinLong ? (
                closestPosition - (containerWidth / (dateWorkList.length - 1)) * (minutesDiff / 30)
            ) : (
                closestPosition + (containerWidth / (dateWorkList.length - 1)) * (minutesDiff / 30)
            );
        }
    }

    // Находит ближайшее время в списке к указанному времени
    function findClosestTime(targetTime: string, timeList: string[]): any {
        let closestTime = timeList[0];
        let minTimeDifference = Math.abs(timeToMinutes(targetTime) - timeToMinutes(closestTime));

        for (let i = 1; i < timeList.length; i++) {
            const currentTime = timeList[i];
            const timeDifference = Math.abs(timeToMinutes(targetTime) - timeToMinutes(currentTime));

            if (timeDifference < minTimeDifference) {
                closestTime = currentTime;
                minTimeDifference = timeDifference;
            }
        }

        const closeMin = closestTime && closestTime.slice(-2)
        const targetMin = targetTime && targetTime.slice(-2)

        const isCurrentMinLong = (+closeMin > +targetMin)
            || ((closeMin === '00') && +targetMin > 30);

        return { closestTime, isCurrentMinLong };
    }

    // Вычисляет разницу в минутах между двумя временами в формате "HH:mm"
    function calculateMinutesDifference(time1: string, time2: string): number {
        const minutes1 = timeToMinutes(time1);
        const minutes2 = timeToMinutes(time2);

        return Math.abs(minutes1 - minutes2);
    }

    // Конвертирует время в формате "HH:mm" в минуты
    function timeToMinutes(time: string): number {
        if (!time) return 0

        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }

    function getPositionOfTime(timeline: string[], containerWidth: number, idxContainer: number) {
        const timeBlockWidth = containerWidth / (timeline.length - 1);// -1

        const idxContainerLocal = idxContainer === -1 ? 1 : idxContainer;

        return idxContainerLocal * +timeBlockWidth.toFixed(1);
    }

    function getDateEnd(dateStart: string, dateEnd: string): string {
        return String(+getDateStart(dateEnd) - +getDateStart(dateStart))
    }

    function onClickAddedBooking(item: any) {
        onClickPropsAdd(item)
    }

    function onClickOpenEditDialog(item: any, isDisabled?: boolean) {
        if (isDisabled) return;

        const timeTo = dateOperation.getTimestampByISODateTime(item.to_date);
        const currentTime = dateOperation.getTimestampByISODateTime(dateOperation.getISODateTimeByObj(new Date()))

        if (currentTime > timeTo) return;

        onClickPropsAdd(item, true)
    }

    function isDisabledCalendarBlock(from: number, widthBlock: number, idx: number, obj?: any) {
        if (obj) {
            const dateProps = new Date(obj.date);
            const currentDate = new Date();

            if (
                (dateProps.getDate() > currentDate.getDate())
                || (dateProps.getMonth() > currentDate.getMonth())
                || (dateProps.getFullYear() > currentDate.getFullYear())
            ) return false;
        }

        const left = (from / fontSizeLocal) * fontSizeLocal;
        const width = (widthBlock / fontSizeLocal) * fontSizeLocal;

        return currentTime > (left + width)
    }

    function onHoverEvent(is: boolean, event: any, item: any) {
        if (isHovered && !is) {
            const yPos = event.clientY - (eventPopup && eventPopup.clientY || 0);
            const xPos = event.clientX - (eventPopup && eventPopup.clientX || 0);

            if ((yPos < 10) || (xPos < 10)) return;
        }

        setIsHovered(is);

        setListPopup((listPopup: any) => {
            const content = [
                {
                    title: 'Имя клиента', //'Клиент',
                    text: item?.user?.name || ''
                },
                {
                    title: 'Устройство / зона',
                    text: item?.devices[0]?.device?.name || '' +
                        ''
                }
            ]

            const date = dateOperation.getFormattedDateByISO(item.date.slice(0, 10))

            return {
                ...listPopup,
                dateStart: `${date} ${item.from}`,
                dateEnd: `${date} ${item.to}`,
                content
            }
        });

        setEventPopup(event)
    }

    return (
        <div className="calendar">
            <ScrollContainer>
                <div className={'calendar__scroll-items'} ref={blockAdd}>
                    <div
                        className="calendar-current-time"
                        style={{
                            left: `${currentTime}px`,
                            display: getIsShowCurrentTime() ? 'none' : 'block'
                        }}
                    ></div>

                    <div
                        className="calendar-current-time calendar-current-time-fon"
                        style={{
                            left: `${currentTime - 258}px`,
                            display: getIsShowCurrentTime() ? 'none' : 'block'
                        }}
                    ></div>

                    <div className="calendar-time-items">
                        <div className="calendar-time" ref={time}>
                            {dateWorkList.map((i: string, idx) => {
                                if (idx % 2 !== 0) {
                                    return (
                                        <div
                                            className="calendar-time-block-half"
                                            key={'calendar-time-block' + idx}
                                        ></div>
                                    )
                                }

                                return (
                                    <div
                                        className="calendar-time-block"
                                        key={'calendar-time-block' + idx}
                                    >
                                        {i.slice(0, 5)}
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="calendar-items">
                        {list.map((i: ListData, idx) => (
                            <div
                                className="calendar-item"
                                key={'calendar-item-' + idx}
                            >
                                <div className="calendar-item-title">
                                    <p>{ i.name }</p>
                                </div>

                                <div className="calendar-container">
                                    {i.items.map((_item: any, __idx: number) => {
                                        return (
                                            <div
                                                key={`devices-item-${__idx}`}
                                                className={'calendar-blocks'}
                                            >
                                                {_item.devices.map((item: any, _idx: number) => {
                                                    if (!item) return;

                                                    const widthBlock = +getDateEnd(item.from, item.to);

                                                    return (
                                                        <div
                                                            key={'calendar-block-' + _idx}
                                                            className={`calendar-block ${isDisabledCalendarBlock(+getDateStart(item.from), widthBlock, idx, item) ? 'disabled': ''}`}
                                                            style={{
                                                                left: `${+getDateStart(item.from) / fontSizeLocal}rem`,
                                                                width: `${widthBlock / fontSizeLocal}rem`,
                                                                backgroundColor: item.color
                                                            }}
                                                            onClick={() => onClickOpenEditDialog({
                                                                    bookingId: item.id,
                                                                    ...item,
                                                                    ...i
                                                                },
                                                                isDisabledCalendarBlock(
                                                                    +getDateStart(item.from),
                                                                    widthBlock,
                                                                    idx,
                                                                    item
                                                                )
                                                            )}
                                                            onMouseEnter={(event) => onHoverEvent(true, event, item)}
                                                            onMouseLeave={(event) => onHoverEvent(false, event, item)}
                                                        >
                                                            <div className="calendar-block-users">
                                                                {(widthBlock > 70) && (
                                                                    <img
                                                                        className="calendar-block-user"
                                                                        src={item?.user?.image || '/img/logo/logo-2.png'}
                                                                        alt={''}
                                                                    />
                                                                )}

                                                                {(item.users && item.users.length && (+widthBlock > 110)) && (
                                                                    <div className="calendar-block-right">
                                                                        <img src="/img/icon/sidebar/users.svg" />

                                                                        <p>+{item.users.length}</p>
                                                                    </div>
                                                                ) || null}
                                                            </div>

                                                            {(widthBlock > 50) && (
                                                                <div className="calendar-block-title">
                                                                    <p>{item?.user?.name}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}

                                    <div
                                        className={'calendar-block-add'}
                                        style={{
                                            // top: position.y - 25,
                                            left: `${(position.x / fontSizeLocal)}rem`,
                                            // opacity: (+currentTime > position.x) ? 0 : 1,
                                            visibility: (((+currentTime - 40) >= position.x) || (currentTime === 0)) ? 'hidden' : 'visible'
                                        }}
                                        onClick={() => onClickAddedBooking(i)}
                                    >
                                        +
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ScrollContainer>

            {isHovered && (
                <PopupDialog
                    eventProps={eventPopup}
                    onClose={() => setIsHovered(false)}
                >
                    <BookingPopup
                        item={listPopup}
                    />
                </PopupDialog>
            )}
        </div>
    )
}

export default CalendarBlock;
