import { FC, useState } from "react";
import { SettingsBlock } from "./SettingsBlock";
import Button from "../form/Button";
import DialogContainer from "../dialog/DialogContainer";
import { SettingsConvertRatings } from "./SettingsConvertRatings";
import { SettingsConvertCourse } from "./SettingConvertСourse";
import RoleUsers from "../../mixins/role";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

export const SettingsPoint: FC = () => {
    const roleUsers = new RoleUsers();

    const userData = useSelector(GetCurrentUserData);

    const isReadByRole = roleUsers.getModelRoleByType('convert', 'read');
    const isUpdateByRole = roleUsers.getModelRoleByType('convert', 'update');

    const [isOpenConvertModal, setIsOpenConvertModal] = useState(false);

    function onClickOpenConversion() {
        setIsOpenConvertModal(true);
    }

    return (
        <div className='col-12 col-md-9 settings__content'>
            <div className='settings__block'>
                <div className='settings__block-left'>
                    <h1 className='settings__block-title'>Конвертация</h1>
                </div>

                {isReadByRole && (
                    <div className='settings__block-right settings__block-right-btn'>
                        <Button
                            text={'Конвертировать'}
                            className={'btn btn-primary'}
                            onClick={onClickOpenConversion}
                        />
                    </div>
                )}
            </div>

            <DialogContainer
                isOpen={isOpenConvertModal}
                label={'Конвертация'}
                closeModal={() => setIsOpenConvertModal(false)}
                widthProps={436}
            >
                <SettingsConvertCourse
                    isOpenDialog={isOpenConvertModal}
                    onClickChangeModal={(val) => setIsOpenConvertModal(val)}
                    isShowSaveBtn={isUpdateByRole && userData?.staffCenter?.isMain}
                />
            </DialogContainer>
        </div>

    )
}
