export default function GameNav({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.3335 10L13.3427 10.0083"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.8335 10L15.8427 10.0083"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.3335 10L18.3427 10.0083"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332C12.7258 18.3332 15.1461 17.0243 16.6665 15.0007L9.99984 9.99984L16.6665 4.99902C15.1461 2.97541 12.7258 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984Z"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
