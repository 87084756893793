import { FC, useEffect, useState } from "react";
import Button from "../form/Button";
import { DashboardMainStatistic } from "../../api/requests/reports/interface";
import { $getStatisticDashboard } from "../../api/requests/reports";

interface ShiftCloseProps {
    openNextDialog: () => void
}

export const ShiftClose: FC<ShiftCloseProps> = (
    {
        openNextDialog
    }
) => {

    const [ statistics, setStatistics ] = useState<DashboardMainStatistic>({
        revenue: 0, // Выручка
        revenueCard: 0, // Выручка картой
        cash: 0, // Наличные
        cashBefore: 0, // Наличных до начала смены
        lastSessionDate: '' // Дата последней сессии
    })

    useEffect(() => init(), []);

    function init() {
        $getStatisticDashboard().then(res => {
            if (!res) return;

            setStatistics(res)
        })
    }

    return (
        <div className='shift__preview'>
            <div className='shift__preview-money'>
                <div className='shift__preview-proceeds'>
                    <div className='shift__preview-block'>
                        <div className='shift__preview-desc'>
                            <p className='shift__preview-title'>Выручка за смену</p>
                            <p className='shift__preview-paragraph24PX'>{ statistics?.revenue || 0 } ₽</p>
                        </div>
                    </div>
                    <div className='shift__preview-seperated'>
                        <div className='shift__preview-desc'>
                            <p className='shift__preview-title'>Наличные</p>
                            <p className='shift__preview-paragraph14PX'>{ statistics?.cash || 0 } ₽</p>
                        </div>
                        <div className='shift__preview-desc'>
                            <p className='shift__preview-title'>Картой</p>
                            <p className='shift__preview-paragraph14PX'>{ statistics?.revenueCard || 0 } ₽</p>
                        </div>
                    </div>
                </div>
                <div className='shift__preview-cashbox'>
                    <div className='shift__preview-desc'>
                        <p className='shift__preview-title'>На начало смены</p>
                        <p className='shift__preview-paragraph14PX'>{ statistics?.cashBefore || 0 } ₽</p>
                    </div>
                </div>
            </div>

            <Button className='btn btn-primary shift__button' text={ 'Далее' } onClick={ openNextDialog }/>
        </div>
    )
}
