import {FC, useEffect, useState} from "react";
import QRCode from "react-qr-code";
import {useSelector} from "react-redux";

import {socket} from "../../mixins/socket";

import {GetCurrentUserData} from "../../redux/slices/userSlice";

const QrPage: FC = () => {
    const userData = useSelector(GetCurrentUserData);

    const [ image, setImage ] = useState<string>('');
    const [ isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        if (!userData?.centerId) return;
        function onConnect() {
            console.log("Connected to server");
            setIsConnected(true);
        }

        function onDisconnect() {
            console.log("Disconnect to server");
            setIsConnected(false);
        }

        function onGetImageQr(value: string) {
            setImage(value);
        }

        socket.connect();
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on(userData.centerId, onGetImageQr)
        socket.emit('verifywithqr', 'start');

        return () => {
            socket.disconnect();
        };
    }, [userData?.centerId]);

    return (
        <div className="qr list-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="guest-container qr-container">
                            <div className="sign-in-form qr-form">
                                <h2>Подтвердите посещение</h2>

                                <form>
                                    <div className={'qr-block'}>
                                        {(isConnected || image) ? (
                                            <QRCode
                                                // size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={image}
                                                // viewBox={`0 0 256 256`}
                                            />
                                        ) : (
                                            <p>QR не доступен</p>
                                        )}
                                    </div>

                                    <h4 className={'text-center'}>Отсканируйте QR-код в приложении «Мой Фиджитал»</h4>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QrPage;

