export default function PcCheck({color= "white", size = 18}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="pc-check (1) 1"
               clipPath="url(#clip0_450_9262)">
                <path id="Vector" d="M5.25 16.5H12.75" stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2"
                      d="M1.5 12.75V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H15C15.3978 1.5 15.7794 1.65804 16.0607 1.93934C16.342 2.22064 16.5 2.60218 16.5 3V12.75C16.5 13.1478 16.342 13.5294 16.0607 13.8107C15.7794 14.092 15.3978 14.25 15 14.25H3C2.60218 14.25 2.22064 14.092 1.93934 13.8107C1.65804 13.5294 1.5 13.1478 1.5 12.75Z"
                      stroke={color}
                      strokeWidth="1.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_450_9262">
                    <rect width={size} height={size} fill={color}
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
