import { FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../redux/slices/userSlice";

const SignInLayout: FC = () => {
    const navigate = useNavigate();

    const userData = useSelector(GetCurrentUserData);

    if (userData?.id) {
        navigate('/');
    }

    return (
        <div className={'sign-in-layout'}>
            <Outlet />
        </div>
    )
};
export default SignInLayout;
