export default function Calculator({color= "white", size = 22}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 22 22`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1218_9336)">
                <path
                    d="M0.916992 19.2494V2.74935C0.916992 2.26312 1.11015 1.7968 1.45396 1.45299C1.79778 1.10917 2.2641 0.916016 2.75033 0.916016H19.2503C19.7366 0.916016 20.2029 1.10917 20.5467 1.45299C20.8905 1.7968 21.0837 2.26312 21.0837 2.74935V19.2494C21.0837 19.7356 20.8905 20.2019 20.5467 20.5457C20.2029 20.8895 19.7366 21.0827 19.2503 21.0827H2.75033C2.2641 21.0827 1.79778 20.8895 1.45396 20.5457C1.11015 20.2019 0.916992 19.7356 0.916992 19.2494Z"
                    stroke={color} strokeWidth="1.5"
                />
                <path d="M13.7497 6.41732H17.4163M13.7497 14.209H17.4163M13.7497 16.959H17.4163M4.58301 6.41732H6.41634M6.41634 6.41732H8.24967M6.41634 6.41732V4.58398M6.41634 6.41732V8.25065M5.12017 16.8802L6.41634 15.584M6.41634 15.584L7.71342 14.2878M6.41634 15.584L5.12017 14.2878M6.41634 15.584L7.71342 16.8802"
                      stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1218_9336">
                    <rect width={size} height={size} fill={{color}}
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

