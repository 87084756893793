export default function Location({ color = "white", width = 22, height = 22}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 22 22`} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd" d="M11 3.26626C8.5984 3.26626 6.14224 5.31897 6.14224 8.45904C6.14224 10.8092 7.10181 13.098 8.23569 14.9733C9.25665 16.6619 10.3911 17.9696 11 18.6213C11.6089 17.9696 12.7433 16.6619 13.7643 14.9733C14.8982 13.098 15.8578 10.8092 15.8578 8.45904C15.8578 5.31897 13.4016 3.26626 11 3.26626ZM4.875 8.45904C4.875 4.60744 7.91022 1.99902 11 1.99902C14.0898 1.99902 17.125 4.60744 17.125 8.45904C17.125 11.1369 16.0398 13.6591 14.8487 15.629C13.6532 17.6063 12.3163 19.0838 11.7376 19.6852C11.3319 20.1068 10.6681 20.1068 10.2624 19.6852C9.68366 19.0838 8.34682 17.6063 7.15127 15.629C5.96019 13.6591 4.875 11.1369 4.875 8.45904Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd" d="M11 6.38462C10.1078 6.38462 9.38462 7.10785 9.38462 8C9.38462 8.89215 10.1078 9.61538 11 9.61538C11.8922 9.61538 12.6154 8.89215 12.6154 8C12.6154 7.10785 11.8922 6.38462 11 6.38462ZM8 8C8 6.34315 9.34315 5 11 5C12.6569 5 14 6.34315 14 8C14 9.65685 12.6569 11 11 11C9.34315 11 8 9.65685 8 8Z" fill="white"/>
        </svg>

    );
}
