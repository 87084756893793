import { FC, FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../form/Button";
import ArrowLeft from "../../../img/icon/ArrowLeft";
import Coins from "../../../img/icon/Coins";

import TextField from "../../form/TextField";
import FilterSelect from "../../FilterSelect";
import { ToggleBlock } from "../../form/ToggleBlock";
import TransformObjArray from "../../../mixins/transform-obj-array";
import cn from "classnames";
import { $achievementCreate, $achievementListTypeGet, $achievementUpdate } from "../../../api/requests/achievement";
import Info from "../../../img/icon/Info";
import TextArea from "../../form/TextArea";
import ImageToBase64 from "../../../mixins/image-to-base64";
import ImageField from "../../form/ImageField";
import FormGroupItem from "../../form/FormGroupItem";
import Token from "../../../img/icon/Token";

export const EditAchievements: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const imageToBase64 = new ImageToBase64();

    const state: any = location.state;
    const isEditPage = state?.current === 'edit';

    const [ image, setImage ] = useState<any>(null);

    const editedData = state?.data || {};

    const transformObjArray = new TransformObjArray();

    const [typeListCondition, setTypeListCondition] = useState<any[]>([])

    const [ formFieldList, setFormFieldList ] = useState([
        {
            key: 'title',
            placeholder: 'Название',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            paddingBottom: 0,
            required: true
        },
        {
            key: 'description',
            placeholder: 'Описание',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textarea: true,
            paddingBottom: 30,
        },
        {
            key: 'type',
            title: 'Тип',
            placeholder: 'Тип',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            select: true,
            list: [
                {value: 1, label: 'Разовые достижения'},
                {value: 2, label: 'Скрытые достижения'},
                {value: 3, label: 'Периодические (или сезонные) достижения'},
                {value: 4, label: 'Специальные достижения'},
                {value: 5, label: 'Многоуровневые достижения'},
            ],
            required: true
        },
        {
            key: 'category',
            title: 'Категория',
            placeholder: 'Категория',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            select: true,
            list: [
                {value: 0, label: 'Достижение'},
                {value: 1, label: 'Особый статус'},
                {value: 2, label: 'Трофей'}
            ]
        },
        {
            key: 'level',
            type: 'number',
            title: 'Сложность',
            placeholder: 'Уровень',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            required: true
        },
        // {
        //     key: 'difficulty',
        //     placeholder: 'Сложность',
        //     value: '',
        //     setValue: (value: string, key: string) => onChangeSetValue(value, key),
        //     select: true,
        //     list: [
        //         {value: 0, label: 'Начальный уровень'},
        //         {value: 1, label: 'Средний уровень'},
        //         {value: 2, label: 'Продвинутый уровень'},
        //     ],
        //     required: true
        // },
        {
            key: 'rating',
            type: 'number',
            placeholder: 'Кол-во очков за достижения',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            imgLeft: <Coins color={ '#3582F6' }/>,
            required: true
        },
        {
            key: 'points',
            type: 'number',
            placeholder: 'Кол-во токенов',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            imgLeft: <Token color={ '#3582F6' }/>,
            required: true
        },
        {
            key: 'typeCondition',
            title: 'Тип условия достижения',
            placeholder: 'Тип',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            setValueInput: (value: string, key: string) => {},
            select: true,
            list: []
        },
        // {
        //     key: 'platform',
        //     title: 'Платформа',
        //     placeholder: 'Любая платформа',
        //     value: null,
        //     setValue: (value: string, key: string) => onChangeSetValue(value, key),
        //     select: true,
        //     imgRight: (<Close color={ 'red' }/>),
        //     list: [
        //         {value: 1, label: 'Мужской'},
        //         {value: 2, label: 'Женский'},
        //     ]
        // },
        {
            key: 'isActive',
            placeholder: 'Состояние',
            value: false,
            setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
            title: 'Состояние',
            checkbox: true,
        },

    ]);

    useEffect(() => init(), []);

    useEffect(() => {
        if (typeListCondition.length) {
            const listUpdate = [ ...formFieldList ].map(i => {
                if (editedData && Object.keys(editedData).includes(i.key)) {
                    i.value = editedData[i.key];

                    if (i.list) {
                        const findItem: any = i.list.find((_i: any) => _i.value === editedData[i.key])

                        i.value = findItem || '';
                    }
                }

                if (i.key === 'typeCondition') {
                    i.list = typeListCondition;
                    i.value = typeListCondition.find((_i: any) => _i.value === editedData[i.key])
                }

                return i;
            });

            if (editedData.image) setImage(editedData.image);

            setFormFieldList(listUpdate);
        }
    }, [typeListCondition]);

    function init() {
        $achievementListTypeGet().then(res => {
            if (!res) return;

            setTypeListCondition(res.map(i => {
                return {
                    label: i.value,
                    value: i.key
                }
            }))
        })
    }

    function onChangeSetValue(value: string | boolean, key: string) {
        const listUpdate = [...formFieldList].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setFormFieldList(listUpdate);
    }

    function onClickHome() {
        navigate("/achievements");
    }

    function onClickSave(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const requestData = {
            ...transformObjArray.getRequestObj(formFieldList),
            points: +transformObjArray.getRequestObj(formFieldList).points,
            rating: +transformObjArray.getRequestObj(formFieldList).rating,
            level: +transformObjArray.getRequestObj(formFieldList).level,
            image: imageToBase64.getImageCheckValid(image)
        };

        if (transformObjArray.formValidateByRequired(requestData, formFieldList, false))
            return console.log(transformObjArray.formValidateByRequired(requestData, formFieldList));

        $achievementCreate(requestData).then(res => {
            if (!res.id) return;

            onClickHome();
        });
    }

    function onClickEdit() {
        const requestData = {
            ...transformObjArray.getRequestObj(formFieldList),
            points: +transformObjArray.getRequestObj(formFieldList).points,
            rating: +transformObjArray.getRequestObj(formFieldList).rating,
            level: +transformObjArray.getRequestObj(formFieldList).level,
            image: imageToBase64.getImageCheckValid(image)
        };

        if (transformObjArray.formValidateByRequired(requestData, formFieldList, false))
            return console.log(transformObjArray.formValidateByRequired(requestData, formFieldList));

        $achievementUpdate(editedData.id, requestData).then(res => {
            if (!res.id) return;

            onClickHome();
        });
    }

    return (
        <div className='edit__block achievements__edit'>
            <div className="achievements__edit-main">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='edit__block__header achievements__edit-header'>
                                <div className="edit__block__item__back">
                                    <Button
                                        text={ 'Назад' }
                                        leftIcon={ (
                                            <ArrowLeft
                                                color={ '#3582F6' }
                                            />
                                        ) }
                                        onClick={ onClickHome }
                                        className={ 'btn btn-icon' }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                <div className='row column'>
                    <div className='col-12 col-sm-7 achievements__edit-form'>
                        <div className="edit__block__item__header">
                            <h2>{ isEditPage ? 'Редактирование' : 'Создание' } достижения</h2>
                        </div>
                        <form className="achievements__edit-content" onSubmit={ onClickSave }>
                            { formFieldList.map((i, idx) => (
                                <div key={ `form-field-achievement-item-${ idx }` }>
                                    { i.title && (
                                        <div className='achievements__edit-title'>
                                            <p>{ i.title }</p>
                                        </div>
                                    ) }

                                    <FormGroupItem
                                        item={i}
                                    />
                                </div>
                            )) }

                            <div className="edit__block__item__edit">
                                <div className={ 'achievements__edit-button' }>
                                    <Button
                                        onClick={ isEditPage ? onClickEdit : () => {
                                        } }
                                        type={ isEditPage ? 'button' : 'submit' }
                                        className={ 'btn btn-primary' }
                                        text={ isEditPage ? 'Изменить' : 'Создать' }
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-xl-4 col-sm-5 achievements__edit-style">
                        <div className="discipline__content__form__right__file">
                            <div className="tournament__edit__inner__right">
                                <h2>Обложка 540x540</h2>
                                {/*<Info*/}
                                {/*    color={ '#3582F6' }*/}
                                {/*/>*/}
                            </div>

                            <div className="discipline__content__form__right__file__field">
                                <ImageField
                                    label={'Статичная'}
                                    upload={image}
                                    setUpload={setImage}
                                />

                                {/*<ImageField*/}
                                {/*    label={ 'Анимация' }*/}
                                {/*    upload={ image }*/}
                                {/*    setUpload={ setImage }*/}
                                {/*/>*/}
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
