import { FC, useEffect, useState } from "react";
import Button from "../form/Button";
import TextField from "../form/TextField";
import Coins from "../../img/icon/Coins";
import Ruble from "../../img/icon/Ruble";
import { $getSettingsList, $updateSettings } from "../../api/requests/settings";
import StarsFull from "../../img/icon/StarsFull";
import { ProjectSettingModel } from "../../api/requests/settings/interface";

interface SettingsConvertCourseParams {
    isOpenDialog: boolean
    onClickChangeModal: (val: boolean) => void
    isShowSaveBtn: boolean
}

export const SettingsConvertCourse: FC<SettingsConvertCourseParams> = (
    {
        isOpenDialog,
        onClickChangeModal,
        isShowSaveBtn
    }
) => {

    const [ countScores, setCountScores ] = useState<number | string>('');
    const [ countMaxRating, setCountMaxRating ] = useState<number | string>('');
    const [ countRuble, setCountRuble ] = useState<number | string>('');
    const [ countRating, setCountRating ] = useState<number | string>('');

    const [ saveResponseList, setSaveResponseList ] = useState<ProjectSettingModel[]>([]);

    const getValueByType = (type: number): string => {
        if (type === 0) return String(countMaxRating)
        if (type === 1) return String(countRating)

        return String(countRuble)
    }

    useEffect(() => {
        if (!isOpenDialog) {
            onResetAllData();
        }
    }, [ isOpenDialog ]);

    useEffect(() => init(), []);

    function init() {
        $getSettingsList().then((res) => {
            if (!res.length) return;

            setSaveResponseList(res)

            res.forEach(i => {
                if (i.type === 0) setCountMaxRating(i.value)
                if (i.type === 1) setCountRating(i.value)
                if (i.type === 2) setCountRuble(i.value)
            })
        })
    }

    function onClickConvert() {
        saveResponseList.forEach(i => {
            if (+i.value !== +getValueByType(i.type)) requestApiUpdate(i.type)
        })
    }

    function requestApiUpdate(type: number) {
        const requestData = {
            type,
            value: getValueByType(type)
        }

        $updateSettings(requestData).then(res => {
            onResetAllData();
            onClickChangeModal(false);
        })
    }

    function onResetAllData() {
        setCountRating('');
        setCountScores('');
        setCountMaxRating('');
        setCountRuble('');
    }

    return (
        <div className={ 'convert__modal' }>
            <TextField
                type={ 'number' }
                label={ 'Максимальный рейтинг за сезон' }
                value={ countMaxRating }
                onChangeValue={ setCountMaxRating }
                imgLeft={ (<Coins/>) }
                disabled={!isShowSaveBtn}
            />

            <TextField
                type={ 'number' }
                label={ 'Курс токенов в рейтинг' }
                value={ countRating }
                onChangeValue={ setCountRating }
                imgLeft={ (<StarsFull/>) }
                disabled={!isShowSaveBtn}
            />

            <TextField
                type={ 'number' }
                label={ 'Курс токенов в рубли' }
                value={ countRuble }
                onChangeValue={ setCountRuble }
                imgLeft={ (<Ruble/>) }
                disabled={!isShowSaveBtn}
            />

            {/*<div className={ 'convert__modal-center' }>*/ }
            {/*    <div className="convert__modal-icon">*/ }
            {/*        <div>*/ }
            {/*            <ArrowUpDown/>*/ }
            {/*        </div>*/ }
            {/*    </div>*/ }
            {/*</div>*/ }

            {/*<TextField*/ }
            {/*    type={ 'number' }*/ }
            {/*    placeholder={ 'Вы получите токенов' }*/ }
            {/*    value={ countScores }*/ }
            {/*    onChangeValue={ (value) => {*/ }
            {/*        setCountRating(+value)*/ }
            {/*        setCountScores(+value / 15)*/ }
            {/*    } }*/ }
            {/*    imgLeft={ (<Coins/>) }*/ }
            {/*    disabled*/ }
            {/*/>*/ }

            {isShowSaveBtn && (
                <div className={ 'pt-3' }>
                    <Button
                        text={ 'Сохранить' }
                        onClick={ onClickConvert }
                        className={ 'btn btn-primary w-100 justify-content-center' }
                    />
                </div>
            )}
        </div>
    )
}
