export default function CashAside({color = "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector"
                      d="M1.66602 14.167V5.83366C1.66602 5.39163 1.84161 4.96771 2.15417 4.65515C2.46673 4.34259 2.89065 4.16699 3.33268 4.16699H16.666C17.108 4.16699 17.532 4.34259 17.8445 4.65515C18.1571 4.96771 18.3327 5.39163 18.3327 5.83366V14.167C18.3327 14.609 18.1571 15.0329 17.8445 15.3455C17.532 15.6581 17.108 15.8337 16.666 15.8337H3.33268C2.89065 15.8337 2.46673 15.6581 2.15417 15.3455C1.84161 15.0329 1.66602 14.609 1.66602 14.167Z"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2"
                      d="M15.4173 10.0083L15.4257 9.99917M4.58398 10.0083L4.59232 9.99917M10.0007 12.5C9.33761 12.5 8.70172 12.2366 8.23288 11.7678C7.76404 11.2989 7.50065 10.663 7.50065 10C7.50065 9.33696 7.76404 8.70107 8.23288 8.23223C8.70172 7.76339 9.33761 7.5 10.0007 7.5C10.6637 7.5 11.2996 7.76339 11.7684 8.23223C12.2373 8.70107 12.5007 9.33696 12.5007 10C12.5007 10.663 12.2373 11.2989 11.7684 11.7678C11.2996 12.2366 10.6637 12.5 10.0007 12.5Z"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
