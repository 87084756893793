import { ComponentType, ReactElement, useEffect, useState } from "react";
import Button from "./Button";
import { selectOption } from "../../types/users";
import PlusBorder from "../../img/icon/PlusBorder";

interface Field {
    value?: any
    selectItem?: any
    placeholder?: string
    options: selectOption[] | any[]
    onChange: (val: any) => void
    isShowBtnDelete?: boolean
    deleteItem?: () => void
}

interface ComponentWrapperProps {
    wrappedComponent : ComponentType<Field>
    textBtnAdd?: string
    zone?: any
    onChangeValue: (val: any) => void
    setResetComplete: () => void
    resetData: boolean
    valueItem?: any
    valueArray?: any
}

export function AddedNewField(
    props: ComponentWrapperProps,
): ReactElement {
    const WrappedComponent = props.wrappedComponent;
    const textBtnAdd = props.textBtnAdd || 'Добавить устройство / зону';
    const zoneItem = props.zone;
    const valueItem = {
        ...props.valueItem,
        label: props.valueItem?.name,
        value: props.valueItem?.id,
    };
    const valueArray = props.valueArray;

    const getChangeDeviceList = (list: any[]) => {
        if (!list) return [];

        return list.map(i => ({
            ...i,
            label: i.name,
            value: i.id
        }))
    }

    const defaultSelectDevice: Field = {
        placeholder: 'Выберите устройство или площадку',
        options: getChangeDeviceList(zoneItem),
        value: valueItem.value ? valueItem : '',
        onChange: (value: string) => onChangeDevice(0, value),
    };
    const [deviceList, setDeviceList] = useState<Field[]>([defaultSelectDevice]);

    const getSelectList = (list: any[]) => {
        return list.map(i => i.value)
    }

    useEffect(() => {
        if (props.resetData) {
            setDeviceList([defaultSelectDevice])
            props.setResetComplete()
        }
    }, [props.resetData])

    useEffect(() => {
        if (valueArray && valueArray.length) {
            setDeviceList((list) => {
                return valueArray.map((i: any) => ({
                    ...list[0],
                    value: {
                        ...i,
                        label: i?.name,
                        value: i?.id,
                    }
                }))
            })
        }
    }, [valueArray])

    function addedNewDevice(): void {
        setDeviceList((prevList) => {
            const listUpdate = { ...defaultSelectDevice }

            if (zoneItem) {
                const selectIdList: string[] = prevList.map(i => i.value?.id);

                listUpdate.options = listUpdate.options.filter(i => !selectIdList.includes(i.id))
            }

            return [...prevList, { ...listUpdate }]
        });
    }

    function onChangeDevice(idx: number, value: string | null) {
        if (value === null) {
            setDeviceList((prevList) => {
                const newList = [...prevList];

                newList.splice(idx, 1);

                return newList;
            })

            return
        }

        setDeviceList((prevList) => {
            const newList = [...prevList];

            newList[idx].value = value;

            props.onChangeValue(getSelectList(newList))

            return newList;
        });
    }

    function deleteItem(idx: number) {
        setDeviceList((prevList) => {
            const list = [...prevList]

            list.splice(idx, 1)

            return list
        });
    }

    return (
        <div className={''}>
            {deviceList.map(({ value, placeholder, options }, idx) => (
                <WrappedComponent
                    key={`device-item-${idx}`}
                    value={value}
                    selectItem={value}
                    placeholder={placeholder}
                    options={options}
                    onChange={(val: string) => onChangeDevice(idx, val)}
                    isShowBtnDelete={!!idx}
                    deleteItem={() => deleteItem(idx)}
                />
            ))}

            <Button
                text={textBtnAdd}
                onClick={addedNewDevice}
                leftIcon={(
                    <PlusBorder color={'#3582F6'}/>
                )}
                className={'btn btn-icon added__booking__dialog-added-btn'}
                gap={14}
            />
        </div>
    );
}
