export default function Loyalty({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_5191_22952)">
				<path
					d="M9.99984 18.3334C14.5832 18.3334 18.3332 14.5834 18.3332 10.0001C18.3332 5.41675 14.5832 1.66675 9.99984 1.66675M9.99984 18.3334C5.4165 18.3334 1.6665 14.5834 1.6665 10.0001C1.6665 5.41675 5.4165 1.66675 9.99984 1.66675M9.99984 18.3334V10.0001M9.99984 1.66675V10.0001M9.99984 10.0001L14.1665 14.5834M9.99984 10.0001L14.1665 5.83341M9.99984 10.0001H15.8332"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>

			<defs>
				<clipPath id="clip0_5191_22952">
					<rect width={size} height={size} fill={color}/>
				</clipPath>
			</defs>
		</svg>
	);
}
