import { FC } from "react";

import FilterSelect from "../FilterSelect";
import TextField from "../form/TextField";

interface UserFieldItemProps {
    item: any
    idx: number
    onChangeValueField: (i: any, item: any) => void
    disabled?: boolean
}

const UserFieldItem: FC<UserFieldItemProps> = (
    {
        item,
        idx,
        onChangeValueField,
        disabled
    }
) => {

    return (
        <div
            key={ `user-info-field-item-${ item.key }-${ idx }` }
            className={ `col-md-${ item.width || 6 } col-12` }
        >
            <div className="form__group">
                <label>{ item.label }</label>

                { item.list ? (
                    <FilterSelect
                        placeholder={ item.placeholder }
                        value={ item.value }
                        options={ item.list }
                        onChange={ (i) => onChangeValueField(i, item) }
                        disabled={disabled}
                    />
                ) : (
                    <TextField
                        type={ item.type }
                        placeholder={ item.placeholder }
                        value={ item.value }
                        onChangeValue={ (value) => onChangeValueField(value, item) }
                        imgLeft={ item.imgLeft }
                        imgRight={ item.imgRight }
                        disabled={disabled}
                    />
                ) }
            </div>
        </div>
    )
}

export default UserFieldItem;
