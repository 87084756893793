import { FC, useEffect, useState } from "react";
import DialogContainer from "../dialog/DialogContainer";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import { $createStatisticCity, $deleteStatisticCity, $updateStatisticCity } from "../../api/requests/rating-city";
import TransformObjArray from "../../mixins/transform-obj-array";
import FormGroupItem from "../form/FormGroupItem";
import { withDebounce } from "../../functions";
import { $getSearchCity } from "../../api/requests/reports";

interface EditRatingDialogProps {
    isShow: boolean
    setIsShow: (item?: any) => void
    item: any
    cityList: any
}

const EditRatingDialog: FC<EditRatingDialogProps> = (
    {
        isShow,
        setIsShow,
        item,
        cityList
    }
) => {
    const transformObjArray = new TransformObjArray();

    const [ formFieldList, setFormFieldList ] = useState([
        {
            key: 'cityId',
            placeholder: 'Город',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            setValueInput: (value: string, key: string) => onChangeSetValueInput(value, key),
            select: true,
            list: []
        },
        {
            key: 'summaryHours',
            type: 'number',
            label: 'Сумма всех часов за игры',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            paddingBottom: 0,
            required: true
        },
        {
            key: 'summaryPointsHours',
            type: 'number',
            label: 'Кол-во баллов за сумму часов',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            paddingBottom: 0,
            required: true
        },
        {
            key: 'averageHours',
            type: 'number',
            label: 'Среднее время часов за игру',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            paddingBottom: 0,
            required: true
        },
        {
            key: 'averagePointsHours',
            type: 'number',
            label: 'Кол-во баллов за среднее время часов за игру',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            paddingBottom: 0,
            required: true
        },
        {
            key: 'percentAttendance',
            type: 'number',
            label: 'Процент посещаемости от всех пользователей',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            paddingBottom: 0,
            required: true
        },
        {
            key: 'percentPointsAttendance',
            type: 'number',
            label: 'Кол-во баллов за процент посещаемости',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
            paddingBottom: 0,
            required: true
        },
    ])

    const [ isLoadingSave, setIsLoadingSave ] = useState<boolean>(false);
    const [ isLoadingDelete, setIsLoadingDelete ] = useState<boolean>(false);

    useEffect(() => init(), [])

    function init() {
        const listUpdate = [ ...formFieldList ].map(i => {
            if (i.key === 'cityId') {
                i.list = cityList && cityList || []
            }

            if (item && Object.keys(item).includes(i.key)) {
                i.value = item[i.key];

                if (i.list) {
                    const findItem: any = i.list.find((_i: any) => _i.value === item[i.key])

                    i.value = findItem || '';
                }
            }

            return i;
        });

        setFormFieldList(listUpdate);
    }

    function onChangeSetValue(value: any, key: string) {
        const listUpdate = [ ...formFieldList ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setFormFieldList(listUpdate);
    }

    function onChangeSetValueInput(value: any, key: string) {
        if (!value) return;

        withDebounce(() => onChangeSearchCityList(value, key))
    }

    function onChangeSearchCityList(search: string = '', key: string) {
        $getSearchCity({ search }).then(res => {
            if (!res || !res.length) return;

            const listUpdate = [ ...formFieldList ].map((item: any) => {
                if (key === 'cityId') {
                    item.list = res.map(i => ({
                        ...i,
                        label: i.name,
                        value: i.id
                    }))
                }

                return item;
            });

            setFormFieldList(listUpdate);
        })
    }

    function onClickSave() {
        setIsLoadingSave(true);

        const obj = transformObjArray.getRequestObj(formFieldList)

        const requestData = {
            ...obj,
            averageHours: +obj.averageHours,
            averagePointsHours: +obj.averagePointsHours,
            percentAttendance: +obj.percentAttendance,
            percentPointsAttendance: +obj.percentPointsAttendance,
            summaryHours: +obj.summaryHours,
            summaryPointsHours: +obj.summaryPointsHours,
        };

        if (item) return updateChange(requestData);

        createCity(requestData)
    }

    function createCity(requestData: any) {
        $createStatisticCity(requestData).then(i => {
            setIsLoadingSave(false);
            if (!i) return;

            setIsShow(true);
        })
    }

    function updateChange(requestData: any) {
        $updateStatisticCity(item.id, requestData).then(i => {
            setIsLoadingSave(false);
            if (!i) return;

            setIsShow(true);
        })
    }

    function onClickDelete() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        setIsLoadingDelete(true);

        $deleteStatisticCity(item.id).then(i => {
            setIsLoadingDelete(false);
            if (!i) return;


            setIsShow(true);
        })
    }

    return (
        <DialogContainer
            isOpen={ isShow }
            closeModal={ setIsShow }
            label={ item ? 'Редактирование' : 'Добавление' }
            closeIcon={ <Close/> }
        >
            <div className={ 'create__group' }>
                { formFieldList.map((i, idx) => (
                    <div key={ `form-field-achievement-item-${ idx }` }>
                        <FormGroupItem
                            item={ i }
                        />
                    </div>
                )) }

                <div className={'d-flex'}>
                    { item && (
                        <Button
                            loading={ isLoadingDelete }
                            text={ 'Удалить' }
                            onClick={ onClickDelete }
                            className={ 'btn btn-danger w-100 justify-content-center' }
                        />
                    ) }

                    <Button
                        loading={ isLoadingSave }
                        text={ 'Сохранить' }
                        onClick={ onClickSave }
                        className={ 'btn btn-primary w-100 justify-content-center' }
                    />
                </div>
            </div>
        </DialogContainer>
    )
}

export default EditRatingDialog;
