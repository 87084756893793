import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/form/Button";
import ArrowLeft from "../../../img/icon/ArrowLeft";
import Ellipse from "../../../img/icon/Ellipse";
import Trash from "../../../img/icon/Trash";
import MassageSecond from "../../../img/icon/MassageSecond";
import DateOperations from "../../../mixins/date-operation";
import { $getListDiscipline } from "../../../api/requests/discipline";
import { DisciplineModel } from "../../../api/requests/discipline/interface";
import {
    $getTournamentsListById,
    $getTournamentsRequestList, $startTournament, $stopTournament,
    $updateStatusRequestTournaments
} from "../../../api/requests/tournaments";
import CommandsItem from "./CommandsItem";
import { TournamentRequestModel } from "../../../api/requests/tournaments/interface";
import StarsFull from "../../../img/icon/StarsFull";
import Pagination from "../../../components/form/Pagination";
import RoleUsers from "../../../mixins/role";

const EditTournamentGame: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const dateOperations = new DateOperations();
    const roleUsers = new RoleUsers();
    const isReadByRole = roleUsers.getModelRoleByType('tournamentsGrid', 'read');

    const isCreateRequestByRole = roleUsers.getModelRoleByType('tournaments', 'update');
    const isCanselRequestByRole = roleUsers.getModelRoleByType('tournaments', 'delete');
    const isUpdateRequestByRole = roleUsers.getModelRoleByType('tournamentsRequest', 'update');

    const state: any = location.state;
    const [editedData, setEditedData] = useState(state?.data || {});
    const currentLocationLink = location.pathname.replace('/tournaments/view/', '');

    const typeList = [ '', 'Командный', 'Одиночный' ];

    const [isTournamentsStatus, setIsTournamentsStatus] = useState(editedData.status || 0);

    const [ isList, setIsList ] = useState<any[]>([
        {
            key: 'disciplineId',
            value: ''
        },
        {
            key: 'rating',
            value: ''
        },
        {
            key: 'type',
            value: ''
        },
        {
            key: 'dateStart',
            value: ''
        }

    ]);

    const [ tournamentsRequestList, setTournamentsRequestList ] = useState<TournamentRequestModel[]>([]);

    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const dataStatusTournaments = [
        {
            text: 'Создан',
            color: '#3582F6',
            value: 0
        },
        {
            text: 'Сбор команд',
            color: '#70D584',
            value: 1
        },
        {
            text: 'В процессе',
            color: '#F9D448',
            value: 2
        },
        {
            text: 'Завершен',
            color: '#FF5858',
            value: 3
        },
        {
            text: 'Отменен',
            color: '#FF5858',
            value: -1
        }
    ]

    const getColorByStatusTournaments = (): string => {
        const findItem = dataStatusTournaments.find(i => i.value === isTournamentsStatus);

        if (findItem) return findItem.color;

        return dataStatusTournaments[0].color;
    }

    const getTextByStatusTournaments = (): string => {
        const findItem = dataStatusTournaments.find(i => i.value === isTournamentsStatus);

        if (findItem) return findItem.text;

        return dataStatusTournaments[0].text;
    }

    useEffect(() => init(), [activePage]);

    function init() {
        $getListDiscipline().then((res) => {
            if (!res.length) return;

            onChangeList(res);
        })

        getCurrentTournament();
        getListApplications();
    }

    function getCurrentTournament() {
        $getTournamentsListById(currentLocationLink).then(res => {
            if (!res.id) return;

            setEditedData(res)

            setIsTournamentsStatus(res.status);
        })
    }

    function onChangeList(list: DisciplineModel[]) {
        const findDisciplineItem = list.find((i: any) => i.id === editedData.disciplineId);

        const listUpdate = [ ...isList ].map(i => {
            if ((i.key === 'disciplineId') && findDisciplineItem) return {
                ...i,
                value: findDisciplineItem.image
            }
            if (i.key === 'rating') return {
                ...i,
                value: `${ editedData.ratingMin }-${ editedData.ratingMax }`
            }

            if (i.key === 'type') return {
                ...i,
                value: typeList[editedData.type]
            }

            if (i.key === 'dateStart') return {
                ...i,
                value: dateOperations.getFormattedDateByISO(dateOperations.getISODateByObj(new Date(editedData.dateStart)))
            }

            return i
        })

        setIsList(listUpdate)
    }

    function getListApplications() {
        $getTournamentsRequestList(
            {
                page: String(activePage),
                id: editedData.id
            }
        ).then(res => {
            if (!res.result) return;

            const totalPages = Math.ceil(res.count / 15);

            setTotalPages(res.totalPages || totalPages || 1);

            setTournamentsRequestList(res.result)
        })
    }

    function onClickBack() {
        navigate('/tournaments/list')
    }

    function onClickMesh() {
        navigate('/tournaments/grid', {
            state: {
                data: editedData
            },
        });
    }

    function onClickAccept(item: TournamentRequestModel) {
        if (!item.id) return;

        $updateStatusRequestTournaments(item.id, {
            status: 1
        }).then(res => {
            if (!res.id) return;

            getListApplications();
        })
    }

    function onClickRemove(item: TournamentRequestModel) {
        if (!item.id) return;

        $updateStatusRequestTournaments(item.id, {
            status: -1
        }).then(res => {
            if (!res.id) return;

            getListApplications();
        })
    }

    function onClickStart() {
        const requestData = {
            id: editedData.id,
            status: 1
        }

        $startTournament(requestData).then(res => {
            if (!res.id) return;

            setIsTournamentsStatus(res.status);
        })
    }

    function onClickCancel() {
        const requestData = {
            id: editedData.id,
            status: -1
        }

        $stopTournament(requestData).then(res => {
            if (!res.id) return;

            setIsTournamentsStatus(res.status);
        })
    }

    return (
        <div className="edit__tournament">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="tournament__edit__back">
                            <Button
                                text={ 'Назад' }
                                leftIcon={ (
                                    <ArrowLeft
                                        color={ '#3582F6' }
                                    />
                                ) }
                                onClick={ onClickBack }
                                className={ 'btn btn-icon' }
                            />
                        </div>

                        <div className="edit__tournament__info">
                            <div className="edit__tournament__info__items">
                                <div className="edit__tournament__info__items__left">
                                    <img src={ editedData.image }/>
                                </div>

                                <div className="edit__tournament__info__items__header">
                                    <div className="edit__tournament__info__items__header__meta">
                                        <span className={'edit__tournament__info__items__header__status'} style={{
                                            backgroundColor: `${getColorByStatusTournaments()}20`,
                                            color: getColorByStatusTournaments()
                                        }}>{getTextByStatusTournaments()}</span>

                                        <h5>{ editedData.title }</h5>

                                        {editedData.isRanking && (
                                            <span className={'edit__tournament__info__items__header__status'} style={{
                                                backgroundColor: `#F9D44820`,
                                                color: '#F9D448'
                                            }}>
                                                Рейтинговый
                                            </span>
                                        )}
                                    </div>

                                    <div className="edit__tournament__info__items__header__map">
                                        { isList.map((item, idx) => {
                                            if (!item.value) return null;

                                            return (
                                                <div key={ `edit-tournament-list-tour-${ idx }` }>
                                                    <div
                                                        className={ `edit__tournament__info__items__header__map__list ${
                                                            (item.key === 'rating')
                                                                ? 'edit__tournament__info__items__header__map__star'
                                                                : ''
                                                        }` }
                                                    >
                                                        { ((item.key === 'disciplineId') && item.value) && (
                                                            <div
                                                                className="edit__tournament__info__items__header__map__list__img">
                                                                <img src={ item.value }/>
                                                            </div>
                                                        ) }

                                                        { ((item.key === 'type') && item.value) && (
                                                            <p>{ item.value }</p>
                                                        ) }

                                                        { ((item.key === 'dateStart') && item.value) && (
                                                            <>
                                                                <MassageSecond/>

                                                                <p>{ item.value }</p>
                                                            </>
                                                        ) }

                                                        { ((item.key === 'rating') && item.value) && (
                                                            <div
                                                                className="edit__tournament__info__items__header__map__star__inner"
                                                            >
                                                                <StarsFull color={ '#3582F6' }/>

                                                                <span>{ item.value }</span>
                                                            </div>
                                                        ) }

                                                        { idx !== (isList.length - 1) && (
                                                            <div className="line__right"></div>
                                                        ) }
                                                    </div>
                                                </div>
                                            )
                                        }) }
                                    </div>

                                    <div className="edit__tournament__info__items__header__txt">
                                        <p>{ editedData.description }</p>
                                    </div>
                                </div>
                            </div>

                            <div className="edit__tournament__info__btn">
                                {isReadByRole && (
                                    <Button
                                        className={ 'btn btn-primary edit__tournament__info__btn-grid' }
                                        text={ 'Перейти к сетке турнира' }
                                        onClick={ onClickMesh }
                                    />
                                )}

                                {(isCreateRequestByRole || isCanselRequestByRole) && (
                                    <Button
                                        className={ `btn ${isTournamentsStatus === 1 ? 'btn-danger' : 'btn-primary'}` }
                                        text={ isTournamentsStatus === 1 ? 'Отменить турнир' : 'Начать прием заявок' }
                                        onClick={ isTournamentsStatus === 1 ? onClickCancel : onClickStart }
                                        disabled={[-1, 2, 3].includes(isTournamentsStatus)}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="edit__tournament__list">
                            <div className="edit__tournament__list__header">
                                <h2>Список заявок</h2>
                            </div>

                            <div className="row">
                                { tournamentsRequestList.map((item: TournamentRequestModel, idx) => (
                                    <div
                                        className="col-sm-6 col-md-6 col-xl-4 d-flex"
                                        key={ `tournaments-request-list-item-${ idx }` }
                                    >
                                        <div
                                            className="edit__tournament__list__team"
                                            style={{
                                                background: (item.status === 1)
                                                    ? ('linear-gradient(0deg, rgba(112, 213, 132, 0.10) 0%, rgba(112, 213, 132, 0.10) 100%), #292A2F')
                                                    : ((item.status === -1) ? 'linear-gradient(0deg, var(--red-20, rgba(255, 88, 88, 0.20)) 0%, var(--red-20, rgba(255, 88, 88, 0.20)) 100%), #292A2F' : '')
                                            }}
                                        >
                                            <CommandsItem
                                                item={ item }
                                            />

                                            {((isUpdateRequestByRole) && (editedData.status === 1)) && (
                                                <div className="edit__tournament__list__team__button">
                                                    { (item.status !== 1) && (
                                                        <Button
                                                            className={ 'btn btn-primary btn__tournament' }
                                                            text={ 'Принять' }
                                                            onClick={ () => onClickAccept(item) }
                                                        />
                                                    ) }

                                                    { (item.status !== -1) && (
                                                        <Button
                                                            className={ 'btn btn-danger m-0' }
                                                            text={ item.status === 1 ? (
                                                                <div className={'d-flex align-items-center'}>
                                                                    <Trash/>

                                                                    <p className={'pl-2'}>Исключить</p>
                                                                </div>
                                                            ) : <Trash/> }
                                                            onClick={ () => onClickRemove(item) }
                                                        />
                                                    ) }
                                                </div>
                                            ) }
                                        </div>
                                    </div>
                                )) }

                                { !tournamentsRequestList.length && (
                                    <div
                                        className="program__block__item__appellation w-100"
                                        style={{
                                            height: '50vh'
                                        }}
                                    >
                                        <p>
                                            Список заявок пуст
                                        </p>
                                    </div>
                                )}
                            </div>

                            {totalPages > 1 && (
                                <div className='logs__carousel'>
                                    <Pagination
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                        pages={totalPages}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditTournamentGame;
