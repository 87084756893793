import { IApiHandlerParams } from "../../interface";
import api from "../../../api";
import { DashboardOption, ProjectSettingModel, } from "./interface";

export const $updateSettings = (data: DashboardOption, handler?: IApiHandlerParams): Promise<ProjectSettingModel> => api
    .post('/settings/update', data, {
        handler
    })

export const $getSettingsList = (handler?: IApiHandlerParams): Promise<ProjectSettingModel[]> => api
    .get('/settings', {
        handler
    })

export const $getSettingsById = (type: string, handler?: IApiHandlerParams): Promise<ProjectSettingModel> => api
    .get('/settings/single/' + type, {
        handler
    })
