export default function Console({ color = "white", size = 20 }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 20 20`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.6665 14.1667V5.83333C1.6665 4.94928 2.01769 4.10143 2.64281 3.47631C3.26794 2.85119 4.11578 2.5 4.99984 2.5H8.24984C8.38245 2.5 8.50962 2.55268 8.60339 2.64645C8.69716 2.74021 8.74984 2.86739 8.74984 3V17C8.74984 17.1326 8.69716 17.2598 8.60339 17.3536C8.50962 17.4473 8.38245 17.5 8.24984 17.5H4.99984C4.11578 17.5 3.26794 17.1488 2.64281 16.5237C2.01769 15.8986 1.6665 15.0507 1.6665 14.1667Z"
				stroke={color}
				strokeWidth="1.25"
			/>
			<path
				d="M5.41683 6.66667C5.63784 6.66667 5.8498 6.57887 6.00608 6.42259C6.16237 6.26631 6.25016 6.05435 6.25016 5.83333C6.25016 5.61232 6.16237 5.40036 6.00608 5.24408C5.8498 5.0878 5.63784 5 5.41683 5C5.19582 5 4.98385 5.0878 4.82757 5.24408C4.67129 5.40036 4.5835 5.61232 4.5835 5.83333C4.5835 6.05435 4.67129 6.26631 4.82757 6.42259C4.98385 6.57887 5.19582 6.66667 5.41683 6.66667ZM14.5835 11.6667C14.8045 11.6667 15.0165 11.5789 15.1728 11.4226C15.329 11.2663 15.4168 11.0543 15.4168 10.8333C15.4168 10.6123 15.329 10.4004 15.1728 10.2441C15.0165 10.0878 14.8045 10 14.5835 10C14.3625 10 14.1505 10.0878 13.9942 10.2441C13.838 10.4004 13.7502 10.6123 13.7502 10.8333C13.7502 11.0543 13.838 11.2663 13.9942 11.4226C14.1505 11.5789 14.3625 11.6667 14.5835 11.6667Z"
				fill={color}
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.3333 14.1667V5.83333C18.3333 4.94928 17.9821 4.10143 17.357 3.47631C16.7319 2.85119 15.8841 2.5 15 2.5H11.75C11.6174 2.5 11.4902 2.55268 11.3964 2.64645C11.3027 2.74021 11.25 2.86739 11.25 3V17C11.25 17.1326 11.3027 17.2598 11.3964 17.3536C11.4902 17.4473 11.6174 17.5 11.75 17.5H15C15.8841 17.5 16.7319 17.1488 17.357 16.5237C17.9821 15.8986 18.3333 15.0507 18.3333 14.1667Z"
				stroke={color}
				strokeWidth="1.25"
			/>
		</svg>
	);
}
