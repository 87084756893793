import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useNavigate } from "react-router-dom";
import PlusBorder from "../../img/icon/PlusBorder";
import Trash from "../../img/icon/Trash";
import { $deleteQuestion, $getQuestionList } from "../../api/requests/faq";
import DateOperations from "../../mixins/date-operation";
import RoleUsers from "../../mixins/role";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

export const Questions: FC = () => {
    const isMobile = window.innerWidth <= 465;
    const navigate = useNavigate();

    const dateOperation = new DateOperations();
    const roleUsers = new RoleUsers();

    const userData = useSelector(GetCurrentUserData);

    const isCreateByRole = roleUsers.getModelRoleByType('faq', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('faq', 'update');

    const [ questionsList, setQuestionsList ] = useState<any[]>([]);

    const handleDelete = (itemToRemove: any) => {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteQuestion(itemToRemove.id).then(res => {
            if (!res.id) return;

            const updatedItems = questionsList.filter((list: any) => list !== itemToRemove);

            setQuestionsList(updatedItems);
        })
    }

    const handleEdit = (item: any) => {
        navigate('edit', {
            state: {
                current: 'edit',
                data: item
            },
        })
    }

    useEffect(() => init(), []);

    function init() {
        $getQuestionList().then(res => {
            if (!res.length) return;

            setQuestionsList(res)
        })
    }

    function onNavigate(link: string) {
        navigate(link);
    }

    return (
        <div className='questions list-wrapper'>
            <div className="booking__header">
                <h1>Вопросы</h1>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 questions-style'>
                        <div className='questions-top__btns'>
                            {/*<Button*/}
                            {/*    text={ 'Назад' }*/}
                            {/*    leftIcon={ (*/}
                            {/*        <ArrowLeft color={ '#3582F6' }/>*/}
                            {/*    ) }*/}
                            {/*    onClick={ () => onNavigate('/') }*/}
                            {/*    className={ 'btn btn-icon' }*/}
                            {/*/>*/}
                            <div>

                            </div>

                            <div className='game__header-right'>
                                {(isCreateByRole && userData?.staffCenter?.isMain) && (
                                    <Button
                                        className={ 'btn btn-primary ml-auto' }
                                        leftIcon={ <PlusBorder/> }
                                        text={ 'Добавить' }
                                        onClick={ () => (navigate('add')) }
                                    />
                                )}
                            </div>

                            {/*<div className='game__header-right'>*/}
                            {/*    {(isCreateByRole && userData?.staffCenter?.isMain) && (*/}
                            {/*        <Button*/}
                            {/*            className={ 'btn btn-primary ml-auto' }*/}
                            {/*            leftIcon={ <PlusBorder/> }*/}
                            {/*            text={ 'Добавить' }*/}
                            {/*            onClick={ () => (navigate('add')) }*/}
                            {/*        />*/}
                            {/*    )}*/}
                            {/*</div>*/}
                        </div>

                        {!!questionsList.length && questionsList.map((item: any, idx, row) => (
                            <div
                                className={ idx + 1 === row.length
                                    ? 'questions__items questions__items-last'
                                    : 'questions__items' }
                                key={ `questions-item-${ idx }` }
                            >
                                <div className='questions__items-top'>
                                    <h1 className='questions__items-title'>
                                        { item.title }
                                    </h1>

                                    {(isUpdateByRole && userData?.staffCenter?.isMain && !isMobile) && (
                                        <div className='questions__items-btns'>
                                            <Button
                                                className={ 'questions__btn' }
                                                text={ 'Редактировать' }
                                                onClick={ () => handleEdit(item) }
                                            />

                                            {/*<div className='achievements-btn achievements__item-delete' onClick={() => handleDelete(item)} >*/ }
                                            {/*    <Trash />*/ }
                                            {/*</div>*/ }

                                            <Button
                                                onClick={ () => handleDelete(item) }
                                                className={ 'questions__btn' }
                                                text={ <Trash color={ 'red' }/> }
                                            />
                                        </div>
                                    )}
                                </div>

                                <p className='questions__items-desc'>
                                    { item.text }
                                </p>

                                <div className="questions__items-date">
                                    {(isUpdateByRole && userData?.staffCenter?.isMain && isMobile) && (
                                        <div className='questions__items-btns'>
                                            <Button
                                                className={ 'questions__btn' }
                                                text={ 'Редактировать' }
                                                onClick={ () => handleEdit(item) }
                                            />

                                            {/*<div className='achievements-btn achievements__item-delete' onClick={() => handleDelete(item)} >*/ }
                                            {/*    <Trash />*/ }
                                            {/*</div>*/ }

                                            <Button
                                                onClick={ () => handleDelete(item) }
                                                className={ 'questions__btn' }
                                                text={ <Trash color={ 'red' }/> }
                                            />
                                        </div>
                                    )}

                                    <p>{item.updatedAt && dateOperation.getFormattedDateByISO(dateOperation.getDate(item.updatedAt))}</p>
                                </div>
                            </div>
                        )) }

                        {!questionsList.length && (
                            <div className="program__block__item__appellation">

                                <p>
                                    Список вопросов пуст
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
