import { FC } from "react";
import { SettingsContent, SettingsHeader } from "../../components/settings";
import { SettingsPoint } from "../../components/settings/SettingPoint";
import { SettingSeason } from "../../components/settings/SettingSeason";
import DirectionPage from "../direction";

export const SettingsPage: FC = () => {

    return (
        <div className='settings'>
            <div className="booking__header">
                <h1>Бронирование</h1>
            </div>

            <SettingsHeader/>

            {/*<SettingsContent/>*/}

            <SettingsPoint/>

            {/*<DirectionPage/>*/}

            {/*<SettingSeason/>*/}
        </div>
    )
}
