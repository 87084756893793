export default function PlusBorder({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_172_143)">
                <path
                    d="M6.66663 10.0001H9.99996M9.99996 10.0001H13.3333M9.99996 10.0001V6.66675M9.99996 10.0001V13.3334M9.99996 18.3334C14.6025 18.3334 18.3333 14.6026 18.3333 10.0001C18.3333 5.39758 14.6025 1.66675 9.99996 1.66675C5.39746 1.66675 1.66663 5.39758 1.66663 10.0001C1.66663 14.6026 5.39746 18.3334 9.99996 18.3334Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_172_143">
                    <rect width={size} height={size} fill={color}
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
