import { FC } from 'react';
import Navigation from './Navigation';
import SidebarFooter from './SidebarFooter';
import NavigationSecond from "./NavigationSecond";

const AdminSidebar: FC = () => {
    return (
        <div className="admin-sidebar">
            <div className="sidebar sidebar-top">
                <Navigation />
            </div>

            <div>
                <div className="divider"></div>
            </div>

            <div className="sidebar sidebar-center">
                <NavigationSecond />
            </div>

            <div className="sidebar-bottom">
                <SidebarFooter />
            </div>
        </div>
    );
}

export default AdminSidebar;
