export default function Percentage({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M15.6667 4L4 15.6667M14 15.6667C13.558 15.6667 13.1341 15.4911 12.8215 15.1785C12.5089 14.866 12.3333 14.442 12.3333 14C12.3333 13.558 12.5089 13.1341 12.8215 12.8215C13.1341 12.5089 13.558 12.3333 14 12.3333C14.442 12.3333 14.866 12.5089 15.1785 12.8215C15.4911 13.1341 15.6667 13.558 15.6667 14C15.6667 14.442 15.4911 14.866 15.1785 15.1785C14.866 15.4911 14.442 15.6667 14 15.6667ZM5.66667 7.33333C5.22464 7.33333 4.80072 7.15774 4.48816 6.84518C4.17559 6.53262 4 6.10869 4 5.66667C4 5.22464 4.17559 4.80072 4.48816 4.48816C4.80072 4.17559 5.22464 4 5.66667 4C6.10869 4 6.53262 4.17559 6.84518 4.48816C7.15774 4.80072 7.33333 5.22464 7.33333 5.66667C7.33333 6.10869 7.15774 6.53262 6.84518 6.84518C6.53262 7.15774 6.10869 7.33333 5.66667 7.33333Z"
                      stroke={color}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}


