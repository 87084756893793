export default function Stock({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.3333 11.6667V5C18.3333 4.33696 18.0699 3.70107 17.6011 3.23223C17.1323 2.76339 16.4964 2.5 15.8333 2.5H7.5C6.83696 2.5 6.20107 2.76339 5.73223 3.23223C5.26339 3.70107 5 4.33696 5 5V10.8333"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.99984 17.4999H4.99984C4.11578 17.4999 3.26794 17.1487 2.64281 16.5236C2.01769 15.8985 1.6665 15.0506 1.6665 14.1666C1.6665 13.2825 2.01769 12.4347 2.64281 11.8096C3.26794 11.1844 4.11578 10.8333 4.99984 10.8333H14.9998C14.3406 10.8333 13.6961 11.0287 13.1479 11.395C12.5998 11.7613 12.1725 12.2819 11.9202 12.891C11.6679 13.5001 11.6019 14.1703 11.7306 14.8169C11.8592 15.4635 12.1766 16.0574 12.6428 16.5236C13.109 16.9898 13.7029 17.3073 14.3495 17.4359C14.9961 17.5645 15.6664 17.4985 16.2754 17.2462C16.8845 16.9939 17.4051 16.5666 17.7714 16.0185C18.1377 15.4703 18.3332 14.8259 18.3332 14.1666V11.6666"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
