import { IApiHandlerParams } from "../../interface";
import api from "../../../api";
import { DashboardCreateQuestion, FaqModel, } from "./interface";

export const $createQuestion = (data: DashboardCreateQuestion, handler?: IApiHandlerParams): Promise<FaqModel> => api
    .post('/question', data, {
        handler
    })

export const $updateQuestion = (id: string, data: DashboardCreateQuestion, handler?: IApiHandlerParams): Promise<FaqModel> => api
    .put('/question/single/' + id, data, {
        handler
    })

export const $deleteQuestion = (id: string, handler?: IApiHandlerParams): Promise<FaqModel> => api
    .delete('/question/single/' + id, {
        handler
    })

export const $getQuestionList = (handler?: IApiHandlerParams): Promise<FaqModel[]> => api
    .get('/question', {
        handler
    })
