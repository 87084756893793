import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";

import TextField from "../form/TextField";
import FilterSelect from "../FilterSelect";
import { ToggleBlock } from "../form/ToggleBlock";
import TransformObjArray from "../..//mixins/transform-obj-array";
import { Editor } from "../form/Editor";
import ImageField from "../form/ImageField";
import ImageToBase64 from "../../mixins/image-to-base64";
import { $createPromotion, $getCategoryPromotionList, $updatePromotion } from "../../api/requests/promotion";
import { PromotionCategoryModel } from "../../api/requests/promotion/interface";

export const EditStocks: FC = () => {
    const isMobile = window.innerWidth < 465
    const location = useLocation();
    const navigate = useNavigate();

    const imageToBase64 = new ImageToBase64();

    const state: any = location.state;
    const isEditPage = state?.current === 'edit';

    const editedData = state?.data || {};

    const transformObjArray = new TransformObjArray();

    const [ formFieldList, setFormFieldList ] = useState([
        {
            key: 'title',
            placeholder: 'Название',
            title: 'Основная информация',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textFiled: true,
            required: true
        },
        {
            key: 'text',
            placeholder: 'Описание',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            editor: true,
            paddingBottom: 30,
            required: true
        },
        // {
        //     key: 'promotionCategoryId',
        //     placeholder: 'Выберите Категорию',
        //     value: '',
        //     setValue: (value: string, key: string) => onChangeSetValue(value, key),
        //     select: true,
        //     options: [],
        //     required: false
        // },
        {
            key: 'type',
            title: 'Дополнительно',
            placeholder: 'Тип ссылки',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            select: true,
            imgLeft: '',//<Info color={'#3582F6'}/>,
            options: [
                {value: 0, label: 'Внешняя ссылка'},
                {value: 1, label: 'Внутренняя ссылка'},
            ],
            required: true
        },
        {
            key: 'link',
            placeholder: 'Введите ссылку',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textFiled: true,
            required: true
        },
        {
            key: 'position',
            type: 'number',
            placeholder: 'Позиция акции',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textFiled: true,
            required: false
        },
        {
            key: 'isPinned',
            value: true,
            setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
            toggleText: 'Закрепить акцию',
            checkbox: true,
            required: false
        },
        {
            key: 'isActive',
            value: true,
            setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
            toggleText: 'Статус',
            checkbox: true,
            required: true
        },
    ]);

    const [ isLoading, setIsLoading ] = useState(false);
    const [ image, setImage ] = useState<any>(null);
    const [ isValidImage, setIsValidImage ] = useState<boolean>(false);

    useEffect(() => init(), []);

    function init() {
        $getCategoryPromotionList().then(res => {
            if (!res.result) return;

            const list = res.result.map((i: PromotionCategoryModel) => ({
                ...i,
                label: i.title,
                value: i.id
            }))

            const listUpdate = [ ...formFieldList ].map((i: any) => {
                if (editedData && Object.keys(editedData).includes(i.key)) i.value = editedData[i.key];

                if (i.key === "promotionCategoryId") {
                    i.options = list
                    i.value = list.find((_i: any) => _i.id === editedData[i.key])
                }

                if (i.options) {
                    const findItem: any = i.options.find((_i: any) => _i.value === editedData[i.key])

                    i.value = findItem || '';
                }

                return i;
            });

            setFormFieldList(listUpdate)
        })

        if (editedData.image) setImage(editedData.image);
    }

    function onChangeSetValue(value: string | boolean, key: string) {
        const listUpdate = [ ...formFieldList ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setFormFieldList(listUpdate)
    }

    function onClickBack() {
        navigate("/stocks");
    }

    function onClickEnter() {
        const requestData = {
            ...transformObjArray.getRequestObj(formFieldList),
            position: +transformObjArray.getRequestObj(formFieldList).position,
            image: imageToBase64.getImageCheckValid(image)
        };

        if (transformObjArray.formValidateByRequired(requestData, formFieldList, true)) {
            const obj = transformObjArray.formValidateByRequired(requestData, formFieldList)

            setIsValidImage(typeof obj === 'boolean' ? obj : false)

            return;
        }

        setIsLoading(true);

        if (isEditPage) return onEditData(requestData);

        onCreateSave(requestData)
    }

    function onCreateSave(request: any) {
        $createPromotion(request).then(res => {
            setIsLoading(false);

            if (!res.id) return;
            onClickBack();
        })
    }

    function onEditData(request: any) {
        if (!editedData.id) return setIsLoading(false);

        $updatePromotion(editedData.id, request).then(res => {
            setIsLoading(false);

            if (!res.id) return;

            onClickBack();
        })
    }

    return (
        <div className='edit__stocks stocks__block'>
            <div className="edit__stocks-main">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='edit__stocks__header achievements__edit-header'>
                                <div className="edit__block__item__back">
                                    <Button
                                        text={!isMobile && 'Назад' }
                                        leftIcon={ (
                                            <ArrowLeft
                                                color={ '#3582F6' }
                                            />
                                        ) }
                                        onClick={ onClickBack }
                                        className={ 'btn btn-icon' }
                                    />
                                </div>

                                <div className="edit__stocks__item__header">
                                    <h2>{ isEditPage ? 'Редактирование' : 'Создание' } Акции</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row column'>
                        <div className='col-12 col-sm-7'>
                            <form className="edit__stocks-content">
                                { formFieldList.map((i, idx) => (
                                    <div key={ `form-field-achievement-item-${ idx }` }>
                                        { i.title && (
                                            <div className='edit__stocks-content__text'>
                                                <p>{ i.title }</p>

                                                { i.imgLeft }
                                            </div>
                                        ) }

                                        { i.textFiled && (
                                            <div
                                                className={ `${ i.paddingBottom ? 'achievements__edit-padding30px' : '' }` }>
                                                <TextField
                                                    type={ i.type }
                                                    className={ i.paddingBottom ? 'achievements__edit-comment' : '' }
                                                    placeholder={ i.placeholder }
                                                    value={ i.value }
                                                    onChangeValue={ (value) => i.setValue(value, i.key) }
                                                />
                                            </div>
                                        ) }


                                        { i.editor && (
                                            <div className='news__editor'>
                                                <Editor
                                                    value={ i.value }
                                                    onChangeValue={ (value) => i.setValue(value, i.key) }
                                                />
                                            </div>
                                        ) }

                                        { i.select && (
                                            <div className={ 'mb-3' }>
                                                <FilterSelect
                                                    placeholder={ i.placeholder }
                                                    value={ i.value }
                                                    options={ i.options }
                                                    onChange={ (_i) => i.setValue(_i, i.key) }
                                                />
                                            </div>
                                        ) }

                                        { i.checkbox && (
                                            <div className={ 'mb-3' }>
                                                <ToggleBlock
                                                    desc={ i.toggleText }
                                                    isChecked={ i.value }
                                                    handleChange={ (_i) => i.setValue(_i, i.key) }
                                                />
                                            </div>
                                        ) }
                                    </div>
                                )) }

                                <div className="edit__block__item__edit">
                                    <div className={ 'achievements__edit-button' }>
                                        <Button
                                            loading={ isLoading }
                                            onClick={ onClickEnter }
                                            className={ 'btn btn-primary' }
                                            text={ isEditPage ? 'Изменить' : 'Создать' }
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-xl-4 col-sm-5 edit__stocks-style">
                            <div className="edit__stocks__right-content">
                                <div className="edit__stocks__header__item__cover">
                                    <h3>Обложка акции</h3>

                                    {/*<Info*/ }
                                    {/*    color={'#3582F6'}*/ }
                                    {/*/>*/ }
                                </div>

                                <ImageField
                                    label={ '512х512' }
                                    upload={ image }
                                    setUpload={ (image) => {
                                        setImage(image)
                                        setIsValidImage(false);
                                    } }
                                />

                                { isValidImage && (
                                    <p className={ 'mt-2' } style={ {color: 'red'} }>Обложка обязательно для
                                        заполнения</p>
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
