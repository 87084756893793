import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import CreateGroupDialog from "../../components/computers/CreateGroupDialog";
import AddedNewDeviceDialog from "./AddedNewDeviceDialog";
import AllGroupsDialog from "./AllGroupsDialog";
import { useSelector } from "react-redux";
import { changeDeviceItem, getDeviceData } from "../../redux/slices/deviceSlice";
import RoleUsers from "../../mixins/role";
import { useAppDispatch } from "../../redux/store";

interface ComputersHeaderBtnProps {
    btnList: any[]
    setUpdateList: () => void
    setSelectFilterGroup: (list: any) => void
    type: string
}

const ComputersHeaderBtn: FC<ComputersHeaderBtnProps> = (
    {
        btnList,
        setUpdateList,
        setSelectFilterGroup,
        type
    }
) => {
    const deviceData = useSelector(getDeviceData);
    const dispatch = useAppDispatch();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('fitZone', 'create');

    const [isShowCreateGroupsModal, setIsShowCreateGroupsModal] = useState(false);
    const [isShowAllGroupsModal, setIsShowAllGroupsModal] = useState(false);
    const [isShowAddModal, setIsShowAddModal] = useState(false);
    const [activeNameBtnName, setActiveNameBtnName] = useState([btnList[0]?.id]);

    const [zoneList, setZoneList] = useState<any[]>([]);

    useEffect(() => {
        if (deviceData) onClickCreatePc()
    }, [deviceData])

    useEffect(() => {
        if (btnList) {
            const listUpdate: any[] = [];

            activeNameBtnName.forEach(i => {
                if (i) listUpdate.push(i)
            })

            listUpdate.length && setSelectFilterGroup(listUpdate)
        }
    }, [btnList])

    function onClickSelect(i: any) {
        setActiveNameBtnName((list) => {
            if (i.name === "Все группы") {
                setSelectFilterGroup([]);
                return []
            }

            const findActive = list.findIndex(id => id === i.id);

            if (findActive !== -1) list.splice(findActive, 1);
            else list.push(i.id);

            setSelectFilterGroup(list);

            return list;
        });
    }

    function onClickCreateGroup() {
        setIsShowCreateGroupsModal(true);
    }

    function onClickAllGroup() {
        setIsShowAllGroupsModal(true);
    }

    function onClickCreatePc() {
        const list = [...btnList].filter(i => i.name !== "Список групп")

        setZoneList(list.map(i => ({
            ...i,
            value: i.id,
            label: i.name,
        })))

        setIsShowAddModal(true);
    }

    return (
        <div className="computers__header">
            <div className="computers__header-left">
                {btnList.map((i, idx) => {
                    if (idx === 3) return (
                        <Button
                            key={`computers-btn-list-${idx}`}
                            text={'Список групп'}
                            onClick={onClickAllGroup}
                            className={`btn btn-primary`}
                        />
                    )

                    if (idx > 2) return null;

                    return (
                        <Button
                            key={`computers-btn-list-${idx}`}
                            text={i.name}
                            onClick={() => onClickSelect(i)}
                            className={`btn btn-link ${(activeNameBtnName.includes(i.id)) && 'active'}`}
                        />
                    )
                })}
            </div>

            <div className="computers__header-right">
                {isCreateByRole && (
                    <Button
                        text={'Добавить устройство / зону'}
                        onClick={onClickCreatePc}
                        className={'btn btn-link'}
                    />
                )}

                <Button
                    text={'Добавить зону'}
                    onClick={onClickCreateGroup}
                    className={'btn btn-primary'}
                />
            </div>

            <AllGroupsDialog
                isShow={isShowAllGroupsModal}
                setIsShow={(item: any) => {
                    setIsShowAllGroupsModal(false);
                }}
                btnList={btnList}
                onClickSelect={onClickSelect}
                activeNameBtnName={activeNameBtnName}
            />

            <CreateGroupDialog
                isShow={isShowCreateGroupsModal}
                setIsShow={(item: any) => {
                    setIsShowCreateGroupsModal(false);

                    item && setUpdateList()
                }}
                type={type}
            />

            <AddedNewDeviceDialog
                deviceData={deviceData}
                list={zoneList}
                isShow={isShowAddModal}
                setIsShow={(item: any) => {
                    setIsShowAddModal(false);
                    dispatch(changeDeviceItem(false))

                    item && setUpdateList()
                }}
                type={type}
            />
        </div>
    );
}

export default ComputersHeaderBtn;

