import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterSelect from "../../components/FilterSelect";
import Button from "../../components/form/Button";
import { TariffsList } from "../../components/tariffs2/TariffsList";
import DatePickerTimeField from "../../components/form/DatePickerTimeField";
import { rateModel } from "../../api/requests/rate/interface";
import { $getCurrentNoDeviceZone } from "../../api/requests/zone";
import { $getCurrentRate } from "../../api/requests/rate";
import RoleUsers from "../../mixins/role";
import Pagination from "../../components/form/Pagination";
import CheckBox from "../../components/form/CheckBox";

export const Tariffs2: FC = () => {
    const isMobile = window.innerWidth < 465
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('tariffs', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('tariffs', 'update');

    const [isCheckedActive, setIsCheckedActive] = useState(false);
    const [isCheckedDiscount, setIsCheckedDiscount] = useState(false);

    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [ filterType, setFilterType ] = useState<any>(-1);

    const zone = [
        {value: null, label: 'Выбор'},
        {value: -1, label: 'Зал'},
        {value: 0, label: 'Не оплачено'},
        {value: 3, label: 'Оплачено'},
    ];

    const [ zones, setZones ] = useState<any[]>([])
    const [ list, setList ] = useState<any[]>([])

    const handleFilter = (type: any) => {
        setFilterType(type);
    };

    const filteredItems = list.filter((item: any) => {

        switch (filterType) {
            case 'all':
                return item.isActive && item.applyDiscount;
            case 'active':
                return item.isActive;
            case 'discount':
                return item.applyDiscount;
            default:
                return true;
        }
    });

    const handleBoxChange = (isActive: any, isDiscount: any) => {
        setIsCheckedActive(isActive)
        setIsCheckedDiscount(isDiscount)

        switch (true) {
            case isActive && isDiscount:
                handleFilter('all');
                break;
            case isActive:
                handleFilter('active');
                break;
            case isDiscount:
                handleFilter('discount');
                break;
            default:
                handleFilter(-1);
        }
    }
    const getZoneByTariff = (
        item: any,
        idx: number,
        zones: any[]
    ): any => {
        const findItem: any = zones.find((i: any) => i.id === item.zoneId)

        return {
            ...item,
            id: idx,
            zone: findItem?.name || '',
            name: findItem?.name,
        }
    }

    const getTariff = (item: rateModel, zone: any[]): any => {
        return {
            ...item,
            type: item.type,
            prices: item?.prices.map((
                i: any,
                idx
            ) => getZoneByTariff(i, idx, zone)),
            durationType: item.durationType,
            availableFrom: item.availableFrom,
            availableTo: item.availableTo,
        }
    }

    useEffect(() => init(), [activePage]);

    function init() {
        $getCurrentNoDeviceZone().then(response => {
            if (!response.length) return

            setZones(response)

            $getCurrentRate({ page: String(activePage) }).then(_response => {
                if (!_response.result) return;

                const totalPages = Math.ceil(_response.count / 15);

                setTotalPages(_response.totalPages || totalPages || 1);

                const list = [ ..._response.result ].map(i => getTariff(i, response))

                setList(list)
            })
        })
    }

    function onClickCreate() {
        navigate('add');
    }

    function handleEdit(data: any) {
        if (!isUpdateByRole) return;

        navigate('edit', {
            state: {
                current: 'edit',
                data: data
            },
        })
    }

    return (
        <div className='tariffs list-wrapper'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='tariffs__header'>
                            {isMobile && (
                                <h3 className={'tariffs__header-title'}>
                                    Тарифы
                                </h3>)}

                            <div className='tariffs__header-left'>
                                {/*<div className='tariffs__header-select'>*/ }
                                {/*    <FilterSelect
                                 options={zones}
                                 onChange={() => (console.log('handleChange'))}
                                 />*/ }
                                {/*</div>*/ }

                                <div className='tariffs__header-checkboxes'>
                                    <CheckBox
                                        isChecked={isCheckedActive}
                                        text={ 'Активные' }
                                        onChange={(e) => handleBoxChange(e, isCheckedDiscount)}
                                    />
                                    {/*<CheckBox */}
                                    {/*    text={ 'Платные' } */}
                                    {/*    onChange={ () => {} } */}
                                    {/*/>*/}
                                    {/*<CheckBox*/}
                                    {/*    isChecked={isCheckedDiscount}*/}
                                    {/*    text={ 'Скидка/Промокод' }*/}
                                    {/*    onChange={(e) => handleBoxChange(isCheckedActive, e )}*/}
                                    {/*/>*/}
                                </div>
                            </div>

                            {isCreateByRole && (
                                <div className="tariffs__header__inner">
                                    <Button
                                        text={ 'Добавить тариф' }
                                        className={ 'tariffs__header-btn btn btn-primary' }
                                        onClick={ onClickCreate }
                                    />
                                </div>
                            )}
                        </div>

                        <div className='tariffs__content'>
                            <div className="row">
                                { filteredItems.map((
                                    item: any,
                                    idx: number
                                ) => (
                                    <div
                                        onClick={ () => handleEdit(item) }
                                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12"
                                        key={ `tariffs-item-${ idx }` }
                                    >
                                        <div className="tariffs__blocks">
                                            <div className={'tariffs__block'}>
                                                <TariffsList
                                                    item={ item }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>

                        {(totalPages > 1) && (
                            <div className='logs__carousel'>
                                <Pagination
                                    activePage={activePage}
                                    setActivePage={setActivePage}
                                    pages={totalPages}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
