import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useNavigate } from "react-router-dom";
import { $deleteDiscipline, $getListDiscipline } from "../../api/requests/discipline";
import DeleteDiscipline from "./DeleteDiscipline";
import PlusBorder from "../../img/icon/PlusBorder";
import RoleUsers from "../../mixins/role";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import Pen from "../../img/icon/Pen";

const DisciplinePage: FC = () => {
    const isMobile = window.innerWidth < 465
    const roleUsers = new RoleUsers();

    const userData = useSelector(GetCurrentUserData);

    const isCreateByRole = roleUsers.getModelRoleByType('disciplines', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('disciplines', 'update');

    const [ list, setList ] = useState<any[]>([]);

    const navigate = useNavigate();

    const onNavigate = (link: string) => {
        navigate(link)
    }

    const [ filterType, setFilterType ] = useState(-1);

    const filteredItems = list.filter((item: any) => {
        return filterType === -1 || item.type === filterType
    });

    useEffect(() => init(), []);

    function init() {
        $getListDiscipline().then(res => {
            if (!res.length) return;

            setList(res);
        })
    }

    function handleEdit(data: any) {
        navigate('edit', {
            state: {
                current: 'edit',
                data: data
            },
        })
    }

    function onDelete(itemToDelete: any) {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteDiscipline(itemToDelete.id).then(res => {
            if (!res.id) return;

            const updatedItems = list.filter(item => item !== itemToDelete);

            setList(updatedItems);
        })
    }

    return (
        <div className="discipline list-wrapper">
            <div className="discipline-main">
                <div className="discipline__add">
                    <div className="booking__header">
                        <h1>Дисциплина</h1>
                    </div>

                    {(isCreateByRole && userData?.staffCenter?.isMain) && (
                        <div className="discipline__add__btn">
                            <Button
                                className={ 'btn btn-primary' }
                                text={ 'Добавить дисциплину' }
                                onClick={ () => onNavigate('create') }
                                leftIcon={ <PlusBorder/> }
                            />
                        </div>
                    )}

                    <div className={ 'discipline__add__list' }>
                        <div className="row w-100">
                            { !!filteredItems.length && filteredItems.map((item, idx) => (
                                <div
                                    className={ 'col-6 col-lg-5 col-sm-6  col-xl-4' }
                                    key={ `discipline-item-${ idx }` }
                                >
                                    <div className="discipline__add__list__wrap">
                                        <div className="discipline__add__list__wrap__inner">
                                            <div className='discipline__add__list__wrap__inner__info'>
                                                <div className="discipline__add__list__wrap__inner__info__img">
                                                    <div style={ {
                                                        backgroundImage: `url(${ item.image })`
                                                    } }></div>
                                                    {/*<img src={ item.image }/>*/ }
                                                </div>

                                                <div className="discipline__add__list__wrap__inner__info__txt">
                                                    <p>{ item.name }</p>
                                                </div>

                                                <div className="discipline__add__list__wrap__inner__info__title">
                                                    <span>{ item.subName }</span>
                                                </div>

                                                {(isUpdateByRole && userData?.staffCenter?.isMain) && (
                                                    <div className="discipline__add__list__wrap__inner__info__button">
                                                        <Button
                                                            className={ 'btn btn-primary btn__discipline' }
                                                            text={isMobile ? <Pen/> : 'Редактировать' }
                                                            onClick={ () => handleEdit(item) }
                                                        />

                                                        <DeleteDiscipline
                                                            onDelete={ () => (onDelete(item)) }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>

                    { !filteredItems.length && (
                        <div className="program__block__item__appellation">
                            <p>
                                Список дисциплин пуст
                            </p>
                        </div>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default DisciplinePage;

