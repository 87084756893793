export default function PeopleDouble({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M5.83366 7.49984L10.0003 8.33317M10.0003 8.33317L14.167 7.49984M10.0003 8.33317V10.8332M10.0003 10.8332L8.33366 14.9998M10.0003 10.8332L11.667 14.9998M10.0003 18.3332C14.6028 18.3332 18.3337 14.6023 18.3337 9.99984C18.3337 5.39734 14.6028 1.6665 10.0003 1.6665C5.39783 1.6665 1.66699 5.39734 1.66699 9.99984C1.66699 14.6023 5.39783 18.3332 10.0003 18.3332Z"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2" d="M9.99967 5.83333C9.88917 5.83333 9.78319 5.78943 9.70505 5.71129C9.62691 5.63315 9.58301 5.52717 9.58301 5.41667C9.58301 5.30616 9.62691 5.20018 9.70505 5.12204C9.78319 5.0439 9.88917 5 9.99967 5C10.1102 5 10.2162 5.0439 10.2943 5.12204C10.3724 5.20018 10.4163 5.30616 10.4163 5.41667C10.4163 5.52717 10.3724 5.63315 10.2943 5.71129C10.2162 5.78943 10.1102 5.83333 9.99967 5.83333Z"
                      fill={color}
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}


