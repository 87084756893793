import { FC, useEffect } from 'react';
import Dashboard from "../../img/icon/Dashboard";
import Map from "../../img/icon/Map";
import Burger from "../../img/icon/Burger";
import Calendar from "../../img/icon/Calendar";
import { useLocation, useNavigate } from "react-router-dom";

interface MobileMenuProps {
    onClose: (is: boolean) => void
    isOpen: boolean
}

const MobileMenu: FC<MobileMenuProps> = ({onClose, isOpen}) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (window.innerWidth <= 768) onClose(false);
    }, [location])

    return (
        <div className="mobile__block">
            <div className="mobile__block__elem__block">
                <div className="mobile__block__menu">
                    <a onClick={ () => navigate("/dashboard") }>
                        <Dashboard/>
                        <p>Дашборд</p>
                    </a>
                    <a onClick={ () => navigate("/map/center/computers") }>
                        <Map/>
                        <p>Карта центра</p>
                    </a>
                </div>

                <div className="mobile__block__burger" onClick={ () => onClose(!isOpen) }>
                    <Burger/>
                </div>

                <div className="mobile__block__menu">
                    <a onClick={ () => navigate("/booking") }>
                        <Calendar/>
                        <p>Бронирование</p>
                    </a>
                    <a onClick={ () => navigate("/tournaments/list") }>
                        <Map/>
                        <p>Турниры</p>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;
