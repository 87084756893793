export default function NonCash({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector"
                      d="M4.16602 15.8333V2.5H15.8327V15.8333C15.8327 16.2754 15.6571 16.6993 15.3445 17.0118C15.032 17.3244 14.608 17.5 14.166 17.5H5.83268C5.39065 17.5 4.96673 17.3244 4.65417 17.0118C4.34161 16.6993 4.16602 16.2754 4.16602 15.8333Z"
                      stroke={color}
                      strokeWidth="1.25"
                />
                <path id="Vector_2"
                      d="M4.16602 5H2.91602C2.58449 5 2.26655 4.8683 2.03213 4.63388C1.79771 4.39946 1.66602 4.08152 1.66602 3.75C1.66602 3.41848 1.79771 3.10054 2.03213 2.86612C2.26655 2.6317 2.58449 2.5 2.91602 2.5H17.0827C17.4142 2.5 17.7321 2.6317 17.9666 2.86612C18.201 3.10054 18.3327 3.41848 18.3327 3.75C18.3327 4.08152 18.201 4.39946 17.9666 4.63388C17.7321 4.8683 17.4142 5 17.0827 5H15.8327"
                      stroke={color}
                      strokeWidth="1.25"
                />
                <path id="Vector_3" d="M12.5 2.5V17.5" stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
