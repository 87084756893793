import React, {FC} from "react";


const DashboardHeader: FC = () => {
    return (
        <div className="dashboard__header">
            <div className="dashboard__header__subject">
                <h2>Дашборд</h2>
            </div>
        </div>
    );
}

export default DashboardHeader;