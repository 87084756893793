import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

const initialState: any = {
    loading: false,
    data: null,
    error: null,
    success: false,
};

const userSlice = createSlice({
    name: 'userEdit',
    initialState,
    reducers: {
        changeUserEdit: (state, action: PayloadAction<boolean>) => {
            state.data = action.payload;
        },
    }
});
export const { changeUserEdit } = userSlice.actions;

export const getUserEditData = (state: RootState) => state.user.data;

export default userSlice.reducer;
