import React, { FC, ReactElement, useEffect } from 'react';
import NavItem from '../NavItem';
import Dashboard from "../../img/icon/Dashboard";
import Game from "../../img/icon/Game";
import Map from "../../img/icon/Map";
import Calendar from "../../img/icon/Calendar"
import Users from "../../img/icon/Users";
import Pc from "../../img/icon/Pc";
import Console from "../../img/icon/Console";
import Vr from "../../img/icon/Vr";
import GameZone from "../../img/icon/GameZone";
import Seasons from "../../img/icon/Seasons";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import Tournament from "../../img/icon/Tournament";
import Center from "../../img/icon/Center";

export interface NavItemList extends NavItemProps {
    dropdownList?: NavItemProps[]
}

export interface NavItemProps {
    link: string
    icon: string | ReactElement
    title: string
    key?: string | string[]
    dropdownList?: NavItemProps[]
}

const navigationItems: NavItemList[] = [
    {
        link: '/dashboard',
        icon: (<Dashboard/>),
        title: 'Дашборд',
        key: 'dashboard'
    },
    {
        link: '/non/game',
        icon: (<Center/>),
        title: 'Центры',
        key: 'center'
    },
    {
        link: '/map/center',
        icon: (<Game/>),
        title: 'Карта Центра',
        key: 'mapCenter',
        dropdownList: [
            {
                link: '/map/center/computers',
                icon: (<Pc/>),
                title: 'ПК'
            },
            {
                link: '/map/center/console',
                icon: (<Console/>),
                title: 'Консоли'
            },
            {
                link: '/map/center/vr',
                icon: (<Vr/>),
                title: 'VR'
            }
        ]
    },
    {
        link: '',
        icon: (<Map/>),
        title: 'Зоны',
        dropdownList: [
            {
                link: '/computers',
                icon: (<Pc/>),
                title: 'ПК',
                key: ''
            },
            {
                link: '/console',
                icon: (<Console/>),
                title: 'Консоли',
                key: ''
            },
            {
                link: '/vr',
                icon: (<Vr/>),
                title: 'VR',
                key: ''
            },
            {
                link: '/open-area',
                icon: (<GameZone/>),
                title: 'Спортивные зоны',
                key: ''
            }
        ],
        key: 'fitZone'
    },
    {
        link: '/booking',
        icon: (<Calendar/>),
        title: 'Бронирование',
        key: 'booking'
    },
    // {
    //     link: '/cash',
    //     icon: (<Cash/>),
    //     title: 'Касса',
    // },
    {
        link: '/users',
        icon: (<Users/>),
        title: 'Пользователи',
        key: 'users'
    },
    {
        link: '/seasons',
        icon: (<Seasons/>),
        title: 'Сезоны',
        key: 'seasons'
    },
    {
        link: '/tournaments/list',
        icon: (<Tournament/>),
        title: 'Турниры',
        key: 'tournaments'
    }
];

const Navigation: FC = () => {
    const userData = useSelector(GetCurrentUserData);

    if (!userData) return null;

    const permissionsList = (userData && userData?.permissions) && Object.keys(userData?.permissions) || [];

    return (
        <div className="navigation">
            { navigationItems.map((item, index) => {
                if ((permissionsList && item.key) && permissionsList.includes(item.key)) {
                    if (Array.isArray(item.key)) {
                        const hideBlock = item.key.every(key => userData?.permissions[key].read === false);

                        if (hideBlock) return null;
                    } else if (!userData?.permissions[item.key].read) return null;
                }

                return (
                    <NavItem
                        key={ `nav-${ index }` }
                        link={ item.link }
                        icon={ item.icon }
                        title={ item.title }
                        dropdownList={ item.dropdownList }
                    />
                )
            }) }
        </div>
    );
}

export default Navigation;
