import { FC, useEffect, useState } from "react";
import DatePickerField from "../form/DatePickerField";
import Button from "../form/Button";
import TableContainer from "../form/TableContainer";
import { $getStatisticShift } from "../../api/requests/reports";
import DateOperations from "../../mixins/date-operation";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import { FilterDate } from "./filter-date/FilterDate";

export const CollectionReport: FC = () => {
    const dateOperation = new DateOperations();

    const userData = useSelector(GetCurrentUserData);
    const timeZone = userData?.staffCenter?.timezone || 0;

    const [ dateStart, setDateStart ] = useState<Date>(new Date());
    const [ dateEnd, setDateEnd ] = useState<Date>(new Date());

    const [ isLengthList, setIsLengthList ] = useState<boolean>(false);

    const [ headerTable, setHeaderTable ] = useState([
        {
            key: 'date',
            title: 'Дата'
        },
        {
            key: 'userId',
            title: 'Пользователь'
        },
        {
            key: 'tillWithdrawal',
            title: 'Сумма до снятия'
        },
        {
            key: 'withdrawal',
            title: 'Сумма снятия'
        }
    ])

    const [ contentTable, setContentTable ] = useState([
        // {
        //     date: '30.08.2023 | 19:43',
        //     userId: 'admin',
        //     tillWithdrawal: '1.000 ₽',
        //     withdrawal: '2.000 ₽'
        // },
        // {
        //     date: '30.08.2023 | 19:43',
        //     userId: 'admin',
        //     tillWithdrawal: '1.000 ₽',
        //     withdrawal: '2.000 ₽'
        // }
    ])

    const onChangeResponseItem = (item: any): any => {
        return {
            date: dateOperation.getFormattedDateAndTime(item.startAt, timeZone) || 'Неизвестно',
            userId: `${ item?.user?.surname || '' } ${ item?.user?.name || '' } ${ item?.user?.lastName || '' }`,
            tillWithdrawal: `${ item.revenueCash || 0 } ₽`,
            withdrawal: `${ item.revenueCard || 0 } ₽`,
        }
    }

    useEffect(() => init(), []);

    function init() {
        const requestObj = {
            fromDate: dateOperation.getISODateTimeByObj(dateStart),
            toDate: dateOperation.getISODateTimeByObj(dateEnd),
        }

        setIsLengthList(true);

        // $getStatisticShift(requestObj).then(res => {
        //     if (!res.length) return setIsLengthList(true);
        //
        //     setContentTable(res.map(i => i && onChangeResponseItem(i)))
        // })
    }

    return (
        <div className='reports__shift'>
            <FilterDate
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                init={init}
            />

            { !isLengthList && <h1 className='reports__shift-title'>Инкассация</h1> }

            { !isLengthList ? (
                <div className='reports__shift-content'>
                    { contentTable.map((item: any, idx) => (
                        <TableContainer
                            key={ idx }
                            sortable={ false }
                            header={ headerTable }
                            content={ [ item ] }
                            onLeftClickTrContent={ () => ('onLeftClickTrContent') }
                            isLastRightText={ false }
                        />
                    )) }

                </div>
            ) : (
                <div className='reports__shift-notFound'>
                    За выбранный период данных нет.
                </div>
            ) }
        </div>
    )
}
