import React, { FC, useState } from "react";
import Button from "../form/Button";
import TextField from "../form/TextField";
import { $shiftOpen } from "../../api/requests/shift";

interface ShiftOpenProps {
    closeDialog: () => void
}

export const ShiftOpen: FC<ShiftOpenProps> = (
    {
        closeDialog
    }
) => {
    const [ isLoadingRequestShiftClose, setIsLoadingRequestShiftClose ] = useState<boolean>(false);

    const [ valueCash, setValueCash ] = useState<string>('');

    const getRequestCloseData = () => {
        return {
            "cashOnHand": +valueCash,
        }
    }

    function onCloseComplete() {
        setIsLoadingRequestShiftClose(true);

        $shiftOpen(getRequestCloseData()).then((res) => {
            if (res.result) return;

            setIsLoadingRequestShiftClose(false);

            closeDialog();
        })
    }

    return (
        <div className='shift__open'>

            <TextField
                className='shift__close-comment'
                type='number'
                label={ 'Введите сумму' }
                value={ valueCash }
                onChangeValue={ setValueCash }
            />

            {/*<div className='shift__open-project'>*/}
            {/*    <p>test</p>*/}
            {/*    <p>test</p>*/}
            {/*</div>*/}

            <Button
                loading={isLoadingRequestShiftClose}
                className='btn btn-primary shift__button'
                text={ 'Открыть смену' }
                onClick={ onCloseComplete }
            />
        </div>
    )
}
