import React, { FC, useState } from "react";
import Button from "../form/Button";
import Calculator from "../../img/icon/Calculator";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import PcCheck from "../../img/icon/PcCheck";
import Info from "../../img/icon/Info";
import Security from "../../img/icon/Security";

export const SettingsHeader: FC = () => {
    const [ currentLink, setCurrentLink ] = useState('')
    const navigate = useNavigate()

    const items = [
        {
            title: 'Панель управления',
            link: '',
            icon: <Calculator/>
        },
        // {
        //     title: 'Касса',
        //     link: '/shell',
        //     icon: <Calculator/>
        // },
        // {
        //     title: 'Шелл',
        //     link: '/shell',
        //     icon: <PcCheck/>
        // },
        // {
        //     title: 'Безопасность',
        //     link: '/shell',
        //     icon: <Security/>
        // },
        // {
        //     title: 'Уведомления',
        //     link: '/shell',
        //     icon: <Info/>
        // },
    ]

    const onNavigate = (link: any,) => {
        navigate(`/settings${ link }`)

        setCurrentLink(`${ link }`)
    }

    const renderedItems = items.map((item, idx) => {
        const isActive = item.link === currentLink

        return (
            <Button
                key={`setting-header-btn-item-${idx}`}
                className={ cn(isActive ? 'settings__header-active' : 'settings__header-button') }
                text={ item.title }
                // onClick={ () => onNavigate(item.link) }
                leftIcon={ item.icon }
            />
        )
    })

    return (
        <div className='col-12 col-md-9 settings__header'>
            {/*{ renderedItems }*/}
        </div>
    )
}
