import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import {
    BookingCenterGet,
    BookingLink,
    BookingZone,
    CalculateBookingData,
    DataBookingSecond
} from "./interface";

export const $getBooking = (params: string, handler?: IApiHandlerParams): Promise<Array<BookingZone>> => api
    .get('/center/booking/byDate/' + params, {
		// mock: require('./mocks/center.booking.date.json'),
        handler
    })

export const $getListBooking = (id: number, params?: BookingCenterGet, handler?: IApiHandlerParams): Promise<DataBookingSecond> => api
    .get('/center/booking/single/' + id, {
        request: {params},
        handler
    })

export const $updateBooking = (id: number, data: BookingLink, handler?: IApiHandlerParams): Promise<any> => api
    .put('/center/booking/single/' + id, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })

export const $deleteBooking = (id: number, params?: BookingCenterGet, handler?: IApiHandlerParams): Promise<DataBookingSecond> => api
    .delete('/center/booking/single/' + id, {
        // mock: require('./mocks/error.delete.json'),
        request: {params},
        handler
    })

export const $bookingCreating = (data: BookingLink, handler?: IApiHandlerParams): Promise<BookingZone> => api
    .post('/center/booking', data, {
        handler
    })


export const $bookingCalculate = (data: BookingLink, handler?: IApiHandlerParams): Promise<CalculateBookingData> => api
    .post('/center/booking/calculation', data, {
        handler
    })



