import React, { FC, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import AdminSidebar from '../components/sidebar/AdminSidebar';
import Header from "../components/header/Header";
import MobileMenu from "../components/sidebar/MobileMenu";
import { CSSTransition } from 'react-transition-group';
import ScrollToTop from "../components/ScrollToTop";
import HeaderMenu from "../components/header/HeaderMenu";
import Exit from "../img/icon/Exit";
import Cookies from "js-cookie";
import { resetUser } from "../redux/slices/userSlice";
import Button from "../components/form/Button";
import { useAppDispatch } from "../redux/store";

const AdminLayout: FC = () => {
    const isMobile = window.innerWidth < 767;
    const [ isShowOpen, setShowOpen ] = useState<any | boolean>(!!isMobile ? false : true);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setShowOpen(window.innerWidth > 767);
        };

        window.addEventListener('pageshow', handleResize);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('pageshow', handleResize);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!Cookies.get('token')) {
        return <Navigate to={'/auth'} replace />;
    }

    return (
        <div className='admin-layout'>
            <Header/>

            <div className="container-fluid p-0 admin-layout-container">
                <div className="row no-gutters admin-layout-row">
                    <CSSTransition
                        in={ isShowOpen }
                        timeout={ 700 }
                        classNames="block"
                        unmountOnExit
                    >
                        <div className={ `col-md-3 col-12 sidebar__content open` }>
                            <div className="sidebar__list">
                                <HeaderMenu/>

                                <div className="dividers"></div>

                                <div className="sidebar__list__item">
                                    <div className="sidebar__link">
                                        <AdminSidebar/>
                                    </div>
                                </div>

                                <div className="dividers"></div>

                                <div className="sidebar__list-exit">
                                    <Button
                                        className='header__aside-button red'
                                        leftIcon={ <Exit color={ '#FF5858' }/> }
                                        text={ 'Выйти' }
                                        onClick={ () => {
                                            Cookies.remove('token');
                                            dispatch(resetUser());
                                            navigate('/');
                                        } }
                                    />
                                </div>
                            </div>
                        </div>
                    </CSSTransition>

                    <div className="col-12 col-md-9 layout-style">
                        <div className="content">
                            <Outlet/>
                        </div>
                    </div>
                </div>

                <MobileMenu
                    isOpen={ isShowOpen }
                    onClose={ (is) => {
                        setShowOpen(is)
                    } }
                />
            </div>

            <ScrollToTop/>
        </div>
    )
};

export default AdminLayout;
