import {IApiHandlerParams} from "../../interface";
import api from "../../../api";
import { DisciplineModel, DisciplineModelResponseData } from "./interface"

export const $disciplineCreate = (data: DisciplineModel, handler?: IApiHandlerParams) : Promise<DisciplineModelResponseData> => api
    .post('/discipline/single', data,{
        handler
    })

export const $updateDiscipline = (id: number, data: DisciplineModel, handler?: IApiHandlerParams) : Promise<DisciplineModelResponseData> => api
    .put('/discipline/single/' + id, data,{
        handler
    })

export const $deleteDiscipline = (id: number, handler?: IApiHandlerParams): Promise<DisciplineModelResponseData> => api
    .delete('/discipline/single/' + id, {
        handler
    })

export const $getDiscipline = (id: number, handler?: IApiHandlerParams) : Promise<DisciplineModelResponseData> => api
    .get('/discipline/single/' + id, {
        handler
    })

export const $getListDiscipline = (handler?: IApiHandlerParams) : Promise<DisciplineModelResponseData[]> => api
    .get('/discipline', {
        handler
    })
