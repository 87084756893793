import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useNavigate } from "react-router-dom";
import PlusBorder from "../../img/icon/PlusBorder";
import { AchievementsItems } from "../../components/achievements/AchievementsItems";
import Setting from "../../img/icon/Setting";
import CreateCategoryDialog from "../../components/news/CreateCategoryDialog";
import { $deleteNews, $getCategoryNews, $getNews } from "../../api/requests/news";
import AllGroupsDialog from "../../components/computers/AllGroupsDialog";
import RoleUsers from "../../mixins/role";
import { MobileAchievementsItems } from "../../components/achievements/MobileAchievementsItems";

export const NewsPage: FC = () => {
    const isMobile = window.innerWidth <= 465
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('news', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('news', 'update');

    const [ headerBtn, setHeaderBtn ] = useState<any[]>([]);

    const [ itemList, setItemList ] = useState<any[]>([]);
    const [ filteredItems, setFilterItems ] = useState<any[]>([]);

    const [ isShowCreateGroupsModal, setIsShowCreateGroupsModal ] = useState(false);
    const [ filterType, setFilterType ] = useState(null);
    const [ selectItem, setSelectItem ] = useState<any>(null)
    const [ isIconVisible, setIsIconVisible ] = useState(headerBtn.map(() => false));

    const [ isShowAllGroupsModal, setIsShowAllGroupsModal ] = useState(false);
    const [ activeNameBtnName, setActiveNameBtnName ] = useState<any[]>([]);

    const handleDelete = (itemToRemove: any) => {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteNews(itemToRemove.id).then(res => {
            if (!res.id) return;

            getList();
        })
    }

    const handleFilter = (type: any) => {
        setFilterType(type.id);

        const updateList = [ ...itemList ].filter((item: any) => {
            return !type.id || item.newsCategoryId === type.id;
        })
        setFilterItems(updateList)
    };

    function onClickSelect(
        isEdit: boolean,
        item?: any,
        e?: any
    ) {
        e.stopPropagation();
        setIsShowCreateGroupsModal(true);
        setIsShowAllGroupsModal(false)

        if (isEdit) {
            setSelectItem(item)
        }
    }

    const handleMouseEnter = (index: number) => {
        if (isShowCreateGroupsModal) {
            setIsIconVisible([])
        }
        const updatedVisibility: any = [ ...isIconVisible ];
        updatedVisibility[index] = true;

        setIsIconVisible(updatedVisibility);
    };

    useEffect(() => {
        handleMouseEnter(5)
    }, [ isShowCreateGroupsModal ])


    const handleMouseLeave = (index: number) => {
        const updatedVisibility = [ ...isIconVisible ];
        updatedVisibility[index] = false;
        setIsIconVisible(updatedVisibility);
    };

    useEffect(() => init(), []);

    function init() {
        getCategory();
        getList();
    }

    function getList() {
        $getNews().then(res => {
            if (!res.result) return;

            const listUpdate = res.result.map(i => ({
                ...i,
                // description: (<span dangerouslySetInnerHTML={{ __html: i.text }}></span>)
            }))

            setItemList(listUpdate)
            setFilterItems(listUpdate)
        })
    }

    function getCategory() {
        $getCategoryNews().then(res => {
            if (!res.result) return;

            const list: any[] = [
                {
                    title: 'Все',
                    isActive: true,
                    id: null
                }
            ]

            list.push(...res.result.map(i => ({
                ...i,
                name: i.title,
                isActive: i.isActive || false
            })))

            setHeaderBtn(list)
        })
    }

    function onClickAllGroup() {
        setIsShowAllGroupsModal(true);
    }

    function scrollTo(id: any) {
        const element: any = document.getElementById(id);
        if (element.style.className.hovered) {

        }
    }

    return (
        <div className='news list-wrapper'>
            <div className="news-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 news__bottom">
                            <div className="booking__header">
                                <h1>Новости</h1>
                            </div>

                            <div className="news__header">
                                <div className="news__header__left">
                                    {/*{ headerBtn.map((item, index: number) => {*/ }
                                    {/*    if (index === 3) return (*/ }
                                    {/*        <Button*/ }
                                    {/*            key={ `achievements-btn-list-${ index }` }*/ }
                                    {/*            text={ 'Список категории' }*/ }
                                    {/*            onClick={ onClickAllGroup }*/ }
                                    {/*            className={ `btn btn-primary` }*/ }
                                    {/*        />*/ }
                                    {/*    )*/ }

                                    {/*    if (index > 2) return null;*/ }

                                    {/*    const isMobile = !!index && (window.innerWidth <= 768);*/ }

                                    {/*    return (*/ }
                                    {/*        <Button*/ }
                                    {/*            id={item.id}*/ }
                                    {/*            onMouseEnter={ () => handleMouseEnter(index) }*/ }
                                    {/*            onMouseLeave={ () => handleMouseLeave(index) }*/ }
                                    {/*            key={ index }*/ }
                                    {/*            className={ `btn btn-primary ${ item.id === filterType ? 'news__header-active' : 'news__header-nonActive' }` }*/ }
                                    {/*            text={ item.title }*/ }
                                    {/*            rightIcon={*/ }
                                    {/*                ((isIconVisible[index] && !isIconVisible[0] && isUpdateByRole) || isMobile) && (*/ }
                                    {/*                    <div*/ }
                                    {/*                        className='news__header-edit'*/ }
                                    {/*                        style={ {visibility: (isIconVisible[index] || isMobile) ? 'visible' : 'hidden'} }*/ }
                                    {/*                        onClick={ (e) => onClickSelect(true, item, e ) }*/ }
                                    {/*                    >*/ }
                                    {/*                        <Setting size={ 14 } color={ '#3582F6' }/>*/ }
                                    {/*                    </div>)*/ }
                                    {/*            }*/ }
                                    {/*            onClick={ () => (handleFilter(item)) }*/ }
                                    {/*        />*/ }
                                    {/*    )*/ }
                                    {/*}) }*/ }
                                </div>

                                { isCreateByRole && (
                                    <div className='news__header-right'>
                                        {/*<Button*/ }
                                        {/*    className={ 'btn btn-primary achievements__header-nonActive' }*/ }
                                        {/*    text={ 'Добавить категорию' }*/ }
                                        {/*    onClick={ () => onClickSelect(true, null) }*/ }
                                        {/*/>*/ }

                                        <Button
                                            className={ 'btn btn-primary' }
                                            leftIcon={ <PlusBorder /> }
                                            text={ 'Добавить' }
                                            onClick={ () => (navigate('create')) }
                                        />
                                    </div>
                                ) }
                            </div>

                            <div className='news__content'>
                                <div className='row'>
                                    { !!filteredItems.length && filteredItems.map((
                                        item,
                                        idx
                                    ) => (
                                        <div className="col-6 col-sm-6 col-xl-4"
                                             key={ `person-item-list-${ idx }` }
                                        >
                                            { isMobile ? (
                                                    <MobileAchievementsItems
                                                        isShowEditBtn={ isUpdateByRole }
                                                        onDelete={ () => (handleDelete(item)) }
                                                        itemInfo={ item }
                                                        isShowCounts={ false }
                                                    />
                                                ) :
                                                (
                                                    <AchievementsItems
                                                        isShowEditBtn={ isUpdateByRole }
                                                        onDelete={ () => (handleDelete(item)) }
                                                        itemInfo={ item }
                                                        isShowCounts={ false }
                                                    />
                                                ) }
                                        </div>
                                    )) }

                                    <CreateCategoryDialog
                                        selectItem={ selectItem }
                                        isShow={ isShowCreateGroupsModal }
                                        setIsShow={ () => {
                                            setIsShowCreateGroupsModal(false);
                                            setSelectItem(null);
                                            getCategory();
                                        } }
                                        typeCategory={ 'news' }
                                    />
                                </div>
                            </div>

                            { !filteredItems.length && (
                                <div className="program__block__item__appellation">
                                    <p>
                                        Список новостей пуст
                                    </p>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>

            <AllGroupsDialog
                isShow={ isShowAllGroupsModal }
                setIsShow={ (item: any) => {
                    setIsShowAllGroupsModal(false);
                } }
                btnList={ headerBtn }
                onClickSelect={ handleFilter }
                onClickSetting={ onClickSelect }
                activeNameBtnName={ activeNameBtnName }
                textBtn={ 'категории' }
                settingsBtn
                onMouseEnter={ handleMouseEnter }
                onMouseLeave={ handleMouseLeave }
                isIconVisible={ isIconVisible }
                isUpdateByRole={ isUpdateByRole }
            />
        </div>
    )
}
