export default function Tariff({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 6.16667V3C2.5 2.86739 2.55268 2.74021 2.64645 2.64645C2.74021 2.55268 2.86739 2.5 3 2.5H7.83333C7.96594 2.5 8.09312 2.55268 8.18689 2.64645C8.28066 2.74021 8.33333 2.86739 8.33333 3V6.16667C8.33333 6.29927 8.28066 6.42645 8.18689 6.52022C8.09312 6.61399 7.96594 6.66667 7.83333 6.66667H3C2.86739 6.66667 2.74021 6.61399 2.64645 6.52022C2.55268 6.42645 2.5 6.29927 2.5 6.16667ZM11.6667 17V13.8333C11.6667 13.7007 11.7193 13.5735 11.8131 13.4798C11.9069 13.386 12.0341 13.3333 12.1667 13.3333H17C17.1326 13.3333 17.2598 13.386 17.3536 13.4798C17.4473 13.5735 17.5 13.7007 17.5 13.8333V17C17.5 17.1326 17.4473 17.2598 17.3536 17.3536C17.2598 17.4473 17.1326 17.5 17 17.5H12.1667C12.0341 17.5 11.9069 17.4473 11.8131 17.3536C11.7193 17.2598 11.6667 17.1326 11.6667 17ZM11.6667 10.3333V3C11.6667 2.86739 11.7193 2.74021 11.8131 2.64645C11.9069 2.55268 12.0341 2.5 12.1667 2.5H17C17.1326 2.5 17.2598 2.55268 17.3536 2.64645C17.4473 2.74021 17.5 2.86739 17.5 3V10.3333C17.5 10.4659 17.4473 10.5931 17.3536 10.6869C17.2598 10.7807 17.1326 10.8333 17 10.8333H12.1667C12.0341 10.8333 11.9069 10.7807 11.8131 10.6869C11.7193 10.5931 11.6667 10.4659 11.6667 10.3333ZM2.5 17V9.66667C2.5 9.53406 2.55268 9.40688 2.64645 9.31311C2.74021 9.21934 2.86739 9.16667 3 9.16667H7.83333C7.96594 9.16667 8.09312 9.21934 8.18689 9.31311C8.28066 9.40688 8.33333 9.53406 8.33333 9.66667V17C8.33333 17.1326 8.28066 17.2598 8.18689 17.3536C8.09312 17.4473 7.96594 17.5 7.83333 17.5H3C2.86739 17.5 2.74021 17.4473 2.64645 17.3536C2.55268 17.2598 2.5 17.1326 2.5 17Z"
                stroke={color}
                strokeWidth="1.5"
            />
        </svg>
    );
}