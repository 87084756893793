export default function Chevron({color = "white", size = 24}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
                <path
                    d="M11.9987 14.071L8.17769 10.25C7.76369 9.83595 7.09169 9.83595 6.67769 10.25C6.26369 10.664 6.26369 11.336 6.67769 11.75L11.2917 16.364C11.6827 16.755 12.3157 16.755 12.7057 16.364L17.3197 11.75C17.7337 11.336 17.7337 10.664 17.3197 10.25C16.9057 9.83595 16.2337 9.83595 15.8197 10.25L11.9987 14.071Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

