import React, {FC, ReactNode} from "react";
import cn from "classnames";
import Button from "./Button";

interface ToggleBlockForms {
    desc: any
    isChecked: boolean
    handleChange: (is: boolean) => void
    imgLeft?: ReactNode | null
    required?: boolean
    className?: string
}

export const ToggleBlock: FC<ToggleBlockForms> = (
    {
        desc,
        isChecked,
        handleChange,
        imgLeft,
        required,
        className = ''
    }
) => {

    return (
        <div className={`toggle ${className}`}>
            <div className="toggle__item">
                {imgLeft}
                <p className='toggle__text'>{desc}</p>
            </div>

            <div onClick={ () => handleChange(!isChecked) }
                 style={ {backgroundColor: `${ isChecked ? '#3582F6' : '#1E1F24' }`} }
                 className='toggle__btn'>
                <span className={ cn(isChecked ? 'toggle-active' : 'toggle-isNotActive') }></span>
            </div>

        </div>
    )
}
