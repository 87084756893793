export default function Location2({color= "white", width = 25, height = 24}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 25 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.8334 10C20.8334 14.418 12.8334 22 12.8334 22C12.8334 22 4.83337 14.418 4.83337 10C4.83337 7.87827 5.67623 5.84344 7.17652 4.34315C8.67681 2.84285 10.7116 2 12.8334 2C14.9551 2 16.9899 2.84285 18.4902 4.34315C19.9905 5.84344 20.8334 7.87827 20.8334 10Z"
                stroke={color}
                strokeWidth="2"
            />
            <path
                d="M12.8334 11C13.0986 11 13.3529 10.8946 13.5405 10.7071C13.728 10.5196 13.8334 10.2652 13.8334 10C13.8334 9.73478 13.728 9.48043 13.5405 9.29289C13.3529 9.10536 13.0986 9 12.8334 9C12.5682 9 12.3138 9.10536 12.1263 9.29289C11.9387 9.48043 11.8334 9.73478 11.8334 10C11.8334 10.2652 11.9387 10.5196 12.1263 10.7071C12.3138 10.8946 12.5682 11 12.8334 11Z"
                fill={color} stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient id="paint0_linear_80_702" x1="4.83337" y1="14.0002" x2="20.9815" y2="14.0002"
                                gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor={color}
                    />
                    <stop offset="1"
                          stopColor={color}
                    />
                </linearGradient>
                <linearGradient id="paint1_linear_80_702" x1="11.8334" y1="10.2" x2="13.8519" y2="10.2"
                                gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor={color}
                    />
                    <stop offset="1"
                          stopColor={color}
                    />
                </linearGradient>
                <linearGradient id="paint2_linear_80_702" x1="11.8334" y1="10.2" x2="13.8519" y2="10.2"
                                gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor={color}
                    />
                    <stop offset="1"
                          stopColor={color}
                    />
                </linearGradient>
            </defs>
        </svg>
    );
}
