import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Cookies from "js-cookie";

const AdminNonAsideLayout: FC = () => {
    if (!Cookies.get('token')) {
        return <Navigate to={'/auth'} replace />;
    }

    return (
        <div className='admin-layout__non-aside'>
            <Header/>

            <Outlet />

            <Footer/>
        </div>
    )
};

export default AdminNonAsideLayout;
