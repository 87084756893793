export default function Timer({color = "white", size = 18}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector"
                      d="M6.75 1.5H11.25M9 7.5V10.5M9 16.5C10.5913 16.5 12.1174 15.8679 13.2426 14.7426C14.3679 13.6174 15 12.0913 15 10.5C15 8.9087 14.3679 7.38258 13.2426 6.25736C12.1174 5.13214 10.5913 4.5 9 4.5C7.4087 4.5 5.88258 5.13214 4.75736 6.25736C3.63214 7.38258 3 8.9087 3 10.5C3 12.0913 3.63214 13.6174 4.75736 14.7426C5.88258 15.8679 7.4087 16.5 9 16.5Z"
                      stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
