export interface CreateUserData {
    page?: string // номер страницы
    totalPages?: number
    count?: number
    hasNextPage?: boolean
    hasPrevPage?: boolean
    limit?: number
    result?: CenterModel[]
}

export interface CenterPostData {
    statusCode?: number
    message?: string
    result?: CenterModel
}

export interface CenterModel {
    id: string // UUID центра
    name: string // Название центра
    city: string // Город
    country: string // Страна
    street: string // Улица
    house: string // Дом
    position: positionCenter // Должность
    employee: string // Имя сотрудника
    phone: string // Номер телефона
    timezone: number // Часовой пояс (в секундах)
    isOwner: boolean // Владелец центра
    isActive: boolean // Центр активен
    createdAt: string // Дата создания
    updatedAt: string // Дата обновления
    qr_available?: boolean
}

export enum positionCenter {
    'OWNER' = 'Владелец',
    'DIRECTOR' = 'Директор',
    'SYSTEM_ADMINISTRATOR' = 'Системный администратор',
    'HALL_ADMINISTRATOR' = 'Администратор Зала'
}

export interface ListUsersRequestParams extends CenterModel {
    image?: string // Картинка центра
    coords?: string // Координаты (Долгота,Широта)
}

export interface CentralReceiptGet {
    id: string // получение центра
}

export interface ChangeIsQr {
    centerId: string
    qr_available: string
}
