export default function Vision({color= "white", size = 18}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector"
                      d="M8.99947 11.4545C9.65045 11.4545 10.2748 11.1959 10.7351 10.7356C11.1954 10.2753 11.454 9.65094 11.454 8.99995C11.454 8.34897 11.1954 7.72465 10.7351 7.26433C10.2748 6.80401 9.65045 6.54541 8.99947 6.54541C8.34848 6.54541 7.72416 6.80401 7.26384 7.26433C6.80353 7.72465 6.54492 8.34897 6.54492 8.99995C6.54492 9.65094 6.80353 10.2753 7.26384 10.7356C7.72416 11.1959 8.34848 11.4545 8.99947 11.4545Z"
                      stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2"
                      d="M16.364 8.99991C14.8184 11.4471 12.0424 13.909 9.00035 13.909C5.95835 13.909 3.18226 11.4471 1.63672 8.99991C3.51772 6.67464 5.72108 4.09082 9.00035 4.09082C12.2796 4.09082 14.483 6.67464 16.364 8.99991Z"
                      stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
