export default function DocumentAdd({color= "white", width = 42, height = 44}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 42 44`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 36H40" stroke={color}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
            <path d="M34 30L34 42" stroke={color}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
            <g opacity="0.2">
                <path
                    d="M32.5 22C32.5 22.8284 33.1716 23.5 34 23.5C34.8284 23.5 35.5 22.8284 35.5 22H32.5ZM20 43.5C20.8284 43.5 21.5 42.8284 21.5 42C21.5 41.1716 20.8284 40.5 20 40.5V43.5ZM24.5997 2.71963L25.752 1.75935L24.5997 2.71963ZM33.5364 13.4437L32.3841 14.404L33.5364 13.4437ZM3.5 38V6H0.5V38H3.5ZM6 3.5H18V0.5H6V3.5ZM18 3.5H23.0632V0.5H18V3.5ZM32.5 14.7241V22H35.5V14.7241H32.5ZM23.4474 3.67991L32.3841 14.404L34.6888 12.4835L25.752 1.75935L23.4474 3.67991ZM20 40.5H6V43.5H20V40.5ZM23.0632 3.5C23.2116 3.5 23.3524 3.56591 23.4474 3.67991L25.752 1.75935C25.087 0.96138 24.102 0.5 23.0632 0.5V3.5ZM0.5 38C0.5 41.0376 2.96243 43.5 6 43.5V40.5C4.61929 40.5 3.5 39.3807 3.5 38H0.5ZM35.5 14.7241C35.5 13.9053 35.2129 13.1125 34.6888 12.4835L32.3841 14.404C32.459 14.4939 32.5 14.6071 32.5 14.7241H35.5ZM3.5 6C3.5 4.61929 4.61929 3.5 6 3.5V0.5C2.96243 0.5 0.5 2.96244 0.5 6H3.5Z"
                    fill={color}
                />
                <path d="M24 3V12C24 13.1046 24.8954 14 26 14H33" stroke={color}
                      strokeWidth="3"
                      strokeLinecap="round"
                />
            </g>
        </svg>
    );
}
