import { FC } from "react";


interface PersonInfoProps {
    title: string
    text: string
    color?: string
}


const PersonInfo: FC<PersonInfoProps> = (
    {
        title,
        text,
        color
    }
) => {

    return (
        <div className="person__items__block__info">
            <span>
                {title}
            </span>

            <div className={color && 'person__items__block__fix__data__work'} style={{
                backgroundColor: `${color}10`,
                color: color
            }}>
                <p>
                    {text}
                </p>
            </div>
        </div>
    );
}

export default PersonInfo;