export default function Qrcode({color= 'white', size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame">
                <path id="Vector"
                      d="M12.5 10V12.5M10 2.5V5M15 10V12.5M10 15H17.5M15 17.5H17.5M5 10H7.5M5 5.00917L5.00833 5M10 10.0092L10.0083 10M2.5 10.0092L2.50833 10M10 7.50917L10.0083 7.5M10 12.5092L10.0083 12.5M12.5 17.5092L12.5083 17.5M10 17.5092L10.0083 17.5M17.5 10.0092L17.5083 10M17.5 12.5092L17.5083 12.5M15 5.00917L15.0083 5M5 15.0092L5.00833 15M7.5 3V7C7.5 7.13261 7.44732 7.25979 7.35355 7.35355C7.25979 7.44732 7.13261 7.5 7 7.5H3C2.86739 7.5 2.74021 7.44732 2.64645 7.35355C2.55268 7.25979 2.5 7.13261 2.5 7V3C2.5 2.86739 2.55268 2.74021 2.64645 2.64645C2.74021 2.55268 2.86739 2.5 3 2.5H7C7.13261 2.5 7.25979 2.55268 7.35355 2.64645C7.44732 2.74021 7.5 2.86739 7.5 3ZM17.5 3V7C17.5 7.13261 17.4473 7.25979 17.3536 7.35355C17.2598 7.44732 17.1326 7.5 17 7.5H13C12.8674 7.5 12.7402 7.44732 12.6464 7.35355C12.5527 7.25979 12.5 7.13261 12.5 7V3C12.5 2.86739 12.5527 2.74021 12.6464 2.64645C12.7402 2.55268 12.8674 2.5 13 2.5H17C17.1326 2.5 17.2598 2.55268 17.3536 2.64645C17.4473 2.74021 17.5 2.86739 17.5 3ZM7.5 13V17C7.5 17.1326 7.44732 17.2598 7.35355 17.3536C7.25979 17.4473 7.13261 17.5 7 17.5H3C2.86739 17.5 2.74021 17.4473 2.64645 17.3536C2.55268 17.2598 2.5 17.1326 2.5 17V13C2.5 12.8674 2.55268 12.7402 2.64645 12.6464C2.74021 12.5527 2.86739 12.5 3 12.5H7C7.13261 12.5 7.25979 12.5527 7.35355 12.6464C7.44732 12.7402 7.5 12.8674 7.5 13Z"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

