import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "../form/TextField";
import Search from "../../img/icon/Search";
import FilterSelect from "../FilterSelect";
import Button from "../form/Button";
import TableContainer from "../form/TableContainer";
import CashAside from "../../img/icon/CashAside";
import Report from "../../img/icon/Report";
import Cash from "../../img/icon/Cash";
import Trash from "../../img/icon/Trash";
import TimerHour from "./TimerHour";
import { $getListRateSecond } from "../../api/requests/rate";
import { $startSession } from "../../api/requests/device";
import { $getDiscountList } from "../../api/requests/discount";
import { successNotify } from "../../notifications";
import { $searchClient } from "../../api/requests/users";
import Close from "../../img/icon/Close";
import RoleUsers from "../../mixins/role";
import Spinner from "../Spinner";

interface OpenPCProps {
    currentItem: any
    setUpdateList: () => void
}

const OpenPC: FC<OpenPCProps> = (
    {
        currentItem,
        setUpdateList
    }
) => {
    const roleUsers = new RoleUsers();
    const navigate = useNavigate();

    const tableHeaderPC = [
        {
            key: 'name',
            title: 'Пакет',
        },
        {
            key: 'price',
            title: 'Стоимость',
        },
        {
            key: 'from',
            title: 'Действует с',
        },
        {
            key: 'to',
            title: 'Действует по',
        },
        {
            key: 'durationText',
            title: 'Длительность',
        },
    ];

    const [ searchTariffsValue, setSearchTariffsValue ] = useState<string>('');
    const [ promoCodeValue, setPromoCodeValue ] = useState<string>('');
    const [ searchUserValue, setSearchUserValue ] = useState<string>('');

    const [ valueDiscount, setValueDiscount ] = useState<any>(null);

    const [ selectTariffsList, setSelectTariffsList ] = useState<any[]>([]);
    const [ allSelectMoney, setAllSelectMoney ] = useState<number>(0);

    const [ usersList, setUsersList ] = useState<any>(null);
    const [ searchUserList, setSearchUserList ] = useState<any>(null);

    const [ btnCashList, setBtnCashList ] = useState<any[]>([
        // {
        //     key: 1,
        //     icon: (<CashAside/>),
        //     onClick: () => {
        //     },
        //     isActive: false
        // },
        // {
        //     key: 2,
        //     icon: (<Report/>),
        //     onClick: () => {
        //     },
        //     isActive: false
        // },
        {
            key: 3,
            icon: (<Cash/>),
            onClick: () => {
            },
            isActive: false
        },
    ]);

    const [ timeEndings, setTimeEndings ] = useState<Date | null>(null);

    const [ tableContentPC, setTableContentPC ] = useState<any[]>([])
    const [ tableFilterContentPC, setTableFilterContentPC ] = useState<any[]>([])

    const [ discountList, setDiscountList ] = useState<any[]>([]);
    const [ isLoadingTable, setIsLoadingTable ] = useState<boolean>(false);

    const timeTypeMin = (idx: number) => {
        if (idx === 1) return 'Минута'

        if (idx < 5) return 'Минуты'

        return 'Минут'
    }

    const timeTypeHour = (idx: number) => {
        if (idx === 1) return 'Час';

        if (idx < 5) return 'Часа';

        return 'Часов'
    }

    const fullTime = (duration: number, durationType: number, timeStart: string) => {
        let secondsInDuration: number;

        if (durationType === 0) {
            secondsInDuration = duration * 60; // Convert minutes to seconds
        } else if (durationType === 1) {
            secondsInDuration = duration * 3600; // Convert hours to seconds
        } else {
            throw new Error("Invalid duration type");
        }

        const [ startHours, startMinutes, startSeconds ] = timeStart.split(":").map(Number);

        const totalStartSeconds = startHours * 3600 + startMinutes * 60 + startSeconds;
        const totalEndSeconds = totalStartSeconds + secondsInDuration;

        const endHours = Math.floor(totalEndSeconds / 3600);
        const endMinutes = Math.floor((totalEndSeconds % 3600) / 60);
        const endSeconds = totalEndSeconds % 60;

        const formattedTime = `${ (endHours > 24 ? endHours - 24 : endHours).toString().padStart(2, "0") }:${ endMinutes.toString().padStart(2, "0") }:${ endSeconds.toString().padStart(2, "0") }`;

        return formattedTime;
    }

    const getChangeTableContentData = (item: any) => {
        const timeStart = new Date().toLocaleTimeString();

        return {
            ...item,
            price: `${ item.prices ? item.prices[0]?.priceForAll : 0 } ₽`,
            money: item.prices ? item.prices[0]?.priceForAll : 0,
            from: timeStart,
            to: fullTime(item.duration, item.durationType, timeStart),
            durationText: `${ item.duration } ${ item.durationType > 0 ? timeTypeHour(item.duration) : timeTypeMin(item.duration) }`,
        }
    }

    const getRequestPayData = () => {
        const findSelectBtnPay = btnCashList.find(i => i.isActive);

        const rates = selectTariffsList.map(i => {
            return {
                rateId: i.id,
                amount: 1//i.prices[0].priceForAll,
            }
        })

        return {
            paymentMethod: findSelectBtnPay.key,
            userId: usersList?.id,
            discountId: (!promoCodeValue.length && valueDiscount) &&  valueDiscount.id || null,
            promoCode: promoCodeValue.length && promoCodeValue || null,
            rates
        }
    }

    useEffect(() => init(), [])

    function init() {
        getListRate();

        getListDiscount();
    }

    function getListRate(rateIds?: string[]) {
        setTableContentPC([]);
        setTableFilterContentPC([]);
        setIsLoadingTable(true);

        $getListRateSecond(
            {
                zoneIds: [ currentItem.zoneId ],
                rateIds: rateIds?.length ? rateIds : undefined
            }
        ).then(response => {
            setIsLoadingTable(false);
            if (response && !response.length) return;

            const list = response.map(i => getChangeTableContentData(i))

            setTableContentPC(list)
            setTableFilterContentPC(list)
        })
    }

    function getListDiscount() {
        $getDiscountList(
            {
                type: '1'
            }
        ).then(res => {
            if (!res.result) return;

            setDiscountList(res.result.map(i => {
                return {
                    ...i,
                    label: i.name,
                    value: i.percent,
                }
            }))
        })
    }

    function onClickPay() {
        if (!selectTariffsList.length) return;

        $startSession(currentItem.id, getRequestPayData()).then(res => {
            if (!res.id) return;

            setUpdateList()
        })
    }

    function onClickAddNew() {
        navigate("");
    }

    function onClickSelectTariff(item: any) {

        const list = [ ...selectTariffsList ];
        const rateList: any[] = [];

        list.push(item);

        list.forEach(i => {
            i.prices?.forEach((_i: any) => {
                rateList.push(_i.rateId)
            })
        })

        getListRate(rateList);

        setSelectTariffsList(list);

        changeSetTime(item, true);

        const money = list.reduce((acc, curr) => (acc.money || 0) + (curr.money || 0), 0);

        setAllSelectMoney((allSelectMoney + money) || 0);
    }

    function changeSetTime(item: any, isAdd: boolean) {
        const currentTime = timeEndings ? new Date(timeEndings) : new Date();

        const durationChange = isAdd ? item.duration : -item.duration;

        item.durationType > 0
            ? currentTime.setHours(currentTime.getHours() + durationChange)
            : currentTime.setMinutes(currentTime.getMinutes() + durationChange)

        setTimeEndings(currentTime);
    }

    function isDisabledPayBtn() {
        const findSelectBtnPay = btnCashList.find(i => i.isActive)

        return !findSelectBtnPay?.isActive;
    }

    function onClickSaveDiscount() {
        successNotify('сохранено');
    }

    function changeListUsers(value: string) {
        setSearchUserValue(value);

        if (!value) return;

        $searchClient({search: value}).then(res => {
            if (!res.id) return;

            setSearchUserList(res)
        })
    }

    function onClickSelectUser() {
        setUsersList(searchUserList);
        setSearchUserList(null);
        changeListUsers('');
    }

    function onClickCloseUser() {
        setUsersList(null);
    }

    function onChangeSearchValue(value: string) {
        setSearchTariffsValue(value);

        const listUpdate = [...tableContentPC].filter(i => i.name.toLowerCase().includes(value.toLowerCase()));

        setTableFilterContentPC(listUpdate);
    }

    return (
        <div className="computers__sale">
            <div className="computers__sale__center">
                <div className="computers__sale__center__block">
                    { !usersList ? (
                        <>
                            <div className={ 'search__dropdown__containers' }>
                                <TextField
                                    imgLeft={ <Search/> }
                                    placeholder={ 'Поиск пользователя' }
                                    value={ searchUserValue }
                                    onChangeValue={ changeListUsers }
                                />

                                { searchUserList && (
                                    <div className={ 'search__dropdown__block' }
                                         onClick={ onClickSelectUser }
                                    >
                                        <div className="search__dropdown__item">
                                            {/*<img src={ searchUserList?.image }/>*/}

                                            <p>{ searchUserList?.name }</p>
                                        </div>
                                    </div>
                                ) }
                            </div>

                            <div className="computers__sale__center__block__login">
                                <div className="computers__sale__center__right__check-center">
                                    <p>Начните вводить логин или телефон авторизованного пользователя.</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="computers__sale__center__block__login">
                            <div className={ 'computers__dialog__right__list__block' }>
                                <div className="computers__dialog__right__list__block__header">
                                    <div className="computers__dialog__right__list__block__header-left">
                                        <p className={ 'title' }>{ usersList?.username }</p>
                                        <p>{ usersList?.phone }</p>

                                        {/*<div className="computers__dialog__right__list__block__header-type">*/}
                                        {/*    <span*/}
                                        {/*        className={ 'green' }>{ roleUsers.getNameRoleUser(usersList?.status) }*/}
                                        {/*    </span>*/}

                                        {/*    <span*/}
                                        {/*        className={ 'blue' }>{ usersList?.activeCenter.status === 2 ? 'На смене' : 'Не в смене' }*/}
                                        {/*    </span>*/}
                                        {/*</div>*/}
                                    </div>

                                    <div className="">
                                        <Button
                                            text={ (<Close/>) }
                                            className={ 'btn btn-icon' }
                                            onClick={ onClickCloseUser }
                                        />
                                    </div>
                                </div>

                                <div className="computers__dialog__right__list__block__header-other">
                                    <div className="computers__dialog__right__list__block__header-other-text">
                                        <CashAside color={ '#3582F6' }/>

                                        <p>{ usersList?.activeCenter.deposit || '0' } ₽</p>
                                    </div>

                                    {/*<div className="computers__dialog__right__list__block__header-other-text">*/ }
                                    {/*    <Percentage color={'#3582F6'}/>*/ }

                                    {/*    <p>{usersList?.percent}%</p>*/ }
                                    {/*</div>*/ }
                                </div>
                            </div>
                        </div>
                    ) }

                    {/*<div className="computers__sale__center__block__discount">*/}
                    {/*    <FilterSelect*/}
                    {/*        value={ valueDiscount }*/}
                    {/*        placeholder={ 'Скидка' }*/}
                    {/*        options={ discountList }*/}
                    {/*        onChange={ (val) => setValueDiscount(val) }*/}
                    {/*    />*/}

                    {/*    <div className="computers__sale__center__block__discount__btn d-flex">*/}
                    {/*        <TextField*/}
                    {/*            placeholder={ 'Введите промокод' }*/}
                    {/*            value={ promoCodeValue }*/}
                    {/*            onChangeValue={ setPromoCodeValue }*/}
                    {/*            className={ 'mb-0' }*/}
                    {/*        />*/}

                    {/*        <Button*/}
                    {/*            className={ 'btn btn-primary' }*/}
                    {/*            text={ 'Сохранить' }*/}
                    {/*            onClick={ onClickSaveDiscount }*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className="computers__sale__center__info">
                    <div className="computers__sale__center__info__tariff">
                        <div className="computers__sale__center__info__tariff__middle d-flex">
                            <Button
                                className={ 'btn btn-link' }
                                text={ 'Пакеты' }
                                onClick={ onClickAddNew }
                            />
                        </div>

                        {/*<TextField*/}
                        {/*    imgLeft={ <Search/> }*/}
                        {/*    placeholder={ 'Поиск' }*/}
                        {/*    value={ searchTariffsValue }*/}
                        {/*    onChangeValue={ onChangeSearchValue }*/}
                        {/*/>*/}
                    </div>

                    <div className={ `computers__sale__center__info__bottom ${ isLoadingTable ? 'd-flex' : '' }` }>
                        <TableContainer
                            header={ tableHeaderPC }
                            content={ tableFilterContentPC }
                            onLeftClickTrContent={ onClickSelectTariff }
                            loading={ isLoadingTable }
                            sortable={false}
                            textContentEmpty={ isLoadingTable ? (
                                <>
                                    <div className="vertical-center" style={ {position: 'relative'} }>
                                        <Spinner/>
                                    </div>
                                </>
                            ) : 'Пусто' }
                        />
                    </div>
                </div>
                <div className="computers__sale__center__right">
                    <div className="computers__sale__center__right__check">
                        { !selectTariffsList.length && (
                            <div className="computers__sale__center__right__check-center">
                                <p>Выберите пакеты, {/*товары или <br/> услуги,*/} чтобы добавить их в корзину</p>
                            </div>
                        ) }

                        { selectTariffsList.map((item, idx) => (
                            <div
                                className={ 'computers__dialog__right__list__block' }
                                key={ `select-tariff-item-${ idx }` }
                            >
                                <div className="computers__dialog__right__list__block__header">
                                    <div className="computers__dialog__right__list__block__header-left">
                                        <p className={ 'title' }>{ item.durationText }</p>
                                        {/*<p>{ item.durationText }</p>*/}
                                    </div>

                                    <div className="computers__dialog__right__list__block__header-right">
                                        <Button
                                            className={ 'btn btn-icon' }
                                            text={ (<Trash color={ '#FFFFFF50' }/>) }
                                            onClick={ () => {
                                                const list = [ ...selectTariffsList ];

                                                const findItem = list[idx]

                                                list.splice(idx, 1);

                                                setAllSelectMoney((allSelectMoney - findItem.money));

                                                setSelectTariffsList(list);
                                                changeSetTime(item, false);
                                            } }
                                        />
                                    </div>
                                </div>

                                <div className="computers__dialog__right__list__block__price">
                                    <p>{ item.price }</p>
                                </div>
                            </div>
                        )) }
                    </div>

                    <div className="computers__sale__center__right__payment">
                        <div className="computers__sale__center__right__payment__cost">
                            {/*<div className="computers__sale__center__right__payment__cost__time">*/}
                            {/*    <p>Скидка</p>*/}
                            {/*    --*/}
                            {/*</div>*/}

                            <div className="computers__sale__center__right__payment__cost__time">
                                <p>Время окончания</p>

                                { (timeEndings instanceof Date) ? <TimerHour startTime={ timeEndings }/> : '--' }
                            </div>
                        </div>

                        <div className="line"></div>

                        <div className="computers__sale__center__right__payment__button">
                            <div className="computers__sale__center__right__payment__button__cash">
                                { btnCashList.map(({icon, onClick, isActive}, idx) => (
                                    <Button
                                        key={ `btn-cash-check-${ idx }` }
                                        className={ `btn btn-link ${ isActive ? 'active' : '' }` }
                                        text={ icon }
                                        onClick={ () => {
                                            const list = [ ...btnCashList ]

                                            list.forEach(i => i.isActive = false)

                                            list[idx].isActive = true

                                            setBtnCashList(list)

                                            onClick()
                                        } }
                                        disabled={ !selectTariffsList.length }
                                    />
                                )) }
                            </div>

                            <div className="computers__sale__center__right__payment__button__module">
                                <Button
                                    rightIcon={ (
                                        <div>
                                            { allSelectMoney } ₽
                                        </div>
                                    ) }
                                    className={ 'btn btn-primary w-100' }
                                    type={ "submit" }
                                    text={ 'Оплатить' }
                                    onClick={ onClickPay }
                                    disabled={ isDisabledPayBtn() }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OpenPC;
