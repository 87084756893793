import { useState, useEffect, FC } from 'react';

interface TimerHourProps {
    startTime: Date
}

const TimerHour: FC<TimerHourProps> = (
    {
        startTime
    }
) => {
    const [currentTime, setCurrentTime] = useState(startTime || new Date());

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const updateCurrentTime = () => {
            const newDate = new Date(currentTime);
            newDate.setSeconds(newDate.getSeconds() + 1);
            setCurrentTime(newDate);

            timeoutId = setTimeout(updateCurrentTime, 1000);
        };

        timeoutId = setTimeout(updateCurrentTime, 1000);

        return () => clearTimeout(timeoutId);
    }, [currentTime]);

    useEffect(() => {
        setCurrentTime(startTime)
    }, [startTime])

    return (
        <p>{currentTime.toLocaleTimeString()}</p>
    );
};

export default TimerHour;
