import { FC, useEffect, useState } from "react";
import DialogContainer from "../../components/dialog/DialogContainer";
import Close from "../../img/icon/Close";
import DatePickerField from "../../components/form/DatePickerField";
import Button from "../../components/form/Button";
import DateOperations from "../../mixins/date-operation";
import { $createSeasons, $updateSeasons } from "../../api/requests/seasons";

interface SeasonsCreateModal {
    selectItem: any
    isShow: boolean
    setIsShow: () => void
    closeModal: () => void
    onClickDelete: () => void
}

const SeasonsModals: FC<SeasonsCreateModal> = (
    {
        isShow,
        setIsShow,
        closeModal,
        selectItem,
        onClickDelete
    }
) => {
    const dateOperations = new DateOperations();

    const [ isLoading, setIsLoading ] = useState(false);

    const [ dateStartFrom, setDateStartFrom ] = useState<null | Date>(null);
    const [ dateStartTo, setDateStartTo ] = useState<any | Date>(null);

    const getTimeDateByNext = (current: Date = new Date(), month: number = 1) => {
        const currentDate = current;
        const timeStart = new Date(currentDate);
        timeStart.setMonth(currentDate.getMonth() + month);

        return timeStart;
    }

    useEffect(() => {
        if (selectItem) return;

        setDateStartFrom(new Date());
        setDateStartTo(getTimeDateByNext(dateStartFrom || new Date()));
    }, [])

    useEffect(() => {
        if (selectItem) init();
    }, [ selectItem ]);

    function init() {
        setDateStartFrom(new Date(selectItem.date_from));
        setDateStartTo(new Date(selectItem.date_to));
    }

    function onClickEnter() {
        setIsLoading(true);

        const requestObject = {
            date_from: (dateStartFrom instanceof Date)
                ? dateOperations.getISODateTimeByObj(dateStartFrom)
                : dateStartFrom,
            date_to: (dateStartTo instanceof Date)
                ? dateOperations.getISODateTimeByObj(dateStartTo)
                : dateStartTo,
        }

        if (selectItem) return onEditData(requestObject);

        onCreateSave(requestObject);
    }

    function onCreateSave(request: any) {
        $createSeasons(request).then(res => {
            setIsLoading(false);

            if (!res.id) return;

            setIsShow();
            resetForm();
        })
    }

    function onEditData(request: any) {
        $updateSeasons(selectItem.id, request).then(res => {
            setIsLoading(false);

            if (!res.id) return;

            setIsShow();
            resetForm();
        })
    }

    function resetForm() {
        setDateStartTo(null);
        setDateStartFrom(null);

        setIsLoading(false);
    }

    return (
        <DialogContainer
            isOpen={ isShow }
            closeModal={ () => {
                closeModal();
                resetForm();
            } }
            closeIcon={ <Close/> }
            label={ 'Сезоны' }
        >
            <div className={ 'seasons-page' }>
                <div className="seasons-page__date">
                    <div className="seasons-page__date-txt">
                        <p>От</p>
                    </div>

                    <DatePickerField
                        isIcon
                        onChangeDate={ setDateStartFrom }
                        startDate={ dateStartFrom }
                        // minDate={dateStartFrom || new Date()}
                    />

                    <div className="seasons-page__date-txt">
                        <p>До</p>
                    </div>

                    <DatePickerField
                        isIcon
                        onChangeDate={ setDateStartTo }
                        startDate={ dateStartTo }
                        // minDate={getTimeDateByNext(dateStartFrom || new Date())}
                    />
                </div>

                <div className="seasons-page__btn">
                    <Button
                        className={ 'btn btn-primary' }
                        text={ selectItem ? 'Изменить' : 'Сохранить' }
                        onClick={ onClickEnter }
                        loading={ isLoading }
                    />

                    { selectItem && (
                        <Button
                            className={ 'btn btn-danger' }
                            text={ 'Удалить' }
                            onClick={ onClickDelete }
                        />
                    ) }
                </div>
            </div>
        </DialogContainer>
    );
}

export default SeasonsModals
