import React, {FC} from 'react';
import AddMedia from "../../img/icon/AddMedia";
import Arrow from "../../img/icon/Arrow";
import ArrowLeft from "../../img/icon/ArrowLeft";
import ArrowRight from "../../img/icon/ArrowRight";
import ArrowTable from "../../img/icon/ArrowTable";
import BattleNet from "../../img/icon/BattleNet";
import Box from "../../img/icon/Box";
import Calculator from "../../img/icon/Calculator";
import Calendar from "../../img/icon/Calendar";
import Cash from "../../img/icon/Cash";
import CashAside from "../../img/icon/CashAside";
import Chart from "../../img/icon/Chart";
import ChatSupport from "../../img/icon/ChatSupport";
import Check from "../../img/icon/Check";
import Chevron from "../../img/icon/Chevron";
import Clock from "../../img/icon/Clock";
import Close from "../../img/icon/Close";
import Coins from "../../img/icon/Coins";
import CompleteDouble from "../../img/icon/CompleteDouble";
import Content from "../../img/icon/Content";
import Dashboard from "../../img/icon/Dashboard";
import DocumentAdd from "../../img/icon/DocumentAdd";
import Download from "../../img/icon/Download";
import DownloadSecond from "../../img/icon/DownloadSecond";
import Exit from "../../img/icon/Exit";
import Eye from "../../img/icon/Eye";
import Flask from "../../img/icon/Flask";
import Game from "../../img/icon/Game";
import History from "../../img/icon/History";
import Info from "../../img/icon/Info";
import Location from "../../img/icon/Location";
import Location2 from "../../img/icon/Location2";
import Lock from "../../img/icon/Lock";
import Logs from "../../img/icon/Logs";
import Mail from "../../img/icon/Mail";
import Map from "../../img/icon/Map";
import Moon from "../../img/icon/Moon";
import NonCash from "../../img/icon/NonCash";
import Notification from "../../img/icon/Notification";
import PcCheck from "../../img/icon/PcCheck";
import Pen from "../../img/icon/Pen";
import Person from "../../img/icon/Person";
import PersonCrown from "../../img/icon/PersonCrown";
import PiggyBank from "../../img/icon/PiggyBank";
import PlusBorder from "../../img/icon/PlusBorder";
import Printer from "../../img/icon/Printer";
import Profile from "../../img/icon/Profile";
import Qrcode from "../../img/icon/Qrcode";
import Report from "../../img/icon/Report";
import RightBorder from "../../img/icon/RightBorder";
import Ruble from "../../img/icon/Ruble";
import Safe from "../../img/icon/Safe";
import Search from "../../img/icon/Search";
import Security from "../../img/icon/Security";
import Setting from "../../img/icon/Setting";
import Electro from "../../img/icon/Electro";
import SmallShop from "../../img/icon/SmallShop";
import SortDown from "../../img/icon/SortDown";
import Star from "../../img/icon/Star";
import Tariff from "../../img/icon/Tariff";
import Telegram from "../../img/icon/Telegram";
import Timer from "../../img/icon/Timer";
import Trash from "../../img/icon/Trash";
import Unavailable from "../../img/icon/Unavailable";
import UpLoad from "../../img/icon/UpLoad";
import Users from "../../img/icon/Users";
import Vision from "../../img/icon/Vision";
import Vk from "../../img/icon/Vk";
import Warning from "../../img/icon/Warning";
import Shift from "../../img/icon/Shift";
import ClosedWindow from "../../img/icon/ClosedWindow";
import Comment from "../../img/icon/Comment";
import WordComplete from "../../img/icon/WordComplete";
import List from "../../img/icon/List";
import ElectroOff from "../../img/icon/ElectroOff";
import ElectroOn from "../../img/icon/ElectroOn";
import SmartShe from "../../img/icon/SmartShe";
import PeopleDouble from "../../img/icon/PeopleDouble";
import Percentage from "../../img/icon/Percentage";
import Reload from "../../img/icon/Reload";
import Execute from "../../img/icon/Execute";
import Burger from "../../img/icon/Burger";
import Loyalty from "../../img/icon/Loyalty";
import Seasons from "../../img/icon/Seasons";
import PromoCodes from "../../img/icon/PromoCodes";
import NewsMenu from "../../img/icon/NewsMenu";
import NewsHorn from "../../img/icon/NewsHorn";
import Stock from "../../img/icon/Stock";
import Avatars from "../../img/icon/Avatars";

const icons: FC = () => {

    const list = [
        {
            icon: <AddMedia/>,
            value: 'AddMedia'
        },
        {
            icon: <Arrow/>,
            value: 'Arrow'
        },
        {
            icon: <ArrowLeft/>,
            value: 'ArrowLeft'
        },
        {
            icon: <ArrowRight/>,
            value: 'ArrowRight'
        },
        {
            icon: <ArrowTable/>,
            value: 'ArrowTable'
        },
        {
            icon: <BattleNet/>,
            value: 'BattleNet'
        },
        {
            icon: <Box/>,
            value: 'Box'
        },
        {
            icon: <Calculator/>,
            value: 'Calculator'
        },
        {
            icon: <Calendar/>,
            value: 'Calendar'
        },
        {
            icon: <Cash/>,
            value: 'Cash'
        },
        {
            icon: <CashAside/>,
            value: 'CashAside'
        },
        {
            icon: <Chart/>,
            value: 'Chart'
        },
        {
            icon: <ChatSupport/>,
            value: 'ChatSupport'
        },
        {
            icon: <Check/>,
            value: 'Check'
        },
        {
            icon: <Chevron/>,
            value: 'Chevron'
        },
        {
            icon: <Clock/>,
            value: 'Clock'
        },
        {
            icon: <Close/>,
            value: 'Close'
        },
        {
            icon: <Coins/>,
            value: 'Coins'
        },
        {
            icon: <CompleteDouble/>,
            value: 'CompleteDouble'
        },
        {
            icon: <Content/>,
            value: 'Content'
        },
        {
            icon: <Dashboard/>,
            value: 'Dashboard'
        },
        {
            icon: <DocumentAdd/>,
            value: 'DocumentAdd'
        },
        {
            icon: <Download/>,
            value: 'Download'
        },
        {
            icon: <DownloadSecond/>,
            value: 'DownloadSecond'
        },
        {
            icon: <Exit/>,
            value: 'Exit'
        },
        {
            icon: <Eye/>,
            value: 'Eye'
        },
        {
            icon: <Flask/>,
            value: 'Flask'
        },
        {
            icon: <Game/>,
            value: 'Game'
        },
        {
            icon: <History/>,
            value: 'History'
        },
        {
            icon: <Info/>,
            value: 'Info'
        },
        {
            icon: <Location/>,
            value: 'Location'
        },
        {
            icon: <Location2/>,
            value: 'Location2'
        },
        {
            icon: <Lock/>,
            value: 'Lock'
        },
        {
            icon: <Logs/>,
            value: 'Logs'
        },
        {
            icon: <Mail/>,
            value: 'Mail'
        },
        {
            icon: <Map/>,
            value: 'Map'
        },
        {
            icon: <Moon/>,
            value: 'Moon'
        },
        {
            icon: <NonCash/>,
            value: 'NonCash'
        },
        {
            icon: <Notification/>,
            value: 'Notification'
        },
        {
            icon: <PcCheck/>,
            value: 'PcCheck'
        },
        {
            icon: <Pen/>,
            value: 'Pen'
        },
        {
            icon: <Person/>,
            value: 'Person'
        },
        {
            icon: <PersonCrown/>,
            value: 'PersonCrown'
        },
        {
            icon: <PiggyBank/>,
            value: 'PiggyBank'
        },
        {
            icon: <PlusBorder/>,
            value: 'PlusBorder'
        },
        {
            icon: <Printer/>,
            value: 'Printer'
        },
        {
            icon: <Profile/>,
            value: 'Profile'
        },
        {
            icon: <Qrcode/>,
            value: 'Qrcode'
        },
        {
            icon: <Report/>,
            value: 'Report'
        },
        {
            icon: <RightBorder/>,
            value: 'RightBorder'
        },
        {
            icon: <Ruble/>,
            value: 'Ruble'
        },
        {
            icon: <Safe/>,
            value: 'Safe'
        },
        {
            icon: <Search/>,
            value: 'Search'
        },
        {
            icon: <Security/>,
            value: 'Security'
        },
        {
            icon: <Setting/>,
            value: 'Setting'
        },
        {
            icon: <Electro/>,
            value: 'Electro'
        },
        {
            icon: <SmallShop/>,
            value: 'SmallShop'
        },
        {
            icon: <SortDown/>,
            value: 'SortDown'
        },
        {
            icon: <Star/>,
            value: 'Star'
        },
        {
            icon: <Tariff/>,
            value: 'Tariff'
        },
        {
            icon: <Telegram/>,
            value: 'Telegram'
        },
        {
            icon: <Timer/>,
            value: 'Timer'
        },
        {
            icon: <Trash/>,
            value: 'Trash'
        },
        {
            icon: <Unavailable/>,
            value: 'Unavailable'
        },
        {
            icon: <UpLoad/>,
            value: 'UpLoad'
        },
        {
            icon: <Users/>,
            value: 'Users'
        },
        {
            icon: <Vision/>,
            value: 'Vision'
        },
        {
            icon: <Vk/>,
            value: 'Vk'
        },
        {
            icon: <Warning/>,
            value: 'Warning'
        },
        {
            icon: <Shift/>,
            value: 'Shift'
        },
        {
            icon: <ClosedWindow/>,
            value: 'ClosedWindow'
        },
        {
            icon: <Comment/>,
            value: 'Comment'
        },
        {
            icon: <WordComplete/>,
            value: 'WordComplete'
        },
        {
            icon: <List/>,
            value: 'List'
        },
        {
            icon: <ElectroOff/>,
            value: 'ElectroOff'
        },
        {
            icon: <ElectroOn/>,
            value: 'ElectroOn'
        },
        {
            icon: <SmartShe/>,
            value: 'SmartShe'
        },
        {
            icon: <PeopleDouble/>,
            value: 'PeopleDouble'
        },
        {
            icon: <Percentage/>,
            value: 'Percentage'
        },
        {
            icon: <Reload/>,
            value: 'Reload'
        },
        {
            icon: <Execute/>,
            value: 'Execute'
        },
        {
            icon: <Burger/>,
            value: 'Burger'
        },
        {
            icon: <Loyalty/>,
            value: 'Loyalty'
        },
        {
            icon: <Seasons/>,
            value: 'Seasons'
        },
        {
            icon: <PromoCodes/>,
            value: 'PromoCodes'
        },
        {
            icon: <NewsHorn/>,
            value: 'NewsHorn'
        },
        {
            icon: <Stock/>,
            value: 'Stock'
        },
        {
            icon: <Avatars/>,
            value: 'Avatars'
        },
    ]

    return (
        <div className="container-fluid">
            <div className="row">
                {list.map(({icon, value}) => (
                    <div
                        className="col-2"
                        style={{
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '15px',
                            marginBottom: '15px'
                        }}
                    >
                        {icon} - {value}
                    </div>
                ))}
            </div>
        </div>
    );
}


export default icons
