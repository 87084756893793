import { FC, useEffect, useState } from "react";
import PersonSearch from "../../components/person/PersonSearch";
import PersonBtn from "../../components/person/PersonBtn";
import PersonItems from "../../components/person/PersonItems";
import { $getListUsers } from "../../api/requests/staff";
import { UserModelLink } from "../../api/requests/staff/interface";
import { withDebounce } from "../../functions";
import Pagination from "../../components/form/Pagination";
import RoleUsers from "../../mixins/role";

const Person: FC = () => {
    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('person', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('person', 'update');

    const [ searchValue, setSearchValue ] = useState('');

    const [ personList, setPersonList ] = useState<UserModelLink[]>([]);
    const [ personFilterList, setPersonFilterList ] = useState<UserModelLink[]>([]);

    const [ activePage, setActivePage ] = useState(1);
    const [ totalPages, setTotalPages ] = useState(1);

    useEffect(() => init(), []);

    useEffect(() => {
        if (activePage === 1) return;

        init();
    }, [ activePage ]);

    function init(value?: string) {
        const getRequestListUsers = {
            page: String(activePage),
            search: value || null
        }

        $getListUsers(getRequestListUsers).then(res => {
            if (!res.result) return;

            const totalPages = Math.ceil(res.count / 15);

            setTotalPages(res.totalPages || totalPages || 1);

            setPersonList(res.result);
            setPersonFilterList(res.result);
        })
    }

    function onChangeSearchValue(value: string) {
        setSearchValue(value);

        const listUpdate = [ ...personList ].filter(i => i.name.toLowerCase().includes(value.toLowerCase()));

        setPersonFilterList(listUpdate);
    }

    return (
        <div className="person__block">
            <div className="booking__header">
                <h1>Сотрудники</h1>
            </div>
            <div className="person__block-style">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="person__block__wrapper">
                            <PersonSearch
                                searchValue={ searchValue }
                                setSearchValue={ (value) => {
                                    setSearchValue(value);
                                    // onChangeSearchValue(value)

                                    withDebounce(() => init(value));
                                } }
                            />

                            { isCreateByRole && <PersonBtn/> }
                        </div>
                    </div>

                    { !!personList.length && (
                        <div className="row">
                            { personFilterList.map((item, idx) => (
                                <div className="col-12 col-md-6"
                                     key={ `person-item-list-${ idx }` }
                                >
                                    <PersonItems
                                        personInfo={ item }
                                        isShowEditPerson={ isUpdateByRole }
                                    />
                                </div>
                            )) }

                            { totalPages > 1 && (
                                <div className='logs__carousel'>
                                    <Pagination
                                        activePage={ activePage }
                                        setActivePage={ setActivePage }
                                        pages={ totalPages }
                                    />
                                </div>
                            ) }
                        </div>
                    ) }

                    { !personList.length && (
                        <div className="program__block__item__appellation">
                            <p>
                                Список сотрудников пуст
                            </p>
                        </div>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default Person;
