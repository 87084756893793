import { FC } from "react";
import TextField from "./TextField";
import FilterSelect from "../FilterSelect";
import { ToggleBlock } from "./ToggleBlock";
import TextArea from "./TextArea";

interface FormGroupItemProps {
    item: any
}

const FormGroupItem: FC<FormGroupItemProps> = (
    {
        item
    }
) => {

    return (
        <>
            { item.textField && (
                <TextField
                    type={item.type}
                    imgLeft={item.imgLeft}
                    placeholder={ item.placeholder }
                    label={ item.label }
                    value={ item.value }
                    onChangeValue={ (value) => item.setValue(value, item.key) }
                    required={ item.required }
                    className={ item.className }
                />
            ) }

            { item.textarea && (
                <TextArea
                    placeholder={ item.placeholder }
                    value={ item.value }
                    onChangeValue={ (value) => item.setValue(value, item.key) }
                    rows={4}
                />
            ) }

            { item.select && (
                <FilterSelect
                    placeholder={ item.placeholder }
                    value={ item.value }
                    options={ item.list }
                    onChange={ (i) => item.setValue(i, item.key) }
                    onChangeInput={ (i) => item.setValueInput && item.setValueInput(i, item.key) }
                    className={ `mb-3 ${item.className}` }
                    required={ item.required }
                    isSearchable={ !!item.setValueInput }
                />
            ) }

            { item.checkbox && (
                <ToggleBlock
                    desc={ item.title }
                    isChecked={ item.value }
                    handleChange={ (value) => item.setValue(value, item.key) }
                    required={ item.required }
                    imgLeft={ item.imgLeft }
                />
            ) }
        </>
    );
}

export default FormGroupItem;
