import { FC } from "react";
import { TournamentRequestModel } from "../../../api/requests/tournaments/interface";
import StarColor from "../../../img/icon/StarColor";

interface CommandsItemProps {
    item: TournamentRequestModel
}

const CommandsItem: FC<CommandsItemProps> = (
    {
        item
    }
) => {

    const getColorByCount = (count: number): string => {
        if (count < 4) return '#FF5858';

        if (count > 7) return '#70D584';

        return '#3582F6';
    }

    return (
        <div className={'edit__tournament__team'}>
            <div className="edit__tournament__list__team__info">
                <h2>{ item.title }</h2>
            </div>

            <div>
                <div className="edit__tournament__list__team__rating">
                    <h5>Средний рейтинг</h5>
                </div>

                <div className="edit__tournament__list__team__item">
                    <div className="edit__tournament__list__team__item__number">
                        <span>{ item.averageRating }</span>
                    </div>
                </div>
            </div>

            <h5>Список игроков</h5>

            <div className="edit__tournament__list__team__roster">
                { item.requestUsers && item.requestUsers.map(({username, rating, image, isCaptain}, idx) => (
                    <div
                        key={ `edit-tournament-game-item${ idx }` }
                    >
                        <div className="edit__tournament__list__team__roster__sport">
                            <span
                                style={ {
                                    color: getColorByCount(rating),
                                    borderColor: getColorByCount(rating),
                                } }
                            >
                                { rating }
                            </span>

                            <div className="edit__tournament__list__team__roster__sport-stars">
                                <img src={ image || '/img/logo/logo-2.png' } alt={''}/>

                                {isCaptain && (<StarColor color={'#F9D448'} width={12} height={12}/>)}
                            </div>

                            <p>{ username }</p>
                        </div>
                    </div>
                )) }
            </div>
        </div>
    )
}

export default CommandsItem;
