export default function Vk({color= "white", width = 25, height = 14}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 25 14`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame 1253">
                <path id="Vector"
                      d="M12.2432 13.7894H13.6773C13.6773 13.7894 14.1108 13.7414 14.3314 13.5029C14.5354 13.2839 14.5279 12.8729 14.5279 12.8729C14.5279 12.8729 14.4994 10.9482 15.3934 10.6647C16.274 10.3857 17.4051 12.5249 18.6037 13.3484C19.5097 13.971 20.1983 13.8345 20.1983 13.8345L23.404 13.7894C23.404 13.7894 25.0811 13.6859 24.2861 12.3673C24.2216 12.2593 23.8225 11.3923 21.9024 9.61014C19.8923 7.744 20.1623 8.04703 22.5835 4.82029C24.0581 2.85515 24.6476 1.65506 24.4631 1.14202C24.2876 0.652984 23.203 0.781994 23.203 0.781994L19.5967 0.802995C19.5967 0.802995 19.3297 0.766993 19.1302 0.885501C18.9367 1.00251 18.8122 1.27253 18.8122 1.27253C18.8122 1.27253 18.2406 2.79364 17.4786 4.08674C15.872 6.81544 15.2284 6.95945 14.9659 6.78993C14.3554 6.39541 14.5084 5.20282 14.5084 4.35676C14.5084 1.71206 14.9089 0.609481 13.7268 0.32446C13.3338 0.229953 13.0458 0.166948 12.0422 0.156448C10.7551 0.142947 9.66451 0.160948 9.04796 0.46247C8.63693 0.663485 8.32041 1.11202 8.51392 1.13752C8.75244 1.16902 9.29248 1.28303 9.579 1.67306C9.94953 2.1756 9.93603 3.30668 9.93603 3.30668C9.93603 3.30668 10.149 6.41941 9.43949 6.80644C8.95196 7.07195 8.28441 6.53042 6.8518 4.05373C6.11825 2.78614 5.56321 1.38354 5.56321 1.38354C5.56321 1.38354 5.4567 1.12252 5.26618 0.983009C5.03517 0.813496 4.71114 0.759492 4.71114 0.759492L1.28189 0.780494C1.28189 0.780494 0.767355 0.795495 0.578341 1.01901C0.410328 1.21853 0.56484 1.62956 0.56484 1.62956C0.56484 1.62956 3.25004 7.91052 6.28926 11.0772C9.07796 13.98 12.2432 13.7894 12.2432 13.7894Z"
                      fill={color}
                />
            </g>
        </svg>
    );
}