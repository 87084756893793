import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import {
    AchievementConditionResponseData,
    AchievementPageRequestData,
    AchievementPageResponseData,
    AchievementRequestData,
    AchievementResponseData, AchievementSortData,

} from "./interface";

export const $achievementCreate = (data: AchievementRequestData, handler?: IApiHandlerParams): Promise<AchievementResponseData> => api
    .post('/achievement/single', data, {
        handler
    })

export const $achievementUpdate = (id: number, data: AchievementRequestData, handler?: IApiHandlerParams): Promise<AchievementResponseData> => api
    .put('/achievement/single/' + id, data, {
        handler
    })

export const $achievementDelete = (id: number, handler?: IApiHandlerParams): Promise<AchievementResponseData> => api
    .delete('/achievement/single/' + id, {
        handler
    })


export const $achievementGet = (id: number, handler?: IApiHandlerParams): Promise<AchievementResponseData> => api
    .get('/achievement/single/' + id, {
        handler
    })

export const $achievementListGet = (params?: AchievementPageRequestData, handler?: IApiHandlerParams): Promise<AchievementPageResponseData> => api
    .get('/achievement', {
        request: {params},
        handler
    })

export const $achievementListTypeGet = ( handler?: IApiHandlerParams): Promise<AchievementConditionResponseData[]> => api
    .get('/achievement/type/conditions', {
        handler
    })

export const $achievementSort = (data: AchievementSortData[], handler?: IApiHandlerParams): Promise<any> => api
    .put('/achievement/sort', data, {
        handler
    })
