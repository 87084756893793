import { FC } from "react";
import DashboardContent from "../../components/dashboard/DashboardContent";
import DashboardActiveUsers from "../../components/dashboard/DashboardActiveUsers";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import { UserModelLink } from "../../api/requests/staff/interface";

const DashboardPage: FC = () => {
    const userData: UserModelLink | null = useSelector(GetCurrentUserData);

    if (!userData || [9, 10].includes(userData?.role)) return null;

    return (
        <>
            <div className="dashboard list-wrapper">
                <div className="container-fluid">
                    <DashboardHeader/>

                    <div className="row">
                        <div className="col-lg-9 col-12">

                            <DashboardContent userData={ userData }/>

                            {/*<DashboardTasks/>*/ }

                            {/*<DashboardProducts/>*/ }

                            {/*<DashboardActiveUsers/>*/}
                        </div>

                        {/*<div className="col-3">*/ }
                        {/*    <div className="aside">*/ }
                        {/*        <DashboardAsideInfoAllContainer userData={ userData }/>*/ }

                        {/*        <DashboardAsideInfoContainer userData={ userData }/>*/ }
                        {/*    </div>*/ }
                        {/*</div>*/ }

                        <div className="mobileElement">
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardPage;

