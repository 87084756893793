import { FC, useEffect, useState } from "react";
import TextField from "../form/TextField";
import DialogContainer from "../dialog/DialogContainer";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import Percentage from "../../img/icon/Percentage";
import { ToggleBlock } from "../form/ToggleBlock";
import Info from "../../img/icon/Info";
import Telegram from "../../img/icon/Telegram";
import TransformObjArray from "../../mixins/transform-obj-array";
import { $createDiscount, $updateDiscount } from "../../api/requests/discount";

interface CreateGroupDialogProps {
    selectItem: any
    isShow: boolean
    setIsShow: () => void
    updateList: () => void
}

const CreatePromoDialog: FC<CreateGroupDialogProps> = (
    {
        selectItem,
        isShow,
        setIsShow,
        updateList
    },
) => {
    const transformObjArray = new TransformObjArray();

    const [ list, setList ] = useState<any[]>([
        {
            key: 'name',
            text: 'Название',
            value: '',
            field: true,
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'percent',
            type: 'number',
            text: 'Значение',
            value: '',
            field: true,
            rightText: <Percentage size={ 15 }/>,
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'limit',
            type: 'number',
            text: 'Ограничение количества',
            rightText: 'шт',
            field: true,
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'isActive',
            toggleText: 'Включен',
            value: true,
            checkbox: true,
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        // {
        //     key: 'notification',
        //     toggleText: 'Уведомления в Telegram',
        //     imgLeft: <Telegram color={ '#3582F6' } width={ 20 }/>,
        //     checkbox: true,
        //     value: '',
        //     setValue: (value: string, key: string) => onChangeSetValue(value, key),
        // },
    ]);

    useEffect(() => init(), []);

    function init() {
        if (selectItem) {
            const listUpdate = [ ...list ].map(i => {
                if (selectItem && Object.keys(selectItem).includes(i.key)) i.value = selectItem[i.key];

                return i;
            });

            setList(listUpdate);
        }
    }

    function onChangeSetValue(value: string, key: string) {
        const listUpdate = [ ...list ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setList(listUpdate)
    }

    function onClickSave() {
        const requestData = {
            ...transformObjArray.getRequestObj(list),
            percent: +transformObjArray.getRequestObj(list).percent,
            limit: +transformObjArray.getRequestObj(list).limit || undefined,
            type: 2,
        };

        if (selectItem) return onClickUpdate(requestData);

        $createDiscount(requestData).then(res => {
            if (!res.name) return;

            resetForm();
            setIsShow();
            updateList();
        })
    }

    function onClickUpdate(item: any) {
        $updateDiscount(selectItem.id, item).then(res => {
            if (!res.name) return;

            resetForm();
            setIsShow();
            updateList();
        })
    }

    function resetForm() {
        const listUpdate = [ ...list ].map(i => ({
            ...i,
            value: ''
        }));

        setList(listUpdate);
    }

    return (
        <DialogContainer
            isOpen={ isShow }
            closeModal={ () => {
                setIsShow();
                resetForm();
            } }
            label={ selectItem ? 'Редактировать промокода' : 'Добавление промокода' }
            closeIcon={ <Close/> }
        >
            <div className={'added__booking__dialog'}>
                { list.map((item, idx) => {
                    return (
                        <div className="create__group__promo"
                             key={ `create-group__promo${ idx }` }
                        >
                            { item.title && (
                                <div className="create__group__promo__title">
                                    <h5>{ item.title }</h5>

                                    {/*{ item.info && (*/}
                                    {/*    <>*/}
                                    {/*        <Info color={ '#3582F6' }/>*/}

                                    {/*        <div></div>*/}
                                    {/*    </>*/}
                                    {/*) }*/}
                                </div>
                            ) }

                            { item.field && (
                                <TextField
                                    type={ item.type }
                                    label={ item.text }
                                    value={ item.value }
                                    onChangeValue={ (value) => item.setValue(value, item.key) }
                                    imgRight={ item.rightText }
                                    imgLeft={ item.img }
                                />
                            ) }

                            { item.checkbox && (
                                <ToggleBlock
                                    isChecked={ item.value }
                                    handleChange={ (i) => item.setValue(i, item.key) }
                                    desc={ item.toggleText }
                                    imgLeft={ item.imgLeft }
                                />
                            ) }

                        </div>
                    )
                }) }

                <div className="create__group__promo__btn">
                    <Button
                        className={ `btn btn-primary` }
                        text={ 'Сохранить' }
                        onClick={ onClickSave }
                    />
                </div>
            </div>

        </DialogContainer>
    )
}

export default CreatePromoDialog;
