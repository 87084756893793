import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import { CreateUserData, ListUsersRequestParams, CenterModel, ChangeIsQr } from "./interface";
import { CreateUserDataDevice } from "../device/interface";

export const $getCurrentCentral = (params?: CreateUserDataDevice, handler?: IApiHandlerParams): Promise<CreateUserData> => api
    .get('/center', {
        request: {params},
        handler
    })

export const $createItemCenter = (data: ListUsersRequestParams, handler?: IApiHandlerParams): Promise<CenterModel> => api
    .post('/center', data, {
        handler
    })

export const $getCentral = (id: string, handler?: IApiHandlerParams): Promise<CenterModel> => api
    .get('/center/single/' + id, {
        handler
    })

export const $updateCentral = (id: number, data: ListUsersRequestParams, handler?: IApiHandlerParams): Promise<CenterModel> => api
    .put('/center/single/' + id, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })

export const $deleteCentral = (id: number, handler?: IApiHandlerParams): Promise<CenterModel> => api
    .delete('/center/single/' + id, {
        // mock: require('./mocks/error.delete.json'),
        handler
    })

export const $changeIsQr = (data: ChangeIsQr, handler?: IApiHandlerParams): Promise<string> => api
    .post('/settings/qr', data, {
        handler
    })
