export default function Tournament({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.75009 0.977625C9.90476 0.888316 10.0954 0.888316 10.2501 0.977633L17.6887 5.27229C17.8433 5.3616 17.9387 5.52667 17.9387 5.7053V14.2946C17.9387 14.4732 17.8433 14.6383 17.6887 14.7276L10.2501 19.0223C10.0954 19.1116 9.90476 19.1116 9.75009 19.0223L2.31152 14.7276C2.15682 14.6383 2.06152 14.4732 2.06152 14.2946V5.7053C2.06152 5.52667 2.15682 5.3616 2.31152 5.27228L9.75009 0.977625Z"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M14.1668 12.5002H5.8335L10.0002 5.8335L14.1668 12.5002Z"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M2.0835 5.4165L10.0002 5.83317"
				stroke={color}
				strokeWidth="1.25"
				strokeLinejoin="round"
			/>

			<path
				d="M2.0835 5.4165L5.8335 12.4998"
				stroke={color}
				strokeWidth="1.25"
				strokeLinejoin="round"
			/>

			<path
				d="M17.9165 5.4165L14.1665 12.4998"
				stroke={color}
				strokeWidth="1.25"
				strokeLinejoin="round"
			/>

			<path
				d="M17.9167 5.41683L10 5.83345V0.833496"
				stroke={color}
				strokeWidth="1.25"
				strokeLinejoin="round"
			/>

			<path
				d="M17.9165 14.5833L14.1665 12.5"
				stroke={color}
				strokeWidth="1.25"
				strokeLinejoin="round"
			/>

			<path
				d="M2.0835 14.5833L5.8335 12.5"
				stroke={color}
				strokeWidth="1.25"
				strokeLinejoin="round"
			/>

			<path
				d="M5.8335 12.5L10.0002 19.1667L14.1668 12.5"
				stroke={color}
				strokeWidth="1.25"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
