export default function Report({color= 'white', size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g
                clipPath={color}>
                <path d="M5.83325 5H14.1666M5.83325 7.5H14.1666M7.49992 14.1667H12.4999" stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path
                    d="M2.50008 9.99999H2.16675C2.03414 9.99999 1.90696 10.0527 1.81319 10.1464C1.71943 10.2402 1.66675 10.3674 1.66675 10.5V17.8333C1.66675 17.899 1.67968 17.964 1.70481 18.0247C1.72994 18.0853 1.76677 18.1404 1.81319 18.1869C1.85962 18.2333 1.91474 18.2701 1.97541 18.2953C2.03607 18.3204 2.10109 18.3333 2.16675 18.3333H17.8334C17.8991 18.3333 17.9641 18.3204 18.0248 18.2953C18.0854 18.2701 18.1405 18.2333 18.187 18.1869C18.2334 18.1404 18.2702 18.0853 18.2954 18.0247C18.3205 17.964 18.3334 17.899 18.3334 17.8333V10.5C18.3334 10.3674 18.2807 10.2402 18.187 10.1464C18.0932 10.0527 17.966 9.99999 17.8334 9.99999H17.5001M2.50008 9.99999V2.16666C2.50008 2.03405 2.55276 1.90687 2.64653 1.8131C2.7403 1.71933 2.86747 1.66666 3.00008 1.66666H17.0001C17.1327 1.66666 17.2599 1.71933 17.3536 1.8131C17.4474 1.90687 17.5001 2.03405 17.5001 2.16666V9.99999M2.50008 9.99999H17.5001"
                    stroke={color}
                    strokeWidth="1.5"
                />
            </g>
            <defs>
                <clipPath id="clip0_75_1449">
                    <rect width={size} height={size} fill={color}
                    />
                </clipPath>
            </defs>
        </svg>
    );
}


