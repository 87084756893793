export default function Chart({color = "white", size = 22}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 22 22`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Component 3">
                <path id="Vector"
                      d="M8.25 19.25H13.75M8.25 19.25V14.6667M8.25 19.25H3.3C3.15413 19.25 3.01424 19.1921 2.91109 19.0889C2.80795 18.9858 2.75 18.8459 2.75 18.7V15.2167C2.75 15.0708 2.80795 14.9309 2.91109 14.8278C3.01424 14.7246 3.15413 14.6667 3.3 14.6667H8.25M13.75 19.25V8.25M13.75 19.25H18.7C18.8459 19.25 18.9858 19.1921 19.0889 19.0889C19.1921 18.9858 19.25 18.8459 19.25 18.7V3.3C19.25 3.15413 19.1921 3.01424 19.0889 2.91109C18.9858 2.80795 18.8459 2.75 18.7 2.75H14.3C14.1541 2.75 14.0142 2.80795 13.9111 2.91109C13.8079 3.01424 13.75 3.15413 13.75 3.3V8.25M8.25 14.6667V8.8C8.25 8.65413 8.30795 8.51424 8.41109 8.41109C8.51424 8.30795 8.65413 8.25 8.8 8.25H13.75"
                      stroke={color}
                      strokeWidth="1.375"
                />
            </g>
        </svg>
    );
}

