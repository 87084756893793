export default function Center({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.8335 7.50828L5.84183 7.49902"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M9.1665 7.50828L9.17484 7.49902"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M5.8335 10.8418L5.84183 10.8325"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M9.1665 10.8418L9.17484 10.8325"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M5.8335 14.1748L5.84183 14.1655"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M9.1665 14.1748L9.17484 14.1655"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path
				d="M12.5 17.5H3C2.72386 17.5 2.5 17.2762 2.5 17V4.66667C2.5 4.39052 2.72386 4.16667 3 4.16667H7.5V3C7.5 2.72386 7.72386 2.5 8 2.5H12C12.2762 2.5 12.5 2.72386 12.5 3V7.5M12.5 17.5H17C17.2762 17.5 17.5 17.2762 17.5 17V8C17.5 7.72386 17.2762 7.5 17 7.5H12.5M12.5 17.5V14.1667M12.5 7.5V10.8333M12.5 14.1667V10.8333M12.5 14.1667H14.1667M12.5 10.8333H14.1667"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
