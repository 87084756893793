export default function History({color= "white", size = 24}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 24 24`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 3V15H2V18C2 19.654 3.346 21 5 21H13V19H5C4.449 19 4 18.551 4 18V17H15L13 15H6V5H19V9H21V3H4ZM8 7V9H10V7H8ZM12 7V9H17V7H12ZM8 11V13H10V11H8ZM15 11V13H16V14.9141L18.0859 17L16 19.0859V21H15V23H24V21H23V19.0859L20.9141 17L23 14.9141V13H24V11H15ZM18 13H21V14.0859L19.5 15.5859L18 14.0859V13ZM19.5 18.4141L21 19.9141V21H18V19.9141L19.5 18.4141Z"
                fill={color}
            />
        </svg>
    );
}
