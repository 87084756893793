import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { CenterAppModel, DashboardUpdateCenterApp, FileImage } from "./interface";

export const $getListApp = (handler?: IApiHandlerParams): Promise<CenterAppModel[]> => api
    .get('/app', {
        handler
    })

export const $getAppById = (id: number, handler?: IApiHandlerParams): Promise<CenterAppModel> => api
    .get('/app/single/' + id, {
        handler
    })

export const $updateApp = (id: number, data: DashboardUpdateCenterApp, handler?: IApiHandlerParams): Promise<CenterAppModel> => api
    .put('/app/single/' + id, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })

export const $deleteApp = (id: number, handler?: IApiHandlerParams): Promise<CenterAppModel> => api
    .delete('/app/single/' + id, {
        // mock: require('./mocks/error.delete.json'),
        handler
    })

export const $appCreate = (data: DashboardUpdateCenterApp, handler?: IApiHandlerParams): Promise<CenterAppModel> => api
    .post('/app/single', data, {
        handler
    })

export const $appUploadImage = (data: FileImage, handler?: IApiHandlerParams): Promise<string> => api
    .post('/app/image/upload', data, {
        handler
    })


