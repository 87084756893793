import { FC, useEffect, useState } from "react";
import Button from "../form/Button";
import { DashboardMainStatistic } from "../../api/requests/reports/interface";
import { $getStatisticDashboard } from "../../api/requests/reports";

interface CloseCollectorProps {
    closeCollector: () => void
}

export const OpenCollector: FC<CloseCollectorProps> = (
    {
        closeCollector
    }
) => {

    const [ statistics, setStatistics ] = useState<DashboardMainStatistic>({
        revenue: 0, // Выручка
        revenueCard: 0, // Выручка картой
        cash: 0, // Наличные
        cashBefore: 0, // Наличных до начала смены
        lastSessionDate: '' // Дата последней сессии
    })

    useEffect(() => init(), []);

    function init() {
        // $getStatisticDashboard().then(res => {
        //     if (!res) return;
        //
        //     setStatistics(res)
        // })
    }

    return (
        <div className='collector__preview'>
            <div className="collector__preview-money">
                <div className="collector__preview-proceeds">
                    <div className="collector__preview-block">
                        <div className="collector__preview-desc">
                            <p className='collector__preview-title'>Выручка за смену</p>
                            <p className='collector__preview-paragraph24PX'>{ statistics?.revenue || 0 } ₽</p>
                        </div>
                    </div>
                    <div className="collector__preview-seperated">
                        <div className="collector__preview-desc">
                            <p className="collector__preview-title">Наличные</p>
                            <p className="collector__preview-paragraph14PX">{ statistics?.cash || 0 } ₽</p>
                        </div>
                        <div className="collector__preview-desc">
                            <p className="collector__preview-title">Картой</p>
                            <p className="collector__preview-paragraph14PX">{ statistics?.revenueCard || 0 } ₽</p>
                        </div>
                    </div>
                </div>
                <div className="collector__preview-cashing">
                    <div className="collector__preview-desc">
                        <p className="collector__preview-title">На начало смены</p>
                        <p className='collector__preview-paragraph14PX'>{ statistics?.cashBefore || 0 } ₽</p>
                    </div>
                </div>
            </div>
            <Button className='btn btn-primary collector__button' text={ 'Инкассировать' } onClick={ closeCollector }/>
        </div>
    )
}
