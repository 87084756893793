export default function UserComplete({color = "#70D584", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none"
             xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.66699 16.6667V15.8333C1.66699 14.2862 2.28157 12.8025 3.37554 11.7085C4.4695 10.6146 5.95323 10 7.50033 10"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.8037 14.1663L13.712 15.0747L15.5303 13.258M7.50033 9.99967C8.38438 9.99967 9.23223 9.64849 9.85735 9.02336C10.4825 8.39824 10.8337 7.5504 10.8337 6.66634C10.8337 5.78229 10.4825 4.93444 9.85735 4.30932C9.23223 3.6842 8.38438 3.33301 7.50033 3.33301C6.61627 3.33301 5.76842 3.6842 5.1433 4.30932C4.51818 4.93444 4.16699 5.78229 4.16699 6.66634C4.16699 7.5504 4.51818 8.39824 5.1433 9.02336C5.76842 9.64849 6.61627 9.99967 7.50033 9.99967Z"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="14"
				cy="14"
				r="4.375"
				stroke={color}
				strokeWidth="1.25"
			/>
        </svg>
    );
}
