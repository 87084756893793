import { FC, useEffect, useState } from "react";
import TableContainer from "../form/TableContainer";
import FilterSelect from "../FilterSelect";
import {
    $getCityAvailable, $getStatisticAverageHours,
    $getStatisticFc,
    $getStatisticRating, $getStatisticSingleRating, $getStatisticSpendHours,
    $getStatisticVisitorCount
} from "../../api/requests/reports";
import DatePickerField from "../form/DatePickerField";
import Button from "../form/Button";
import DateOperations from "../../mixins/date-operation";
import {useSelector} from "react-redux";
import {GetCurrentUserData} from "../../redux/slices/userSlice";

export const RatingByCityReport: FC = () => {
    const dateOperation = new DateOperations();
    const userData = useSelector(GetCurrentUserData);

    const [ dateStart, setDateStart ] = useState<Date>(new Date());
    const [ dateEnd, setDateEnd ] = useState<Date>(new Date());

    const [ isLengthList, setIsLengthList ] = useState<boolean>(false);
    const [ isLengthVizitList, setIsLengthVizitList ] = useState<boolean>(false);
    const [ isLengthAverageList, setIsLengthAverageList ] = useState<boolean>(false);
    const [ isLengthSpendList, setIsLengthSpendList ] = useState<boolean>(false);

    const headerTable = [
        {
            key: 'center',
            title: 'Имя центра'
        },
        {
            key: 'generalScore',
            title: 'Общее количество очков'
        },
        {
            key: 'spendHourScore',
            title: 'Очки за потраченное время'
        },
        {
            key: 'avarageHourScore',
            title: 'Среднее количество очков за час'
        },
        {
            key: 'visitorsScore',
            title: 'Количество очков за посещение'
        },
        {
            key: 'seasonScore',
            title: 'Очки за сезон'
        },
    ];
    const headerVizitorTable = [
        {
            key: 'center',
            title: 'Имя центра'
        },
        {
            key: 'visitorCount',
            title: 'Количество визитов'
        },
        {
            key: 'activePercent',
            title: 'Активный проценст'
        },
        {
            key: 'score',
            title: 'Очков'
        }
    ];
    const headerAverageHoursTable = [
        {
            key: 'center',
            title: 'Имя центра'
        },
        {
            key: 'playTimes',
            title: 'Игровое время'
        },
        {
            key: 'playCount',
            title: 'Количество играющих'
        },
        {
            key: 'averageHours',
            title: 'Среднее количество часов'
        },
        {
            key: 'score',
            title: 'Очков'
        }
    ];
    const headerSpendHoursTable = [
        {
            key: 'center',
            title: 'Имя центра'
        },
        {
            key: 'spendHours',
            title: 'Потрачено часов'
        },
        {
            key: 'score',
            title: 'Очков'
        }
    ];

    const [ contentTable, setContentTable ] = useState<any[]>([]);
    const [ contentVizitorTable, setContentVizitorTable ] = useState<any[]>([]);
    const [ contentAverageHoursTable, setContentAverageHoursTable ] = useState<any[]>([]);
    const [ contentSpendHoursTable, setContentSpendHoursTable ] = useState<any[]>([]);

    const processNumber = (num: number): number => {
        return Math.round(num * 100) / 100
    }

    const onChangeResponseItem = (item: any): any => {
        return {
            center: `${item.center.name || ''}`,
            spendHours: `${item.spendHours ? processNumber(item.spendHours) : 0} ч.`,
            generalScore: `${item.generalScore || 0} ₽`,
            spendHourScore: `${item.spendHourScore || 0} ₽`,
            avarageHourScore: `${item.avarageHourScore || 0}`,
            visitorsScore: `${item.visitorsScore || 0}`,
            seasonScore: `${item.seasonScore || 0} ₽`,
        }
    }

    const onChangeResponseVizitorItem = (item: any): any => {
        return {
            center: `${item.center.name || ''}`,
            visitorCount: `${item.visitorCount || 0}`,
            activePercent: `${item.activePercent || 0} %`,
            score: `${item.score || 0} ₽`,
        }
    }

    const onChangeResponseAverageHoursItem = (item: any): any => {
        return {
            center: `${item.center.name || ''}`,
            playTimes: `${item.playTimes || 0} ч.`,
            playCount: `${item.playCount || 0}`,
            averageHours: `${item.averageHours || 0} ч.`,
            score: `${item.score || 0} ₽`,
        }
    }

    const onChangeResponseSpendHoursItem = (item: any): any => {
        return {
            center: `${item.center.name || ''}`,
            spendHours: `${item.spendHours ? processNumber(item.spendHours) : 0} ч.`,
            score: `${item.score || 0} ₽`,
        }
    }

    useEffect(() => {
        userData && init();
    }, [userData]);

    function init() {
        const requestObj = {
            fromDate: dateOperation.getISODateTimeByObj(dateStart),
            toDate: dateOperation.getISODateTimeByObj(dateEnd),
        }

        setIsLengthList(false);
        setIsLengthVizitList(false);
        setIsLengthAverageList(false);
        setIsLengthSpendList(false);

        $getStatisticSingleRating({
            centerId: userData?.staffCenter?.id || '',
            ...requestObj
        }).then(res => {
            if (!res) return setIsLengthList(true);

            setContentTable([onChangeResponseItem(res)])
        })

        $getStatisticVisitorCount(requestObj).then(res => {
            if (!res) return setIsLengthVizitList(true);

            setContentVizitorTable([onChangeResponseVizitorItem(res)])
        })

        $getStatisticAverageHours(requestObj).then(res => {
            if (!res) return setIsLengthAverageList(true);

            setContentAverageHoursTable([onChangeResponseAverageHoursItem(res)])
        })

        $getStatisticSpendHours(requestObj).then(res => {
            if (!res) return setIsLengthSpendList(true);

            setContentSpendHoursTable([onChangeResponseSpendHoursItem(res)])
        })
    }

    return (
        <div className='reports__shift'>
            <div className='reports__filter'>
                <div className='reports__filter-dates'>
                    <DatePickerField
                        className='reports-date'
                        isIcon
                        startDate={dateStart}
                        onChangeDate={(date) => {
                            setDateStart(date)
                        }}
                    />

                    <span className='reports__filter-line'></span>

                    <DatePickerField
                        className='reports-date'
                        isIcon
                        startDate={dateEnd}
                        onChangeDate={(date) => {
                            setDateEnd(date)
                        }}
                    />
                </div>

                <div className='reports__filter-buttons'>
                    <Button
                        text={'Применить'}
                        className='btn btn-primary'
                        onClick={init}
                    />

                    {/*<FilterSelect*/}
                    {/*    placeholder='Выбор'*/}
                    {/*    options={ [ {label: 'first', value: 0}, {label: 'second', value: 1} ] }*/}
                    {/*    onChange={ () => ('select') }*/}
                    {/*    className='reports__filter-selection'*/}
                    {/*/>*/}
                </div>
            </div>

            <h1 className='reports__shift-title'>Отчет по рейтингу</h1>

            {!isLengthList ? (
                <div className='reports__shift-content'>
                    {contentTable.map((item: any, idx: number) => (
                        <TableContainer
                            key={idx}
                            sortable={false}
                            header={headerTable}
                            content={[item]}
                            onLeftClickTrContent={() => ('onLeftClickTrContent')}
                            isLastRightText={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='reports__shift-notFound reports__rating-notFound'>
                    За выбранный период данных нет.
                </div>
            )}

            <h1 className='reports__shift-title'>Отчет по посещаемости</h1>

            {!isLengthVizitList ? (
                <div className='reports__shift-content'>
                    {contentVizitorTable.map((item: any, idx: number) => (
                        <TableContainer
                            key={idx}
                            sortable={false}
                            header={headerVizitorTable}
                            content={[item]}
                            onLeftClickTrContent={() => ('onLeftClickTrContent')}
                            isLastRightText={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='reports__shift-notFound reports__rating-notFound'>
                    За выбранный период данных нет.
                </div>
            )}

            <h1 className='reports__shift-title'>Отчет по среднему использованных часов</h1>

            {!isLengthAverageList ? (
                <div className='reports__shift-content'>
                    {contentAverageHoursTable.map((item: any, idx: number) => (
                        <TableContainer
                            key={idx}
                            sortable={false}
                            header={headerAverageHoursTable}
                            content={[item]}
                            onLeftClickTrContent={() => ('onLeftClickTrContent')}
                            isLastRightText={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='reports__shift-notFound reports__rating-notFound'>
                    За выбранный период данных нет.
                </div>
            )}

            <h1 className='reports__shift-title'>Отчет по использованных часов</h1>

            {!isLengthSpendList ? (
                <div className='reports__shift-content'>
                    {contentSpendHoursTable.map((item: any, idx: number) => (
                        <TableContainer
                            key={idx}
                            sortable={false}
                            header={headerSpendHoursTable}
                            content={[item]}
                            onLeftClickTrContent={() => ('onLeftClickTrContent')}
                            isLastRightText={false}
                        />
                    ))}
                </div>
            ) : (
                <div className='reports__shift-notFound reports__rating-notFound'>
                    За выбранный период данных нет.
                </div>
            )}
        </div>
    )
}
