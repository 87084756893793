import { FC } from "react";
import Button from "../form/Button";
// import Info from "../../img/icon/Info";
import Download from "../../img/icon/Download";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useNavigate } from "react-router-dom";

const CardClubRight: FC = () => {
    const navigate = useNavigate();

    const isActive = true;

    function onClickHome() {
        navigate("/");
    }

    return (
        <div className="club__add__right">
            {/*<div className="club__header club__add__header">*/}
            {/*    <h4>Подписка центра</h4>*/}
            {/*</div>*/}

            {/*<div className="club__add__content">*/}
            {/*    <div className="club__add__content-title">*/}
            {/*        <p>Данные центра</p>*/}

            {/*        <a href="#">*/}
            {/*            Что входит в подписку?*/}
            {/*        </a>*/}
            {/*    </div>*/}

            {/*    <div className="club__add__content-block club__add__content-type">*/}
            {/*        <div className="club__add__content-type__name">*/}
            {/*            <p>Trial</p>*/}

            {/*            <span className={ isActive && 'active' }>*/}
            {/*                                Активна*/}
            {/*                            </span>*/}
            {/*        </div>*/}

            {/*        <div className="club__add__content-type__items">*/}
            {/*            <div className="club__add__content-type__item">*/}
            {/*                <p>Лицензий</p>*/}

            {/*                <span>100 шт</span>*/}
            {/*            </div>*/}
            {/*            <div className="club__add__content-type__item club__add__content-type-date">*/}
            {/*                <p>Активна до</p>*/}

            {/*                <span>12.05.2023</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <Button*/}
            {/*        text={ 'Подключить платную подписку' }*/}
            {/*        onClick={ onClickHome }*/}
            {/*        className={ 'btn btn-link btn-map-center club__add__content-block' }*/}
            {/*    />*/}

            {/*    <div className="club__add__content-block club__add__content-stock">*/}
            {/*        <h4>30 дней бесплатно</h4>*/}

            {/*        <p>*/}
            {/*            У вас активен бесплатный период использования SmartShell, в течение которого*/}
            {/*            можно оценить все функции и выбрать подходящую подписку. Если вы оформите*/}
            {/*            платную подписку во время пробного периода, то она активируется сразу, а пробный*/}
            {/*            период перестанет действовать.*/}
            {/*        </p>*/}
            {/*    </div>*/}

            {/*    <div className="line"></div>*/}

            {/*    <div className="club__add__content-block club__add__content-cloud">*/}
            {/*        <div className="club__add__content-cloud__header">*/}
            {/*            <h4>У вас облачный сервер</h4>*/}

            {/*            <Button*/}
            {/*                text={''}//<Info color={'#3582F6'}/>}*/}
            {/*                onClick={() => {}}*/}
            {/*                className={'btn btn-icon'}*/}
            {/*            />*/}
            {/*        </div>*/}

            {/*        <div className="club__add__content-cloud__content">*/}
            {/*            <p>*/}
            {/*                Для первого входа в панель центра используйте телефон и пароль от личного кабинета.*/}
            {/*            </p>*/}
            {/*        </div>*/}

            {/*        <div className="club__add__content-cloud__footer">*/}
            {/*            <Button*/}
            {/*                text={ 'Скачать шелл' }*/}
            {/*                leftIcon={ (*/}
            {/*                    <Download/>*/}
            {/*                ) }*/}
            {/*                onClick={ onClickHome }*/}
            {/*                className={ 'btn btn-primary' }*/}
            {/*            />*/}

            {/*            <Button*/}
            {/*                text={ 'В панель центра' }*/}
            {/*                leftIcon={ (*/}
            {/*                    <ArrowLeft/>*/}
            {/*                ) }*/}
            {/*                onClick={ onClickHome }*/}
            {/*                className={ 'btn btn-primary' }*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default CardClubRight;
