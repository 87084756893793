export default function Warning({color="white", size = 18}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector"
                      d="M8.9996 6.87511V9.70845M14.6967 15.3751H3.30248C2.21306 15.3751 1.53164 14.1964 2.07423 13.2522L7.77135 3.34407C8.31677 2.39703 9.68314 2.39703 10.2279 3.34407L15.925 13.2522C16.4676 14.1964 15.7854 15.3751 14.6967 15.3751Z"
                      stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                />
                <path id="Vector_2" d="M9 12.5504L9.0085 12.541" stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
