import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import {
    CenterDiscountDataModel,
    CenterDiscountRequestData,
    CenterDiscountResponseData
} from "./interface";

export const $getDiscountList = (params?: CenterDiscountRequestData, handler?: IApiHandlerParams): Promise<CenterDiscountResponseData> => api
    .get('/center/discount', {
        request: {params},
        handler
    });

export const $createDiscount = (data: CenterDiscountDataModel, handler?: IApiHandlerParams): Promise<CenterDiscountDataModel> => api
    .post('/center/discount', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $getDiscountListById = (id: string, handler?: IApiHandlerParams): Promise<CenterDiscountDataModel> => api
    .get('/center/discount/single/' + id, {
        handler
    });

export const $updateDiscount = (id: string, data: CenterDiscountDataModel, handler?: IApiHandlerParams): Promise<CenterDiscountDataModel> => api
    .put('/center/discount/single/' + id, data,{
        handler
    });

export const $deleteDiscount = (id: string, handler?: IApiHandlerParams): Promise<CenterDiscountDataModel> => api
    .delete('/center/discount/single/' + id, {
        handler
    });

