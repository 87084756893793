import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

const initialState: any = {
    data: null,
};

const deviceSlice = createSlice({
    name: 'device',
    initialState,
    reducers: {
        changeDeviceItem: (state, action: PayloadAction<boolean>) => {
            state.data = action.payload;
        },
    }
});
export const { changeDeviceItem } = deviceSlice.actions;

export const getDeviceData = (state: RootState) => state.device.data;

export default deviceSlice.reducer;
