import { FC, ReactNode, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../../img/icon/ArrowLeft";
import Pen from "../../img/icon/Pen";
import Star from "../../img/icon/Star";
import { selectOption } from "../../types/users";
import Ruble from "../../img/icon/Ruble";
import { getUserEditData } from "../../redux/slices/userEditSlice";
import { useSelector } from "react-redux";
import UserFieldItem from "../../components/users/UserFieldItem";
import Person from "../../img/icon/Person";
import RoleUsers from "../../mixins/role";
import { $cancelDeleteClient, $searchClient, $updateBalanceClient, $updateClient } from "../../api/requests/users";
import LocationCurrent from "../../mixins/location";
import TransformObjArray from "../../mixins/transform-obj-array";
import Token from "../../img/icon/Token";
import { successNotify } from "../../notifications";


interface infoField {
    key: string
    label: string
    placeholder: string
    value: string
    width: number
    list?: any[]
    imgLeft?: ReactNode
    imgRight?: ReactNode
    disabled?: boolean
    type?: string
}

const UsersPage: FC = () => {
    const navigate = useNavigate();
    const locationCurrent = new LocationCurrent();

    const urlData = locationCurrent.getTypeLocation(location.pathname);

    const userData = useSelector(getUserEditData);
    const roleUsers = new RoleUsers();

    const transformObjArray = new TransformObjArray();

    const [ clientLocalData, setClientLocalData ] = useState(userData || {});

    const [ isEdit, setIsEdit ] = useState(false);
    const [ isShowEdit, setIsShowEdit ] = useState(true);

    const isUpdateByRole = roleUsers.getModelRoleByType('users', 'update');

    const [ strategy, setStrategy ] = useState<selectOption[]>([
        {
            label: 'Фиджитал',
            value: 0
        },
        {
            label: 'Спорт',
            value: 1
        },
        {
            label: 'Киберспорт',
            value: 2
        }
    ]);

    const [ verificationList, setVerificationList ] = useState<selectOption[]>([
        {
            label: 'Верифицирован',
            value: true
        },
        {
            label: 'Не верифицирован',
            value: false
        }
    ]);

    const [ statusList, setStatusList ] = useState<selectOption[]>([
        {
            label: 'Забанен',
            value: 0
        },
        {
            label: 'Активен',
            value: 1
        },
        // {
        //     label: 'На смене',
        //     value: 2
        // }
    ]);

    const [ infoFieldList, setInfoFieldList ] = useState<infoField[]>([
        {
            key: 'name',
            label: 'Имя',
            placeholder: 'Имя',
            value: '',
            width: 6,
        },
        {
            key: 'surname',
            label: 'Фамилия',
            placeholder: 'Фамилия',
            value: '',
            width: 6,
        },
        {
            key: 'lastName',
            label: 'Отчество',
            placeholder: 'Отчество',
            value: '',
            width: 4,
        },
        {
            key: 'username',
            label: 'Никнейм',
            placeholder: 'Никнейм',
            value: '',
            width: 4,
        },
        {
            key: 'birthDate',
            label: 'Дата рождения',
            placeholder: 'Дата рождения',
            value: '',
            width: 4,
        },
        {
            key: 'email',
            label: 'E-mail',
            placeholder: 'E-mail',
            value: '',
            width: 4,
        },
        {
            key: 'phone',
            label: 'Номер телефона',
            placeholder: 'Номер телефона',
            value: '',
            width: 4,
        },
        {
            key: 'city',
            label: 'Город',
            placeholder: 'Город',
            value: '',
            width: 4,
        },
        {
            key: 'type',
            label: 'Направление',
            placeholder: 'Направление',
            value: '',
            width: 4,
            list: strategy
        },
        {
            key: 'isVerified',
            label: 'Верификация',
            placeholder: 'Верификация',
            value: '',
            width: 4,
            list: verificationList
        },
        {
            key: 'status',
            label: 'Статус',
            placeholder: 'Статус',
            value: '',
            width: 4,
            list: statusList
        },
    ]);

    const [ centerFieldList, setCenterFieldList ] = useState<infoField[]>([
        // {
        //     key: 'center',
        //     label: 'Фиджитал центр',
        //     placeholder: 'Фиджитал центр',
        //     value: '',
        //     width: 4,
        //     list: strategy
        // },
        // {
        //     key: 'udid-phygital',
        //     label: 'UDID Phygital',
        //     placeholder: 'UDID Phygital',
        //     value: '',
        //     width: 4,
        //     imgRight: <Qrcode color={'#3582F6'}/>,
        // },
        // {
        //     key: 'udid',
        //     label: 'UDID',
        //     placeholder: 'UDID',
        //     value: '',
        //     width: 4,
        // },
        {
            key: 'deposit',
            label: 'Текущий Баланс',
            placeholder: 'Баланс',
            value: '',
            width: 4,
            imgLeft: <Ruble/>,
            disabled: true
        },
        {
            key: 'bonuses',
            label: 'Текущие Токены',
            placeholder: 'Токены',
            value: '',
            width: 4,
            imgLeft: <Token/>,
            disabled: true
        },
        {
            key: 'rating',
            label: 'Текущие очки',
            placeholder: 'Очки',
            value: '',
            width: 4,
            disabled: true
        },
        // {
        //     key: 'discount',
        //     label: 'Скидка',
        //     placeholder: 'Скидка',
        //     value: '',
        //     width: 4,
        //     imgRight: <Percentage/>
        // }
        {
            key: 'changeDeposit',
            type: 'number',
            label: 'Баланс',
            placeholder: 'Введите Баланс',
            value: '',
            width: 4,
            imgLeft: <Ruble/>,
        },
        {
            key: 'changeBonuses',
            type: 'number',
            label: 'Токены',
            placeholder: 'Введите Токены',
            value: '',
            width: 4,
            imgLeft: <Token/>,
        },
        {
            key: 'changeRating',
            type: 'number',
            label: 'Очки',
            placeholder: 'Введите очки',
            value: '',
            width: 4,
        },
    ]);

    const verifiedColor = !!transformObjArray.getRequestObj(infoFieldList).isVerified ? '#70D584' : '#FF5858';

    const getChangeList = (arr: infoField[], data?: any) => {
        const client = data || clientLocalData;

        return arr.map(i => {
            const findKey = Object.keys(client).find(_i => _i === i.key)

            if (findKey) {
                if (i.list) i.value = i.list.find(_i => _i.value === client[findKey])
                else i.value = client[findKey];
            }

            return i
        })
    }

    useEffect(() => {
        if (urlData.type !== 'edit') return;

        if (userData) {
            setInfoFieldList((list) => getChangeList(list));
            setCenterFieldList((list) => getChangeList(list));

            // setIsShowEdit(userData.model.includes('edit'))

            return;
        }

        getClientData(urlData.id);
    }, [])

    function getClientData(id: string) {
        $searchClient({search: id}).then(res => {
            if (!res.id) return;

            const updateRes = {
                ...res.activeCenter,
                ...res
            }

            setClientLocalData(updateRes);
            setInfoFieldList((list) => getChangeList(list, updateRes));
            setCenterFieldList((list) => getChangeList(list, updateRes));
        })
    }

    function onClickBack() {
        navigate('/users')
    }

    function onClickEdit() {
        setIsEdit(true);
    }

    function onClickSaveUserInfo() {
        setIsEdit(false);

        $updateClient(clientLocalData.id, transformObjArray.getRequestObj(infoFieldList)).then(res => onClickSaveUserAmount());
    }

    function onClickSaveUserAmount() {
        setIsEdit(false);

        const obj = transformObjArray.getRequestObj(centerFieldList)

        const requestObj = {
            status: transformObjArray.getRequestObj(infoFieldList).status,
            bonuses: +obj.changeBonuses || 0,
            deposit: +obj.changeDeposit || 0,
            rating: +obj.changeRating || 0,
        }

        $updateBalanceClient(clientLocalData.id, requestObj).then(res => {
            if (!res.id) return setIsEdit(true);

            resetFormAmount();
            getClientData(urlData.id);
        })
    }

    function onChangeValueField(value: string | any, item: any, arr: infoField[], set: any) {
        const list = [ ...arr ].map(i => {
            if (i.key === item.key) return {
                ...i,
                value: value
            }

            return i
        })

        set(list)
    }

    function resetFormAmount() {
        setCenterFieldList((list) => {
            return list.map(i => {
                if ([ 'changeDeposit', 'changeBonuses', 'changeRating' ].includes(i.key)) return {
                    ...i,
                    value: ''
                }

                return i
            })
        });
    }

    function onClickCancelDelete() {
        setIsEdit(false);

        $cancelDeleteClient(clientLocalData.id).then(res => {
            if (!res.id) return;

            successNotify('Успешно отменен');

            getClientData(urlData.id);
        })
    }

    return (
        <div className="users__edit list-wrapper">
            <div className="users__edit-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 editors">
                            <div className="users__user-back">
                                <Button
                                    className={ 'btn' }
                                    text={ 'Пользователи' }
                                    onClick={ onClickBack }
                                    leftIcon={ (
                                        <ArrowLeft
                                            color={ '#ffff' }
                                        />
                                    ) }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="users__user">
                                <div className="users__user-header">
                                    <div className="users__user-header-photo">
                                        <div
                                            className="users__user-header-photo-img"
                                            style={{
                                                backgroundColor: clientLocalData?.color || '#919BAD'
                                            }}
                                        >
                                            { clientLocalData?.image ? <img src={ clientLocalData?.image }/> : <Person/> }
                                        </div>

                                        <p>{ clientLocalData?.username || clientLocalData.name }</p>

                                        <span className={'edit__tournament__info__items__header__status'} style={{
                                            backgroundColor: `${verifiedColor}20`,
                                            color: verifiedColor
                                        }}>
                                                { verifiedColor === '#70D584' ? 'Верифицирован' : 'Не верифицирован' }
                                            </span>
                                    </div>

                                    {/*<div className="users__user-header-btn">*/}
                                    {/*    { (isShowEdit && isUpdateByRole) && (*/}
                                    {/*        <Button*/}
                                    {/*            text={ 'Редактировать' }*/}
                                    {/*            leftIcon={ (*/}
                                    {/*                <Pen/>*/}
                                    {/*            ) }*/}
                                    {/*            onClick={ onClickEdit }*/}
                                    {/*            className={ 'btn btn-primary w-100 justify-content-center' }*/}
                                    {/*        />*/}
                                    {/*    ) }*/}
                                    {/*</div>*/}
                                </div>

                                <div className="users__user-content">
                                    <div className="users__user-content__block users__user-content-info">
                                        <h4>Информация о пользователе</h4>

                                        <form>
                                            <div className="row">
                                                { infoFieldList.map((item, idx) => (
                                                    <UserFieldItem
                                                        key={ `info-field-item-${ idx }` }
                                                        item={ item }
                                                        idx={ idx }
                                                        onChangeValueField={ (value, item) =>
                                                            onChangeValueField(value, item, infoFieldList, setInfoFieldList) }
                                                        disabled={ !isEdit }
                                                    />
                                                )) }
                                            </div>
                                        </form>
                                    </div>

                                    <div className="users__user-content__block users__user-content-game">
                                        <div>
                                            {/*<h4>Приоритетные дисциплины</h4>*/ }

                                            {/*<Button*/ }
                                            {/*    text={(*/ }
                                            {/*        <img src="/img/cs-go.png"/>*/ }
                                            {/*    )}*/ }
                                            {/*    onClick={onClickEdit}*/ }
                                            {/*    className={'btn btn-icon users__user-content-game__block'}*/ }
                                            {/*    disabled*/ }
                                            {/*/>*/ }
                                        </div>
                                    </div>

                                    <div className="users__user-content__block users__user-content-center">
                                        <div className="users__user-content-center-header">
                                            <h4>Фиджитал-центр</h4>

                                            {/*<div className="users__user-content-center-right">*/ }
                                            {/*    {clientLocalData.phygitalId*/ }
                                            {/*        ? (<CompleteDouble color={'#70D584'}/>)*/ }
                                            {/*        : (<Unavailable color={'#B4474E'}/>)*/ }
                                            {/*    }*/ }

                                            {/*    <p>Phygital ID</p>*/ }
                                            {/*</div>*/ }
                                        </div>

                                        <form>
                                            <div className="row">
                                                { centerFieldList.map((item, idx) => (
                                                    <UserFieldItem
                                                        key={ `center-field-item-${ idx }` }
                                                        item={ item }
                                                        idx={ idx }
                                                        onChangeValueField={ (value, item) =>
                                                            onChangeValueField(value, item, centerFieldList, setCenterFieldList) }
                                                        disabled={ item.disabled || !isEdit }
                                                    />
                                                )) }
                                            </div>
                                        </form>
                                    </div>

                                    {/*<div className="users__user-content__block users__user-content-progress">*/ }
                                    {/*    <h4>Достижения</h4>*/ }

                                    {/*    <div className="users__user-content-progress__items">*/ }
                                    {/*        <div className="users__user-content-progress__item">*/ }
                                    {/*            <img src="/img/progress.png"/>*/ }
                                    {/*        </div>*/ }

                                    {/*        <div className="users__user-content-progress__item">*/ }
                                    {/*            <img src="/img/progress.png"/>*/ }
                                    {/*        </div>*/ }
                                    {/*    </div>*/ }
                                    {/*</div>*/ }
                                </div>

                                <div className="users__user-footer">
                                    <div className="users__user-footer-btn">
                                        {/*<Button*/}
                                        {/*    text={'Статистика'}*/}
                                        {/*    leftIcon={(*/}
                                        {/*        <Dashboard color={'#3582F6'}/>*/}
                                        {/*    )}*/}
                                        {/*    onClick={onClickEdit}*/}
                                        {/*    className={'btn btn-link'}*/}
                                        {/*    disabled*/}
                                        {/*/>*/}

                                        {/*<Button*/}
                                        {/*    text={'Логи'}*/}
                                        {/*    leftIcon={(*/}
                                        {/*        <Report color={'#3582F6'}/>*/}
                                        {/*    )}*/}
                                        {/*    onClick={onClickEdit}*/}
                                        {/*    className={'btn btn-link'}*/}
                                        {/*    disabled*/}
                                        {/*/>*/}
                                    </div>

                                    { isUpdateByRole && (
                                        <Button
                                            text={ 'Отменить удаление аккаунта' }
                                            onClick={ onClickCancelDelete }
                                            className={ 'btn btn-danger mr-3 users__btn__last' }
                                            disabled={ !clientLocalData.deletedAt }
                                        />
                                    ) }

                                    {isUpdateByRole && (
                                        <>
                                            {isEdit ? (
                                                <Button
                                                    text={ 'Сохранить' }
                                                    onClick={ onClickSaveUserInfo }
                                                    className={ 'btn btn-primary users__btn__last' }
                                                    disabled={ !isEdit }
                                                />
                                            ) : (
                                                <Button
                                                    text={ 'Редактировать' }
                                                    leftIcon={ (
                                                        <Pen/>
                                                    ) }
                                                    onClick={ onClickEdit }
                                                    className={ 'btn btn-primary users__btn__last' }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersPage;

