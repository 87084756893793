import { FC, useRef, useEffect, ReactNode, useState } from 'react';
import { IMaskInput } from 'react-imask';

interface PhoneInputProps {
    type?: string
    value: any
    onChangeValue?: (value: string) => void
    onKeyDown?: (key: string) => void
    placeholder?: string
    imgLeft?: ReactNode | null
    imgRight?: ReactNode | null
    className?: string
    label?: string
    id?: string
    mask?: string
    minLength?: number
    disabled?: boolean
    required?: boolean
    setFocused?: (is: boolean) => void
}

const PhoneInput: FC<PhoneInputProps> = (
    {
        type = 'text',
        value,
        onChangeValue,
        placeholder = '',
        imgLeft,
        imgRight,
        className = '',
        label,
        id = `text-field-${ Math.random() }`,
        mask = '+0 000 000-00-00',
        minLength,
        disabled,
        onKeyDown,
        required,
        setFocused
    }
) => {
    const [ isFocused, setIsFocused ] = useState(false);
    const [ valueLocal, setValue ] = useState<string>(value);

    const phoneInputRef = useRef<any>(null);

    const onChangeLocalValue = (value: string) => {
        setValue(value);

        if (onChangeValue) onChangeValue(value);
    };

    useEffect(() => {
        if (phoneInputRef.current) {
            phoneInputRef.current.value = value;
        }
    }, [value]);

    function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (onKeyDown) onKeyDown(e.key);
    }

    return (
        <div className={ `form-group ${ className }` }>
            { imgLeft && (
                <div className="form-icon-left">
                    { imgLeft }
                </div>
            ) }

            <div className="form-block">
                { label && (
                    <label htmlFor={ id }
                           className={ `form-label${ (isFocused || String(valueLocal).length) ? ' focus' : '' }
                           ${ imgLeft ? ' left' : '' }${ imgRight ? ' right' : '' }` }>
                        { label }
                    </label>
                ) }

                <IMaskInput
                    mask={mask}
                    definitions={{
                        '#': /[0-9]/
                    }}
                    onAccept={(value) => onChangeLocalValue(value)}
                    inputRef={phoneInputRef}
                    id={ id }
                    type={ type }
                    value={ valueLocal }
                    placeholder={ placeholder }
                    className={ `form-control${ label ? ' label' : '' }${ imgLeft ? ' left' : '' }${ imgRight ? ' right' : '' }` }
                    onFocus={ () => setIsFocused(true) }
                    onBlur={ () => setIsFocused(false) }
                    minLength={ minLength }
                    disabled={ disabled }
                    onKeyDown={ handleKeyPress }
                    onWheel={(e) => (e.target as any).blur()}
                />
            </div>

            { imgRight && (
                <div className="form-icon-right">
                    { imgRight }
                </div>
            ) }
        </div>
    );
};

export default PhoneInput;
