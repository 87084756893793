import { FC, useEffect, useState } from "react";
import TextField from "../form/TextField";
import DialogContainer from "../dialog/DialogContainer";
import FilterSelect from "../FilterSelect";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import { $createNewDevice, $updateDevice } from "../../api/requests/device";
import { ListUsersRequestParamsDevice } from "../../api/requests/device/interface";

interface AddedNewDeviceDialogProps {
    isShow: boolean
    setIsShow: (item?: any) => void
    list: any[]
    deviceData?: any
    type: string
}

const AddedNewDeviceDialog: FC<AddedNewDeviceDialogProps > = (
    {
        isShow,
        setIsShow,
        list,
        deviceData,
        type
    }
) => {

    const [name, setName] = useState<string>('');
    const [position, setPosition] = useState<string>('');
    const [capacity, setCapacity] = useState<string>('');

    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    const [valueZone, setValueZone] = useState<any>(null);
    const [zones, setZone] = useState<any[]>([]);

    const [valueDevice, setValueDevice] = useState<any>(null);
    const typeDevice = [
        // { value: 1, label: 'ПК' },
        { value: 1, label: 'Консоль', type: 'console' },
        { value: 2, label: 'VR', type: 'vr' },
        { value: 3, label: 'Спортивные зоны', type: 'open-area' }
    ];

    const isFormValid = () => {
        return (valueZone && !valueZone.value)
            && !String(capacity).length
            // && !String(position).length
            // || (valueDevice && !valueDevice.value);
    }

    useEffect(() => {
        if (list.length) {
            const listZone = list.filter(i => i.label !== "Все группы");

            setZone(listZone);
        }
    }, [list])

    useEffect(() => {
        if (isShow && deviceData) {
            const zoneList = zones.length ? zones : list;

            setName(deviceData.name);
            setPosition(deviceData.position);
            setCapacity(deviceData.capacity);
            setValueZone(zoneList.find(i => i.id === deviceData.zoneId) || null);
            setValueDevice(typeDevice.find(i => i.value === deviceData.type) || null);
        }
    }, [isShow])

    function onClickSave() {
        if (isFormValid() || !valueZone) return;

        setIsLoadingSave(true);

        const findTypeItem = typeDevice.find(i => i.type === type);

        const objRequest: ListUsersRequestParamsDevice = {
            zoneId: String(valueZone.value),
            name,
            capacity: +capacity,
            // position: +position,
            status: 1,
            type: findTypeItem ? findTypeItem.value : 1
        }

        deviceData ? updateCurrentDevice(objRequest) : createDevice(objRequest)
    }

    function createDevice(objRequest: any) {
        $createNewDevice(objRequest).then(i => {
            setIsLoadingSave(false)
            if (!i) return;

            resetData();

            setIsShow(true);
        })
    }

    function updateCurrentDevice(objRequest: any) {
        $updateDevice(deviceData.id, objRequest).then(i => {
            setIsLoadingSave(false);
            if (!i) return;

            resetData();

            setIsShow(true);
        })
    }

    function resetData() {
        setName('')
        setCapacity('')
        setPosition('')
        setValueZone(null)
        setValueDevice(null)
    }

    return (
        <DialogContainer
            isOpen={isShow}
            closeModal={() => {
                resetData();
                setIsShow();
            }}
            label={deviceData ? 'Редактирование устройства / зоны' : 'Добавление устройства / зоны'}
            closeIcon={<Close/>}
        >
            <div className={'create__group'}>
                <TextField
                    label={ 'Название' }
                    imgRight={(
                        <p>{`${name.length}/100`}</p>
                    )}
                    value={ name }
                    onChangeValue={ setName }
                    maxLength={100}
                />

                <TextField
                    type={'number'}
                    label={ 'Вместимость'}
                    value={ capacity }
                    onChangeValue={ setCapacity }
                />

                {/*<TextField*/}
                {/*    type={'number'}*/}
                {/*    label={ 'Позиция'}*/}
                {/*    value={ position }*/}
                {/*    onChangeValue={ setPosition }*/}
                {/*/>*/}

                <FilterSelect
                    placeholder={'Выберете группу'}
                    value={valueZone}
                    options={zones}
                    className={'mb-3'}
                    onChange={(val) => setValueZone(val)}
                />

                {/*<FilterSelect*/}
                {/*    placeholder={'Тип устройства'}*/}
                {/*    value={valueDevice}*/}
                {/*    options={typeDevice}*/}
                {/*    className={'mb-3'}*/}
                {/*    onChange={(val) => setValueDevice(val)}*/}
                {/*/>*/}

                <Button
                    loading={isLoadingSave}
                    text={ 'Сохранить' }
                    onClick={ onClickSave }
                    className={ 'btn btn-primary w-100 justify-content-center' }
                />
            </div>
        </DialogContainer>
    )
}

export default AddedNewDeviceDialog;
