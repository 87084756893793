import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TransformObjArray from "../../../mixins/transform-obj-array";
import Button from "../../form/Button";
import ArrowLeft from "../../../img/icon/ArrowLeft";
import TextField from "../../form/TextField";
import TextArea from "../../form/TextArea";
import { $createQuestion, $updateQuestion } from "../../../api/requests/faq";
import { ToggleBlock } from "../../form/ToggleBlock";

export const QuestionEdit: FC = () => {
    const isMobile = window.innerWidth <= 465;
    const navigate = useNavigate()
    const location = useLocation()

    const state: any = location.state;
    const isEditPage = state?.current === 'edit';

    const editedData = state?.data || {};

    const transformObjArray = new TransformObjArray();

    const [ formFieldList, setFormFieldList ] = useState([
        {
            key: 'title',
            placeholder: 'Заголовок',
            title: 'Вопрос',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textField: true,
        },
        {
            key: 'text',
            placeholder: 'Ответ',
            value: [],
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            textarea: true,
            paddingBottom: 30,
        },
        {
            key: 'isActive',
            value: true,
            setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
            toggleText: 'Статус',
            checkbox: true
        },
    ]);

    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => init(), []);

    function init() {
        const listUpdate = [ ...formFieldList ].map(i => {
            if (editedData && Object.keys(editedData).includes(i.key)) i.value = editedData[i.key];

            return i;
        });

        setFormFieldList(listUpdate)
    }

    function onChangeSetValue(value: any | boolean, key: string) {
        const listUpdate = [ ...formFieldList ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setFormFieldList(listUpdate)
    }

    function onClickEnter() {
        const requestData = {
            ...transformObjArray.getRequestObj(formFieldList),
        };

        setIsLoading(true);

        if (isEditPage) return onEditData(requestData);

        onCreateSave(requestData)
    }

    function onCreateSave(request: any) {
        $createQuestion(request).then(res => {
            setIsLoading(false);

            if (!res.id) return;
            onClickBack();
        })
    }

    function onEditData(request: any) {
        if (!editedData.id) return setIsLoading(false);

        $updateQuestion(editedData.id, request).then(res => {
            setIsLoading(false);

            if (!res.id) return;
            onClickBack();
        })
    }

    function onClickBack() {
        navigate("/questions");
    }

    return (
        <div className='questions-edit list-wrapper'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='questions-edit__header'>
                            <div className="edit__block__item__back">
                                <Button
                                    text={!isMobile && 'Назад' }
                                    leftIcon={ (
                                        <ArrowLeft
                                            color={ '#3582F6' }
                                        />
                                    ) }
                                    onClick={ onClickBack }
                                    className={ 'btn btn-icon' }
                                />

                                {isMobile && (
                                    <h2 className='questions-edit__title'>
                                        { isEditPage ? 'Редактирование' : 'Создание' } FAQ
                                    </h2>
                                )}
                            </div>

                            {!isMobile && (
                                <h2 className='questions-edit__title'>{ isEditPage ? 'Редактирование' : 'Создание' }</h2>
                            )}
                        </div>

                        <div className='row'>
                            <div className='col-12 col-sm-7'>
                                <form className="achievements__edit-content">
                                    { formFieldList.map((i, idx) => (
                                        <div key={ `form-field-achievement-item-${ idx }` }>
                                            { i.title && (
                                                <div className='achievements__edit-title'>
                                                    <p>{ i.title }</p>
                                                </div>
                                            ) }

                                            { i.textField && (
                                                <div
                                                    className={ `${ i.paddingBottom ? 'achievements__edit-padding30px' : '' }` }
                                                >
                                                    <TextField
                                                        className={ i.paddingBottom ? 'achievements__edit-comment' : '' }
                                                        placeholder={ i.placeholder }

                                                        value={ i.value }
                                                        onChangeValue={ (value) => i.setValue(value, i.key) }
                                                    />
                                                </div>
                                            ) }

                                            { i.textarea && (
                                                <TextArea
                                                    placeholder={ i.placeholder }
                                                    value={ i.value }
                                                    onChangeValue={ (value) => i.setValue(value, i.key) }
                                                />
                                            ) }

                                            { i.checkbox && (
                                                <div>
                                                    <ToggleBlock
                                                        desc={ i.toggleText }
                                                        isChecked={ i.value }
                                                        handleChange={ (_i) => i.setValue(_i, i.key) }
                                                    />
                                                </div>
                                            ) }
                                        </div>
                                    )) }

                                    <div className={ 'achievements__edit-button' }>
                                        <Button
                                            loading={ isLoading }
                                            onClick={ onClickEnter }
                                            className={ 'btn btn-primary mt-0 mt-md-3 justify-content-center w-100' }
                                            text={ isEditPage ? 'Сохранить' : 'Создать' }
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
