import React, { FC, useEffect, useState } from "react";
import Button from "../form/Button";
import cn from "classnames";
import Chart from "../form/Chart";
import TableContainer from "../form/TableContainer";
import { $getStatisticSessions } from "../../api/requests/reports";
import { DashboardGetStatisticSessionsByPeriod } from "../../api/requests/reports/interface";
import DateOperations from "../../mixins/date-operation";
import { FilterDate } from "./filter-date/FilterDate";


interface tableList {
    zones: string;
    devices: string;
    totalTime: React.ReactElement;
    averageTime: string;
    totalSessions: string | number;
    revenue: string;
}

export const OccupancyReport: FC = () => {
    const dateOperations = new DateOperations();

    const notFound: boolean = false

    const [ isZones, setIsZones ] = useState(false);
    const [ isTime, setIsTime ] = useState(false);

    const [ dateStart, setDateStart ] = useState<Date>(new Date());
    const [ dateEnd, setDateEnd ] = useState<Date>(new Date());

    const [ datasets, setDatasets ] = useState<number[]>([]);
    const [ timeLineChartData, setTimeLineChartData ] = useState<number[]>([]);

    const [ labels, setLabels ] = useState<any[]>([]);

    const [ subheaderContent, setSubheaderContent ] = useState([
        {
            key: 'totalTime',
            text: '0 ч',
            desc: 'Всего машиночасов'
        },
        // {
        //     key: 'totalTime',
        //     time: '0 ч',
        //     desc: 'Общее время занятости'
        // },
        {
            key: 'totalSessions',
            text: '0',
            desc: 'Общее количество сессий'
        },
        {
            key: 'averageTime',
            text: '0',
            desc: 'Среднее время сессии'
        },
        {
            key: 'revenue',
            text: '0 ₽',
            desc: 'Выручка'
        }
    ]);

    const [ defaultDeviceList, setDefaultDeviceList] = useState<tableList[]>([]);
    const [ zoneList, setZoneList] = useState<tableList[]>([]);

    useEffect(() => init(), [])

    useEffect(() => {
        if (!isZones) {
            setTableContent(defaultDeviceList)
        } else setTableContent(zoneList)
    }, [isZones])

    function init() {
        getStatistics();
    }

    const tableHeader = !isZones ?
        [
            {
                key: 'zones',
                title: 'Зона'
            },
            {
                key: 'devices',
                title: 'Зал'
            },
            {
                key: 'totalTime',
                title: 'Общая занятость'
            },
            {
                key: 'averageTime',
                title: 'Ср. время сессии'
            },
            {
                key: 'totalSessions',
                title: 'Всего сессий'
            },
            {
                key: 'revenue',
                title: 'Выручка'
            }
        ]
        :
        [
            {
                key: 'devices',
                title: 'Зал'
            },
            // {
            //     key: 'hosts',
            //     title: 'Кол-во хостов'
            // },
            {
                key: 'totalTime',
                title: 'Общая занятость'
            },
            {
                key: 'averageTime',
                title: 'Ср. время сессии'
            },
            {
                key: 'totalSessions',
                title: 'Всего сессий'
            },
            // {
            //     key: 'revenuePerHour',
            //     title: 'Средняя выручка с хоста'
            // },
            {
                key: 'revenue',
                title: 'Выручка'
            }
        ]

    const [ tableContent, setTableContent ] = useState<any[]>([
        {
            zones: 'DzWPWFESCpjg',
            devices: 'Общий зал',
            totalTime: (<div>0 мин. <span className='reports__occupancy-percent'>0%</span></div>),
            averageTime: '0 ч',
            totalSessions: '0 шт',
            revenue: '0 Р'
        }
    ])

    const getTextSubHeader = (key: string): string => {
        if ([ 'totalTime', 'averageTime' ].includes(key)) return 'ч.'
        if (key === 'revenue') return '₽'

        return ''
    }

    const getTimeCode = (minutes: number): any =>  {
        let hours = Math.floor(minutes / 60);
        let remainingMinutes = Math.floor(minutes % 60);

        return { hours, min: remainingMinutes }
    }

    const setUpdateList = (list: any, is: boolean): any =>  {
        let updateZoneList = [...list]

        if (is) {
            updateZoneList = [ ...updateZoneList.reduce((acc: any[], obj) => {
                const existingObj: any = acc.find(item => item.zoneName === obj.zoneName);

                if (existingObj) {
                    existingObj.averageTime = (existingObj.averageTime > obj.averageTime) ? existingObj.averageTime : obj.averageTime;
                    existingObj.totalTime += obj.totalTime;
                    existingObj.revenue += obj.revenue;
                } else {
                    acc.push({ ...obj });
                }

                return acc;
            }, []) ]
        }

        return [ ...updateZoneList.map(i => {
            return {
                zones: i.name,
                devices: i.zoneName,
                totalTime: (<div>{ getTimeCode(i.totalTime).hours } ч. { getTimeCode(i.totalTime).min ? `${getTimeCode(i.totalTime).min} мин.` : '' }</div>), //<span className='reports__occupancy-percent'>0%</span>
                averageTime: `${ getTimeCode(i.averageTime).hours } ч. ${getTimeCode(i.averageTime).min ? `${getTimeCode(i.averageTime).min} мин.` : ''}`,
                totalSessions: Math.ceil(i.averageRevenue),
                revenue: i.revenue
            }
        }) ]
    }

    function getStatistics() {
        const requestData: DashboardGetStatisticSessionsByPeriod = {
            fromDate: dateOperations.getISODateByObj(dateStart) + 'T00:00:00',
            toDate: dateOperations.getISODateByObj(dateEnd) + 'T00:00:00',
            userId: ''
        }

        $getStatisticSessions(requestData).then(res => {
            if (!res.zones) return;

            changeDeviceList(res.zones)
            changeSubHeaderList(res)
        })
    }

    function changeDeviceList(zones: any[]) {
        const deviceList: any[] = [];

        zones.forEach(i => deviceList.push(...i.devices.map((_i: any) => ({
            ..._i,
            zoneName: i.name
        }))));

        setZoneList(setUpdateList(deviceList, true));
        setDefaultDeviceList(setUpdateList(deviceList, false));

        setTableContent(setUpdateList(deviceList, isZones))

        setLabels([ ...deviceList.map(i => {
            return {
                title: i.name,
                subTitle: i.zoneName
            }
        }) ])

        setDatasets([ ...deviceList.map(i => i.revenue) ])
        setTimeLineChartData([ ...deviceList.map(i => (parseInt(String((i.totalTime / 60) * 100))) / 100) ])
    }

    function changeSubHeaderList(item: any) {
        const deviceList: any[] = [ ...subheaderContent ].map(i => {
            if (Object.keys(item).includes(i.key)) {
                if (['totalTime', 'averageTime'].includes(i.key)) {
                    const int = (parseInt(String((item[i.key] / 60) * 100))) / 100

                    return {
                        ...i,
                        text: int
                    }
                }

                return {
                    ...i,
                    text: item[i.key]
                }
            }

            return i
        });

        setSubheaderContent(deviceList)
    }

    return (
        <div className='reports__occupancy'>
                <FilterDate
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    setDateStart={setDateStart}
                    setDateEnd={setDateEnd}
                    init={init}
                />

                {/*<Button */ }
                {/*    className='btn btn-primary' */ }
                {/*    leftIcon={<DownloadSecond/>} */ }
                {/*    text={'Экспорт в .CSV'}*/ }
                {/*/>*/ }

            <div className='reports__occupancy-content'>
                <div className='reports__occupancy-header'>
                    <h1 className='reports__occupancy-title'>Занятость оборудования / зон за выбранный период</h1>

                    <div className='reports__occupancy-btns'>
                        <div className='reports__occupancy-btn'>
                            <Button
                                onClick={ () => (setIsZones(false)) }
                                className={ cn(!isZones
                                    ? 'btn btn-primary'
                                    : 'reports__occupancy-nonActive') }
                                text={ 'Оборудование' }
                            />

                            <Button
                                onClick={ () => setIsZones(true) }
                                className={ cn(isZones
                                    ? 'btn btn-primary'
                                    : 'reports__occupancy-nonActive') }
                                text={ 'Зоны' }
                            />
                        </div>

                        <div className='reports__occupancy-btn'>
                            <Button
                                onClick={ () => setIsTime(false) }
                                className={ cn(!isTime
                                    ? 'btn btn-primary'
                                    : 'reports__occupancy-nonActive') }
                                text={ 'Выручка' }
                            />

                            <Button
                                onClick={ () => setIsTime(true) }
                                className={ cn(isTime
                                    ? 'btn btn-primary'
                                    : 'reports__occupancy-nonActive') }
                                text={ 'Время' }
                            />
                        </div>
                    </div>
                </div>

                <div className='reports__occupancy-subheader'>
                    { subheaderContent.map((item: any, idx) => (
                        <div
                            className='reports__occupancy-block'
                            key={ `reports-occupancy-item-${ idx }` }
                        >
                            { notFound
                                ? (<p className='reports__occupancy-line'></p>)
                                : <p className='reports__occupancy-22Px'>
                                    { item.text } { getTextSubHeader(item.key) }
                                </p>
                            }

                            <p className='reports__occupancy-16Px'>
                                { item.desc }
                            </p>
                        </div>
                    )) }
                </div>

                { notFound ? (
                        <div className='reports__occupancy-notFound'>
                            Сначала выберите период
                            <br/>
                            за который хотите посмотреть отчёт
                        </div>
                    ) :
                    (
                        <div className='reports__occupancy-chart'>
                            <h1 className='reports__occupancy-title'>{isTime ? 'Часы' : 'Выручка'} за выбраный период</h1>

                            {isTime ? (
                                <Chart
                                    type={'bar'}
                                    tension={ 0.4 }
                                    labels={ labels }
                                    datasets={ timeLineChartData }
                                />
                            ) : (

                                <Chart
                                    tension={ 0.4 }
                                    labels={ labels }
                                    datasets={ datasets }
                                />
                            )}
                        </div>
                    )
                }
            </div>

            <div className='reports__occupancy-table'>
                <TableContainer header={ tableHeader } content={ tableContent }/>
            </div>
        </div>
    )
}
