export default function List({color = "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M6.66634 4.99986H16.6663M3.33301 5.00819L3.34134 4.99902M3.33301 10.0082L3.34134 9.99902M3.33301 15.0082L3.34134 14.999M6.66634 9.99986H16.6663M6.66634 14.9999H16.6663"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}


