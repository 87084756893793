import {FC, ReactNode, useEffect, useRef, useState} from "react";
import ScrollButton from "./ScrollButton";

interface ScrollContainerProps {
    children: ReactNode;
}

const ScrollContainer: FC<ScrollContainerProps> = ({ children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const slider = useRef(null);

    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [isLeftBtn, setIsLeftBtn] = useState(false);
    const [isRightBtn, setIsRightBtn] = useState(true);

    let isDragging = false;
    let dragStartX = 0;

    const handleScrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: 0,
                left: 100,
                behavior: "smooth",
            });
            isShowBtn(containerRef.current.scrollLeft)
        }
    };

    const handleScrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: 0,
                left: -100,
                behavior: "smooth",
            });
            isShowBtn(containerRef.current.scrollLeft)
        }
    };

    const handleDragStart = (e: MouseEvent | TouchEvent) => {
        isDragging = true;

        dragStartX = (e instanceof MouseEvent ? (e.clientX - 425 - 30) : e.touches[0].clientX);

        if (slider.current) {
            (slider.current as any).style.cursor = 'grabbing';
        }
    };

    const handleDragMove = (e: MouseEvent | TouchEvent) => {
        if (!isDragging) return;

        const clientX = e instanceof MouseEvent ? (e.clientX - 425 - 30) : e.touches[0].clientX;
        const deltaX = (dragStartX - clientX === 0) ? clientX : dragStartX;

        if (containerRef.current) {
            const scrollSpeed = 0.8;

            containerRef.current.scrollLeft = (deltaX * scrollSpeed);

            dragStartX = (clientX);
            isShowBtn(containerRef.current.scrollLeft);
        }
    };

    const handleDragEnd = () => {
        isDragging = false;

        if (slider.current) {
            (slider.current as any).style.cursor = 'grab';
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (slider && slider.current) {
            const scrollButton: any = slider.current;

            if (scrollButton) {
                scrollButton.addEventListener("mousedown", handleDragStart);
                scrollButton.addEventListener("touchstart", handleDragStart, { passive: false });
                document.addEventListener("mousemove", handleDragMove);
                document.addEventListener("touchmove", handleDragMove, { passive: false });
                document.addEventListener("mouseup", handleDragEnd);
                document.addEventListener("touchend", handleDragEnd);
            }

            return () => {
                if (scrollButton) {
                    scrollButton.removeEventListener("mousedown", handleDragStart);
                    scrollButton.removeEventListener("touchstart", handleDragStart);
                }
                document.removeEventListener("mousemove", handleDragMove);
                document.removeEventListener("touchmove", handleDragMove);
                document.removeEventListener("mouseup", handleDragEnd);
                document.removeEventListener("touchend", handleDragEnd);
            };
        }
    }, []);

    function isShowBtn(container: any) {
        const isMobile = window.innerWidth <= 768;

        if (container >= 100) setIsLeftBtn(true)
        else setIsLeftBtn(false)


        if (container >= (isMobile ? 1200 : 600)) setIsRightBtn(false)
        else setIsRightBtn(true)
    }

    return (
        <div style={{ overflowX: "hidden", overflowY: 'clip', height: '100%' }} ref={containerRef}>
            {children}

            <div className="calendar__scroll-btn" ref={slider}>
                {isLeftBtn && <ScrollButton onClick={handleScrollLeft} direction="left" />}

                {isRightBtn && <ScrollButton onClick={handleScrollRight} direction="right" />}
            </div>
        </div>
    );
};

export default ScrollContainer;
