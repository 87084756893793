export default class ImageToBase64 {

    hasBase64 = (text: string): boolean => {
        const base64Regex = /data:image\/[a-zA-Z]*;base64,/;

        return base64Regex.test(text);
    };

    hasBase64Svg = (text: string): boolean => {
        const base64RegexSvg = /^data:image\/svg\+xml;base64,/;

        return base64RegexSvg.test(text);
    };

    removeBase64 = (text: string): string => {
        return text.replace(/data:image\/[a-zA-Z]*;base64,/, '');
    };

    removeBase64Svg = (text: string): string => {
        return text.replace(/data:image\/svg\+xml;base64,/, '');
    };

    hasLink = (text: string): boolean => {
        const linkRegex = /https?:\/\/\S+/;
        return linkRegex.test(text);
    };

    getImageCheckValid = (image: string): string | undefined | null => {
        if (this.hasBase64(image)) return this.removeBase64(image);

        if (this.hasBase64Svg(image)) return this.removeBase64Svg(image);

        if (this.hasLink(image)) return undefined;
        if (!image) return null;

        return image
    }
}
