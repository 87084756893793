import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import PopupDialog from "../../components/dialog/PopupDialog";
import cn from "classnames";
import Warning from "../../img/icon/Warning";
import PlusBorder from "../../img/icon/PlusBorder";
import ElectroOn from "../../img/icon/ElectroOn";
import ElectroOff from "../../img/icon/ElectroOff";
import DialogContainer from "../../components/dialog/DialogContainer";
import { DialogComputersAdd } from "../../components/dialog/DialogComputersAdd";
import { ListData } from "../../components/calendar";
import { DeviceModel } from "../../api/requests/device/interface";
import { $getMapDeviceList, $installPositionDevice } from "../../api/requests/device";
import RoleUsers from "../../mixins/role";

const MapVrCenterPage: FC = () => {
    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('mapCenter', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('mapCenter', 'update');
    const isDeleteByRole = roleUsers.getModelRoleByType('mapCenter', 'delete');

    const [eventPopup, setEventPopup] = useState<MouseEvent | null>(null);
    const [selectPositionData, setSelectPositionData] = useState<any>(null);

    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [isEditBlock, setIsEditBlock] = useState<boolean>(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

    const [deviceList, setDeviceList] = useState<DeviceModel[]>([]);

    const defaultList = [
        {
            id: 1,
            name: '',
            position: 1,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 2,
            name: '',
            position: 1,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 3,
            name: '',
            position: 2,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 4,
            name: '',
            position: 2,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
    ];
    const [list, setList] = useState<any[]>([])

    const handleClick = (event: React.MouseEvent) => {
        const target = event.target as HTMLElement;

        if (!target.classList.contains('btn')) {
            setEventPopup(null);
        }
    };

    const renderItems = () => {
        const rows: JSX.Element[] = [];

        for (let i = 0; i < list.length; i += 2) {
            const rowItems: JSX.Element[] = [];

            for (let j = 0; j < 2; j += 2) {
                const item1 = list[i + j];
                const item2 = list[i + j + 1];

                const isNameAll = item1.name || item2.name

                if (item1 || item2) {
                    rowItems.push(
                        <div className="map-center__item map-center__console-item" key={j}>
                            <div
                                className={'map-center__console-block'}
                                onClick={(event) => {
                                    if (!isCreateByRole || !isUpdateByRole || !isDeleteByRole) return;

                                    onClickOpenPopup(event, item1, item2);
                                }}
                            >
                                {item1 && (
                                    <div
                                        className={cn(item1.name && !item1.warning ? "map-center__block map-center__console__selected" : 'map-center__block')}
                                    >
                                        <Button
                                            className={`btn btn-icon ${item1.name ? 'is-text' : ''}`}
                                            text={item1.warning ? <Warning color={'#FF5858'} size={24}/>
                                                : (isNameAll ? item1.name : <PlusBorder size={22}/> )}
                                            onClick={() => {}}
                                        />
                                    </div>
                                )}
                                {/*{(item2 && !item1.name) && (*/}
                                {/*    <div*/}
                                {/*        className={cn(item1.name && !item2.warning ? "map-center__block map-center__console__selected" : 'map-center__block')}*/}
                                {/*    >*/}
                                {/*        <Button*/}
                                {/*            className={`btn btn-icon ${item2.name ? 'is-text' : ''}`}*/}
                                {/*            text={item2.warning*/}
                                {/*                ? <Warning color={'#FF5858'} size={24}/>*/}
                                {/*                : (isNameAll*/}
                                {/*                    ? item2.name*/}
                                {/*                    : <PlusBorder size={22}/>*/}
                                {/*                )}*/}
                                {/*            onClick={() => {}}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*)}*/}
                            </div>
                        </div>
                    );
                }
            }

            rows.push(
                <div className="map-center__items map-center__border map-center__console-border" key={i}>
                    {rowItems}
                </div>
            );
        }

        return rows;
    };

    useEffect(() => init(), []);

    function init() {
        $getMapDeviceList({ type: 2 }).then(res => {
            if (!res.length) return setList(defaultList);

            setDeviceList(res);

            setList(() => {
                return [ ...defaultList ].map(i => {
                    const item = res.find(_i => _i.position === i.position);

                    if (item) return {
                        ...i,
                        ...item,
                        turnOn: item.isOnline,
                        turnOff: item.isOnline
                    }

                    return {
                        ...i
                    }
                });
            })
        })
    }

    function onClickOpenPopup(event: any, item: any, item2: any) {
        if (!item.name.length) {
            setEventPopup(null);
            return openDialog(true, item)
        }

        setSelectPositionData(item.name ? item : (item2.name ? item2 : item))

        setEventPopup(event);


    }

    function openDialog(is?: boolean, item?: ListData, isEdit?: boolean) {

        setIsShowDialog(is || false)

        setSelectPositionData(!is ? null : item);
    }

    function onClickEditBlock() {
        setIsEditBlock(true);
        setIsShowDialog(true);
        setEventPopup(null);
    }

    function onClickDeleteBlock() {
        const requestData = {
            deviceId: selectPositionData.id || '',
            position: 0,
            type: 0
        }

        $installPositionDevice(requestData).then(res => {
            if (!res.id) return;

            resetData();
        })
    }

    function onSaveNewDevicePosition(item: any) {
        $installPositionDevice(item).then(res => {
            setIsLoadingRequest(false);

            if (!res.id) return;

            resetData();
        })
    }

    function resetData() {
        setEventPopup(null);
        setIsShowDialog(false);
        setIsEditBlock(false);
        setDeviceList([]);

        setIsLoadingRequest(false);

        init();
    }

    return (
        <>
            <div className="map-center dashboard" onClick={handleClick}>
                <div className="map-center-section">
                    <div className="container-fluid">
                        <div className="map-center-header dashboard__header">
                            <div className="dashboard__header__subject">
                                <h2>Карта VR</h2>
                            </div>
                        </div>

                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="map-center__content">
                                {renderItems()}
                            </div>
                        </div>
                    </div>

                    {eventPopup && (
                        <PopupDialog
                            eventProps={eventPopup}
                            onClose={() => {}}
                        >
                            <div className="map-center__popup__block">
                                <div>
                                    <div className={'map-center__popup__item'}>
                                        <Button
                                            text={'Редактировать'}
                                            onClick={onClickEditBlock}
                                            className={'btn btn-primary map-center__popup__btn'}
                                        />
                                    </div>

                                    <div className={'map-center__popup__item'}>
                                        <Button
                                            text={'Удалить'}
                                            onClick={onClickDeleteBlock}
                                            className={'btn btn-danger map-center__popup__btn'}
                                        />
                                    </div>
                                </div>

                                <div className='map-center__popup__header'>
                                    {selectPositionData.warning && (
                                        <>
                                            <Warning color={'#FF5858'} size={20}/>
                                            <p>Неполадки</p>
                                        </>
                                    )}

                                    {selectPositionData.turnOn && (
                                        <>
                                            <ElectroOn color={'#70D584'} size={20}/>
                                            <p>Включен</p>
                                        </>
                                    )}

                                    {selectPositionData.turnOff && (
                                        <>
                                            <ElectroOff
                                                color={'#FF5858'}
                                                width={20}
                                                height={20}
                                            />

                                            <p>Выключен</p>
                                        </>
                                    )}
                                </div>

                                <div className='map-center__popup__content'>
                                    {selectPositionData.zone && (
                                        <p> {selectPositionData.zone},</p>
                                    )}

                                    <p>{selectPositionData.name}</p>
                                </div>
                            </div>
                        </PopupDialog>
                    )}

                        <DialogContainer
                            isOpen={isShowDialog}
                            closeModal={openDialog}
                            label={'Добавить устройство'}
                            widthProps={346}
                        >
                            <DialogComputersAdd
                                isType={2}
                                deviceList={deviceList}
                                item={selectPositionData}
                                isEdit={isEditBlock}
                                onSave={onSaveNewDevicePosition}
                                isLoadingRequest={isLoadingRequest}
                                setIsLoadingRequest={setIsLoadingRequest}
                            />
                        </DialogContainer>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MapVrCenterPage;

