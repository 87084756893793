import { FC } from 'react';

interface BookingPopupProps {
    item: any
}

const BookingPopup: FC<BookingPopupProps> = (
    {
        item
    }
) => {

    return (
        <div className="booking__popup">
            <div className="booking__popup__header">
                <div className="title">
                    <span></span>

                    <p>{ item.title }</p>
                </div>
            </div>

            <div className="booking__popup__content">
                <div className="booking__popup__content-block">
                    <p className={'title'}>Начало</p>
                    <p>{ item.dateStart }</p>
                </div>

                <div className="booking__popup__content-block">
                    <p className={'title'}>Конец</p>
                    <p>{ item.dateEnd }</p>
                </div>
            </div>

            <div className="line"></div>

            <div className="booking__popup__content">
                {item.content && item.content.map((i: any, idx: number) => {
                    if (!i.text) return null;

                    return (
                        <div
                            className="booking__popup__content-block"
                            key={`booking-popup-content-${idx}`}
                        >
                            <p className={'title'}>{ i.title }</p>
                            <p>{ i.text }</p>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default BookingPopup;
