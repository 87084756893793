import api from '../../../api';
import { IApiHandlerParams, } from "../../interface";
import {
    ClientListGet,
    UpdateClientBalanceData,
    UpdateClientData,
    UserGetList,
    UserSearchRequestData,
    UsersGet
} from "./interface";

// начало users

export const $clientList = (params?: UserGetList, handler?: IApiHandlerParams): Promise<ClientListGet> => api
    .get('/users', {
        request: { params },
        handler
    });

export const $searchClient = (data: UserSearchRequestData, handler?: IApiHandlerParams): Promise<UsersGet> => api
    .post('/users/search/single', data, {
        handler
    });

export const $updateClient = (id: string, data: UpdateClientData, handler?: IApiHandlerParams): Promise<UsersGet> => api
    .put('/users/single/' + id, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $cancelDeleteClient = (id: string, handler?: IApiHandlerParams): Promise<UsersGet> => api
    .put('/users/deletion/cancellation/' + id, undefined, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $updateBalanceClient = (id: string, data: UpdateClientBalanceData, handler?: IApiHandlerParams): Promise<UsersGet> => api
    .post('/users/balance/'+ id, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })
