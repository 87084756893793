import { FC, useEffect, useState } from "react";
import Button from "../form/Button";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import Cookies from "js-cookie";
import { GetCurrentUserData, resetUser } from "../../redux/slices/userSlice";
import { HeaderAside } from "./HeaderAside";
import RoleUsers from "../../mixins/role";
import DropdownCenter from "./DropdownCenter";
import { Notification } from "./Notifications";
import { useSelector } from "react-redux";

const roleUsers = new RoleUsers();

interface HeaderProps {
}

const Header: FC<HeaderProps> = ({}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [ isAsideOpen, setIsAsideOpen ] = useState(false);
    const userData = useSelector(GetCurrentUserData);

    const [ nameUser, setNameUser ] = useState<string>('Имя Фамилия');
    const [ photoUser, setPhotoUser ] = useState<string>('/img/logo/logo-2.png');
    const [ roleUser, setRoleUser ] = useState<string>('Менеджер');
    const [ isNotificationOpen, setIsNotificationOpen ] = useState(false)

    const logout = () => {
        if (!confirm('Вы уверены что хотите выйти?')) return;

        Cookies.remove('token');
        dispatch(resetUser());
        navigate('/');
    }

    useEffect(() => {
        if (userData) {
            setNameUser(`${ userData.surname || '' } ${ userData.name || '' } ${ userData.lastName || '' }`);
            userData.image && setPhotoUser(userData.image);
            userData.role && setRoleUser(roleUsers.getNameRoleUser(userData.role));
        }

    }, [ userData ])

    function onClickMap(notification: boolean) {
        if (notification) {
            setIsNotificationOpen(!isNotificationOpen)
        }
    }

    return (
        <div className="header">
            <div className="header__left">
                <div className={ 'header__logo' }>
                    <img src="/img/logo/logo.svg" alt=""/>
                </div>

                <div className={ 'header__map' }>
                    <DropdownCenter/>
                </div>
            </div>

            <div className="header__right">
                <div className='header__profile'>
                    <div onClick={ () => (setIsAsideOpen(!isAsideOpen)) } className={ 'header__profile' }>
                        <div className="header__profile-logo">
                            <img src={ photoUser } alt=""/>
                        </div>

                        <p className="header__profile-name">{ nameUser }</p>

                        <div className="header__profile-type">
                            <p>{ roleUser }</p>
                        </div>
                    </div>

                    <HeaderAside
                        isOpen={ isAsideOpen }
                        onClose={ () => setIsAsideOpen(false) }
                        username={ 'admin1' }
                        id={ 998911231 }
                        job={ 'manager' }
                        time={ '17.09.2023' }
                    />

                    <div className={ 'notification-open' }>
                        <Notification
                            onClose={ () => setIsNotificationOpen(false) }
                            isOpen={ isNotificationOpen }
                        />
                    </div>

                </div>

                <div className="header__separator"></div>

                <div className={ 'header__other' }>
                    {/*<Button*/ }
                    {/*    className={ 'btn btn-icon' }*/ }
                    {/*    text={ (*/ }
                    {/*        <>*/ }
                    {/*            <img src="/img/icon/search.svg" alt="search"/>*/ }
                    {/*        </>*/ }
                    {/*    ) }*/ }
                    {/*    onClick={() => onClickMap(false) }*/ }
                    {/*/>*/ }

                    {/*<Button*/ }
                    {/*    className={ 'btn btn-icon' }*/ }
                    {/*    text={ (*/ }
                    {/*        <>*/ }
                    {/*            <img  src="/img/icon/notification.svg" alt="notification"/>*/ }
                    {/*        </>*/ }
                    {/*    ) }*/ }
                    {/*    onClick={ () => onClickMap(true) }*/ }
                    {/*/>*/ }

                    <Button
                        className={ 'btn btn-icon' }
                        text={ (
                            <>
                                <img src="/img/icon/exit.svg" alt="exit"/>
                            </>
                        ) }
                        onClick={ logout }
                    />
                </div>
            </div>
        </div>
    );
}

export default Header;
