import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import TextField from "../../components/form/TextField";
import Info from "../../img/icon/Info";
import FilterSelect from "../../components/FilterSelect";
import { ToggleBlock } from "../../components/form/ToggleBlock";
import Unavailable from "../../img/icon/Unavailable";
import { selectOption } from "../../types/users";
import TransformObjArray from "../../mixins/transform-obj-array";
import { $appCreate, $deleteApp, $updateApp } from "../../api/requests/app";
import FileField from "../../components/form/FileField";
import { HOST_URL } from "../../api/config";
import Cookies from "js-cookie";

const AddGamePage: FC = () => {
    const isMobile = window.innerWidth < 465
    const navigate = useNavigate();
    const state: any = useLocation().state;

    const editedData: any = state?.data;
    const isEditPage = state?.current === 'edit'

    const [ image, setImage ] = useState<any>(null);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const transformObjArray = new TransformObjArray();

    const [ listGroupAccounts, setListGroupAccounts ] = useState<selectOption[]>([
        {
            label: '',
            value: ''
        },
    ]);

    const [ list, setList ] = useState<any[]>([
        {
            key: 'name',
            title: 'Название игры',
            placeholder: 'Введите название игры',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'path',
            title: 'Путь к игре',
            placeholder: 'Введите путь до игры',
            value: '',
            info: {
                title: '',
                text: ''
            },
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'path2',
            placeholder: 'Введите путь до игры',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'path3',
            placeholder: 'Введите путь до игры',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'commandLineArgument',
            title: 'Аргумент командной строки',
            placeholder: 'Введите аргумент командной строки',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'workDirectory',
            title: 'Рабочая директория',
            placeholder: 'Укажите рабочую директорию',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        // {
        //     key: 'groupAccounts',
        //     title: 'Группа аккаунтов',
        //     placeholder: 'Нет доступных групп',
        //     value: '',
        //     setValue: (value: string, key: string) => onChangeSetValue(value, key),
        //     select: true,
        //     list: listGroupAccounts,
        // },
        {
            key: 'startupScript',
            title: 'Скрипт запуска',
            placeholder: 'Введите скрипт запуска',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'startupPassword',
            title: 'Пароль запуска',
            placeholder: 'Введите пароль',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'isAgeLimitActive',
            title: 'Возрастное ограничение',
            value: false,
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            checkbox: true,
            checkboxList: [ 0, 6, 12, 16, 18, ],
            bottomText: 'Игры для клиентов с возрастом ниже указанного будут ограничены для запуска.',
            imgLeft: <Unavailable color={ '#3582F6' }/>,
            toggleText: 'Ограничить возрастной контент',
        },
        {
            key: 'openAsAdmin',
            title: 'Дополнительно',
            value: false,
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            checkbox: true,
            toggleText: 'Запускать от имени администратора',
        },
    ]);

    const [ selectAllowedYear, setSelectAllowedYear ] = useState<number>(NaN);

    const btnListYears = [ 0, 6, 12, 16, 18, ];

    const onUploadImage = async () => {
        // FormData
        const accessToken = Cookies.get('token');
        const data = new FormData();

        data.append('file', image);

        const requestOptions: RequestInit = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${ accessToken }`,
                Accept: 'application/json',
            },
            body: data
        };

        try {
            const response = await fetch(`${ HOST_URL }/app/image/upload`, requestOptions);

            const json = await response.json();
            const { error } = json || {};

            if (!error) {
                return json || response.text();
            }

            if (error) {
                throw new Error(error);
            }
        } catch (err) {
            console.error('>>> API Error', err);
            return err;
        }
    };

    useEffect(() => init(), []);

    function init() {
        const listUpdate = [ ...list ].map(i => {
            if (editedData && Object.keys(editedData).includes(i.key)) i.value = editedData[i.key];

            return i;
        });

        setList(listUpdate);

        if (isEditPage && editedData) {
            setImage(editedData.image);
            setSelectAllowedYear(editedData.ageLimit)
        }
    }

    function onClickYearsBtn(count: number) {
        setSelectAllowedYear(count)
    }

    function onClickBack() {
        navigate("/programsGame");
    }

    function onChangeSetValue(value: string, key: string) {
        const listUpdate = [ ...list ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setList(listUpdate)
    }

    function onClickSave() {
        setIsLoading(true);

        const requestObj: any = {
            ...transformObjArray.filterEmptyStringsAndFalsyValues(transformObjArray.getRequestObj(list)),
            ageLimit: !isNaN(selectAllowedYear) ? selectAllowedYear : undefined,
        };

        if (image instanceof File) {
            onUploadImage().then((res: any) => {
                if (!res.result) return setIsLoading(false);

                onClickSaveSecond({
                    ...requestObj,
                    image: res.result
                })
            })

            return;
        }

        onClickSaveSecond({
            ...requestObj,
            image
        })
    }

    function onClickSaveSecond(requestObj: any) {
        if (isEditPage) return onClickSaveEdit(requestObj);

        $appCreate(requestObj).then(res => {
            setIsLoading(false);
            if (!res.id) return;

            onClickBack();
        })
    }

    function onClickSaveEdit(obj: any) {
        $updateApp(editedData.id, obj).then(res => {
            setIsLoading(false);
            if (!res.id) return;

            onClickBack();
        })
    }

    function onClickDelete() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteApp(editedData.id).then(res => {
            if (!res.id) return;

            onClickBack();
        })
    }

    return (
        <div className="game__list__header">
            <div className="game__list__header__back">
                <Button
                    className={ 'btn' }
                    leftIcon={ (
                        <ArrowLeft color={ '#3582F6' }/>
                    ) }
                    text={!isMobile && 'Назад' }
                    onClick={ onClickBack }
                />

                {isMobile && (
                    <h3>{ isEditPage ? 'Редактирование' : 'Создание' } игры</h3>
                )}
            </div>
            <div className="game__list__header-main">
                <div className="row column">
                    <div className="col-12 col-xl-5 col-sm-6 game__list__header__show">
                        <form className="game__list__header__form">
                            <div className="game__list__header__tariffs">
                                {!isMobile && (
                                    <h3>{ isEditPage ? 'Редактирование' : 'Создание' } игры</h3>
                                )}
                            </div>
                            { list.map((item, idx) => {
                                return (
                                    <div className="game__list__header__block"
                                         key={ `game-list__header-block${ idx }` }
                                    >
                                        <div className="game__list__header__block__inner">
                                            { item.title && (
                                                <div className={ 'game__list__header__block__inner__title' }>
                                                    <h5>{ item.title }</h5>

                                                {/*{ item.info && (*/}
                                                {/*    <>*/}
                                                {/*        <Info color={ '#3582F6' }/>*/}

                                                {/*        <div></div>*/}
                                                {/*    </>*/}
                                                {/*) }*/}
                                            </div>
                                        ) }

                                        { item.select && (
                                            <FilterSelect
                                                placeholder={ item.placeholder }
                                                value={ item.value }
                                                options={ item.list }
                                                onChange={ (i) => item.setValue(i, item.key) }
                                            />
                                        ) }

                                        { item.checkbox && (
                                            <ToggleBlock
                                                desc={ item.toggleText }
                                                isChecked={ item.value || false }
                                                handleChange={ (i) => item.setValue(i, item.key) }
                                                imgLeft={ item.imgLeft }
                                            />
                                        ) }

                                        { item.bottomText && (
                                            <div className={ 'bottom-block' }>{ item.bottomText }</div>
                                        ) }

                                        { item.checkboxList && (
                                            <div className={ (item.value && (item.key === 'isAgeLimitActive'))
                                                ? 'active game__list__header__block__inner-btn mt-2'
                                                : 'isNotActive d-none' }>
                                                { btnListYears.map((name, index) => (
                                                    <Button
                                                        key={ `btn-item-years-${ index }` }
                                                        className={ `btn btn-link ${ selectAllowedYear === name ? 'active' : '' }` }
                                                        text={ `${ name }+` }
                                                        onClick={ () => onClickYearsBtn(name) }
                                                    />
                                                )) }
                                            </div>
                                        ) }

                                        { (!item.select && !item.checkbox) && (
                                            <TextField
                                                placeholder={ item.placeholder }
                                                value={ item.value || '' }
                                                onChangeValue={ (value) => item.setValue(value, item.key) }
                                            />
                                        ) }
                                    </div>
                                </div>
                            )
                        }) }

                        <div className="game__list__header__button">
                            { isEditPage && (
                                <Button
                                    loading={ isLoading }
                                    className={ 'btn btn-danger' }
                                    text={ 'Удалить' }
                                    onClick={ onClickDelete }
                                    disabled={isLoading}
                                />
                            ) }

                            <Button
                                loading={ isLoading }
                                className={ 'btn btn-primary' } //btn-secondary
                                text={ isEditPage ? 'Обновить' : 'Сохранить' }
                                onClick={ onClickSave }
                                disabled={isLoading}
                            />
                        </div>
                    </form>
                </div>

                <div className="col-12 col-sm-4 col-md-4">
                    <div className="edit__news__right-content game-style">
                        <div className="edit__news__header__item__cover">
                            <h3>Обложка игры</h3>

                            {/*<Info*/}
                            {/*    color={ '#3582F6' }*/}
                            {/*/>*/}
                        </div>

                            <FileField
                                label={ '512х512' }
                                upload={ image }
                                setUpload={ setImage }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddGamePage;
