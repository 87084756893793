import { FC, useEffect, useState } from "react";
import TextField from "../form/TextField";
import DialogContainer from "../dialog/DialogContainer";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import { ToggleBlock } from "../form/ToggleBlock";
import { DashboardCreatePromotionCategory, PromotionCategoryModel } from "../../api/requests/promotion/interface";
import {
    $createCategoryPromotion,
    $deleteCategoryPromotion,
    $updateCategoryPromotion
} from "../../api/requests/promotion";
import { $createCategoryNews, $deleteCategoryNews, $updateCategoryNews } from "../../api/requests/news";
import { NewsCategoryModel } from "../../api/requests/news/interface";

interface CreateGroupDialogProps {
    typeCategory: string
    selectItem: any
    isShow: boolean
    setIsShow: () => void
}

const CreateGroupDialog: FC<CreateGroupDialogProps > = (
    {
        selectItem,
        isShow,
        setIsShow,
        typeCategory
    }
) => {
    const [title, setTitle] = useState('');
    const [isActive, setIsChecked] = useState<boolean>(false);

    useEffect(() => init(), [selectItem]);

    function init() {
        if (selectItem) {
            setTitle(selectItem.title);
            setIsChecked(selectItem.isActive);
        }
    }

    function onClickSave() {
        const requestData = {
            centerId: '',
            title,
            isActive
        }

        if (selectItem) return onEdit(requestData)

        onSave(requestData)
    }

    function onSave(data: DashboardCreatePromotionCategory) {
        const request: any = typeCategory === 'news' ? $createCategoryNews : $createCategoryPromotion;

        request(data).then((res: NewsCategoryModel | PromotionCategoryModel) => {
            if (!res.id) return;

            resetData();
        })
    }

    function onEdit(data: DashboardCreatePromotionCategory) {
        const request: any = typeCategory === 'news' ? $updateCategoryNews : $updateCategoryPromotion;

        request(selectItem.id, data).then((res: NewsCategoryModel | PromotionCategoryModel) => {
            if (!res.id) return;

            resetData();
        })
    }

    function resetData() {
        setTitle('');
        setIsChecked(false);

        setIsShow();
    }

    function onClickDelete() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        const request: any = typeCategory === 'news' ? $deleteCategoryNews : $deleteCategoryPromotion;

        request(selectItem.id).then((res: NewsCategoryModel | PromotionCategoryModel) => {
            if (!res.id) return;

            resetData();
        })
    }

    return (
        <DialogContainer
            isOpen={isShow}
            closeModal={setIsShow}
            label={`${selectItem ? 'Изменить' : 'Добавить '} категорию `}
            closeIcon={<Close/>}
        >
            <div className={'create__group'}>
                <TextField
                    label={ 'Название' }
                    value={ title }
                    onChangeValue={ setTitle }
                />

                <ToggleBlock
                    desc={'Статус'}
                    isChecked={isActive}
                    handleChange={setIsChecked}
                />

                {selectItem === null
                    ? (
                        <Button
                            text={ 'Сохранить'}
                            onClick={ onClickSave }
                            className={ 'btn btn-primary w-100 justify-content-center' }
                        />
                    )
                    : (
                        <div className={'d-flex'}>
                            <Button
                                text={'Удалить'}
                                className={ 'btn btn-danger w-100 justify-content-center' }
                                onClick={ onClickDelete }
                            />

                            <Button
                                text={'Редактировать'}
                                className={ 'btn btn-primary w-100 justify-content-center' }
                                onClick={ onClickSave }
                            />
                        </div>
                    )
                }
            </div>
        </DialogContainer>
    )
}

export default CreateGroupDialog;
