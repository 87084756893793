export default function NewsHorn({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.6667 11.6667V5.00004M11.6667 11.6667L16.7517 14.5725C16.8277 14.616 16.9137 14.6388 17.0013 14.6386C17.0888 14.6383 17.1748 14.6151 17.2506 14.5713C17.3263 14.5274 17.3892 14.4644 17.433 14.3886C17.4768 14.3127 17.4999 14.2268 17.5 14.1392V2.52754C17.5 2.43992 17.477 2.35383 17.4333 2.27789C17.3896 2.20195 17.3267 2.13883 17.2509 2.09486C17.1751 2.05089 17.0891 2.02761 17.0015 2.02735C16.9139 2.02708 16.8277 2.04985 16.7517 2.09337L11.6667 5.00004M11.6667 11.6667H5.83333M11.6667 5.00004H5.83333C4.94928 5.00004 4.10143 5.35123 3.47631 5.97635C2.85119 6.60147 2.5 7.44932 2.5 8.33337C2.5 9.21743 2.85119 10.0653 3.47631 10.6904C4.10143 11.3155 4.94928 11.6667 5.83333 11.6667M5.83333 11.6667L6.46417 16.0834C6.52999 16.5131 6.76273 16.8995 7.11182 17.1585C7.46091 17.4176 7.89814 17.5284 8.32849 17.467C8.75884 17.4055 9.14754 17.1766 9.41013 16.8302C9.67271 16.4837 9.78796 16.0477 9.73083 15.6167L9.16667 11.6667H5.83333Z"
				stroke={color}
				strokeWidth="1.25"
			/>
		</svg>
	);
}
