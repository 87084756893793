export default function ArrowTable({ color="white", size = 14 }) {
    return (
        <svg width={size} height={size} viewBox={`0 0 14 14`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="arrow-separate-vertical 1">
                <path
                    id="Vector" d="M9.91732 4.66667L7.00065 1.75L4.08398 4.66667M9.91732 9.33333L7.00065 12.25L4.08398 9.33333"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
