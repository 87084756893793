import React, { FC,} from "react";
import TextField from "../form/TextField";
import Search from "../../img/icon/Search";

interface PersonSearchProps {
    searchValue: string
    setSearchValue: (value: string) => void
}

const PersonSearch: FC<PersonSearchProps> = (
    {
        searchValue,
        setSearchValue
    }
) => {
    return (
        <form className="person__block-form">
            <TextField
                placeholder={ 'Поиск сотрудника' }
                value={ searchValue }
                onChangeValue={ setSearchValue }
                imgLeft={ (
                    <Search/>
                ) }
            />
        </form>
    );
}

export default PersonSearch;
