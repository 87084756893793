import React, { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomMatchBracket, MatchesItem } from "../../components/tournaments-brascket/CustomMatchBrasket";
import PopupDialog from "../../components/dialog/PopupDialog";
import {
    $getListAwards, $getListPrize,
    $getTournamentsGridList,
    $setTeamTournament,
    $startTournament, $updateListPrize,
    $updateTeamTournament
} from "../../api/requests/tournaments";
import ArrowLeft from "../../img/icon/ArrowLeft";
import CommandsItem from "./tournamentEditGame/CommandsItem";
import RoleUsers from "../../mixins/role";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {prizeSeatsPost} from "../../api/requests/tournaments/interface";

type Team = string;
type Stage = number;

const generateMatchups = (teams: Team[]): Team[][] => {
    const matchups: Team[][] = [];
    for (let i = 0; i < teams.length / 2; i++) {
        matchups.push([ teams[i], teams[teams.length - 1 - i] ]);
    }
    return matchups;
};

const StageBracket: FC<{ stage: Stage; matchups: Team[][] }> = ({stage, matchups}) => {
    return (
        <div style={ {
            margin: '20px',
            textAlign: 'center'
        } }>
            <h2>Этап { stage }</h2>

            <div style={ {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            } }>
                { matchups.map((matchup, index) => (
                    <div
                        style={ {
                            display: 'flex',
                            margin: '5px',
                            alignItems: 'center'
                        } }
                        key={ index }
                    >
                        <span>{ matchup[0] }</span>
                        <span>vs</span>
                        <span>{ matchup[1] }</span>
                    </div>
                )) }
            </div>
        </div>
    );
};

const TournamentBracket: FC<{ numStages: Stage; teams: Team[] }> = ({numStages, teams}) => {
    const brackets: JSX.Element[] = [];
    let currentTeams = teams.slice();

    for (let stage = 1; stage <= numStages; stage++) {
        const matchups = generateMatchups(currentTeams);
        brackets.push(<StageBracket key={ stage } stage={ stage } matchups={ matchups }/>);
        currentTeams = matchups.map((matchup) => matchup[0]);
    }

    return (
        <div style={ {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        } }>
            { brackets }
        </div>
    );
};

const TournamentsGridPage: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const state: any = location.state;
    const editedData = state?.data || {};

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('tournamentsGrid', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('tournamentsGrid', 'update');

    const [ isTournamentsStatus, setIsTournamentsStatus ] = useState(editedData.status || 0);
    const [ isAwarded, setIsAwarded ] = useState(false);

    const [ eventPopup, setEventPopup ] = useState<MouseEvent | null>(null);
    const [ selectedTeam, setSelectedTeam ] = useState<any>(null);
    const [ winnerSelectItem, setWinnerSelectItem ] = useState<any>(null);
    const [ roundInProcess, setRoundInProcess ] = useState<any>(null);
    const [ roundInFilled, setRoundInFilled ] = useState<any>(null);
    const [ isStatusApplicationAcceptance, setIsStatusApplicationAcceptance ] = useState<boolean>(false);

    const [ teamList, setTeamList ] = useState<any[]>([]);
    const [ roundsSaveData, setRoundsSaveData ] = useState<any[]>([]);
    const [ lostTeams, setLostTeams ] = useState<any[]>([]);

    const [ list, setList ] = useState<MatchesItem[]>([
        {
            "id": 0,
            "name": "Этап 1",
            "startTime": "Этап 1",
            "state": "Этап 1",
            "participants": [
                {
                    "id": "8960aa3a-5100-43a4-a17a-b41d59d36143",
                    "tournamentTeamId": "b5600662-f9aa-49e4-abc6-e96a91096b22",
                    "tournamentRoundSubId": "32e7af9e-cd70-4119-acd9-efd67bc8f398",
                    "status": '0',
                    "createdAt": "2023-10-01T15:53:40.104Z",
                    "updatedAt": "2023-10-02T02:10:27.287Z",
                    "team": {
                        "id": "b5600662-f9aa-49e4-abc6-e96a91096b22",
                        "centerId": "ca548338-758f-4972-a2e9-6708f2621e31",
                        "tournamentId": "5b70e0f0-9bf7-4b7d-98e0-5b44d81b31a7",
                        "tournamentRequestId": "5578875a-44d9-4cd0-8030-6c8056e1b0ca",
                        "title": "first team",
                        "averageRating": 0,
                        "createdAt": "2023-10-02T01:45:04.205Z",
                        "updatedAt": "2023-10-02T01:45:04.205Z",
                        "users": [
                            {
                                "id": "66b9df4e-a540-42d1-8fb7-8966dbf521f9",
                                "tournamentTeamId": "b5600662-f9aa-49e4-abc6-e96a91096b22",
                                "userId": "4e45a826-39e6-4aba-8cf3-60ec58ef7bb4",
                                "username": "client",
                                "image": null,
                                "rating": 0,
                                "isCaptain": false,
                                "createdAt": "2023-10-02T01:45:04.205Z",
                                "updatedAt": "2023-10-02T01:45:04.205Z"
                            },
                            {
                                "id": "926cbc7a-98a5-49a1-bb5e-ce7fc22e87e8",
                                "tournamentTeamId": "b5600662-f9aa-49e4-abc6-e96a91096b22",
                                "userId": "4812cbe7-907a-4746-9fb3-e5dc25065d32",
                                "username": "Test7",
                                "image": "https://api-phygital-app.teamtim.tech/storage/static/avatars/avatar_f.png",
                                "rating": 0,
                                "isCaptain": true,
                                "createdAt": "2023-10-02T01:45:04.205Z",
                                "updatedAt": "2023-10-02T01:45:04.205Z"
                            }
                        ]
                    },
                    "isWinner": false,
                    "isWaiting": true,
                    "title": "first team",
                    "name": "TBD",
                    "resultText": ""
                },
                {
                    "id": "f1131b08-70d6-4b8a-97e7-6a9ba9a057a9",
                    "tournamentTeamId": "3328ffb4-c9ae-45a2-bb19-5feaf5c691b2",
                    "tournamentRoundSubId": "32e7af9e-cd70-4119-acd9-efd67bc8f398",
                    "status": '0',
                    "createdAt": "2023-10-01T15:53:40.104Z",
                    "updatedAt": "2023-10-02T02:26:01.713Z",
                    "team": {
                        "id": "3328ffb4-c9ae-45a2-bb19-5feaf5c691b2",
                        "centerId": "ca548338-758f-4972-a2e9-6708f2621e31",
                        "tournamentId": "5b70e0f0-9bf7-4b7d-98e0-5b44d81b31a7",
                        "tournamentRequestId": "76f4c3be-70da-4440-bacb-a788e1513e2a",
                        "title": "second team",
                        "averageRating": 0,
                        "createdAt": "2023-10-02T02:25:53.442Z",
                        "updatedAt": "2023-10-02T02:25:53.442Z",
                        "users": [
                            {
                                "id": "937e35eb-1ee5-45b2-81ce-22703fe4c669",
                                "tournamentTeamId": "3328ffb4-c9ae-45a2-bb19-5feaf5c691b2",
                                "userId": "ffd9d87e-97a1-47f5-942e-2d465648c2c6",
                                "username": "client2",
                                "image": null,
                                "rating": 0,
                                "isCaptain": true,
                                "createdAt": "2023-10-02T02:25:53.442Z",
                                "updatedAt": "2023-10-02T02:25:53.442Z"
                            }
                        ]
                    },
                    "isWinner": false,
                    "isWaiting": true,
                    "title": "second team",
                    "name": "TBD",
                    "resultText": ""
                }
            ],
            "nextMatchId": 2,
            "nextLooserMatchId": 1
        },
        {
            "id": 1,
            "name": "Этап 1",
            "startTime": "Этап 1",
            "state": "Этап 1",
            "participants": [
                {
                    "id": "9436a2d6-1508-4354-9d14-f7595c075708",
                    "tournamentTeamId": "3328ffb4-c9ae-45a2-bb19-5feaf5c691b2",
                    "tournamentRoundSubId": "ea99a004-599a-44f1-8775-9601c68d5aea",
                    "status": '0',
                    "createdAt": "2023-10-01T15:53:40.104Z",
                    "updatedAt": "2023-10-02T02:27:22.934Z",
                    "team": {
                        "id": "3328ffb4-c9ae-45a2-bb19-5feaf5c691b2",
                        "centerId": "ca548338-758f-4972-a2e9-6708f2621e31",
                        "tournamentId": "5b70e0f0-9bf7-4b7d-98e0-5b44d81b31a7",
                        "tournamentRequestId": "76f4c3be-70da-4440-bacb-a788e1513e2a",
                        "title": "second team",
                        "averageRating": 0,
                        "createdAt": "2023-10-02T02:25:53.442Z",
                        "updatedAt": "2023-10-02T02:25:53.442Z",
                        "users": [
                            {
                                "id": "937e35eb-1ee5-45b2-81ce-22703fe4c669",
                                "tournamentTeamId": "3328ffb4-c9ae-45a2-bb19-5feaf5c691b2",
                                "userId": "ffd9d87e-97a1-47f5-942e-2d465648c2c6",
                                "username": "client2",
                                "image": null,
                                "rating": 0,
                                "isCaptain": true,
                                "createdAt": "2023-10-02T02:25:53.442Z",
                                "updatedAt": "2023-10-02T02:25:53.442Z"
                            }
                        ]
                    },
                    "isWinner": false,
                    "isWaiting": true,
                    "title": "second team",
                    "name": "TBD",
                    "resultText": ""
                },
                {
                    "id": "a4b782fd-6911-41b9-bcb0-439c3201cd8b",
                    "tournamentTeamId": "b5600662-f9aa-49e4-abc6-e96a91096b22",
                    "tournamentRoundSubId": "ea99a004-599a-44f1-8775-9601c68d5aea",
                    "status": '0',
                    "createdAt": "2023-10-01T15:53:40.104Z",
                    "updatedAt": "2023-10-02T02:27:24.811Z",
                    "team": {
                        "id": "b5600662-f9aa-49e4-abc6-e96a91096b22",
                        "centerId": "ca548338-758f-4972-a2e9-6708f2621e31",
                        "tournamentId": "5b70e0f0-9bf7-4b7d-98e0-5b44d81b31a7",
                        "tournamentRequestId": "5578875a-44d9-4cd0-8030-6c8056e1b0ca",
                        "title": "first team",
                        "averageRating": 0,
                        "createdAt": "2023-10-02T01:45:04.205Z",
                        "updatedAt": "2023-10-02T01:45:04.205Z",
                        "users": [
                            {
                                "id": "66b9df4e-a540-42d1-8fb7-8966dbf521f9",
                                "tournamentTeamId": "b5600662-f9aa-49e4-abc6-e96a91096b22",
                                "userId": "4e45a826-39e6-4aba-8cf3-60ec58ef7bb4",
                                "username": "client",
                                "image": null,
                                "rating": 0,
                                "isCaptain": false,
                                "createdAt": "2023-10-02T01:45:04.205Z",
                                "updatedAt": "2023-10-02T01:45:04.205Z"
                            },
                            {
                                "id": "926cbc7a-98a5-49a1-bb5e-ce7fc22e87e8",
                                "tournamentTeamId": "b5600662-f9aa-49e4-abc6-e96a91096b22",
                                "userId": "4812cbe7-907a-4746-9fb3-e5dc25065d32",
                                "username": "Test7",
                                "image": "https://api-phygital-app.teamtim.tech/storage/static/avatars/avatar_f.png",
                                "rating": 0,
                                "isCaptain": true,
                                "createdAt": "2023-10-02T01:45:04.205Z",
                                "updatedAt": "2023-10-02T01:45:04.205Z"
                            }
                        ]
                    },
                    "isWinner": false,
                    "isWaiting": true,
                    "title": "first team",
                    "name": "TBD",
                    "resultText": ""
                }
            ],
            "nextMatchId": 2,
            "nextLooserMatchId": 2
        },
        {
            "id": 2,
            "name": "Финал",
            "startTime": "Финал",
            "state": "Финал",
            "participants": [
                {
                    "id": "5f63a531-f5c8-42df-9f35-45fa5b60fd58",
                    "tournamentTeamId": null,
                    "tournamentRoundSubId": "4be4974b-3cc3-42c8-b7ee-4e435e1b4533",
                    "status": '0',
                    "createdAt": "2023-10-01T15:53:40.104Z",
                    "updatedAt": "2023-10-01T15:53:40.104Z",
                    "team": null,
                    "isWinner": false,
                    "isWaiting": true,
                    "title": "Ожидание",
                    "name": "TBD",
                    "resultText": ""
                },
                {
                    "id": "a3a658af-a793-46ab-9b04-3f4d2946757e",
                    "tournamentTeamId": null,
                    "tournamentRoundSubId": "4be4974b-3cc3-42c8-b7ee-4e435e1b4533",
                    "status": '0',
                    "createdAt": "2023-10-01T15:53:40.104Z",
                    "updatedAt": "2023-10-01T15:53:40.104Z",
                    "team": null,
                    "isWinner": false,
                    "isWaiting": true,
                    "title": "Ожидание",
                    "name": "TBD",
                    "resultText": ""
                }
            ],
            "nextMatchId": null,
            "nextLooserMatchId": 3
        }
    ]);

    const headerBtn = [ 'Все', 'Активные', 'Выключенные' ];

    const [ filterType, setFilterType ] = useState(-1);
    const handleFilter = (type: any) => {
        setFilterType(type);
    };

    const handleClick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (
            !target.classList.contains('tournaments__grid__tour__block__team-first')
            && !target.classList.contains('tournaments__grid__tour__popup')
            && !target.classList.contains('tournaments__grid__tour__popup__team')
            && !target.classList.contains('tournaments__grid__tour__container__winner')
            && !target.classList.contains('btn')
        ) {
            setEventPopup(null);
        }
    };

    const [shouldShowBlock, setShouldShowBlock] = useState(false);
    const [isShowCommandsList, setShowCommandsList] = useState(false);

    useEffect(() => init(), []);

    function init() {
        if (editedData) {
            if (!editedData.id) return navigate('/tournaments/list')

            $getTournamentsGridList(editedData.id).then(res => {
                if (!res.id) return;

                setUpdateGrid(res);
                res.teams && setTeamList(res.teams);
                setIsTournamentsStatus(res.status);
                setIsStatusApplicationAcceptance(res.status === 1);
                res.isAwarded && setIsAwarded(res.isAwarded);

                if ((res.status === 3) && res.teams) {
                    getListAwards(res.id)
                }
            })
        }
    }

    function getListAwards(id: string) {
        $getListPrize(id).then(res => {
            if (!res || !res.length) return;

            setContent(res.map((i: any, idx: number) => ({
                ...i,
                position: idx + 1
            })))
        })
    }

    function setUpdateGrid(res: any) {
        const rounds = [ ...res.rounds ];

        calculateNextSubRoundId(rounds);

        recordMatchWonByRound(rounds);

        setRoundsSaveData(rounds);

        const subRoundsList: any[] = [];

        rounds.forEach(({subRounds, id, ...data}) => {
            subRounds.forEach((sub: any) => {
                const findItem = getSubRoundList(res.rounds).findIndex(i => i.id === sub.nextMatchId);

                const subRound = {
                    teams: sub.teams,
                    nextMatchId: findItem === -1 ? null : findItem,
                    ...data
                }

                subRoundsList.push(subRound)
            })

        });

        const subRoundsMapList = subRoundsList
            .map((i, idx) => {
                const checkIsWinner = i.teams.find((_i: any) => _i.isWinner);

                return ({
                    // ...i,
                    id: idx,
                    name: i.title,
                    participants: i.teams.map((item: any) => {
                        return ({
                            ...item,
                            isWinner: item.status === 1 || false,
                            isWaiting: item.status === 0,
                            title: item.team ? item.team.title : 'Ожидание',
                            requestUsers: item.team?.users || [],
                            averageRating: item.team?.averageRating,
                            round: i.round,
                        })
                    }),
                    nextMatchId: i.nextMatchId,
                    nextLooserMatchId: idx + 1,
                    nextRound: checkIsWinner ? (i.round + 1) : i.round
                })
            })
            .filter(i => {
                if (i.nextMatchId === null) setWinnerSelectItem(i)

                return i.nextMatchId !== null
            })

        const subRoundsFilterList = subRoundsMapList.map((i: any, idx) => {
            if (subRoundsMapList.length - 1 === idx) return {
                ...i,
                nextMatchId: null
            }

            return i
        });

        setList(subRoundsFilterList);
    }

    function recordMatchWonByRound (rounds: any[]) {
        function findErrorInWonRound(rounds: any[]): any | null {
            for (const round of rounds) {
                for (const subRounds of round.subRounds) {
                    const find = subRounds.teams.find((i: any) => [1].includes(i.status));

                    if (!find) return { round, subRounds };
                }
            }

            return null;
        }

        function findErrorTeamReg(rounds: any[]): any | null {
            for (const round of rounds) {
                for (const subRounds of round.subRounds) {
                    const find = subRounds.teams.find((i: any) => !!i.team)

                    if (!find) return { round, subRounds };
                }
            }

            return { round: null, subRounds: null };
        }

        const updateRounds: any = findErrorInWonRound(rounds);

        if (updateRounds?.round) setRoundInProcess(updateRounds.round)

        const absenceOfTeam = findErrorTeamReg(rounds);

        if (updateRounds?.round) setRoundInFilled(absenceOfTeam.round ? absenceOfTeam.round.round : 0)

        const filteredTeams = rounds.flatMap((round) =>
            round.subRounds
                .map((subRound: any) =>
                    subRound.teams
                        .filter((team: any) => team.status === -1)
                        .map((team: any) => team.team)
                )
                .flat()
        );

        setLostTeams(filteredTeams)
    }

    function calculateNextSubRoundId(rounds: any[]): void {
        for (let i = 0; i < rounds.length; i++) {
            const currentRound = rounds[i];
            const nextRound = rounds[i + 1];

            if (nextRound) {
                const currentSubRounds = currentRound.subRounds;
                const nextSubRounds = nextRound.subRounds;

                if (currentSubRounds && nextSubRounds) {
                    for (let j = 0; j < currentSubRounds.length; j += 2) {
                        const subRound = currentSubRounds[j];
                        const nextSubRound = nextSubRounds[j / 2];

                        if (nextSubRound) {
                            subRound.nextMatchId = nextSubRound.id;
                        }
                    }

                    for (let j = 1; j < currentSubRounds.length; j += 2) {
                        const subRound = currentSubRounds[j];
                        const nextSubRound = nextSubRounds[Number((j / 4).toFixed())];

                        if (nextSubRound) {
                            subRound.nextMatchId = nextSubRound.id;
                        } else {
                            subRound.nextMatchId = null;
                        }
                    }
                }
            }
        }
    }

    function getSubRoundList(rounds: any[]): any[] {
        const newArr: any[] = [];

        rounds.forEach(({subRounds, id, ...data}) => {
            subRounds.forEach((sub: any) => {
                const subRound = {
                    teams: sub.teams,
                    id: sub.id,
                    ...data
                }

                newArr.push(subRound)
            })

        })

        return newArr
    }

    function onOpenPopup(item: any, event: any, match: any) {
        // if ((item.status === 0) && !item.team) return;
        if ((item.round > roundInProcess) && (roundInProcess !== null)) return;

        const updateTeamList = [...teamList].filter((i) => {
            const findItem = lostTeams.find(item => item.id === i.id)

            return !findItem;
        });

        setTeamList(updateTeamList);

        changeShowBtnStatusList(item);
        changeShowCommandsList(item);

        setSelectedTeam(item)
        setEventPopup(event);
    }

    const dynamicDivision = (startingNumber: number, num = 2) => {
        // подсчета раундов от кол-ва
        let result = startingNumber;
        let countDivisions = 1;
        while (result >= 1) {
            result /= num;
            countDivisions += 1;
        }

        return countDivisions;
    };

    function onClickAccept(select: any) {
        const requestData = {
            tournamentRoundSubTeamId: selectedTeam.id,
            tournamentTeamId: select.id
        }

        $setTeamTournament(requestData).then(res => {
            if (!res.id) return;

            setEventPopup(null);
            init();
        })
    }

    function onChangeStatusTeam(status: number) {
        $updateTeamTournament({
            tournamentRoundSubTeamId: selectedTeam.id,
            status
        }).then(res => {
            if (!res.id) return;

            init();
        })
    }

    function onClickBack() {
        navigate(`/tournaments/view/${ editedData.id }`, {
            state: {
                current: 'view',
                data: editedData
            },
        })
    }

    function onClickStartTournaments() {
        const requestData = {
            id: editedData.id,
            status: 2
        }

        $startTournament(requestData).then(res => {
            if (!res.id) return;

            setIsTournamentsStatus(res.status);
            init();
        })
    }

    function onClickFinishTournaments() {
        const requestData = {
            id: editedData.id,
            status: 3
        }

        $startTournament(requestData).then(res => {
            if (!res.id) return;

            setIsTournamentsStatus(res.status);
            init();
        })
    }

    function isDisabledBtnStartStop() {
        if ([-1, 3].includes(isTournamentsStatus)) return true;

        return false //roundInFilled < 1;
    }

    function changeShowBtnStatusList (selectedTeam: any) {
        if ((!selectedTeam.team) || selectedTeam.round < roundInProcess) return setShouldShowBlock(false);

        if (isTournamentsStatus === 2) return setShouldShowBlock(true);

        setShouldShowBlock(false);
    }

    function changeShowCommandsList(selectedTeam: any) {
        if (isStatusApplicationAcceptance) return setShowCommandsList(true);

        if (!selectedTeam.team) return setShowCommandsList(true);

        setShowCommandsList(false);
    };

    const header = [
        {
            key: 'position',
            title: 'Место',
            width: ''
        },
        {
            key: 'title',
            title: 'Команды / Участники',
            width: ''
        }
    ]

    const [content, setContent] = useState<any[]>([])

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result.map((i, idx) => ({
            ...i,
            position: idx + 1
        }));
    };

    function onDragEnd(result: any) {
        if (isAwarded) return;

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const quotes = reorder(content, result.source.index, result.destination.index);

        onClickAutoSaveTable(quotes);
    }

    function onClickSaveTable(list: any[]) {
        const requestArr = list.map((i, idx) => ({
            id: i.id,
            position: idx + 1
        }))

        $getListAwards(editedData.id, { data: requestArr }).then(res => {
            navigate('/tournaments/list')
        })
    }

    function onClickAutoSaveTable(list: any[]) {
        const requestObj: prizeSeatsPost = {
            tournamentId: editedData.id,
            data: list.map((i, idx) => ({
                id: i.id,
                order: idx + 1
            }))
        }

        $updateListPrize(requestObj).then((res) => {
            if (!res?.message) return;

            setContent(list);
        })
    }

    return (
        <div className="news tournaments__grid" onClick={ (e: any) => handleClick(e) }>
            <div className="news__header">
                <div className="news__header__left">
                    {/*{ headerBtn.map((title: string, index: number) => (*/ }
                    {/*    <Button*/ }
                    {/*        key={ index }*/ }
                    {/*        className={ `btn btn-primary ${ (index - 1) === filterType ? 'achievements__header-active' : 'achievements__header-nonActive' }` }*/ }
                    {/*        text={ title }*/ }
                    {/*        onClick={ () => (handleFilter(index - 1)) }*/ }
                    {/*    />*/ }
                    {/*)) }*/ }

                    <Button
                        text={ 'Назад' }
                        leftIcon={ (
                            <ArrowLeft
                                color={ '#3582F6' }
                            />
                        ) }
                        onClick={ onClickBack }
                        className={ 'btn btn-icon' }
                    />
                </div>

                {(isCreateByRole || isUpdateByRole) && (
                    <div className='news__header-right'>
                        <Button
                            className={ `btn ${ isTournamentsStatus === 2 ? 'btn-danger' : 'btn-primary' }` }
                            text={ isTournamentsStatus === 2 ? 'Завершить турнир' : 'Начать турнир' }
                            onClick={ isTournamentsStatus === 2 ? onClickFinishTournaments : onClickStartTournaments }
                            disabled={ isDisabledBtnStartStop() }
                        />
                    </div>
                )}
            </div>

            <div className="computers__content">
                <div className="computers__content__table-header">
                    <h4>{ editedData.title }</h4>

                    <div className="computers__content__table-header-btn">
                        {/*<Button*/ }
                        {/*    text={<Setting/>}*/ }
                        {/*    onClick={() => {*/ }
                        {/*    }}*/ }
                        {/*    className={'btn btn-icon'}*/ }
                        {/*/>*/ }

                        {/*<Button*/ }
                        {/*    text={<Trash color={'#FF5858'}/>}*/ }
                        {/*    onClick={() => {*/ }
                        {/*    }}*/ }
                        {/*    className={'btn btn-icon'}*/ }
                        {/*/>*/ }
                    </div>
                </div>

                <div className="tournaments__grid__tour">
                    <div className="tournaments__grid__tour__block">
                        <CustomMatchBracket
                            list={ list }
                            onClickTop={ (item, event, match) =>
                                onOpenPopup(item, event, match) }
                            onClickBottom={ (item, event, match) =>
                                onOpenPopup(item, event, match) }
                            winner={ winnerSelectItem }
                        />

                        { eventPopup && (
                            <PopupDialog
                                eventProps={ eventPopup }
                                onClose={ () => {
                                } }
                                isGridTournaments={ true }
                            >
                                <div className="tournaments__grid__tour__popup">
                                    { (shouldShowBlock && (isCreateByRole || isUpdateByRole)) && (
                                        <div className={ 'tournaments__grid__tour__popup__judge mb-3' }>
                                            <Button
                                                className={ 'btn btn-primary' }
                                                text={ 'Выиграл' }
                                                onClick={ () => onChangeStatusTeam(1) }
                                                disabled={selectedTeam.status === 1}
                                            />

                                            <Button
                                                className={ 'btn btn-danger' }
                                                text={ 'Проиграл' }
                                                onClick={ () => onChangeStatusTeam(-1) }
                                                disabled={selectedTeam.status === -1}
                                            />
                                        </div>
                                    ) }

                                    { (isShowCommandsList && (isCreateByRole || isUpdateByRole)) ? (
                                        <>
                                            { teamList.length ? teamList.map((item, idx) => (
                                                <div
                                                    key={ `team-list-item-${ idx }` }
                                                    className={ 'tournaments__grid__tour__popup__team' }
                                                >
                                                    { item.title }

                                                    <Button
                                                        className={ 'btn btn-green' }
                                                        text={ (selectedTeam.tournamentTeamId === item.id) ? 'Принятый' : 'Принять' }
                                                        onClick={ () => onClickAccept(item) }
                                                        disabled={ selectedTeam.tournamentTeamId === item.id }
                                                    />
                                                </div>
                                            )) : (
                                                <div>Команды отсутствуют</div>
                                            ) }
                                        </>
                                    ) : (
                                        <CommandsItem
                                            item={ selectedTeam }
                                        />
                                    ) }
                                </div>
                            </PopupDialog>
                        ) }
                    </div>
                </div>
            </div>

            {isTournamentsStatus === 3 && (
                <div className={'computers__content tournaments__grid__table'}>
                    <div className="tournaments__grid__table-header">
                        <h4>Таблица участников</h4>

                        {!isAwarded && (
                            <p>можете изменить позиции победителей и после сохранения начисляться награды за турнир</p>
                        )}
                    </div>

                    <div className="table">
                        <div className="table__header table__block-header">
                            {header.map((column, idx) => (
                                <div
                                    key={column.key}
                                    style={{ width: column.width }}
                                    className={'table__header-th'}
                                >
                                    <div className={'table__header__th'}>
                                        <p>{ column.title }</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className={`table__content table__block-content`}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='list'>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {content.map((item, index) => (
                                                <div key={ index } className={'table__content-tr'}>
                                                    <Draggable
                                                        draggableId={`content-item-${index}`}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                { header.map((column, idx) => (
                                                                    <div
                                                                        key={ column.key }
                                                                        className={'table__content-td'}
                                                                    >
                                                                        { item[column.key] }
                                                                    </div>
                                                                )) }
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>

                    {!isAwarded && (
                        <div className="tournaments__grid__table-footer">
                            <Button
                                text={'Начислить награды'}
                                className={'btn btn-primary'}
                                onClick={() => onClickSaveTable(content)}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default TournamentsGridPage;

