import React, { FC } from "react";
import Telegram from "../../img/icon/Telegram";
import Vk from "../../img/icon/Vk";

interface FooterProps {
}
const Footer: FC<FooterProps> = ({ }) => {

    const social = [
        {
            value: <Telegram/>,
            href: '#'
        },
        {
            value: <Vk/>,
            href: '#'
        },
        {
            value: <Vk/>,
            href: '#'
        },
        {
            value: 'Чат взаимопомощи',
            href: '#'
        }
    ]

    return (
        <div className="">
            {/*<div className="container-fluid">*/}
            {/*    <div className="row">*/}
            {/*        <div className="col-12">*/}
            {/*            <div className="line"></div>*/}

            {/*            <div className="footer__social">*/}
            {/*                {social.map(({value, href}) => (*/}
            {/*                    <a*/}
            {/*                        href={href}*/}
            {/*                        className={ 'btn btn-primary' }*/}
            {/*                    >*/}
            {/*                        {value}*/}
            {/*                    </a>*/}
            {/*                ))}*/}
            {/*            </div>*/}

            {/*            <div className="line"></div>*/}

            {/*            <div className="footer__info">*/}
            {/*                <div className="footer__info-left">*/}
            {/*                    <p>© Project 2023</p>*/}
            {/*                </div>*/}

            {/*                <div className="footer__info-right">*/}
            {/*                    <a href="#">Реквизиты</a>*/}
            {/*                    <a href="#">Пользовательское соглашение</a>*/}
            {/*                    <a href="#">Политика обработки персональных данных</a>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default Footer;
