import { FC, useEffect, useState } from "react";
import DatePickerField from "../form/DatePickerField";
import Button from "../form/Button";
import TableContainer from "../form/TableContainer";
import DateOperations from "../../mixins/date-operation";
import { DashboardGetStatisticSessionsByPeriod } from "../../api/requests/reports/interface";
import { $getStatisticUsers } from "../../api/requests/reports";
import { FilterDate } from "./filter-date/FilterDate";

export const UserReport: FC = () => {
    const isMobile = window.innerWidth <= 465
    const dateOperations = new DateOperations();

    const [dateStart, setDateStart] = useState<Date>(new Date());
    const [dateEnd, setDateEnd] = useState<Date>(new Date());

    const [notFound, setNotFound] = useState(true);

    const headerTable = [
        {
            key: 'id',
            title: 'ID Клиента'
        },
        {
            key: 'username',
            title: 'Никнейм'
        },
        {
            key: 'deposit',
            title: 'Текущий баланс'
        },
        {
            key: 'spends',
            title: 'Траты'
        },  {
            key: 'averageSpends',
            title: 'Средние траты'
        }
    ];

    const [contentTable, setContentTable] = useState<any[]>([
        {
            id: 'aksmdkas=adkvmaksd-sadmkasmk',
            username: 'Name',
            deposit: '500',
            spends: '25.000 ₽',
            averageSpends: 100
        }
    ]);

    const getValidData = (data: any): string => {
        return (data && (parseInt(String(data * 100))) / 100 || 0) + ' ₽'
    }

    useEffect(() => init(), [])

    function init() {
        setNotFound(true);

        const requestData: DashboardGetStatisticSessionsByPeriod = {
            fromDate: dateOperations.getISODateByObj(dateStart) + 'T00:00:00',
            toDate: dateOperations.getISODateByObj(dateEnd) + 'T00:00:00',
            userId: ''
        }

        $getStatisticUsers(requestData).then(res => {
            if (!res) return;

            setNotFound(false);

            const updateList = res.map((i) => ({
                id: i.id,
                username: i.username,
                deposit: getValidData(i.deposit),
                spends: getValidData(i.spends),
                averageSpends: getValidData(i.averageSpends),
            }))

            setContentTable(updateList);
        })
    }

    return (
        <div className='reports__user'>

            <FilterDate
                dateStart={dateStart}
                dateEnd={dateEnd}
                setDateStart={setDateStart}
                setDateEnd={setDateEnd}
                init={init}
            />

            <div className={notFound ? 'reports__user-notFound' : 'reports__user-content'}>
                { notFound ? (
                        <div className='reports__shift-notFound'>
                            За выбранный период данных нет.
                        </div>
                    ) :
                    (
                        <TableContainer
                            header={headerTable}
                            textContentEmpty={'За выбранный период данных нет.'}
                            content={notFound ? [] : contentTable}
                        />
                    )
                }
            </div>
        </div>
    )
}
