import { FC, useEffect, useState } from 'react';
import Button from "../../components/form/Button";
import Pen from "../../img/icon/Pen";
import { useNavigate } from "react-router-dom";
import Trash from "../../img/icon/Trash";
import CreatePromoDialog from "../../components/promo-code/CreatePromoDialog";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { $deleteDiscount, $getDiscountList } from "../../api/requests/discount";
import RoleUsers from "../../mixins/role";
import CreateGroupDiscounts from "../../components/discounts/CreateGroupDiscounts";

const PromoCode: FC = () => {
    const isMobile = window.innerWidth < 465
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('promoCode', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('promoCode', 'update');

    const [ isShowCreateGroupsModal, setIsShowCreateGroupsModal ] = useState(false);

    const [ list, setList ] = useState<any[]>([]);

    const [ selectItem, setSelectItem ] = useState<any>(null);

    useEffect(() => init(), []);

    function init() {
        $getDiscountList(
            {
                type: '2'
            }
        ).then(res => {
            if (!res.result) return;

            setList(res.result);
        })
    }

    function onClickDelete(idx: number) {
        if (!confirm('Вы подтверждаете удаление?')) return;

        const newList = [ ...list ];

        $deleteDiscount(newList[idx].id).then(res => {
            if (!res.name) return;

            newList.splice(idx, 1);

            setList(newList);
        })
    }

    function onClickSelectModal(
        isEdit: boolean,
        item?: any
    ) {
        setIsShowCreateGroupsModal(true);

        if (isEdit) onClickHome(item);
    }

    function onClickHome(item: any) {
        setSelectItem(item)
    }

    function onClickBack() {
        navigate('/')
    }

    return (
        <div className="promo-code list-wrapper">
            <div className="promo-code-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 promo-code-page">
                            <div className="promo-code__item">
                                <div className="booking__header">
                                    <h1>Промокоды</h1>
                                </div>

                                { isCreateByRole && (
                                    <div className="promo-code__item__btn">
                                        <Button
                                            className={ 'btn btn-primary' }
                                            text={ 'Добавить промокод' }
                                            onClick={ () => onClickSelectModal(false) }
                                        />
                                    </div>
                                ) }

                                <div className={ 'promo-code__item__list' }>
                                    { !!list.length && list.map((
                                        item,
                                        idx
                                    ) => (
                                        <div
                                            onClick={ () => isMobile && onClickSelectModal(true, item) }
                                            className="promo-code__item__list__info"
                                            key={ `promo-code__item__list-info${ idx }` }
                                        >
                                            <div className="promo-code__item__list__info__meta">
                                                <div className="promo-code__item__list__info__meta__text">
                                                    <p>{ item.name }</p>

                                                    <div style={ {
                                                        color: '#70D584',
                                                        backgroundColor: 'rgba(112, 213, 132, 0.10)'
                                                    } }>
                                                        { item.limit || 'Неограниченно' }
                                                    </div>
                                                </div>

                                                <span>{ item.percent } %</span>
                                            </div>

                                            { isUpdateByRole && !isMobile && (
                                                <div className="promo-code__item__list__info__inner">
                                                    <Button
                                                        className={ 'btn' }
                                                        text={ (
                                                            <Pen
                                                                width={ 16 }
                                                                height={ 16 }
                                                                color={ '#3582F6' }
                                                            />
                                                        ) }
                                                        onClick={ () => onClickSelectModal(true, item) }
                                                    />

                                                    <Button
                                                        className={ 'btn' }
                                                        text={ (
                                                            <Trash
                                                                color={ '#FF5858' }
                                                            />
                                                        ) }
                                                        onClick={ () => onClickDelete(idx) }
                                                    />
                                                </div>
                                            ) }
                                        </div>
                                    )) }
                                </div>

                                { isShowCreateGroupsModal && (
                                    <>
                                        { isMobile ? (
                                            <CreateGroupDiscounts
                                                selectItem={ selectItem }
                                                isShow={ isShowCreateGroupsModal }
                                                setIsShow={ () => {
                                                    setIsShowCreateGroupsModal(false);
                                                    setSelectItem(null);
                                                } }
                                                updateList={ () => init() }
                                                isPromo
                                            />
                                        ) : (
                                            <CreatePromoDialog
                                                selectItem={ selectItem }
                                                isShow={ isShowCreateGroupsModal }
                                                setIsShow={ () => {
                                                    setIsShowCreateGroupsModal(false);
                                                    setSelectItem(null);
                                                } }
                                                updateList={ () => init() }
                                            />
                                        ) }

                                    </>
                                ) }
                            </div>

                            { !list.length && (
                                <div className="program__block__item__appellation">
                                    <p>
                                        Список промокодов пуст
                                    </p>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PromoCode;
