import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import {
    NotificationRequestData,
    NotificationResponseData,
    NotificationListRequestData,
    NotificationListResponseData
} from "./interface";

export const $notificationCreate = (data: NotificationRequestData, handler?: IApiHandlerParams): Promise<NotificationResponseData> => api
    .post('/notification/single', data, {
        handler
    })

export const $notificationUpdate = (id: number, data: NotificationRequestData, handler?: IApiHandlerParams): Promise<NotificationResponseData> => api
    .put('/notification/single/' + id, data, {
        handler
    })

export const $notificationDelete = (id: number, handler?: IApiHandlerParams): Promise<NotificationResponseData> => api
    .delete('/notification/single/' + id, {
        handler
    })

export const $notificationGet = (id: number, handler?: IApiHandlerParams): Promise<NotificationResponseData> => api
    .get('/notification/single/' + id, {
        handler
    })

export const $getNotificationList = (params?: NotificationListRequestData, handler?: IApiHandlerParams): Promise<NotificationListResponseData> => api
    .get('/notification', {
        request: {params},
        handler
    })
