export default function Person({color= 'white', width = 21, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 21 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector (Stroke)"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.93323 2.36553C7.87622 1.43506 9.15894 0.90918 10.5002 0.90918C11.8414 0.90918 13.1241 1.43506 14.0671 2.36553C15.0096 3.29543 15.536 4.55314 15.536 5.86114C15.536 7.16913 15.0096 8.42685 14.0671 9.35674C13.9132 9.50859 13.7503 9.64966 13.5795 9.77942C14.5861 10.1821 15.5103 10.7817 16.2917 11.5526C17.8241 13.0647 18.682 15.112 18.682 17.2433V18.278C18.682 18.727 18.3054 19.091 17.8409 19.091C17.3763 19.091 16.9998 18.727 16.9998 18.278V17.2433C16.9998 15.5325 16.3109 13.8951 15.09 12.6905C13.8697 11.4864 12.2183 10.8131 10.5002 10.8131C8.78205 10.8131 7.13069 11.4864 5.91038 12.6905C4.6895 13.8951 4.0006 15.5325 4.0006 17.2433V18.278C4.0006 18.727 3.62402 19.091 3.15948 19.091C2.69494 19.091 2.31836 18.727 2.31836 18.278V17.2433C2.31836 15.112 3.17625 13.0647 4.70866 11.5526C5.49002 10.7817 6.41425 10.1821 7.42081 9.77942C7.25005 9.64966 7.08711 9.50859 6.93323 9.35674C5.9908 8.42685 5.46437 7.16913 5.46437 5.86114C5.46437 4.55314 5.9908 3.29543 6.93323 2.36553ZM10.5002 2.5352C9.61642 2.5352 8.76526 2.88145 8.13495 3.50338C7.50406 4.12589 7.14662 4.9737 7.14662 5.86114C7.14662 6.74858 7.50406 7.59638 8.13495 8.21889C8.76526 8.84083 9.61642 9.18708 10.5002 9.18708C11.3839 9.18708 12.2351 8.84083 12.8654 8.21889C13.4963 7.59638 13.8537 6.74858 13.8537 5.86114C13.8537 4.9737 13.4963 4.12589 12.8654 3.50338C12.2351 2.88145 11.3839 2.5352 10.5002 2.5352Z"
                      fill={color}
                />
            </g>
        </svg>
    );
}