import { FC } from "react";
import DatePickerField from "../../form/DatePickerField";
import Button from "../../form/Button";
import { FilterDate } from "./FilterDate";

interface FilterDateSecondProps {
    dateStart: any
    dateEnd: any
    setDateStart: any
    setDateEnd: any
    init: () => void
}

export const FilterDateSecond:FC<FilterDateSecondProps>  = ({
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
    init
}) => {
    const isMobile = window.innerWidth <= 465

    return (
        <div className=' reports__overview-header'>
            {!isMobile ? (
                    <div className='reports__overview-header-left'>
                        <div className='reports__overview-header-block'>
                            <h1 className='reports__overview-header-title'>Дата начала</h1>

                            <div className='reports__overview-header-dates'>
                                <div className='reports__overview-header-date'>
                                    <DatePickerField
                                        isIcon
                                        startDate={ dateStart }
                                        onChangeDate={ (date) => {
                                            setDateStart(date)
                                        } }
                                    />
                                </div>
                            </div>
                        </div>

                        <span className='reports__filter-line'/>

                        <div className='reports__overview-header-block'>
                            <h1 className='reports__overview-header-title'>Дата окончания</h1>

                            <div className='reports__overview-header-dates'>
                                <div className='reports__overview-header-date'>
                                    <DatePickerField
                                        isIcon
                                        startDate={ dateEnd }
                                        onChangeDate={ (date) => {
                                            setDateEnd(date)
                                        } }
                                    />
                                </div>

                                <Button
                                    text={ 'Применить' }
                                    className={ 'btn btn-primary reports__overview-header-btn' }
                                    onClick={ init }
                                />
                            </div>
                        </div>
                    </div>
                ) :
                (
                    <FilterDate
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        setDateStart={setDateStart}
                        setDateEnd={setDateEnd}
                        init={init}
                    />
                )}

            {/*<div className='reports__subheader-right'>*/ }
            {/*    { headerBlocks.map((item: any, idx: number) => (*/ }
            {/*        <div key={ idx } className='reports__overview-header-right'>*/ }
            {/*            <h1*/ }
            {/*                style={ {color: 'grey'} }*/ }
            {/*                className='reports__overview-header-title'*/ }
            {/*            >*/ }
            {/*                { item.title }*/ }
            {/*            </h1>*/ }

            {/*            { [ 'first', 'second' ].includes(item.key)*/ }
            {/*                ? (*/ }
            {/*                    <div className='reports__overview-header-numbers'>*/ }
            {/*                        { item.firstValue*/ }
            {/*                            ? <span className='reports__overview-value'>{ item.firstValue }</span>*/ }
            {/*                            : <span className='reports__overview-white'></span>*/ }
            {/*                        }*/ }

            {/*                        { item.secondValue*/ }
            {/*                            ? <span className='reports__overview-value'>{ item.secondValue }</span>*/ }
            {/*                            : <span className='reports__overview-blue'></span>*/ }
            {/*                        }*/ }
            {/*                    </div>*/ }
            {/*                )*/ }
            {/*                : (*/ }
            {/*                    <div style={ {marginTop: 25} } className='reports__overview-header-numbers'>*/ }
            {/*                        { item.value ?*/ }
            {/*                            <span className='reports__overview-value'> { item.value }</span> :*/ }
            {/*                            <span className='reports__overview-white'></span> }*/ }
            {/*                    </div>*/ }
            {/*                ) }*/ }
            {/*        </div>*/ }
            {/*    )) }*/ }
            {/*</div>*/ }
        </div>
    )
}