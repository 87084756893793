export default function Check({color= "white", width = 10, height = 7}) {
    return (
        <svg  width={width} height={height} viewBox={`0 0 10 7`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.76744 3.76744L4 6L8.46512 1.53488" stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
        </svg>
    );
}
