import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import TableContainer from "../../components/form/TableContainer";
import TextField from "../../components/form/TextField";
import Search from "../../img/icon/Search";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { changeUserEdit } from "../../redux/slices/userEditSlice";
import { $clientList } from "../../api/requests/users";
import { UsersGet } from "../../api/requests/users/interface";
import Person from "../../img/icon/Person";
import Pagination from "../../components/form/Pagination";
import GuestListPage from "./GuestListPage";
import { withDebounce } from "../../functions";
import RoleUsers from "../../mixins/role";
import moment from "moment";

const UsersPage: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const roleUsers = new RoleUsers();

    const isCreateGuestByRole = roleUsers.getModelRoleByType('fitZoneSession', 'create');

    const [ searchUser, setSearchUser ] = useState('');

    const [headerTableList, setHeaderTableList] = useState([
        {
            key: 'nicknameTable',
            title: 'Никнейм',
        },
        {
            key: 'name',
            title: 'Имя',
        },
        {
            key: 'lastName',
            title: 'Фамилия',
        },
        {
            key: 'birthDate',
            title: 'Дата рождения',
        },
        {
            key: 'phone',
            title: 'Телефон',
        },
        {
            key: 'deposit',
            title: 'Депозит',
        },
        {
            key: 'bonuses',
            title: 'Токены',
        },
        {
            key: 'discount',
            title: 'Скидка',
        },
        {
            key: 'rating',
            title: 'Рейтинг',
        },
        {
            key: 'isVerifiedTable',
            title: 'Верификация',
        },
    ]);
    const [contentDefaultTableList, setContentDefaultTableList] = useState<any[]>([]);
    const [contentTableList, setContentTableList] = useState<any[]>([]);

    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const backgroundColors = ['#919BAD', '#D39497', '#666762', '#4A5046'];

    const getChangeResultData = (item: UsersGet, index: number) => {
        return {
            ...item,
            nicknameTable: (
                <div className={ 'users-table-center' }>
                    <div
                        className={ 'icon' }
                        style={{
                            backgroundColor: backgroundColors[index % 4]
                        }}
                    >
                        {item.image ? <img src={item.image}/> : <Person/>}
                    </div>

                    <div className={ 'text' }>{item.username || item.name}</div>
                </div>
            ),
            birthDate: item.birthDate ? moment(item.birthDate).format('DD.MM.yyyy') : '',
            phone: item.phone,
            deposit: item.activeCenter?.deposit,
            bonuses: item.activeCenter?.bonuses,
            discount: item.activeCenter?.discount ? `${item?.activeCenter.discount}%` : '-',
            rating: item.activeCenter?.rating || '-',
            isVerifiedTable: (
                <div className={ 'users-table-center' }>
                    <div className={ 'text' } style={ {color: item.isVerified ? '#70D584' : '#B4474E'} }>{item.isVerified ? 'Верифицирован' : 'Не верифицирован'}</div>
                </div>
            ),
        }
    }

    useEffect(() => init(), [activePage]);

    function init (value?: string) {
        $clientList({ page: String(activePage), search: value }).then(res => {
            if (!res.result) return;

            const totalPages = Math.ceil(res.count / 15);

            setTotalPages(res.totalPages || totalPages || 1);

            const list = res.result.map((item, index) => getChangeResultData(item, index))

            setContentTableList(list);
            setContentDefaultTableList(list);
        })
    }

    function onClickAddNew() {
    }

    function onLeftClickTrContent(item: any) {
        const itemUpdate = {
            ...item,
            nicknameTable: item.nicknameTable.props.children[1].props.children,
            color: item.nicknameTable.props.children[0].props?.style?.backgroundColor,
            isVerifiedTable: item.isVerifiedTable.props.children.props.children,
        }

        dispatch(changeUserEdit(itemUpdate))

        navigate(`/users/edit/${item.phone}`)
    }

    function setFilterSearchUser(value: string) {
        setSearchUser(value);

        // if (!value) return setContentTableList(contentDefaultTableList);

        withDebounce(() => init(value));

        // const list = [ ...contentDefaultTableList ].filter((item) => {
        //     const nickname = item.nicknameTable.props.children[1].props.children
        //
        //     const valueLocal = nickname || item.name || item.lastName;
        //
        //     return valueLocal.toLowerCase().includes(value.toLowerCase());
        // })
        //
        // setContentTableList(list);
    }

    return (
        <div className="computers list-wrappers">
            <div className="computers-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 computers-style">
                            <div className="booking__header">
                                <h1>Пользователи</h1>
                            </div>
                            <div className="computers__header">
                                <div className="computers__header-left">
                                    <TextField
                                        placeholder={ 'Поиск пользователя' }
                                        value={ searchUser }
                                        onChangeValue={ setFilterSearchUser }
                                        imgLeft={ (<Search/>) }
                                    />
                                </div>

                                <div className="computers__header-right">
                                    {/*{isCreateGuestByRole && (*/}
                                    {/*    <Button*/}
                                    {/*        className={ 'btn btn-primary' }*/}
                                    {/*        onClick={ () => navigate('/guest') }*/}
                                    {/*        text={ 'Создать гостевой профиль' }*/}
                                    {/*    />*/}
                                    {/*)}*/}

                                    {/*<Button*/}
                                    {/*    className={ 'btn btn-primary' }*/}
                                    {/*    onClick={ onClickAddNew }*/}
                                    {/*    leftIcon={ (*/}
                                    {/*        <PlusBorder/>*/}
                                    {/*    ) }*/}
                                    {/*    text={ 'Добавить' }*/}
                                    {/*/>*/}
                                </div>
                            </div>

                            <div className="computers__content users__content">
                                <div className="computers__content__table users__content__table">
                                    <TableContainer
                                        header={ headerTableList }
                                        content={ contentTableList }
                                        setData={setContentTableList}
                                        onLeftClickTrContent={ onLeftClickTrContent }
                                        isLastRightText={ false }
                                        sortable={ false }
                                    />
                                </div>
                            </div>

                            {(totalPages > 1) && (
                                <div className='logs__carousel'>
                                    <Pagination
                                        activePage={activePage}
                                        setActivePage={setActivePage}
                                        pages={totalPages}
                                        isLoading={isLoading}
                                    />
                                </div>
                            )}

                            {isCreateGuestByRole && (
                                <GuestListPage/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersPage;

