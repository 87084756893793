export default function Cash({color= 'white', size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8333 16.6666H4.16667C3.72464 16.6666 3.30072 16.4911 2.98816 16.1785C2.67559 15.8659 2.5 15.442 2.5 15V7.49998C2.5 7.05795 2.67559 6.63403 2.98816 6.32147C3.30072 6.00891 3.72464 5.83331 4.16667 5.83331H15.8333C16.2754 5.83331 16.6993 6.00891 17.0118 6.32147C17.3244 6.63403 17.5 7.05795 17.5 7.49998V15C17.5 15.442 17.3244 15.8659 17.0118 16.1785C16.6993 16.4911 16.2754 16.6666 15.8333 16.6666Z"
                stroke={color}
                strokeWidth="1.5"
            />
            <path
                d="M13.7499 11.6666C13.6394 11.6666 13.5334 11.6227 13.4553 11.5446C13.3772 11.4665 13.3333 11.3605 13.3333 11.25C13.3333 11.1395 13.3772 11.0335 13.4553 10.9554C13.5334 10.8772 13.6394 10.8333 13.7499 10.8333C13.8604 10.8333 13.9664 10.8772 14.0445 10.9554C14.1227 11.0335 14.1666 11.1395 14.1666 11.25C14.1666 11.3605 14.1227 11.4665 14.0445 11.5446C13.9664 11.6227 13.8604 11.6666 13.7499 11.6666Z"
                fill={color} stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 5.83336V4.66919C14.9999 4.41378 14.9412 4.1618 14.8282 3.93271C14.7153 3.70362 14.5512 3.50355 14.3487 3.34795C14.1461 3.19236 13.9105 3.08539 13.66 3.03533C13.4096 2.98526 13.151 2.99342 12.9042 3.05919L3.7375 5.50336C3.38254 5.59795 3.06878 5.80716 2.84499 6.09847C2.6212 6.38977 2.49992 6.74685 2.5 7.11419V7.50003"
                stroke={color}
                strokeWidth="1.5"
            />
        </svg>
    );
}

