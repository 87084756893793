export default function Flask({ color = "white", width = 17, height = 16}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 17 16`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path
                    id="Vector" d="M13.6038 11.125H4.39551"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                />
                <path id="Vector_2"
                      d="M11.8333 3.33339H6.16667M6.875 3.68756V7.76756C6.87517 8.10579 6.75431 8.43292 6.53429 8.68981L2.965 12.852C2.74523 13.109 2.62464 13.4361 2.625 13.7742V13.9584C2.625 14.3341 2.77426 14.6944 3.03993 14.9601C3.30561 15.2258 3.66594 15.3751 4.04167 15.3751H13.9583C14.3341 15.3751 14.6944 15.2258 14.9601 14.9601C15.2257 14.6944 15.375 14.3341 15.375 13.9584V13.7742C15.375 13.4359 15.2539 13.1088 15.0336 12.852L11.4657 8.68981C11.2457 8.43292 11.1248 8.10579 11.125 7.76756V3.68756M9 6.88214L9.00708 6.87435M8.29167 1.92381L8.29875 1.91602"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}