export default function WordComplete({color = "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M7.08301 3.3335H4.99967C4.55765 3.3335 4.13372 3.50909 3.82116 3.82165C3.5086 4.13421 3.33301 4.55814 3.33301 5.00016V16.6668C3.33301 17.1089 3.5086 17.5328 3.82116 17.8453C4.13372 18.1579 4.55765 18.3335 4.99967 18.3335H9.99967M12.9163 3.3335H14.9997C15.4417 3.3335 15.8656 3.50909 16.1782 3.82165C16.4907 4.13421 16.6663 4.55814 16.6663 5.00016V12.5002"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                />
                <path id="Vector_2" d="M6.66699 5.3335V3.75016C6.66699 3.63966 6.71089 3.53368 6.78903 3.45553C6.86717 3.37739 6.97315 3.3335 7.08366 3.3335C7.31366 3.3335 7.50366 3.14683 7.54366 2.92016C7.66699 2.21016 8.14533 0.833496 10.0003 0.833496C11.8553 0.833496 12.3337 2.21016 12.457 2.92016C12.497 3.14683 12.687 3.3335 12.917 3.3335C13.0275 3.3335 13.1335 3.37739 13.2116 3.45553C13.2898 3.53368 13.3337 3.63966 13.3337 3.75016V5.3335C13.3337 5.4661 13.281 5.59328 13.1872 5.68705C13.0934 5.78082 12.9663 5.8335 12.8337 5.8335H7.16699C7.03438 5.8335 6.90721 5.78082 6.81344 5.68705C6.71967 5.59328 6.66699 5.4661 6.66699 5.3335Z"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                />
                <path id="Vector_3" d="M12.917 17.0835L14.5837 18.7502L18.7503 14.5835"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

