export default function Accept({color = "white", size = 20}) {
    return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15 1.66699H5C4.33696 1.66699 3.70107 1.93038 3.23223 2.39923C2.76339 2.86807 2.5 3.50395 2.5 4.16699V5.83366C2.5 6.4967 2.76339 7.13259 3.23223 7.60143C3.70107 8.07027 4.33696 8.33366 5 8.33366H15C15.663 8.33366 16.2989 8.07027 16.7678 7.60143C17.2366 7.13259 17.5 6.4967 17.5 5.83366V4.16699C17.5 3.50395 17.2366 2.86807 16.7678 2.39923C16.2989 1.93038 15.663 1.66699 15 1.66699Z"
				  stroke={color}
				  strokeWidth="1.25"
			/>

			<path d="M2.5 13.333V14.9997C2.5 15.8837 2.85119 16.7316 3.47631 17.3567C4.10143 17.9818 4.94928 18.333 5.83333 18.333H14.1667C15.0507 18.333 15.8986 17.9818 16.5237 17.3567C17.1488 16.7316 17.5 15.8837 17.5 14.9997V13.333M10 8.33301V14.9997M10 14.9997L7.5 12.4997M10 14.9997L12.5 12.4997"
				  stroke={color}
				  strokeWidth="1.25"
				  strokeLinecap="round"
				  strokeLinejoin="round"
			/>
		</svg>
    );
}
