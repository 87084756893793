import { FC, useEffect, useState } from "react";
import TableContainer, { DataItem, TableColumn } from "../form/TableContainer";
import PopupContainer from "../dialog/PopupContainer";
import DialogContainer from "../dialog/DialogContainer";
import OpenPC from "./OpenPC";
import Button from "../form/Button";
import Calendar from "../../img/icon/Calendar";
import ComputersTableUnderBlock from "./ComputersTableUnderBlock";

interface ComputersTableContentProps {
    onLeftClick: (item: DataItem) => void
    headerTable: TableColumn[]
    contentTable: DataItem[]
    setUpdateList: () => void
}

const ComputersTableContent: FC<ComputersTableContentProps> = (
    {
        onLeftClick,
        headerTable,
        contentTable,
        setUpdateList
    }
) => {

    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0, isTop: false });
    const [isLoadItem, setIsLoadItem] = useState<any>({ event: null, item: null });

    const [currentItemDevice, setCurrentItemDevice] = useState<DataItem>({});

    const [isShowSelectTariff, setIsShowSelectTariff] = useState(false);

    useEffect(() => {
        if (!popupVisible && isLoadItem.event && isLoadItem.item) {
            onRightClickTrContent(isLoadItem.event, isLoadItem.item);
        }
    }, [isLoadItem])

    function onRightClickTrContent(event: MouseEvent, item: DataItem) {
        event.preventDefault();

        if (popupVisible) {
            setPopupVisible(false);
            setIsLoadItem({ event, item });

            return;
        }

        setCurrentItemDevice(item);

        const isRight = (window.innerWidth - event.clientX) < 270;
        const isTop = (window.innerHeight + window.scrollY - event.pageY) < 350;

        const defaultXPos = (event.clientX - (window.innerWidth * 0.25) - 10);

        const xPos = isRight ? (defaultXPos - 253) : defaultXPos;
        const yPos = event.pageY - 100;

        setPopupPosition({ x: xPos, y: yPos, isTop });
        setPopupVisible(true);
    }

    return (
        <div className="computers__content__table">
            <TableContainer
                header={headerTable}
                content={contentTable}
                onRightClickTrContent={onRightClickTrContent}
                onLeftClickTrContent={onLeftClick}
                textContentEmpty={'Нет информации'}
                sortable={false}
                tableBodyUnderBlock={(item: any) => {
                    return (
                        <ComputersTableUnderBlock
                            data={item}
                            setUpdateList={setUpdateList}
                            onOpenSelectTariff={() => {
                                setCurrentItemDevice(item);
                                setIsShowSelectTariff(true);
                            }}
                        />
                    )
                }}
            />

            {popupVisible && (
                <PopupContainer
                    x={popupPosition.x}
                    y={popupPosition.y}
                    isTop={popupPosition.isTop}
                    onClose={() => setPopupVisible(false)}
                    data={currentItemDevice}
                    setUpdateList={setUpdateList}
                    onOpenSelectTariff={() => {
                        setIsShowSelectTariff(true);
                    }}
                />
            )}

            <DialogContainer
                isOpen={isShowSelectTariff}
                closeModal={() => setIsShowSelectTariff(false)}
                widthProps={1380}
                label={`Продажа для ${currentItemDevice.title}`}
            >
                <OpenPC
                    currentItem={currentItemDevice}
                    setUpdateList={() => {
                        setUpdateList();
                        setIsShowSelectTariff(false);
                    }}
                />
            </DialogContainer>
        </div>
    );
}

export default ComputersTableContent;

