import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import {
    ActionDeviceCommand, ActionDeviceStart, ActiveDeviceSession,
    CreateUserDataDevice, DashboardDeviceAvailableDevices,
    DeviceCenterData, DeviceInstallPositionData, DeviceModel,
    DevicePostDataCenter, GetDeviceMapListData,
    ListUsersRequestParamsDevice
} from "./interface";
import { AvailableDeviceData } from "../booking/interface";

export const $getCurrentDevice = (params?: CreateUserDataDevice, handler?: IApiHandlerParams): Promise<DeviceCenterData> => api
    .get('/center/device', {
        request: {params},
        handler
    })

export const $createNewDevice = (data: ListUsersRequestParamsDevice, handler?: IApiHandlerParams): Promise<DeviceModel[]> => api
    .post('/center/device', data, {
        handler
    })

export const $getCurrentAvailable = (data: AvailableDeviceData, handler?: IApiHandlerParams): Promise<DeviceModel[]> => api
    .post('/center/device/available', data,{
        handler
    })

export const $getDevice = (params: DashboardDeviceAvailableDevices, id: number, handler?: IApiHandlerParams): Promise<DeviceModel[]> => api
    .get('/center/device/single/' + id, {
        handler
    })

export const $updateDevice = (id: number, data: ListUsersRequestParamsDevice, handler?: IApiHandlerParams): Promise<DeviceModel> => api
    .put('/center/device/single/' + id, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })

export const $deleteDevice = (id: number, handler?: IApiHandlerParams): Promise<DeviceModel[]> => api
    .delete('/center/device/single/' + id, {
        // mock: require('./mocks/error.delete.json'),
        handler
    })

export const $requestCommandByDevice = (data: ActionDeviceCommand, handler?: IApiHandlerParams): Promise<DeviceModel> => api
    .post('/center/device/action', data,{
        handler
    })

export const $startSession = (id: string, data: ActiveDeviceSession, handler?: IApiHandlerParams): Promise<DeviceModel> => api
    .post('/center/device/action/start/' + id, data, {
        handler
    });

export const $stopSession = (id: string, handler?: IApiHandlerParams): Promise<DeviceModel> => api
    .post('/center/device/action/stop/' + id, undefined,{
        handler
    })

export const $startActionMaintenance = (data: ActionDeviceStart, handler?: IApiHandlerParams): Promise<DeviceModel> => api
    .post('/center/device/action/maintenance', data,{
        handler
    })

export const $installPositionDevice = (data: DeviceInstallPositionData, handler?: IApiHandlerParams): Promise<DeviceModel> => api
    .post('/center/device/map', data, {
        handler
    })

export const $getMapDeviceList = (params?: GetDeviceMapListData, handler?: IApiHandlerParams): Promise<DeviceModel[]> => api
    .get('/center/device/map', {
        request: {params},
        handler
    })
