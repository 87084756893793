import { FC } from "react";
import { Line } from "react-chartjs-2";
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

interface LineChartProps {
    type?: any
    labels: any
    datasets: any,
    tension?: number
    rows?: number[]
    overview?: any
}


export const Chart: FC<LineChartProps> = (
    {
        type = 'line',
        tension,
        labels,
        datasets,
        overview
    }
) => {
    const maxNum = Math.max(...getData(datasets)).toString()


    const row = [ getMax(parseInt(maxNum).toString()),
        Math.round(getMax(parseInt(maxNum).toString()) / 1.25),
        Math.round(getMax(parseInt(maxNum).toString()) / 1.5),
        Math.round(getMax(parseInt(maxNum).toString()) / 2),
        Math.round(getMax(parseInt(maxNum).toString()) / 3),
        Math.round(getMax(parseInt(maxNum).toString()) / 6),
        Math.round(getMax(parseInt(maxNum).toString()) / 100),
    ]

    const getChartSideItems = () => {
        return row && row.map((
            item: any,
            idx: number
        ) => {
            return (
                <span key={ idx } className='chart__numbers-number'>{ item }</span>
            );
        })
    }

    const getChartBottomItems = () => {
        return labels.map((
            item: any,
            idx: number
        ) => {
            return (


                <div key={ idx } className='chart__bottom-item'>

                    { item.title }
                    {/*<div className='chart__bottom-device'><p>{ item?.title }</p></div>*/ }
                    {/*<p className='chart__bottom-zone'>{ item.subTitle }</p>*/ }
                </div>
            );
        })
    }

    const data: any = {
        labels: labels.map((item: any) => item.subTitle),
        datasets: [ {

            data: datasets,
            // label: '',
            labelColor: 'red',
            borderColor: '#3582F6',
            pointColor: '#3582F6',
            pointBackgroundColor: '#3582F6',
            backgroundColor: '#3582F6',
            barThickness: 20,
            tension,
        } ],
        label: {
            font: {
                color: "#fff"
            }
        }
    }

    const options: any = {
        aspectRatio: 0,
        plugins: {
            // border: '10px solid red',
            legend: false,
        },

        scales: {
            x: {
                grid: {
                    display: false,
                },
                display: true,
                // borderColor: 'red'
            },
            yAxes: [ {
                display: true,
                ticks: {
                    min: 0,
                    max: getMax(maxNum)
                }
            } ],
            y: {
                // backgroundColor: 'white',
                // borderColor: 'red',
                display: false,
                min: 0,
                max: getMax(maxNum),
                ticks: {
                    display: true,
                },
            }
        }
    }

    const getChartBgItems = () => {
        return [ 1, 2, 3, 4, 5, 6, 7 ].map((
            item,
            idx
        ) => {
            return (
                <span key={ idx } className='chart__lines-line'></span>
            );
        })
    }

    function getData(data: any) {
        if (data.length === 0) {
            data = [ 0, 0 ]
        } else if (data.length === 1) {
            return [ 0, data[0] ]
        } else {
            return data.map((item: any) => Math.round(item))
        }

        return data
    }

    function getMax(num: any) {
        const zeros = num.length
        const numbers: any = 10 ** zeros

        return numbers
    }

    return (
        <div className='chart'>
            <div className='chart__numbers'>
                { getChartSideItems() }
            </div>

            <div className='chart__lines'>
                { getChartBgItems() }

                <div className={ `chart__chart ${ overview ? 'chart__chart-overview' : '' }` }>
                    { type === 'bar' ? (
                        <Bar height={ '100%' }
                             className={ 'chart__lineChart' }
                             options={ options }
                             data={ data }
                        />
                    ) : (
                        <Line
                            height={ '100%' }
                            className={ 'chart__lineChart' }
                            options={ options }
                            data={ data }
                        />
                    ) }

                    <div className='chart__bottom'>
                        { getChartBottomItems() }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chart;
