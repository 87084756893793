import { IApiHandlerParams } from "../../interface";
import api from "../../../api";
import { DashboardReplyFeedback, FeedbackDataModel, FeedbackModel } from "./interface";
import { AchievementPageRequestData } from "../achievement/interface";

export const $responseFeedback = (id: number, data: DashboardReplyFeedback, handler?: IApiHandlerParams): Promise<FeedbackModel> => api
    .post('/feedback/single/' + id, data, {
        handler
    })

export const $getFeedbackList = (params?: AchievementPageRequestData, handler?: IApiHandlerParams): Promise<FeedbackDataModel> => api
    .get('/feedback', {
        request: { params },
        handler
    })
