export default function Users({color= "white", size = 20}) {
    return (
        <svg  width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.833252 16.6667V15.8333C0.833252 14.2862 1.44783 12.8025 2.5418 11.7085C3.63576 10.6146 5.11949 10 6.66659 10C8.21368 10 9.69741 10.6146 10.7914 11.7085C11.8853 12.8025 12.4999 14.2862 12.4999 15.8333V16.6667"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M10.8333 11.6667C10.8333 10.5616 11.2722 9.50179 12.0536 8.72039C12.835 7.93899 13.8948 7.5 14.9999 7.5C15.5471 7.5 16.0889 7.60777 16.5944 7.81717C17.1 8.02656 17.5593 8.33348 17.9462 8.72039C18.3331 9.1073 18.64 9.56663 18.8494 10.0722C19.0588 10.5777 19.1666 11.1195 19.1666 11.6667V12.0833"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M6.66659 10C7.55064 10 8.39849 9.64881 9.02361 9.02369C9.64873 8.39857 9.99992 7.55072 9.99992 6.66667C9.99992 5.78261 9.64873 4.93477 9.02361 4.30964C8.39849 3.68452 7.55064 3.33333 6.66659 3.33333C5.78253 3.33333 4.93468 3.68452 4.30956 4.30964C3.68444 4.93477 3.33325 5.78261 3.33325 6.66667C3.33325 7.55072 3.68444 8.39857 4.30956 9.02369C4.93468 9.64881 5.78253 10 6.66659 10ZM14.9999 7.5C15.663 7.5 16.2988 7.23661 16.7677 6.76777C17.2365 6.29893 17.4999 5.66304 17.4999 5C17.4999 4.33696 17.2365 3.70107 16.7677 3.23223C16.2988 2.76339 15.663 2.5 14.9999 2.5C14.3369 2.5 13.701 2.76339 13.2322 3.23223C12.7633 3.70107 12.4999 4.33696 12.4999 5C12.4999 5.66304 12.7633 6.29893 13.2322 6.76777C13.701 7.23661 14.3369 7.5 14.9999 7.5Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    );
}
