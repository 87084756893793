export default function Info({color = "white", size = 22}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 22 22`} fill="none">
            <circle cx="11" cy="11" r="9.25" stroke={color}
                    strokeWidth="1.5"
            />
            <path
                d="M11.9136 8.81579V16H10.0741V8.81579H11.9136ZM11 6C11.2716 6 11.5062 6.07018 11.7037 6.21053C11.9012 6.34211 12 6.59211 12 6.96053C12 7.32018 11.9012 7.57018 11.7037 7.71053C11.5062 7.85088 11.2716 7.92105 11 7.92105C10.7202 7.92105 10.4815 7.85088 10.284 7.71053C10.0947 7.57018 10 7.32018 10 6.96053C10 6.59211 10.0947 6.34211 10.284 6.21053C10.4815 6.07018 10.7202 6 11 6Z"
                fill={color}/>
        </svg>
    );
}
