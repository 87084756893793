export default class DateOperations {

    getISODateByTimestamp(v: number): string {
        return this.getISODateByObj(new Date(v));
    }

    getTimestampByISODate(v: string): number {
        return this.getTimestampByISODateTime(v + 'T00:00:00');
    }

    getTimestampByISODateTime(v: string): number {
        return (new Date(v)).getTime();
    }

    getTimestampByFormattedDate(v: string): number {
        return this.getTimestampByISODate(this.getISODateByFormatted(v));
    }

    getISODateByFormatted(v: string): string {
        return v.split('.').reverse().join('-');
    }

    getTimestampByFormatted(value: string): number {
        const formattedDate = this.getISODateByFormatted(value.slice(0, 10));
        const formattedTime = value.slice(11);

        return Date.parse(`${formattedDate}T${formattedTime}`);
    }

    getISODateByObj(d: Date): string {
        let y: number | string = d.getFullYear();
        let m: number | string = d.getMonth() + 1;
        let i: number | string = d.getDate();

        if (m < 10) m = '0' + m;
        if (i < 10) i = '0' + i;

        return `${y}-${m}-${i}`;
    }

    getNumByISODate(v: string): number {
        return +(v.split('-').join(''));
    }

    getNowISODate(): string {
        return this.getISODateByObj(new Date());
    }

    getHoursAndMinutesByTimestamp(v: number): string {
        return this.getISOTimeByDateObj(new Date(v)).slice(0, 5);
    }

    getISOTimeByDateObj(d: Date): string {
        let h: number | string = d.getHours();
        let m: number | string = d.getMinutes();
        let s: number | string = d.getSeconds();

        if (h < 10) h = '0' + h;
        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;

        return `${h}:${m}:${s}`;
    }

    getISODateTimeByObj(d: Date): string {
        return this.getISODateByObj(d) + 'T' + this.getISOTimeByDateObj(d);
    }

    getNowISODateTime(): string {
        return this.getISODateTimeByObj(new Date());
    }

    getPrevISODate(): string {
        return this.getISOPrevDateByObj(new Date());
    }

    setPrevDateObj(value: Date): Date {
        value.setDate(value.getDate() - 1);

        return value;
    }

    getISOPrevDateByObj(d: Date): string {
        this.setPrevDateObj(d);

        return this.getISODateByObj(d);
    }

    getFormattedDateByISO(v: string): string {
        return v.split('-').reverse().join('.');
    }

    getTitleByISONowAndPrevDates(v: string, nowV: string, prevV: string): string {
        return nowV === v
            ? 'Сегодня'
            : (
                prevV === v
                    ? 'Вчера'
                    : this.getFormattedDateByISO(v)
            );
    }

    getTitleByISODate(v: string): string {
        const nowISODate: string = this.getNowISODate();
        const prevISODate: string = this.getPrevISODate();

        return this.getTitleByISONowAndPrevDates(v, nowISODate, prevISODate);
    }

    getFormattedDateStrByISODateArr(_v: string[], splitter: string = ' ~ '): string {
        let v: string = "";

        if (_v[0]) v += this.getFormattedDateByISO(_v[0]);
        if (_v[1]) v += splitter + this.getFormattedDateByISO(_v[1]);

        return v;
    }

    getFormattedDateAndTime(value: string, timeZoneOffset: number): string {
        if (!value) return '';

        const date = new Date(value);

        const offsetInMinutes = timeZoneOffset * 60;

        date.setMinutes(date.getMinutes() + offsetInMinutes);

        const formattedDate = this.getISODateByFormatted(date.toISOString().slice(0, 10));
        const dateString = this.getFormattedDateByISO(formattedDate);

        const formattedTime = date.toISOString().slice(11);
        const timeString = formattedTime.slice(0, 8);

        return `${dateString}, ${timeString}`;
    }

    getFormattedTime(value: Date): string {
        const timeString = value.toLocaleTimeString('ru');
        const timeParts = timeString.split(':');
        const hoursAndMinutes = timeParts.slice(0, 2).join(':');

        // .split('.')
        // .reverse()
        // .join('.');

        return `${hoursAndMinutes}`;
    }

    getTimeFromSeconds(seconds: number): any {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        const formattedTime: any = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return formattedTime;
    }

    getTimeInSeconds(time: string): number {
        const [hour, min] = time.split(":").map(Number);

        const formattedTime = (hour * 3600) + (min * 60);

        return formattedTime;
    }

    getDate (dateString: string): any {
        const date = new Date(dateString);

        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const day = date.getUTCDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    }

    getFormattedDateAndTimeByString(value: string): string {
        if (!value) return '';

        const formattedDate = this.getISODateByFormatted(value.slice(0, 10));
        const dateString = this.getFormattedDateByISO(formattedDate);

        const formattedTime = value.slice(11);
        const timeString = formattedTime.slice(0, 8);

        return `${dateString}, ${timeString}`;
    }
}
