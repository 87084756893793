import { FC, useEffect, useRef, useState } from "react";
import { ProblemsContent } from "../../components/questions/problemsContent";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useNavigate } from "react-router-dom";
import { MessengerContent } from "../../components/questions/messengerContent";
import DateOperations from "../../mixins/date-operation";
import { $getFeedbackList, $responseFeedback } from "../../api/requests/feedback";

export const Messenger: FC = () => {
    const navigate = useNavigate();
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const dateOperations = new DateOperations();

    const [problemsList, setProblemsList] = useState<any[]>([
        {
            id: 1,
            title: 'Проблемы со входом',
            text: 'Проблемы со входом',
            date: '2023-09-08T17:05:24.151Z',
            feedbackAnswer: {
                id: '',
                feedbackId: '',
                text: 'asdsadsa',
                createdAt: '',
                updatedAt: '',
            }
            // chat: [
            //     {
            //         key: 0,
            //         desc: 'Настройте действие и время, через которое оно произойдет после окончания времени сеанса клиента.Настройте действие и время, через которое оно произойдет после окончания времени сеанса клиента.Настройте действие и время, через которое оно произойдет после окончания времени сеанса клиента.Настройте действие и время, через которое оно произойдет после окончания времени сеанса клиента.',
            //         time: 3700,
            //         seen: true
            //     },
            //     {
            //         key: 1,
            //         desc: 'dasdsad',
            //         time: '4200',
            //         seen: true,
            //     },
            //     {
            //         key: 1,
            //         desc: 'Настройте действие и время, через которое оно произойдет после окончания времени сеанса клиента.',
            //         time: '4300',
            //         seen: false,
            //     },
            // ]
        },
        {
            id: 2,
            title: 'Проблемы со входом',
            text: 'Проблемы со входом',
            date: '2023-09-08T17:05:24.151Z'
        },
    ])

    const [chatData, setChatData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [activePanel, setActivePanel] = useState('messenger__right');

    const toggleActivePanel = () => {
        setActivePanel(activePanel === 'messenger__left' ? 'messenger__right' : 'messenger__left');
    };

    const handleSelect = (item: any) => {
        setChatData(item);
    }

    useEffect(() => init(), []);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    }, [problemsList])

    function onSaveAnswer(value: string, item: any) {
        setIsLoading(true);

        $responseFeedback(item.id, { text: value }).then(res => {
            setIsLoading(false);

            if (!res.id) return;

            init();
            handleSelect(item);
        })

        // const timeHours = dateOperations.getISOTimeByDateObj(new Date());
        //
        // const list = [...problemsList].map(i => {
        //     if ((i.id === item.id) && i.chat) {
        //         i.chat.push({
        //             key: 1,
        //             desc: value,
        //             time: dateOperations.getTimeInSeconds(timeHours),
        //             seen: false
        //         })
        //
        //         return i
        //     }
        //
        //     return i
        // })
        //
        // setProblemsList(list);

        // setTimeout(() => setIsLoading(false), 1500);
    }

    function init() {
        $getFeedbackList().then(res => {
            if (!res.result) return;

            const updateList = res.result.map(i => ({
                ...i,
                date: i.createdAt
            }))

            setProblemsList(updateList);
            setChatData(updateList[0]);
        })
    }

    return (
        <div className='messenger list-wrapper'>
            <div className='container-fluid h-100'>
                <div className='row h-100'>
                    <div className={`col-12 p-0 d-flex h-100 ${activePanel}-active`} onClick={toggleActivePanel}>
                        <div className='col-12 col-md-4 p-0 messenger__left'>
                            {/*<Button*/}
                            {/*    text={'Назад'}*/}
                            {/*    leftIcon={(*/}
                            {/*        <ArrowLeft color={'#3582F6'} />*/}
                            {/*    )}*/}
                            {/*    onClick={() => navigate('/questions')}*/}
                            {/*    className={'btn btn-icon'}*/}
                            {/*/>*/}

                            <div className='messenger__left-items'>
                                {problemsList.map((
                                    item: any,
                                    idx: number
                                ) => (
                                    <ProblemsContent
                                        key={`messenger-item-${idx}`}
                                        selectedItem={chatData}
                                        item={item}
                                        onClick={() => handleSelect(item)}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className='col-12 col-md-8 p-0 messenger__right'>
                            {(!isLoading && chatData) && (
                                <MessengerContent
                                    item={chatData}
                                    onSave={onSaveAnswer}
                                    scrollContainerRef={scrollContainerRef}
                                    isLoading={isLoading}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
