import { FC, MutableRefObject, useEffect, useState } from "react";
import CompleteDouble from "../../img/icon/CompleteDouble";
import OneClick from "../../img/icon/OneClick";
import SendIcon from "../../img/icon/SendIcon";
import DateOperations from "../../mixins/date-operation";
import Button from "../form/Button";
import Token from "../../img/icon/Token";

interface MessengerContentProps {
    item: any
    onSave: (val: string, item: any) => void
    scrollContainerRef: MutableRefObject<HTMLDivElement | null>
    isLoading: boolean
}

export const MessengerContent: FC<MessengerContentProps> = (
    {
        item,
        onSave,
        scrollContainerRef,
        isLoading = false
    }
) => {
    const dateOperations = new DateOperations();

    const [ textValue, setTextValue ] = useState( '');

    // const renderedMessage = item?.chat?.map((message: any, idx: number) => (
    //     <div
    //         key={ `chat-message-${ idx }` }
    //         className={ `messenger-chat__block ${ message.key == 0
    //             ? 'messenger-chat__response'
    //             : 'messenger-chat__message' }` }
    //     >
    //         <p>
    //             { message.desc }
    //         </p>
    //
    //         <div className='messenger-chat__block-bottom'>
    //             <p> { dateOperations.getTimeFromSeconds(message.time) }</p>
    //
    //             { message.seen
    //                 ? <CompleteDouble color={ '#3582F6' }/>
    //                 : <OneClick color={ '#3582F6' }/> }
    //         </div>
    //     </div>
    // ))

    useEffect(() => init(), [item]);

    function init() {
        setTextValue(item.feedbackAnswer?.text || '')
    }

    function onSaveAnswer() {
        if (!textValue.length || isLoading) return;

        onSave(textValue, item);
    }

    return (
        <div className='messenger-chat'>
            <div className='messenger-chat__header'>
                <img src={item?.user?.image || '/img/logo/logo-2.png'} alt=""/>

                <div className='messenger-chat__header-desc'>
                    <p className='messenger-chat__header-name'>{ item.user?.name }</p>

                    <p className='messenger-chat__header-problem'>{ item.title }</p>
                </div>
            </div>

            <div className='messenger-chat__content' ref={ scrollContainerRef }>
                <div className={ 'messenger-chat__block messenger-chat__response' }>
                    <p>
                        { item.text }
                    </p>
                </div>

                {/*<div className='messenger-chat__date'>*/}
                {/*    { dateOperations.getFormattedDateByISO(dateOperations.getDate(item.date)) }*/}
                {/*</div>*/}

                {/*{ renderedMessage }*/}
            </div>

            <div className='messenger-chat__footer'>
                <div className='messenger-chat__textField'>
                    <textarea
                        className={ 'form-control form-textarea' }
                        value={ textValue }
                        onChange={ (e) => setTextValue(e.target.value) }
                        placeholder={ 'Напишите ответ' }
                        rows={ 3 }
                        disabled={item.feedbackAnswer?.text}
                    ></textarea>
                </div>

                {!item.feedbackAnswer?.text && (
                    <Button
                        // loading={ isLoading }
                        text={ (
                            <SendIcon
                                width={ 30 }
                                height={ 80 }
                                color={ '#3582F6' }
                            />
                        ) }
                        className={ 'btn btn-icon' }
                        onClick={ onSaveAnswer }
                    />
                )}
            </div>
        </div>
    )
}
