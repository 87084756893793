import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import TextField from "../../components/form/TextField";
import Search from "../../img/icon/Search";
import DateOperations from "../../mixins/date-operation";
import TableContainer from "../../components/form/TableContainer";
import Button from "../../components/form/Button";
import Pagination from "../../components/form/Pagination";

import { $getNotificationList } from "../../api/requests/notification";
import RoleUsers from "../../mixins/role";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

export const NotificationPage: FC = () => {
    const dateOperations = new DateOperations();
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();
    const userData = useSelector(GetCurrentUserData);
    const timeZone = userData?.staffCenter?.timezone || 0;

    const isCreateByRole = roleUsers.getModelRoleByType('notification', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('notification', 'update');

    const [ notificationItems, setNotificationItems ] = useState<any[]>([]);
    const [ filterNotificationItems, setFilterNotificationItems ] = useState<any[]>([]);
    const [ notificationsResponseData, setNotificationsResponseData ] = useState<any[]>([]);
    const [ searchValue, setSearchValue ] = useState<string>('');
    const [ activePage, setActivePage ] = useState(1);
    const [ totalPages, setTotalPages ] = useState(1);

    const deviceTypeText = [ 'iOS', 'Android', 'Все' ];
    const statusNotificationText = [ 'В ожидании', 'В процессе', 'Завершен', 'Прерван' ];
    const typeText = [ 'Пуш', 'SMS', 'Всплывающее окно', 'Банер' ];

    const headerTable = [
        {
            key: 'title',
            title: 'Заголовок',
            width: '15%'
        },
        {
            key: 'text',
            title: 'Описание',
            width: '30%'
        },
        {
            key: 'deviceType',
            title: 'Тип устройства',
        },
        {
            key: 'isActive',
            title: 'Статус',
        },
        {
            key: 'status',
            title: 'Статус отправки уведомлений',
        },
        {
            key: 'type',
            title: 'Тип уведомления',
        },
        {
            key: 'sendAt',
            title: 'Дата и время отправки',
        }
    ];

    const groupByDate = (data: any[]) => {
        const groupedData: any[] = [];

        data.forEach((item) => {
            const existingGroup = groupedData.find((group) => group.date === item.sendAt);

            const obj = {
                ...item,
                sendAt: dateOperations.getFormattedDateAndTime(item.sendAt, 0),
                deviceType: deviceTypeText[item.deviceType],
                status: statusNotificationText[item.status],
                type: typeText[item.type],
                isActive: item.isActive ? 'Включен' : 'Выключен'
            }

            if (existingGroup) {
                existingGroup.list.push(obj);
            } else {
                groupedData.push({
                    date: item.sendAt,
                    list: [ obj ],
                });
            }
        });

        // Сортировка групп по дате, начиная с текущей даты
        const currentDate = new Date().toLocaleDateString();

        groupedData.sort((a, b) => {
            if (a.date === currentDate) return -1;
            if (b.date === currentDate) return 1;

            return -((new Date(a.date) as any) - (new Date(b.date) as any));
        });

        return groupedData;
    };

    useEffect(() => init(), [activePage]);

    function init() {
        $getNotificationList(
            {
                page: String(activePage)
            }
        ).then(res => {
            if (!res.result) return;

            const listUpdate = groupByDate(res.result);

            const totalPages = Math.ceil(res.count / 15);

            setTotalPages(res.totalPages || totalPages || 1);

            setNotificationsResponseData(res.result);
            setNotificationItems(listUpdate);
            setFilterNotificationItems(listUpdate);
        });
    }

    function onChangeSearchValue(value: string) {
        setSearchValue(value);

        const listUpdate = [ ...notificationItems ]
            .filter(i => i.list
                .some((_i: any) => _i.text
                    .toLowerCase()
                    .includes(value
                        .toLowerCase())));

        setFilterNotificationItems(listUpdate);
    }

    function onLeftClickTrContent(item: any) {
        if (!isUpdateByRole) return;

        const findItem = notificationsResponseData.find(i => i.id === item.id);

        navigate('edit', {
            state: {
                current: 'edit',
                data: findItem
            },
        })
    }

    return (
        <div className='logs list-wrapper'>
            <div className="booking__header">
                <h1>Уведомление</h1>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 logs-style'>
                        <div className='logs__header'>
                            <div className='logs__header-search'>
                                <TextField
                                    imgLeft={ <Search/> }
                                    placeholder={ 'Поиск уведомления' }
                                    value={ searchValue }
                                    onChangeValue={ onChangeSearchValue }
                                />
                            </div>

                            {isCreateByRole && (
                                <div className="notification__header-btn">
                                    <Button
                                        className={ 'btn btn-primary w-100' }
                                        text={ 'Добавить уведомление' }
                                        onClick={ () => navigate('create') }
                                    />
                                </div>
                            )}
                        </div>

                        { filterNotificationItems.length && filterNotificationItems.map(({date, list}, idx) => (
                            <div
                                className='logs__content'
                                key={ `logs-content-item-${ idx }` }
                            >
                                <h1 className='logs-title'>{ dateOperations.getFormattedDateByISO(dateOperations.getDate(date)) }</h1>

                                <div className='notification__content-items users__content__table'>
                                    <TableContainer
                                        header={ headerTable }
                                        content={ list }
                                        sortable={false}
                                        onLeftClickTrContent={ onLeftClickTrContent }
                                        isLastRightText={ false }
                                    />
                                </div>
                            </div>
                        )) || null }

                        <div className='logs__carousel'>
                            { filterNotificationItems.length && (
                                <Pagination
                                    setActivePage={ setActivePage }
                                    activePage={ activePage }
                                    pages={ totalPages }
                                />
                            ) || null }
                        </div>

                        { !notificationItems.length && (
                            <div className="program__block__item__appellation">
                                <p>
                                    Список уведомлений пуст
                                </p>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        </div>
    )
}
