export default function Safe({color= "white", size = 18}) {
    return (
        <svg  width={size} height={size} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="safe (1) 1">
                <path id="Vector"
                      d="M2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H14.25C14.6478 2.25 15.0294 2.40804 15.3107 2.68934C15.592 2.97064 15.75 3.35218 15.75 3.75V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25Z"
                      stroke={color}
                      strokeWidth="1.5"
                />
                <path id="Vector_2"
                      d="M13.5 10.5V7.5M9.375 7.125L10.125 6.375M5.625 7.125L4.875 6.375M4.875 11.625L5.625 10.875M10.125 11.625L9.375 10.875M1.5 6H2.25M1.5 4.5H2.25M2.25 12H1.5M2.25 13.5H1.5M7.5 11.25C6.90326 11.25 6.33097 11.0129 5.90901 10.591C5.48705 10.169 5.25 9.59674 5.25 9C5.25 8.40326 5.48705 7.83097 5.90901 7.40901C6.33097 6.98705 6.90326 6.75 7.5 6.75C8.09674 6.75 8.66903 6.98705 9.09099 7.40901C9.51295 7.83097 9.75 8.40326 9.75 9C9.75 9.59674 9.51295 10.169 9.09099 10.591C8.66903 11.0129 8.09674 11.25 7.5 11.25Z"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

