import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import { CenterGetZone, CenterPostZones, ZonesModel } from "../zone/interface";
import { CreateUserGroup, ListUsersRequestGroup, } from "./interface";

export const $getCurrentZone = (
    params?: CreateUserGroup,
    handler?: IApiHandlerParams
): Promise<CenterGetZone> => api
    .get('/center/zone', {
        request: { params },
        handler
    })

export const $getListZone = (
    data: ListUsersRequestGroup,
    handler?: IApiHandlerParams
): Promise<CenterPostZones> => api
    .post('/center/zone', data, {
        handler
    })

export const $getZone = (
    id: number,
    handler?: IApiHandlerParams
): Promise<CenterPostZones> => api
    .get('/center/zone/single/' + id, {
        handler
    })

export const $updateZone = (
    id: number,
    data: ListUsersRequestGroup,
    handler?: IApiHandlerParams
): Promise<CenterPostZones> => api
    .put('/center/zone/single/' + id, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    })

export const $deleteZone = (
    id: number,
    handler?: IApiHandlerParams
): Promise<ZonesModel> => api
    .delete('/center/zone/single/' + id, {
        // mock: require('./mocks/error.delete.json'),
        handler
    })

export const $getCurrentNoDeviceZone = (handler?: IApiHandlerParams): Promise<Array<ZonesModel>> => api
    .get('/center/zone/only_zones', {
        handler
    })
