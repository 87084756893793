import React, { FC } from "react";
import Moon from "../../img/icon/Moon";
import Percentage from "../../img/icon/Percentage";
import Vision from "../../img/icon/Vision";
import Coins from "../../img/icon/Coins";
import Person from "../../img/icon/Person";
import PersonCrown from "../../img/icon/PersonCrown";
import DateOperations from "../../mixins/date-operation";

interface TariffsListProps {
    item: any
}

export const TariffsList: FC<TariffsListProps> = ({
    item
}) => {
    const dateOperations: any = new DateOperations()

    const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

    const filteredDays = item.availableDays ? item.availableDays.sort((a: number, b: number) => a - b ) : ''

    const durationType: any[] = [
        {
            label: 'минут',
            value: 0
        },
        {
            label: 'часов',
            value: 1
        },
        // {
        //     label: 'часов',
        //     value: 2
        // },
        // {
        //     label: 'недель',
        //     value: 3
        // },
    ]

    const tariffType: any[] = [
        {
            label: 'Фиксированный',
            value: 0
        },
        // {
        //     label: 'Поминутный',
        //     value: 1
        // },
        {
            label: 'Пакетный',
            value: 2
        },
        // {
        //     label: 'Несгораемый',
        //     value: 3
        // },
    ]

    const findItemType: any = (
        arr: any,
        obj: any
    ) => {
        const findTariffType: any = arr.find((item: any) => item.value === obj)

        return findTariffType?.label
    }

    return (
        <>
            <h3 className='tariffs__block-title'>{ item.name }</h3>

            <div className='tariffs__block-header'>
                <div style={ {backgroundColor: item.background, border: `1px solid ${ item.buttonColor }`} }
                     className={`tariffsAdd__block-btn tariffsAdd__block-btn${item.type === 0 ? '-red' : '-blue'}`}>
                    { item.buttonIcon }
                    <p style={ {color: item.buttonColor} }>{ findItemType(tariffType, item.type) }</p>
                </div>
                { item.applyDiscount && (
                    <div className='tariffs__block-icon'><Percentage size={ 18 } color={ '#3582F6' } /></div>) }
                {/*{item.showForClient && ( <div className='tariffs__block-icon'><Vision size={18} color={'#3582F6'} /></div>)}*/ }
                {/*{item.showForOperator && (<div className='tariffs__block-icon'><Coins size={18} color={'#3582F6'} /></div>)}*/ }
            </div>

            <div className='tariffs__items'>
                    { item.prices.map((
                        item: any,
                        idx: number
                    ) => (
                        <div key={ `tariffs__item-zones${ idx }` } className='tariffs__items-zones'>
                            <div className='tariffs__items-left'>
                                <p className='tariffs__items-paragraph'>Зоны</p>

                                <h1 className='tariffs__items-title'>{ item.zone }</h1>
                            </div>

                            <div className='tariffs__items-right tariffs__items-center'>
                                <p className='tariffs__items-paragraph'>Цена</p>

                                <div className='tariffs__items-prices'>
                                    { item.priceForAll ?
                                        <p><Person color={ '#3582F6' } width={ 25 }
                                                   height={ 25 } /> { item.priceForAll } ₽
                                        </p>
                                        :
                                        (<div className='tariffs__items-lines'><Person color={ '#3582F6' } width={ 25 }
                                                                                       height={ 25 } />
                                                <span className='tariffs__items-line'></span>
                                            </div>
                                        ) }

                                    { item.priceForAll ?
                                        <p style={ {color: 'grey'} }><PersonCrown color={ 'rgba(53, 130, 246, 0.40)' }
                                                                                  width={ 32 }
                                                                                  height={ 32 } /> { item.priceForUsers } ₽
                                        </p>
                                        :
                                        (<div className='tariffs__items-lines'><PersonCrown color={ '#A414FF' }
                                                                                            width={ 32 }
                                                                                            height={ 32 } />
                                            <span className='tariffs__items-line'></span>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    )) }
            </div>

            <div className='seperator'></div>

            <div className='tariffs__items-duration'>
                <div className='tariffs__items-desc'>
                    <p className='tariffs__items-paragraph'>Длительность</p>

                    { item.duration ?
                        <h1 className='tariffs__items-title'>{ item.duration } { findItemType(durationType, item.durationType) }</h1>
                        :
                        <div className='tariffs__items-line'></div>
                    }
                </div>

                <div className='tariffs__items-right tariffs__items-center'>
                    <p className='tariffs__items-paragraph'>Длительность</p>
                    { item.availableDays ?
                        (
                            <div>
                                <div className='tariffs__items-days'>
                                    {item.availableDays ? filteredDays.map((day: any, idx: number) =>
                                        <span key={idx}>
                                            {days[day - 1]}{(filteredDays.length - 1) !== idx ? ',' : '' } &nbsp;
                                        </span>
                                    ): ''}
                                </div>
                                <p className='tariffs__items-time'>
                                    { dateOperations.getTimeFromSeconds(item.availableFrom) } - { dateOperations.getTimeFromSeconds(item.availableTo) }
                                </p>
                            </div>
                        ) :
                        (
                            <div className='tariffs__items-line'></div>
                        )
                    }
                </div>
            </div>
        </>
    )
}
