import { FC } from 'react';
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

import NavItem from '../NavItem';
import { NavItemList } from "./Navigation";

import Tariff from "../../img/icon/Tariff";
import Content from "../../img/icon/Content";
import Report from "../../img/icon/Report";
import Person from "../../img/icon/Person";
import Logs from "../../img/icon/Logs";
import Setting from "../../img/icon/Setting";
import Discounts from "../../img/icon/Discounts";
import Discipline from "../../img/icon/Discipline";
import Notification from "../../img/icon/Notification";
import PromoCodes from "../../img/icon/PromoCodes";
import Loyalty from "../../img/icon/Loyalty";
import Stock from "../../img/icon/Stock";
import NewsHorn from "../../img/icon/NewsHorn";
import NewsMenu from "../../img/icon/NewsMenu";
import Search from "../../img/icon/Search";
import Avatars from "../../img/icon/Avatars";
import Comment from "../../img/icon/Comment";
import GameNav from "../../img/icon/GameNav";

const navigationItems: NavItemList[] = [
    {
        link: '/tariffs',
        icon: (<Tariff/>),
        title: 'Тарифы',
        key: 'tariffs'
    },
    // {
    //     link: '',
    //     icon: (<Loyalty/>),
    //     title: 'Система лояльности',
    //     key: 'discount',
    //     dropdownList: [
    //         {
    //             link: '/discounts',
    //             icon: (<Search/>),
    //             title: 'Скидки',
    //             key: 'discount'
    //         },
    //         {
    //             link: '/promo-code',
    //             icon: (<PromoCodes/>),
    //             title: 'Промокоды',
    //             key: 'promoCode'
    //         }
    //     ]
    // },
    {
        link: '',
        icon: (<Content/>),
        title: 'Геймификация',
        key: ['apps', 'achievements', 'disciplines'],
        dropdownList: [
            {
                link: '/programsGame',
                icon: (<GameNav/>),
                title: 'Игры',
                key: 'apps'
            },
            {
                link: '/achievements',
                icon: (<Discounts/>),
                title: 'Достижения',
                key: 'achievements'
            },
            {
                link: '/discipline',
                icon: (<Discipline/>),
                title: 'Дисциплины',
                key: 'disciplines'
            },
        ]
    },
    {
        link: '',
        icon: (<NewsMenu/>),
        title: 'Новости',
        key: ['news', 'promotions'],
        dropdownList: [
            {
                link: '/news',
                icon: (<NewsHorn/>),
                title: 'Новости',
                key: 'news',
            },
            // {
            //     link: '/stocks',
            //     icon: (<Stock/>),
            //     title: 'Акции',
            //     key: 'promotions',
            // },
        ]
    },
    {
        link: '/reports/shift',
        icon: (<Report/>),
        title: 'Отчёты',
        key: 'reports',
    },
    {
        link: '/avatars',
        icon: (<Avatars/>),
        title: 'Аватары',
        key: 'avatars',
    },
    // {
    //     link: '/direction',
    //     icon: (<Avatars/>),
    //     title: 'Направление',
    //     key: 'direction',
    // },
    {
        link: '/person',
        icon: (<Person/>),
        title: 'Сотрудники',
        key: 'person',
    },
    {
        link: '/logs',
        icon: (<Logs/>),
        title: 'Логи',
        key: 'logs',
    },
    {
        link: '/notifications',
        icon: (<Notification size={ 20 }/>),
        title: 'Уведомления',
        key: 'notification',
    },
    {
        link: '/questions',
        icon: (<img src={ '/img/icon/form/chat-support.svg' } alt=''/>),
        title: 'FAQ',
        key: 'faq',
    },
    {
        link: '/feedback',
        icon: (<Comment/>),
        title: 'Обратная связь',
        key: 'feedback',
    },
    {
        link: '/settings',
        icon: (<Setting/>),
        title: 'Настройки',
        key: 'convert',
    }
];

const NavigationSecond: FC = () => {
    const userData = useSelector(GetCurrentUserData);

    if (!userData) return null;

    const permissionsList = (userData && userData.permissions) && Object.keys(userData.permissions) || [];

    return (
        <div className="navigation">
            { navigationItems.map((item, index) => {
                if ((permissionsList && item.key) && (permissionsList.includes(item.key) || Array.isArray(item.key))) {
                    if (Array.isArray(item.key)) {
                        const hideBlock = userData?.permissions ? item.key.every(key => userData?.permissions[key].read === false) : true;

                        if (hideBlock) return null;
                    } else if (!userData?.permissions[item.key].read) return null;
                }

                return (
                    <NavItem
                        key={ `nav-${ index }` }
                        link={ item.link }
                        icon={ item.icon }
                        title={ item.title }
                        dropdownList={ item.dropdownList }
                    />
                )
            }) }
        </div>
    );
}

export default NavigationSecond;
