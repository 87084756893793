import React, { FC, useEffect, useState } from "react";
import ArrowLeft from "../../img/icon/ArrowLeft";
import Button from "../../components/form/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Info from "../../img/icon/Info";
import TextField from "../../components/form/TextField";
import { ToggleBlock } from "../../components/form/ToggleBlock";
import TransformObjArray from "../../mixins/transform-obj-array";
import { $disciplineCreate, $updateDiscipline } from "../../api/requests/discipline";
import ImageField from "../../components/form/ImageField";
import ImageToBase64 from "../../mixins/image-to-base64";

const DisciplineAddPage: FC = () => {
    const isMobile = window.innerWidth < 465
    const location = useLocation();
    const navigate = useNavigate();

    const state: any = location.state;
    const isEditPageDiscipline = state?.current === 'edit';

    const editedData = state?.data || {};

    const transformObjArray = new TransformObjArray();
    const imageToBase64 = new ImageToBase64();

    const [ imageStatic, setImageStatic ] = useState<any>(null);

    const [ isLoading, setIsLoading ] = useState(false);

    const [ list, setList ] = useState<any[]>([
        {
            key: 'name',
            placeholder: 'Заголовок',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            field: true
        },
        {
            key: 'subName',
            placeholder: 'Доп. дисциплина',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            field: true
        },
        {
            key: 'isActive',
            title: 'Статус',
            value: true,
            setValue: (value: boolean, key: string) => onChangeSetValue(value, key),
            checkbox: true
        }
    ]);

    useEffect(() => init(), []);

    function init() {
        const listUpdate = [ ...list ].map(i => {
            if (editedData && Object.keys(editedData).includes(i.key)) i.value = editedData[i.key];

            return i;
        });

        if (editedData.image) setImageStatic(editedData.image);

        setList(listUpdate)
    }

    function onChangeSetValue(value: string | boolean, key: string) {
        const listUpdates = [ ...list ].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setList(listUpdates);
    }

    function onClickBack() {
        navigate('/discipline');
    }

    function onClickEnter() {
        setIsLoading(true);

        const requestData = {
            ...transformObjArray.getRequestObj(list),
            image: imageToBase64.getImageCheckValid(imageStatic)
        };

        if (isEditPageDiscipline) return onEditData(requestData);

        onCreateSave(requestData)
    }

    function onCreateSave(request: any) {
        $disciplineCreate(request).then(res => {
            setIsLoading(false);

            if (!res.id) return;
            onClickBack();
        })
    }

    function onEditData(request: any) {
        if (!editedData.id) return setIsLoading(false);

        $updateDiscipline(editedData.id, request).then(res => {
            setIsLoading(false);

            if (!res.id) return;

            onClickBack();
        })
    }

    return (
        <div className="discipline__content">
            <div className="discipline__content__back">
                <Button
                    className={ 'btn' }
                    leftIcon={ (
                        <ArrowLeft color={ '#3582F6' }/>
                    ) }
                    text={!isMobile && 'Назад' }
                    onClick={ onClickBack }
                />

                {isMobile && (
                    <h3>{ isEditPageDiscipline ? 'Редактирование' : 'Создание' } дисциплины</h3>
                )}
            </div>

            <div className="discipline__content__form">
                <div className="discipline__content__form__left">
                    {!isMobile && (
                        <h3>{ isEditPageDiscipline ? 'Редактирование' : 'Создание' } дисциплины</h3>
                    )}


                    { list.map((item, idx) => (
                        <div className="discipline__content__form__left__block"
                             key={ `discipline__content__form__left__block${ idx }` }
                        >
                            { item.field && (
                                <TextField
                                    placeholder={ item.placeholder }
                                    value={ item.value }
                                    onChangeValue={ (value) => item.setValue(value, item.key) }
                                />
                            ) }

                            { item.checkbox && (
                                <ToggleBlock
                                    desc={ item.title }
                                    isChecked={ item.value }
                                    handleChange={ (i) => item.setValue(i, item.key) }
                                />
                            ) }
                        </div>
                    )) }

                    <div className="discipline__content__form__left__bottom">
                        <Button
                            loading={ isLoading }
                            className={ 'btn btn-primary' }
                            text={ isEditPageDiscipline ? 'Обновить' : 'Добавить' }
                            onClick={ onClickEnter }
                        />
                    </div>
                </div>

                <div className="discipline__content__form__right">
                    <div className="discipline__content__form__right__text">
                        <h3>Обложка 540х540</h3>

                        {/*<Info*/}
                        {/*    size={ 16 }*/}
                        {/*    color={ '#3582F6' }*/}
                        {/*/>*/}
                    </div>

                    <div className="discipline__content__form__right__file">
                        <ImageField
                            label={ 'Статичная' }
                            upload={ imageStatic }
                            setUpload={ setImageStatic }
                        />

                        {/*<ImageField*/ }
                        {/*    label={'Анимация'}*/ }
                        {/*    upload={null}*/ }
                        {/*    setUpload={file => file}*/ }
                        {/*/>*/ }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DisciplineAddPage;

