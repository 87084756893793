export default function Token ({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 3C6.14 3 3 6.14 3 10C3 13.86 6.14 17 10 17C13.86 17 17 13.86 17 10C17 6.14 13.86 3 10 3ZM10 16C9.28 16 8.59 15.87 7.95 15.63L8.9 14.83C8.96 14.78 8.99 14.71 8.99 14.63V12.14L7.11 13.71C7.07 13.76 7 13.73 7 13.66V11.33C7 11.25 7.03 11.18 7.09 11.13L8.99 9.53V7.08C8.99 7.02 8.92 6.98 8.87 7.02L7 8.6C5.33 10 5 10.93 5 12.89V13.31C4.37 12.36 4 11.23 4 10C4 6.69 6.69 4 10 4C10.72 4 11.41 4.13 12.05 4.37L11.1 5.17C11.03 5.22 11 5.29 11 5.37V7.86L12.88 6.29C12.93 6.24 13 6.27 13 6.34V8.67C13 8.75 12.97 8.82 12.91 8.87L11.01 10.47V12.92C11.01 12.98 11.08 13.02 11.13 12.98L13 11.4C14.67 10 15 9.07 15 7.11V6.69C15.63 7.64 16 8.78 16 10C16 13.31 13.31 16 10 16ZM11 10.47L9 12.15V9.54L11 7.86V10.47Z"
				fill={color}
			/>
		</svg>
	);
}
