import { FC, useEffect, useState } from 'react';
import Button from "../../components/form/Button";
import { useNavigate } from "react-router-dom";
import TournamentList from "./tournaments";
import { $deleteTournaments, $getTournamentsList } from "../../api/requests/tournaments";
import { CenterTournamentsDataModel } from "../../api/requests/tournaments/interface";
import RoleUsers from "../../mixins/role";

const GameTournament: FC = () => {
    const isMobile = window.innerWidth <= 465;
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('tournaments', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('tournaments', 'update');

    const headerBtn = [ 'Все', 'С рейтингом выше 5' ];

    const [ list, setList ] = useState<CenterTournamentsDataModel[]>([]);

    const [ filterType, setFilterType ] = useState(-1);

    const handleDelete = (itemToRemove: any) => {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteTournaments(itemToRemove.id).then(res => {
            if (!res.id) return;

            const updatedItems = list.filter((list: any) => list !== itemToRemove);

            setList(updatedItems);
        })
    }

    const handleFilter = (type: any) => {
        setFilterType(type);
    };

    const filteredItems = list.filter((item: any) => {
        return filterType === -1 || item.type === filterType;
    });

    useEffect(() => init(), [])

    function init() {
        $getTournamentsList().then(res => {
            if (!res.result) return;

            setList(res.result)
        })
    }

    return (
        <div className="game list-wrapper">
            <div className="booking__header">
                <h1>Турниры</h1>
            </div>
            <div className="game__header">
                <div className="game__header__left">
                    {/*{ headerBtn.map((title: string, index: number) => (*/}
                    {/*    <Button*/}
                    {/*        key={ index }*/}
                    {/*        className={ `btn btn-primary ${ (index - 1) === filterType */}
                    {/*            ? 'achievements__header-active' */}
                    {/*            : 'achievements__header-nonActive' }` }*/}
                    {/*        text={ title }*/}
                    {/*        onClick={ () => (handleFilter(index - 1)) }*/}
                    {/*    />*/}
                    {/*)) }*/}
                </div>

                <div className='game__header-right'>
                    {isCreateByRole && (
                        <Button
                            className={ 'btn btn-primary' }
                            text={ 'Создать турнир' }
                            onClick={ () => (navigate('/tournaments/create')) }
                        />
                    )}
                </div>
            </div>

            <div className="game__content">
                {!!filteredItems.length && filteredItems.map((item, idx) => (
                    <div className={ ` ${isMobile && 'col-6'} game__content__pro` }
                         key={ `game-item-list-pro-${ idx }` }
                    >
                        <TournamentList
                            onDelete={ () => (handleDelete(item)) }
                            item={ item }
                            isShowEdit={isUpdateByRole}
                        />
                    </div>
                )) }
            </div>

            {!filteredItems.length && (
                <div className="program__block__item__appellation">
                    <p>
                        Список турниров пуст
                    </p>
                </div>
            )}
        </div>
    );
}

export default GameTournament;
