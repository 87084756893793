import { FC } from "react";
import DatePickerField from "../../form/DatePickerField";
import Button from "../../form/Button";

interface FilterDateProps {
    dateStart: any
    dateEnd: any
    setDateStart: any
    setDateEnd: any
    init: () => void
}

export const FilterDate:FC<FilterDateProps>  = ({
    dateStart,
    dateEnd,
    setDateStart,
    setDateEnd,
    init
}) => {
    return (
        <div className='reports__filter'>
            <div className='reports__filter-dates'>
                <DatePickerField
                    className='reports-date'
                    isIcon
                    startDate={ dateStart }
                    onChangeDate={ (date) => {
                        setDateStart(date)
                    } }
                />

                <span className='reports__filter-line'></span>

                <DatePickerField
                    className='reports-date'
                    isIcon
                    startDate={ dateEnd }
                    onChangeDate={ (date) => {
                        setDateEnd(date)
                    } }
                />
            </div>

            <div className='reports__filter-buttons'>
                <Button
                    text={ 'Применить' }
                    className='btn btn-primary'
                    onClick={ init }
                />

                {/*<FilterSelect*/}
                {/*    placeholder='Выбор'*/}
                {/*    options={ [ {label: 'first', value: 0}, {label: 'second', value: 1} ] }*/}
                {/*    onChange={ () => ('select') }*/}
                {/*    className='reports__filter-selection'*/}
                {/*/>*/}
            </div>
        </div>
    )
}