import { FC, ReactNode, useEffect, useRef, useState } from 'react';

interface PopupProps {
    eventProps: MouseEvent | null;
    onClose: () => void;
    children: ReactNode;
    isGridTournaments?: boolean
}

const PopupDialog: FC<PopupProps> = (
    {
        eventProps,
        onClose,
        children,
        isGridTournaments = false,
    }
) => {
    const popupRef = useRef(null);

    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !(popupRef.current as any).contains(event.target as Node)
            ) onClose();
        };

        document.addEventListener('click', handleDocumentClick);

        return () => document.removeEventListener('click', handleDocumentClick);
    }, []);

    useEffect(() => {
        if (eventProps) {
            eventProps.preventDefault();

            const isRight = (window.innerWidth - eventProps.clientX) < 325;
            const calculateX = eventProps.clientX - (window.innerWidth * 0.25) - 10;

            const xPosMinus = isGridTournaments ? (calculateX + 25) : calculateX;
            const yPosMinus = isGridTournaments ? (-20) : 160;

            const xPos = (isRight ? (calculateX - (isGridTournaments ? 200 : 385)) : xPosMinus);
            const yPos = ((eventProps.clientY - 73) - yPosMinus);

            setPopupPosition({ x: xPos, y: yPos });
        }
    }, [eventProps]);

    if (!popupPosition.x && !popupPosition.y) return null;

    return (
        <div
            ref={popupRef}
            style={{
                left: popupPosition.x,
                top: popupPosition.y
            }}
            className={'popup__container'}
        >
            {children}
        </div>
    );
};

export default PopupDialog;
