export default function NewsMenu({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.99984 4.16667C10.2209 4.16667 10.4328 4.07887 10.5891 3.92259C10.7454 3.76631 10.8332 3.55435 10.8332 3.33333C10.8332 3.11232 10.7454 2.90036 10.5891 2.74408C10.4328 2.5878 10.2209 2.5 9.99984 2.5C9.77882 2.5 9.56686 2.5878 9.41058 2.74408C9.2543 2.90036 9.1665 3.11232 9.1665 3.33333C9.1665 3.55435 9.2543 3.76631 9.41058 3.92259C9.56686 4.07887 9.77882 4.16667 9.99984 4.16667Z"
				fill={color}
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3332 0.833252C13.3332 0.833252 14.5832 1.66659 14.5832 3.33325C14.5832 4.99992 13.3332 5.83325 13.3332 5.83325M6.6665 0.833252C6.6665 0.833252 5.4165 1.66659 5.4165 3.33325C5.4165 4.99992 6.6665 5.83325 6.6665 5.83325M5.83317 19.1666L6.759 15.8333M6.759 15.8333L7.9165 11.6666M6.759 15.8333H13.2407M14.1665 19.1666L13.2407 15.8333M13.2407 15.8333L12.0832 11.6666M12.0832 11.6666L9.99984 4.16658L7.9165 11.6666M12.0832 11.6666H7.9165"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
