export default function Vr({ color = "white", size = 20 }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 20 20`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1.6665 12.5V7.5C1.6665 6.17392 2.19329 4.90215 3.13097 3.96447C4.06865 3.02678 5.34042 2.5 6.6665 2.5H13.3332C14.6593 2.5 15.931 3.02678 16.8687 3.96447C17.8064 4.90215 18.3332 6.17392 18.3332 7.5V12.5C18.3332 13.8261 17.8064 15.0979 16.8687 16.0355C15.931 16.9732 14.6593 17.5 13.3332 17.5H6.6665C5.34042 17.5 4.06865 16.9732 3.13097 16.0355C2.19329 15.0979 1.6665 13.8261 1.6665 12.5Z"
				stroke={color}
				strokeWidth="1.25"
			/>
			<path
				d="M10.8332 12.9166V10.5833M10.8332 10.5833V7.08325H13.214C13.809 7.08325 14.9998 7.08325 14.9998 8.83325C14.9998 10.5833 13.809 10.5833 13.214 10.5833M10.8332 10.5833H13.214M13.214 10.5833L14.9998 12.9166M9.1665 7.08325L6.6665 12.9166L4.1665 7.08325"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
