import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import {
    DashboardGetStatisticSessionsByPeriod,
    DashboardMainStatistic,
    DashboardStatisticSessionDashboardStatisticSession,
    StatisticUser,
    ShiftStatistic,
    StatisticAmount, StatisticResponseSeasonsData, StatisticResponseRatingData
} from "./interface";

export const $getStatisticSessions = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<DashboardStatisticSessionDashboardStatisticSession> => api
    .post('/center/statistic/sessions', data, {
        handler
    })

export const $getStatisticUsers = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<StatisticUser[]> => api
    .post('/center/statistic/users', data, {
        handler
    })

export const $getStatisticFc = (data: { cityId: string }, handler?: IApiHandlerParams): Promise<any[]> => api
    .post('/center/statistic/centers', data,{
        handler
    })

export const $getStatisticDashboard = (handler?: IApiHandlerParams): Promise<DashboardMainStatistic> => api
    .get('/center/statistic/dashboard',{
        handler
    })

export const $getCityAvailable = (handler?: IApiHandlerParams): Promise<any[]> => api
    .get('/city/available', {
        handler
    })

export const $getSearchCity = (params: { search: string }, handler?: IApiHandlerParams): Promise<any[]> => api
    .get('/city/search', {
        request: {params},
        handler
    })

export const $getStatisticShift = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<ShiftStatistic[]> => api
    .post('/center/statistic/shift', data, {
        handler
    })

export const $getStatisticRevenue = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<StatisticAmount[]> => api
    .post('/center/statistic/revenue', data, {
        handler
    })

export const $getStatisticSeasons = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<StatisticResponseSeasonsData[]> => api
    .post('/center/statistic/seasons', data,{
        handler
    })

export const $getStatisticSpendHours = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<StatisticResponseSeasonsData[]> => api
    .post('/center/statistic/spendHours', data,{
        handler
    })

export const $getStatisticVisitorCount = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<StatisticResponseSeasonsData[]> => api
    .post('/center/statistic/visitorCount', data,{
        handler
    })

export const $getStatisticAverageHours = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<StatisticResponseSeasonsData[]> => api
    .post('/center/statistic/averageHours', data,{
        handler
    })

export const $getStatisticRating = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<StatisticResponseRatingData[]> => api
    .post('/center/statistic/rating', data,{
        handler
    })

export const $getStatisticSingleRating = (data: DashboardGetStatisticSessionsByPeriod, handler?: IApiHandlerParams): Promise<StatisticResponseRatingData[]> => api
    .post('/center/statistic/rating/single', data,{
        handler
    })