import React, { FC, useEffect, useState } from "react";
import TextField from "../form/TextField";
import DialogContainer from "../dialog/DialogContainer";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import { $getListZone, $updateZone } from "../../api/requests/zone";
import ImageField from "../form/ImageField";
import FilterSelect from "../FilterSelect";
import ImageToBase64 from "../../mixins/image-to-base64";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

interface EditGroupDialogProps {
    isShow: boolean
    setIsShow: (item?: any) => void
    item: any
    type: string
}

const EditGroupDialog: FC<EditGroupDialogProps > = (
    {
        isShow,
        setIsShow,
        item,
        type
    }
) => {
    if (!item) return null;

    const imageToBase64 = new ImageToBase64();

    const isAdmin = useSelector(GetCurrentUserData)?.role === 1;

    const [ name, setName ] = useState(item.name || '');
    const [ position, setPosition ] = useState(item.position || 0);
    const [ monthValue, setMonthValue ] = useState<any>(null);
    const [ image, setImage ] = useState<any>(item.image || null);

    const dateMonthList = [
        {
            label: 'Январь',
            value: 1
        },
        {
            label: 'Февраль',
            value: 2
        },
        {
            label: 'Март',
            value: 3
        },
        {
            label: 'Апрель',
            value: 4
        },
        {
            label: 'Май',
            value: 5
        },
        {
            label: 'Июнь',
            value: 6
        },
        {
            label: 'Июль',
            value: 7
        },
        {
            label: 'Август',
            value: 8
        },
        {
            label: 'Сентябрь',
            value: 9
        },
        {
            label: 'Октябрь',
            value: 10
        },
        {
            label: 'Ноябрь',
            value: 11
        },
        {
            label: 'Декабрь',
            value: 12
        },
    ];

    const typeDevice = [
        { value: 0, label: 'ПК', type: 'comp' },
        { value: 1, label: 'Консоль', type: 'console' },
        { value: 2, label: 'VR', type: 'vr' },
        { value: 3, label: 'Спортивные зоны', type: 'open-area' }
    ];

    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    const isFormValid = () => {
        return !name.length || !item || !item.id;
    }

    useEffect(() => init(), [])

    function init() {
        if (item) {
            const newMonthList = dateMonthList.filter(i => item.months && item.months.includes(i.value));

            setMonthValue(item.months ?newMonthList : null);
        }
    }

    function onClickSave() {
        if (isFormValid()) return;

        setIsLoadingSave(true);

        const findTypeItem = typeDevice.find(i => i.type === type)

        const requestData = {
            name,
            // position: +position,
            type: findTypeItem ? findTypeItem.value : 0,
            months: monthValue && monthValue.map((i: any) => i.value),
            image: imageToBase64.getImageCheckValid(image)
        }

        $updateZone(item.id, requestData).then(i => {
            if (!i) return;

            setIsLoadingSave(false);

            setIsShow(true);
        })
    }

    return (
        <DialogContainer
            isOpen={isShow}
            closeModal={setIsShow}
            label={'Редактирование'}
            closeIcon={<Close/>}
        >
            {isAdmin && (
                <div className="edit__stocks__right-content">
                    <ImageField
                        label={ '150x150' }
                        upload={ image }
                        setUpload={ setImage }
                    />
                </div>
            )}

            <div className={'create__group'}>
                <TextField
                    label={ 'Название' }
                    imgRight={(
                        <p>{`${name.length}/100`}</p>
                    )}
                    value={ name }
                    onChangeValue={ setName }
                    maxLength={100}
                />

                {/*<TextField*/}
                {/*    type={'number'}*/}
                {/*    label={ 'Позиция' }*/}
                {/*    value={ position }*/}
                {/*    onChangeValue={ setPosition }*/}
                {/*/>*/}

                <div>
                    <FilterSelect
                        isMulti
                        className={ '' }
                        placeholder={ 'Какие месяцы доступны' }
                        value={ monthValue }
                        options={ dateMonthList }
                        onChange={ setMonthValue }
                    />
                </div>

                <Button
                    loading={isLoadingSave}
                    text={ 'Сохранить' }
                    onClick={ onClickSave }
                    className={ 'btn btn-primary w-100 justify-content-center' }
                />
            </div>
        </DialogContainer>
    )
}

export default EditGroupDialog;
