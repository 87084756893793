import { FC, useState } from "react";
import TextField from "../form/TextField";
import DialogContainer from "../dialog/DialogContainer";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import { $getListZone } from "../../api/requests/zone";
import Setting from "../../img/icon/Setting";

interface AllGroupsDialogProps {
    isShow: boolean
    setIsShow: (item?: any) => void
    onClickSelect: (item?: any) => void
    onClickSetting?: any
    btnList: any[]
    activeNameBtnName: string[]
    textBtn?: string
    settingsBtn?: boolean
    onMouseEnter?: any
    onMouseLeave?: any
    isIconVisible?: any
    isUpdateByRole?: any
}

const AllGroupsDialog: FC<AllGroupsDialogProps > = (
    {
        isShow,
        setIsShow,
        btnList,
        onClickSelect,
        activeNameBtnName,
        textBtn = 'групп',
        onClickSetting,
        settingsBtn,
        onMouseEnter,
        onMouseLeave,
        isIconVisible,
        isUpdateByRole
    }
) => {

    const [name, setName] = useState('');

    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    const isFormValid = () => {
        return !name.length;
    }

    function onClickSave() {
        if (isFormValid()) return;

        setIsLoadingSave(true);

        $getListZone({ name }).then(i => {
            if (!i) return;

            setIsLoadingSave(false);

            setIsShow(true);
        })
    }

    return (
        <DialogContainer
            isOpen={isShow}
            closeModal={setIsShow}
            label={`Список ${textBtn}`}
            closeIcon={<Close/>}
            widthProps={848}
        >
            <div className={'computers__header computers__dialog__all-groups'}>
                {btnList.map((i, idx) => {
                    if (idx < 1) return null;

                    const isMobile = !!idx && (window.innerWidth <= 768);

                    return (
                        <Button
                            onMouseEnter={ () => onMouseEnter && onMouseEnter(idx)}
                            onMouseLeave={ () => onMouseLeave && onMouseLeave(idx) }
                            key={`computers-btn-list-${idx}`}
                            text={i.name}
                            onClick={() => onClickSelect(i)}
                            className={`btn btn-link ${(activeNameBtnName.includes(i)) && 'active'}`}
                            rightIcon={
                                (settingsBtn && ((isIconVisible && isIconVisible[idx]) && (isIconVisible && !isIconVisible[0]) && isUpdateByRole) || isMobile) && (
                                    <div
                                        className='news__header-edit'
                                        style={ {visibility: ((isIconVisible && isIconVisible[idx]) || isMobile) ? 'visible' : 'hidden'} }
                                        onClick={ (e) => onClickSetting(true, i, e) }
                                    >
                                        <Setting size={ 14 } color={ '#3582F6' }/>
                                    </div>)
                            }
                        />
                    )
                })}
            </div>
        </DialogContainer>
    )
}

export default AllGroupsDialog;
