import { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";

type DateInterface = Date | null;

interface DatePickerProps {
	startDate?: DateInterface
	onChangeDate: (date: Date) => void
	disabled?: boolean
	className?: string
	minTime?: any
	maxTime?: any
}

const DatePickerTimeField: FC<DatePickerProps> = (
	{
		startDate,
		onChangeDate,
		disabled,
		className = '',
		minTime,
		maxTime
	}
) => {
	const dateStart = (date: any): DateInterface => {
		if (typeof date === 'string') return new Date(date);

		return startDate || null
	};

	const [ startDateLocal, setStartDate ] = useState<DateInterface>(dateStart(startDate));

	const onDateRange = (value: Date | null) => {
		onChangeDate(value || new Date())
	}

	let handleDateChangeRaw = (e: { preventDefault: () => void; }) => {
		e.preventDefault();
	}

	useEffect(() => {
		if (startDate) setStartDate(dateStart(startDate))
	}, [ startDate ]);

	return (
		<div className={ `custom-calendar ${ className }` }>
			<div className="icon-wrapper">
				<img src={ '/img/icon/form/clock.svg' }/>
			</div>

			<div className="picker-wrapper iconLeft">
				<DatePicker
					locale={ ru }
					selected={ startDateLocal }
					onChange={ (date) => {
						onDateRange(date);

						setStartDate(date);
					} }
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={ 30 }
					timeCaption="Время"
					timeFormat="HH:mm"
					dateFormat="HH:mm"
					disabled={ disabled }
					onChangeRaw={ handleDateChangeRaw }
					minTime={ minTime }
					maxTime={ maxTime }
				/>
			</div>
		</div>
	)
}

export default DatePickerTimeField;
