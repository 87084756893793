export default function ElectroOn({color= "#70D584", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M1.66699 12.5V7.5C1.66699 6.17392 2.19378 4.90215 3.13146 3.96447C4.06914 3.02678 5.34091 2.5 6.66699 2.5H13.3337C14.6597 2.5 15.9315 3.02678 16.8692 3.96447C17.8069 4.90215 18.3337 6.17392 18.3337 7.5V12.5C18.3337 13.8261 17.8069 15.0979 16.8692 16.0355C15.9315 16.9732 14.6597 17.5 13.3337 17.5H6.66699C5.34091 17.5 4.06914 16.9732 3.13146 16.0355C2.19378 15.0979 1.66699 13.8261 1.66699 12.5Z"
                      stroke={color}
                      strokeWidth="1.25"
                />
                <path id="Vector_2" d="M9.72217 6.6665L8.33301 9.99984H11.6663L10.2772 13.3332"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}


