export default function Pc({ color = "white", size = 20 }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 20 20`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.8335 18.3333H14.1668"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.6665 14.1667V3.33341C1.6665 2.89139 1.8421 2.46746 2.15466 2.1549C2.46722 1.84234 2.89114 1.66675 3.33317 1.66675H16.6665C17.1085 1.66675 17.5325 1.84234 17.845 2.1549C18.1576 2.46746 18.3332 2.89139 18.3332 3.33341V14.1667C18.3332 14.6088 18.1576 15.0327 17.845 15.3453C17.5325 15.6578 17.1085 15.8334 16.6665 15.8334H3.33317C2.89114 15.8334 2.46722 15.6578 2.15466 15.3453C1.8421 15.0327 1.6665 14.6088 1.6665 14.1667Z"
				stroke={color}
				strokeWidth="1.25"
			/>
		</svg>
	);
}
