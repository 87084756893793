export default function Star({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="star (3) 1">
                <g id="Group 719">
                    <path id="Vector"
                          d="M16.6482 7.08989L12.4686 6.70225L10.8083 2.84699C10.6921 2.57704 10.3094 2.57704 10.1931 2.84699L8.53287 6.70222L4.35329 7.08989C4.06062 7.11702 3.94236 7.48104 4.16315 7.67501L7.31666 10.4453L6.39378 14.5401C6.32915 14.8269 6.63882 15.0518 6.89152 14.9018L10.5007 12.7587L14.11 14.9018C14.3627 15.0519 14.6723 14.8269 14.6077 14.5401L13.6848 10.4453L16.8383 7.67501C17.0591 7.48104 16.9408 7.11702 16.6482 7.08989Z"
                          fill={color}
                    />
                    <g id="Group">
                        <path id="Vector_2"
                              d="M6.22779 2.7153C6.44086 3.01125 7.04226 4.28224 7.38609 5.02296C7.44015 5.1394 7.29405 5.24459 7.20077 5.15637C6.6074 4.59534 5.59282 3.6218 5.37975 3.32582C5.21115 3.09165 5.26431 2.76514 5.49851 2.59654C5.73267 2.42794 6.05919 2.48113 6.22779 2.7153Z"
                              fill={color}
                        />
                        <path id="Vector_3"
                              d="M14.7735 2.7153C14.5604 3.01125 13.959 4.28224 13.6152 5.02296C13.5611 5.1394 13.7072 5.24459 13.8005 5.15637C14.3939 4.59534 15.4084 3.62177 15.6215 3.32582C15.7901 3.09165 15.737 2.76514 15.5028 2.59654C15.2686 2.42794 14.9421 2.48113 14.7735 2.7153Z"
                              fill={{color}}
                        />
                        <path id="Vector_4"
                              d="M18.1389 11.3956C17.7922 11.2825 16.3985 11.0958 15.5884 10.9933C15.461 10.9771 15.4052 11.1483 15.5176 11.2104C16.2324 11.6052 17.4682 12.276 17.8149 12.3891C18.0892 12.4785 18.3841 12.3287 18.4736 12.0543C18.563 11.78 18.4132 11.4851 18.1389 11.3956Z"
                              fill={color}
                        />
                        <path id="Vector_5"
                              d="M2.8606 11.3917C3.2073 11.2786 4.60095 11.0919 5.41108 10.9893C5.53843 10.9732 5.59424 11.1444 5.4819 11.2065C4.76708 11.6013 3.53131 12.2721 3.18461 12.3852C2.91028 12.4746 2.61537 12.3248 2.52589 12.0504C2.43642 11.7761 2.58627 11.4812 2.8606 11.3917Z"
                              fill={color}
                        />
                        <path id="Vector_6"
                              d="M9.99414 17.2925C9.99414 16.9279 10.2487 15.545 10.4024 14.743C10.4266 14.6169 10.6066 14.6169 10.6308 14.743C10.7845 15.545 11.0391 16.9279 11.0391 17.2925C11.0391 17.5811 10.8052 17.815 10.5166 17.815C10.2281 17.815 9.99414 17.5811 9.99414 17.2925Z"
                              fill={{color}}
                        />
                    </g>
                    <path id="Vector_7"
                          d="M11.4121 4.24893L10.8083 2.84699C10.6921 2.57704 10.3093 2.57704 10.1931 2.84699L8.53284 6.70222L4.35329 7.08989C4.06062 7.11702 3.94236 7.48103 4.16315 7.67501L7.31666 10.4453L6.39378 14.5401C6.32915 14.8269 6.63882 15.0518 6.89152 14.9018L7.37188 14.6166C8.15939 9.51884 10.3733 5.77674 11.4121 4.24893Z"
                          fill={{color}}
                    />
                </g>
            </g>
        </svg>
    );
}
