export default function Content({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_75_1445)">
                <path
                    d="M5.00008 4.99999H15.0001M5.00008 8.33332H15.0001M10.8334 11.6667H15.0001M10.8334 15H15.0001M1.66675 17.8333V2.16666C1.66675 2.03405 1.71943 1.90687 1.81319 1.8131C1.90696 1.71933 2.03414 1.66666 2.16675 1.66666H17.8334C17.966 1.66666 18.0932 1.71933 18.187 1.8131C18.2807 1.90687 18.3334 2.03405 18.3334 2.16666V17.8333C18.3334 17.9659 18.2807 18.0931 18.187 18.1869C18.0932 18.2806 17.966 18.3333 17.8334 18.3333H2.16675C2.03414 18.3333 1.90696 18.2806 1.81319 18.1869C1.71943 18.0931 1.66675 17.9659 1.66675 17.8333Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M5 15V11.6667H7.5V15H5Z" stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_75_1445">
                    <rect width={size} height={size} fill={color}
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
