export default function ChatSupport({color= "white", width = 21, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 21 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5 6.66675V10.0001M10.5 13.3417L10.5084 13.3326M10.5 18.3334C15.1025 18.3334 18.8334 14.6026 18.8334 10.0001C18.8334 5.39758 15.1025 1.66675 10.5 1.66675C5.89752 1.66675 2.16669 5.39758 2.16669 10.0001C2.16669 11.5176 2.57252 12.9417 3.28169 14.1667L2.58335 17.9167L6.33335 17.2184C7.59964 17.951 9.03711 18.3356 10.5 18.3334Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
