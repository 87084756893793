export default function Game({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_73_1422)">
                <path
                    d="M14.5833 14.5833C16.6666 17.5 19.9575 15.3458 19.1666 12.5C17.9791 8.22581 17.3333 5.84665 16.9975 4.58998C16.9014 4.23014 16.6894 3.912 16.3943 3.68482C16.0992 3.45764 15.7374 3.3341 15.365 3.33331H4.63496C3.86996 3.33331 3.20329 3.85415 3.01746 4.59581C2.31662 7.38581 1.69412 9.83498 0.953291 12.5C0.163291 15.3458 3.45329 17.5 5.53662 14.5833"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.3333 3.33331V4.99998C13.3333 5.44201 13.1577 5.86593 12.8452 6.17849C12.5326 6.49105 12.1087 6.66665 11.6667 6.66665H8.33333C7.89131 6.66665 7.46738 6.49105 7.15482 6.17849C6.84226 5.86593 6.66667 5.44201 6.66667 4.99998V3.33331M6.66667 13.3333C7.10869 13.3333 7.53262 13.1577 7.84518 12.8452C8.15774 12.5326 8.33333 12.1087 8.33333 11.6666C8.33333 11.2246 8.15774 10.8007 7.84518 10.4881C7.53262 10.1756 7.10869 9.99998 6.66667 9.99998C6.22464 9.99998 5.80072 10.1756 5.48816 10.4881C5.17559 10.8007 5 11.2246 5 11.6666C5 12.1087 5.17559 12.5326 5.48816 12.8452C5.80072 13.1577 6.22464 13.3333 6.66667 13.3333ZM13.3333 13.3333C13.7754 13.3333 14.1993 13.1577 14.5118 12.8452C14.8244 12.5326 15 12.1087 15 11.6666C15 11.2246 14.8244 10.8007 14.5118 10.4881C14.1993 10.1756 13.7754 9.99998 13.3333 9.99998C12.8913 9.99998 12.4674 10.1756 12.1548 10.4881C11.8423 10.8007 11.6667 11.2246 11.6667 11.6666C11.6667 12.1087 11.8423 12.5326 12.1548 12.8452C12.4674 13.1577 12.8913 13.3333 13.3333 13.3333Z"
                    stroke={color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_73_1422">
                    <rect width={size} height={size} fill={color}
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
