import { useEffect, useState } from "react";
import TableContainer from "../form/TableContainer";
import DatePickerField from "../form/DatePickerField";
import Button from "../form/Button";
import DateOperations from "../../mixins/date-operation";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import { $getStatisticShift } from "../../api/requests/reports";

export const ShiftReport = () => {
    const dateOperation = new DateOperations();
    const userData = useSelector(GetCurrentUserData);

    const [ dateStart, setDateStart ] = useState<Date>(new Date());
    const [ dateEnd, setDateEnd ] = useState<Date>(new Date());

    const timeZone = userData?.staffCenter?.timezone || 0;

    const [ isLengthList, setIsLengthList ] = useState<boolean>(false);

    const [ headerTable, setHeaderTable ] = useState([
        {
            key: 'fromDate',
            title: 'Начало'
        },
        {
            key: 'toDate',
            title: 'Конец'
        },
        {
            key: 'userId',
            title: 'Сотрудник'
        },
        {
            key: 'totalTime',
            title: 'Отработано'
        },
        {
            key: 'cash',
            title: 'Наличные'
        },
        // {
        //     key: 'cashless',
        //     title: 'Безналичные'
        // },
        {
            key: 'revenue',
            title: 'Выручка'
        },
        // {
        //     key: 'bonus',
        //     title: 'Бонусы'
        // }
    ]);

    const [ contentTable, setContentTable ] = useState<any[]>([]);

    const getTimeWorked = (dateStart: string, dateEnd: string): string => {
        if (!dateStart) return 'Неизвестно'

        const startAt = new Date(dateStart);
        const now = dateEnd ? new Date(dateEnd) : new Date();

        // now.setMinutes(now.getMinutes() - (timeZone * 60))

        const timeDifference = now.getTime() - startAt.getTime();
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        return `${ hours.toString().padStart(2, '0') } ч. ${ minutes.toString().padStart(2, '0') } мин.`;
    }

    const onChangeResponseItem = (item: any): any => {
        return {
            fromDate: dateOperation.getFormattedDateAndTime(item.startAt, timeZone) || 'Неизвестно',
            toDate: dateOperation.getFormattedDateAndTime(item.endAt, timeZone) || 'Смена не закончилась',
            userId: `${item?.user?.surname || ''} ${item?.user?.name || ''} ${item?.user?.lastName || ''}`,
            totalTime: (item.startAt) && getTimeWorked(item.startAt, item.endAt),
            cash: `${item.revenueCash || 0} ₽`,
            cashless: `${item.revenueCard || 0} ₽`,
            revenue: `${item.revenue || 0} ₽`,
        }
    }

    useEffect(() => {
        userData && init();
    }, [userData]);

    function init() {
        const requestObj = {
            fromDate: dateOperation.getISODateTimeByObj(dateStart),
            toDate: dateOperation.getISODateTimeByObj(dateEnd),
        }

        setIsLengthList(false);

        $getStatisticShift(requestObj).then(res => {
            if (!res.length) return setIsLengthList(true);

            setContentTable(res.map(i => i && onChangeResponseItem(i)))
        })
    }

    return (
        <div className='reports__shift'>
            <div className='reports__filter'>
                <div className='reports__filter-dates'>
                    <DatePickerField
                        className='reports-date'
                        isIcon
                        startDate={ dateStart }
                        onChangeDate={ (date) => {
                            setDateStart(date)
                        } }
                    />

                    <span className='reports__filter-line'></span>

                    <DatePickerField
                        className='reports-date'
                        isIcon
                        startDate={ dateEnd }
                        onChangeDate={ (date) => {
                            setDateEnd(date)
                        } }
                    />
                </div>

                <div className='reports__filter-buttons'>
                    <Button
                        text={ 'Применить' }
                        className='btn btn-primary'
                        onClick={ init }
                    />

                    {/*<FilterSelect*/}
                    {/*    placeholder='Выбор'*/}
                    {/*    options={ [ {label: 'first', value: 0}, {label: 'second', value: 1} ] }*/}
                    {/*    onChange={ () => ('select') }*/}
                    {/*    className='reports__filter-selection'*/}
                    {/*/>*/}
                </div>
            </div>

            { !isLengthList && <h1 className='reports__shift-title'>Отчет по сменам</h1> }

            { !isLengthList ? (
                    <div className='reports__shift-content'>
                        { contentTable.map((item: any, idx: number) => (
                            <TableContainer
                                key={ idx }
                                sortable={ false }
                                header={ headerTable }
                                content={ [ item ] }
                                onLeftClickTrContent={ () => ('onLeftClickTrContent') }
                                isLastRightText={ false }
                            />
                        )) }
                    </div>
                ) : (
                    <div className='reports__shift-notFound'>
                        За выбранный период данных нет.
                    </div>
                )
            }
        </div>
    )
}
