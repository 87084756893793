export default function PromoCodes({ color="white", size = 20 }) {
  return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2.5 7.08325V5.41659M8.33333 2.49992V0.833252M12.0833 17.4999H13.75M17.5 12.4999H19.1667M2.5 9.99992C2.5 13.3333 4.16667 17.4999 9.16667 17.4999L15 12.4999C10 12.4999 8.33333 8.33325 8.33333 4.99992L2.5 9.99992Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
  );
}
