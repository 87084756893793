import { positionCenter } from "../api/requests/center/interface";
import { GetCurrentUserData } from "../redux/slices/userSlice";
import {store} from "../redux/store";

export default class RoleUsers {
    getNameRoleUser(v: number): string {
        const roleList = [
            '',
            'Главный администратор',
            'Оператор региона',
            'Управляющий компании',
            'Управляющий центром',
            'Администратор центра',
            'Администратор зала',
            'Маркетолог',
            'Судья',
            'Гость',
            'QR',
        ]

        return roleList[v];
    }

    getNameStatusUser(v: number): string {
        const statusList = ['Забанен', 'Активен', 'На смене']

        return statusList[v]
    }

    getNamePositionCenter(value: string, arr: any[]): string {
        const idx = arr.findIndex(item => item === value)

        return idx === -1 ? '' : Object.values(positionCenter)[idx]
    }

    getModelRoleByType(page: string, type: 'create' | 'read' | 'update' | 'delete'): boolean {
        const userData = GetCurrentUserData(store.getState());

        const findPage = userData?.permissions && userData?.permissions[page];

        return findPage && findPage[type] || false;
    }
}
