import { FC, useEffect, useState } from 'react';
import Button from "../../components/form/Button";
import Pen from "../../img/icon/Pen";
import CreateGroupDiscounts from "../../components/discounts/CreateGroupDiscounts";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useNavigate } from "react-router-dom";
import { $getDiscountList } from "../../api/requests/discount";
import RoleUsers from "../../mixins/role";

const DiscountsPage: FC = () => {
    const isMobile = window.innerWidth < 465
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('discount', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('discount', 'update');

    const [ isShowCreateGroupsModal, setIsShowCreateGroupsModal ] = useState(false);

    const [ list, setList ] = useState<any[]>([]);

    const [ selectItem, setSelectItem ] = useState<any>(null);

    useEffect(() => init(), []);

    function init() {
        $getDiscountList(
            {
                type: '1'
            }
        ).then(res => {
            if (!res.result) return;

            setList(res.result);
        })
    }

    function onClickSelect(isEdit: boolean, item?: any) {
        setIsShowCreateGroupsModal(true);

        if (isEdit) onClickEditModal(item);
    }

    function onClickEditModal(item: any) {
        setSelectItem(item);
    }

    return (
        <div className="discounts__block">
            <div className="discounts__block-main">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 discounts__block-page">
                            <div className="discounts__item">
                                <div className="booking__header">
                                    <h1>Скидки</h1>
                                </div>

                                {isCreateByRole && (
                                    <div className="discounts__item__button">
                                        <Button
                                            className={ 'btn btn-primary' }
                                            text={ 'Добавить скидку' }
                                            onClick={ () => onClickSelect(false) }
                                        />
                                    </div>
                                )}

                                <div className={ 'discounts__item__list' }>
                                    { !!list.length && list.map((item, idx) => (
                                        <div
                                            onClick={() => isMobile && onClickSelect(true, item)}
                                            className="discounts__item__block"
                                             key={ `discount-item-block-${ idx }` }
                                        >
                                            <div className="discounts__item__block__meta">
                                                <p>{ item.name }</p>

                                                <span>{ item.percent }%</span>
                                            </div>

                                            {isUpdateByRole && !isMobile && (
                                                <div className="discounts__item__block__inner">
                                                    <Button
                                                        className={ 'btn' }
                                                        text={
                                                            <Pen
                                                                width={ 16 }
                                                                height={ 16 }
                                                                color={ '#3582F6' }
                                                            /> }
                                                        onClick={ () => onClickSelect(true, item) }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )) }
                                </div>

                                <CreateGroupDiscounts
                                    selectItem={ selectItem }
                                    isShow={ isShowCreateGroupsModal }
                                    setIsShow={ () => {
                                        setIsShowCreateGroupsModal(false);
                                        setSelectItem(null);
                                    } }
                                    updateList={ () => init() }
                                />
                            </div>

                            { !list.length && (
                                <div className="program__block__item__appellation">
                                    <p>
                                        Список скидок пуст
                                    </p>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiscountsPage;
