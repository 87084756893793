export default function SmallShop({color= "white", size = 18}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="small-shop-alt 1">
                <path id="Vector"
                      d="M2.25 7.5V14.25C2.25 14.6478 2.40804 15.0294 2.68934 15.3107C2.97064 15.592 3.35218 15.75 3.75 15.75H14.25C14.6478 15.75 15.0294 15.592 15.3107 15.3107C15.592 15.0294 15.75 14.6478 15.75 14.25V7.5"
                      stroke={color}
                      strokeWidth="1.5"
                />
                <path id="Vector_2"
                      d="M11.125 15.75V11.25C11.125 10.8522 10.967 10.4706 10.6857 10.1893C10.4044 9.90804 10.0228 9.75 9.625 9.75H8.125C7.72718 9.75 7.34564 9.90804 7.06434 10.1893C6.78303 10.4706 6.625 10.8522 6.625 11.25V15.75"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeMiterlimit="16"
                />
                <path id="Vector_3"
                      d="M16.3635 7.023L15.093 2.57625C15.0661 2.48224 15.0093 2.39954 14.9312 2.34067C14.8532 2.28181 14.758 2.24998 14.6603 2.25H11.625L11.9813 6.528C11.9866 6.59679 12.0084 6.6633 12.0446 6.722C12.0809 6.7807 12.1306 6.8299 12.1898 6.8655C12.4823 7.04025 13.0538 7.36275 13.5 7.5C14.262 7.73475 15.375 7.65 16.0095 7.572C16.0712 7.56403 16.1304 7.54269 16.183 7.50947C16.2356 7.47626 16.2803 7.43197 16.314 7.3797C16.3477 7.32742 16.3696 7.26843 16.3781 7.20682C16.3867 7.14521 16.3817 7.08248 16.3635 7.023Z"
                      stroke={color}
                      strokeWidth="1.5"
                />
                <path id="Vector_4"
                      d="M10.5008 7.5C10.9268 7.36875 11.4668 7.0695 11.7683 6.891C11.8386 6.84917 11.8956 6.78831 11.9328 6.71545C11.9699 6.64259 11.9857 6.5607 11.9783 6.47925L11.6258 2.25H6.37583L6.02333 6.47925C6.01578 6.56082 6.0315 6.64287 6.06867 6.71587C6.10583 6.78888 6.16293 6.84986 6.23333 6.89175C6.53483 7.0695 7.07483 7.36875 7.50083 7.5C8.62058 7.845 9.38108 7.845 10.5008 7.5Z"
                      stroke={color}
                      strokeWidth="1.5"
                />
                <path id="Vector_5"
                      d="M2.90789 2.57625L1.63739 7.02375C1.61946 7.08313 1.61467 7.14571 1.62334 7.20714C1.63202 7.26856 1.65396 7.32736 1.68765 7.37945C1.72133 7.43155 1.76594 7.47568 1.8184 7.5088C1.87085 7.54192 1.92988 7.56323 1.99139 7.57125C2.62514 7.65 3.73889 7.734 4.50089 7.5C4.94714 7.36275 5.51939 7.04025 5.81114 6.86625C5.87035 6.83057 5.92016 6.78126 5.95643 6.72242C5.9927 6.66358 6.01437 6.59692 6.01964 6.528L6.37589 2.25H3.34064C3.24286 2.24998 3.14773 2.28181 3.06965 2.34067C2.99157 2.39954 2.93478 2.48224 2.90789 2.57625Z"
                      stroke={color}
                      strokeWidth="1.5"
                />
            </g>
        </svg>
    );
}

