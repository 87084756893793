import React, { FC, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Button from "../../components/form/Button";
import Timer from "../../img/icon/Timer";
import TimerSecond from "../../img/icon/TimerSecond";
import Trash from "../../img/icon/Trash";
import DateOperations from "../../mixins/date-operation";
import Pen from "../../img/icon/Pen";

interface TournamentListProps {
    onDelete?: () => void,
    item: any
    isShowEdit: boolean
}

const TournamentList: FC<TournamentListProps> = (
    {
        onDelete,
        item,
        isShowEdit
    }
) => {
    const isMobile = window.innerWidth <= 465;
    const navigate = useNavigate();
    const refsBtnBlock = useRef(null);
    const dateOperations = new DateOperations();

    const handleEdit = () => {
        navigate('/tournaments/edit', {
            state: {
                current: 'edit',
                data: item
            },
        })
    }

    const getColorTextByTime = (time: string): string => {
        if (!time) return '#FFF';

        const timeStampByNow = dateOperations.getTimestampByISODateTime(dateOperations.getNowISODate());
        const timeStampByTime = dateOperations.getTimestampByISODateTime(dateOperations.getISODateByFormatted(time))

        if (timeStampByNow < timeStampByTime) return '#FFF';
        if (timeStampByNow > timeStampByTime) return '#70D584';

        return '#F9D448';
    }

    function onClickOpenCheckPage(event: any) {
        const refs: any = refsBtnBlock.current;

        if ((refs && refs.firstChild.type) === event.target.type) return;

        navigate(`/tournaments/view/${ item.id }`, {
            state: {
                current: 'view',
                data: item
            },
        })
    }

    const currentDate = item.dateApplicationsStart
        ? dateOperations.getFormattedDateByISO(dateOperations.getISODateByFormatted(item.dateApplicationsStart.slice(0, 10)))
        : ''


    return (
        <div className="game__content__pro__item" onClick={ onClickOpenCheckPage }>
            <img src={ item.image } />

            <div className="game__content__pro__item__hour">
                <p>{ item.title }</p>

                <div className="game__content__pro__item__hour__timer">
                    { !isMobile &&
                      <>
                          { (getColorTextByTime(item.dateApplicationsStart) === '#FFF')
                              ? (<Timer />)
                              : (<TimerSecond
                                  color={ getColorTextByTime(item.dateApplicationsStart) }
                              />) }
                      </> }

                    <span
                        style={ {color: getColorTextByTime(item.dateApplicationsStart)} }
                    >
                         { isMobile &&
                           <>
                               { (getColorTextByTime(item.dateApplicationsStart) === '#FFF')
                                   ? (<Timer />)
                                   : (<TimerSecond
                                       color={ getColorTextByTime(item.dateApplicationsStart) }
                                   />) }
                           </>
                         }
                        { currentDate }
                    </span>

                    { isShowEdit && (
                        <div className="game__content__pro__item__delete" ref={ refsBtnBlock }>
                            <Button
                                className={ 'btn btn-primary' }
                                text={ isMobile ? <Pen /> : 'Редактировать' }
                                onClick={ handleEdit }
                                disabled={ item.status !== 0 }
                            />

                            <Button
                                className={ 'btn btn-danger' }
                                text={ <Trash /> }
                                onClick={ onDelete }
                            />
                        </div>
                    ) }
                </div>
            </div>
        </div>
    );
}

export default TournamentList;
