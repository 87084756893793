export default function Burger({color= "white", size = 22}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 22 22`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M2.75 4.58301H19.25M2.75 10.9997H19.25M2.75 17.4163H19.25"
                      stroke={color}
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

