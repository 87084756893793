import SignInForm from '../../components/auth/SignInForm';

const Login = () => {
    return (
        <div className="login-page">
            <div className="sign-bg">
                <div className="sign-logo">
                    <img src="/img/logo/logo.svg" alt="logo"/>
                </div>

                <SignInForm />
            </div>
        </div>
    );
}

export default Login;
