import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useNavigate } from "react-router-dom";
import PlusBorder from "../../img/icon/PlusBorder";
import { AchievementsItems } from "../../components/achievements/AchievementsItems";
import CreateCategoryDialog from "../../components/news/CreateCategoryDialog";
import Setting from "../../img/icon/Setting";
import { $deletePromotion, $getCategoryPromotionList, $getPromotionList } from "../../api/requests/promotion";
import AllGroupsDialog from "../../components/computers/AllGroupsDialog";
import RoleUsers from "../../mixins/role";
import { MobileAchievementsItems } from "../../components/achievements/MobileAchievementsItems";

export const StocksPage: FC = () => {
    const isMobile = window.innerWidth < 465
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('promotions', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('promotions', 'update');

    const [ headerBtn, setHeaderBtn ] = useState<any[]>([]);

    const [ itemList, setItemList ] = useState<any[]>([]);

    const [ isShowCreateGroupsModal, setIsShowCreateGroupsModal ] = useState(false);
    const [ filterType, setFilterType ] = useState(-1);
    const [ selectItem, setSelectItem ] = useState<any>(null);
    const [ isIconVisible, setIsIconVisible ] = useState(headerBtn.map(() => false));

    const [ isShowAllGroupsModal, setIsShowAllGroupsModal ] = useState(false);
    const [ activeNameBtnName, setActiveNameBtnName ] = useState<any[]>([]);

    const handleDelete = (itemToRemove: any) => {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deletePromotion(itemToRemove.id).then(res => {
            if (!res.id) return;

            const updatedItems = itemList.filter((list: any) => list !== itemToRemove);

            setItemList(updatedItems);
        })
    }

    const handleFilter = (type: any) => {
        setFilterType(type);
    };

    const filteredItems = itemList.filter((item: any) => {
        return filterType === -1 || item.type === filterType;
    });

    function onClickSelect(isEdit: boolean, item?: any) {
        setIsShowCreateGroupsModal(true);

        if (isEdit) {
            setSelectItem(item)
        }
    }

    const handleMouseEnter = (index: number) => {
        const updatedVisibility = [ ...isIconVisible ];
        updatedVisibility[index] = true;
        setIsIconVisible(updatedVisibility);
    };

    const handleMouseLeave = (index: number) => {
        const updatedVisibility = [ ...isIconVisible ];
        updatedVisibility[index] = false;
        setIsIconVisible(updatedVisibility);
    };

    useEffect(() => init(), []);

    function init() {
        getCategory();
        getList();
    }

    function getList() {
        $getPromotionList().then(res => {
            if (!res.result) return;

            setItemList(res.result.map(i => ({
                ...i,
                // description: (<span dangerouslySetInnerHTML={{ __html: i.text }}></span>)
            })))
        })
    }

    function getCategory() {
        $getCategoryPromotionList().then(res => {
            if (!res.result) return;

            const list: any[] = [
                {
                    title: 'Все',
                    isActive: true
                }
            ]

            list.push(...res.result.map(i => ({
                ...i,
                name: i.title,
                isActive: false
            })))

            setHeaderBtn(list)
        })
    }

    function onClickAllGroup() {
        setIsShowAllGroupsModal(true);
    }

    return (
        <div className='news list-wrapper'>
            <div className="news-meta">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 styles">
                            <div className="booking__header">
                                <h1>Акции</h1>
                            </div>
                            <div className="news__header">
                                <div className="news__header__left">
                                    {/*{ headerBtn.map((item, index: number) => {*/}
                                    {/*    if (index === 3) return (*/}
                                    {/*        <Button*/}
                                    {/*            key={ `achievements-btn-list-${ index }` }*/}
                                    {/*            text={ 'Список категории' }*/}
                                    {/*            onClick={ onClickAllGroup }*/}
                                    {/*            className={ `btn btn-primary` }*/}
                                    {/*        />*/}
                                    {/*    )*/}

                                    {/*    if (index > 2) return null;*/}

                                    {/*    return (*/}
                                    {/*        <Button*/}
                                    {/*            onMouseEnter={ () => handleMouseEnter(index) }*/}
                                    {/*            onMouseLeave={ () => handleMouseLeave(index) }*/}
                                    {/*            key={ index }*/}
                                    {/*            className={ `btn btn-primary ${ (index - 1) === filterType ? 'news__header-active' : 'news__header-nonActive' }` }*/}
                                    {/*            text={ item.title }*/}
                                    {/*            rightIcon={*/}
                                    {/*                (isIconVisible[index] && !isIconVisible[0] && isUpdateByRole) && (*/}
                                    {/*                    <div*/}
                                    {/*                        className='news__header-edit'*/}
                                    {/*                        style={ {visibility: isIconVisible[index] ? 'visible' : 'hidden',} }*/}
                                    {/*                        onClick={ () => onClickSelect(true, item) }*/}
                                    {/*                    >*/}
                                    {/*                        <Setting size={ 14 } color={ '#3582F6' }/>*/}
                                    {/*                    </div>)*/}
                                    {/*            }*/}
                                    {/*            onClick={ () => (handleFilter(index - 1)) }*/}
                                    {/*        />*/}
                                    {/*    )*/}
                                    {/*}) }*/}
                                </div>

                                { isCreateByRole && (
                                    <div className='news__header-right'>
                                        {/*<Button*/}
                                        {/*    className={ 'btn btn-primary achievements__header-nonActive' }*/}
                                        {/*    text={ 'Добавить категорию' }*/}
                                        {/*    onClick={ () => onClickSelect(true, null) }*/}
                                        {/*/>*/}

                                        <Button
                                            className={ 'btn btn-primary' }
                                            leftIcon={ <PlusBorder/> }
                                            text={ 'Добавить акцию' }
                                            onClick={ () => (navigate('create')) }
                                        />
                                    </div>
                                ) }
                            </div>

                            <div className='news__content'>
                                <div className='row'>
                                    { !!filteredItems.length && filteredItems.map((item, idx) => (
                                        <div className="col-6 col-sm-6 col-xl-4"
                                             key={ `person-item-list-${ idx }` }
                                        >
                                            { isMobile ? (
                                                    <MobileAchievementsItems
                                                        isShowEditBtn={ isUpdateByRole }
                                                        onDelete={ () => (handleDelete(item)) }
                                                        itemInfo={ item }
                                                        isShowCounts={ false }
                                                    />
                                                ) :
                                                (
                                                    <AchievementsItems
                                                        isShowEditBtn={ isUpdateByRole }
                                                        onDelete={ () => (handleDelete(item)) }
                                                        itemInfo={ item }
                                                        isShowCounts={ false }
                                                    />
                                                ) }
                                        </div>
                                    )) }

                                    <CreateCategoryDialog
                                        selectItem={ selectItem }
                                        isShow={ isShowCreateGroupsModal }
                                        setIsShow={ () => {
                                            setIsShowCreateGroupsModal(false);
                                            setSelectItem(null);
                                            getCategory();
                                        } }
                                        typeCategory={ 'promotion' }
                                    />
                                </div>
                            </div>

                            { !filteredItems.length && (
                                <div className="program__block__item__appellation">
                                    <p>
                                        Список акций пуст
                                    </p>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>

            <AllGroupsDialog
                isShow={ isShowAllGroupsModal }
                setIsShow={ (item: any) => {
                    setIsShowAllGroupsModal(false);
                } }
                btnList={ headerBtn }
                onClickSelect={ handleFilter }
                onClickSetting={onClickSelect}
                activeNameBtnName={ activeNameBtnName }
                textBtn={ 'категории' }
                settingsBtn
                onMouseEnter={ handleMouseEnter }
                onMouseLeave={ handleMouseLeave }
                isIconVisible={isIconVisible}
                isUpdateByRole={isUpdateByRole}
            />
        </div>
    )
}
