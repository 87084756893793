import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import { useNavigate } from "react-router-dom";
import PlusBorder from "../../img/icon/PlusBorder";
import { AchievementsItems } from "../../components/achievements/AchievementsItems";
import { $achievementDelete, $achievementListGet, $achievementSort } from "../../api/requests/achievement";
import AllGroupsDialog from "../../components/computers/AllGroupsDialog";
import RoleUsers from "../../mixins/role";
import { ListManager } from "react-beautiful-dnd-grid";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";
import { MobileAchievementsItems } from "../../components/achievements/MobileAchievementsItems";

export const AchievementsPage: FC = () => {
    const mobile = window.innerWidth < 465
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();

    const userData = useSelector(GetCurrentUserData);

    const isCreateByRole = roleUsers.getModelRoleByType('achievements', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('achievements', 'update');

    const isMobile = window.innerWidth < 767;
    const isLaptop = window.innerWidth > 767 && window.innerWidth < 1075;

    const [ btnList, setBtnList ] = useState([
        {
            "name": "Все",
            "isActive": false,
            "id": -1
        },
        {
            "name": "Достижение",
            "isActive": false,
            "id": 0
        },
        {
            "name": "Особый статус",
            "isActive": false,
            "id": 1
        },
        {
            "name": "Трофей",
            "isActive": false,
            "id": 2
        }
    ]);

    const [ itemList, setItemList ] = useState<any[]>([]);
    const [ filteredItems, setFilteredItems ] = useState<any[]>([]);

    const [ isShowAllGroupsModal, setIsShowAllGroupsModal ] = useState(false);
    const [ activeNameBtnName, setActiveNameBtnName ] = useState<any[]>([ btnList[0].id ]);

    const handleDelete = (itemToRemove: any) => {
        if (!isUpdateByRole) return;

        if (!confirm('Вы подтверждаете удаление?')) return;

        $achievementDelete(itemToRemove.id).then(res => {
            if (!res.id) return;

            const updatedItems = itemList.filter((list: any) => list.id !== itemToRemove.id);

            setItemList(updatedItems);
            setFilteredItems(updatedItems);
        })
    }

    const reorder = (
        list: any[],
        startIndex: number,
        endIndex: number
    ) => {
        const result = Array.from(list);
        const [ removed ] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    useEffect(() => init(), []);

    function init() {
        $achievementListGet().then(res => {
            if (!res.result) return;

            const updateList = res.result.map(i => ({
                ...i,
                text: i.points,
                subText: i?.level > 0 ? `Уровень ${ i.level }` : '',
            })).sort((
                a,
                b
            ) => a.position - b.position);

            setItemList(updateList);
            setFilteredItems(updateList);
        })
    }

    function onClickAllGroup() {
        setIsShowAllGroupsModal(true);
    }

    function onClickSelect(i: any) {
        setActiveNameBtnName((list) => {
            if (i.name === "Все") {
                changeFilterItems([]);
                return []
            }

            const findActive = list.findIndex(id => id === i.id);

            if (findActive !== -1) list.splice(findActive, 1);
            else list.push(i.id);

            changeFilterItems(list);

            return list;
        });
    }

    function changeFilterItems(list: any) {
        const listUpdate = list.length ? [ ...itemList ].filter(i => list.includes(i.category)) : itemList;

        setFilteredItems(listUpdate)
    }

    function onDragEnd(
        sourceIndex: number | null,
        destinationIndex: number | null
    ) {
        if (!isCreateByRole || !isUpdateByRole || !userData?.staffCenter?.isMain) return;

        if (sourceIndex === null || destinationIndex === null) return;

        if (destinationIndex === sourceIndex) return;

        const quotes = reorder(filteredItems, sourceIndex, destinationIndex);

        const requestArr = quotes.map((
            i,
            idx
        ) => ({
            id: i.id,
            position: idx
        }))

        $achievementSort(requestArr).then(res => {
            if (!res || !res.length) return;

            setFilteredItems(quotes.map((
                i,
                idx
            ) => ({
                ...i,
                position: idx
            })));
        })
    }

    return (
        <div className='achievements list-wrapper'>
            <div className="achievements-main">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 achievements__bottom'>
                            <div className="booking__header">
                                <h1>Ачивки</h1>
                            </div>

                            <div className='achievements__header'>
                                <div className='achievements__header-left'>
                                    { btnList.map((
                                        item,
                                        index
                                    ) => {
                                        if (index === 3) return (
                                            <Button
                                                key={ `achievements-btn-list-${ index }` }
                                                text={ 'Список категории' }
                                                onClick={ onClickAllGroup }
                                                className={ `btn btn-primary` }
                                            />
                                        )

                                        if (index > 2) return null;

                                        return (
                                            <Button
                                                key={ `achievements-btn-list-${ index }` }
                                                text={ item.name }
                                                onClick={ () => onClickSelect(item) }
                                                className={ `btn btn-primary ${ activeNameBtnName.includes(item.id) ? 'achievements__header-active' : 'achievements__header-nonActive' }` }
                                            />
                                        )
                                    }) }
                                </div>

                                { (isCreateByRole && userData?.staffCenter?.isMain) && (
                                    <div className='achievements__header-right'>
                                        <Button
                                            className={ 'btn btn-primary' }
                                            leftIcon={ <PlusBorder /> }
                                            text={ 'Добавить достижение' }
                                            onClick={ () => (navigate('/achievements/create')) }
                                        />
                                    </div>
                                ) }
                            </div>

                            { mobile ? (
                                <div className='news__content'>
                                    <div className='row'>
                                        { !!filteredItems.length && filteredItems.map((
                                            item,
                                            idx
                                        ) => (
                                            <div className="col-6 col-sm-6 col-xl-4"
                                                 key={ `person-item-list-${ idx }` }
                                            >
                                                <MobileAchievementsItems
                                                    isShowEditBtn={ isUpdateByRole && userData?.staffCenter?.isMain }
                                                    onDelete={ () => (handleDelete(item)) }
                                                    itemInfo={ item }
                                                />
                                            </div>
                                        )) }

                                    </div>
                                </div>
                            ) : (
                                <div className='achievements__content'>
                                    <ListManager
                                        items={ filteredItems }
                                        direction="horizontal"
                                        maxItems={ isLaptop ? 2 : (isMobile ? 1 : 3) }
                                        render={ item => (
                                            <div className={ 'achievements__items col-12 col-sm-6 col-lg-4' }>
                                                <AchievementsItems
                                                    isShowEditBtn={ isUpdateByRole && userData?.staffCenter?.isMain }
                                                    onDelete={ () => (handleDelete(item)) }
                                                    itemInfo={ item }
                                                />

                                                <div className={ 'line' }></div>
                                            </div>
                                        ) }
                                        onDragEnd={ onDragEnd }
                                    />
                                </div>
                            ) }

                            { !filteredItems.length && (
                                <div className="program__block__item__appellation">
                                    <p>
                                        Список достижения пуст
                                    </p>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>

            <AllGroupsDialog
                isShow={ isShowAllGroupsModal }
                setIsShow={ (item: any) => {
                    setIsShowAllGroupsModal(false);
                } }
                btnList={ btnList }
                onClickSelect={ onClickSelect }
                activeNameBtnName={ activeNameBtnName }
                textBtn={ 'категории' }
            />
        </div>
    )
}
