import React from "react";

interface ScrollButtonProps {
    onClick: () => void;
    direction: "left" | "right";
}

const ScrollButton: React.FC<ScrollButtonProps> = ({ onClick, direction }) => {
    return (
        <>
            <button
                className={`calendar-time-btn ${direction}`}
                type={'button'}
                onClick={onClick}
            >
                <img src="/img/icon/form/right-border.svg"/>
            </button>
        </>
    );
};

export default ScrollButton;
