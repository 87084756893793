export default function Security({color = "white", size = 22}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 22 22`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Component 3">
                <path id="Vector"
                      d="M12.8333 11.0007H13.2C13.3459 11.0007 13.4858 11.0586 13.5889 11.1617C13.6921 11.2649 13.75 11.4048 13.75 11.5507V14.1173C13.75 14.2632 13.6921 14.4031 13.5889 14.5062C13.4858 14.6094 13.3459 14.6673 13.2 14.6673H8.8C8.65413 14.6673 8.51424 14.6094 8.41109 14.5062C8.30795 14.4031 8.25 14.2632 8.25 14.1173V11.5507C8.25 11.4048 8.30795 11.2649 8.41109 11.1617C8.51424 11.0586 8.65413 11.0007 8.8 11.0007H9.16667M12.8333 11.0007V9.16732C12.8333 8.5559 12.4667 7.33398 11 7.33398C9.53333 7.33398 9.16667 8.5559 9.16667 9.16732V11.0007M12.8333 11.0007H9.16667"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2"
                      d="M0.916992 6.87428L10.7301 1.89859C10.814 1.8561 10.9065 1.83398 11.0003 1.83398C11.0941 1.83398 11.1866 1.8561 11.2706 1.89859L21.0837 6.87428M19.067 9.9419V18.1222C19.067 18.6646 18.8545 19.1848 18.4763 19.5683C18.0981 19.9519 17.5852 20.1673 17.0503 20.1673H4.95033C4.41547 20.1673 3.90253 19.9519 3.52433 19.5683C3.14613 19.1848 2.93366 18.6646 2.93366 18.1222V9.9419"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
