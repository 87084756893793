import { FC, useEffect, useState } from "react";
import { UserModelLink } from "../../api/requests/staff/interface";
import RoleUsers from "../../mixins/role";
import DateOperations from "../../mixins/date-operation";
import { $getStatisticDashboard } from "../../api/requests/reports";
import { DashboardMainStatistic } from "../../api/requests/reports/interface";

interface DashboardContentProps {
    userData: UserModelLink | null
}

const DashboardContent: FC<DashboardContentProps> = (
    {
        userData
    }
) => {
    const roleUsers = new RoleUsers();
    const dateOperation = new DateOperations();
    const timeZone = userData?.staffCenter?.timezone || 0;

    const [ statistics, setStatistics ] = useState<DashboardMainStatistic>({
        revenue: 0, // Выручка
        revenueCard: 0, // Выручка картой
        cash: 0, // Наличные
        cashBefore: 0, // Наличных до начала смены
        lastSessionDate: '' // Дата последней сессии
    })

    const getName = (): string => {
        const name = userData?.name || '';
        const surname = userData?.surname || '';
        const lastName = userData?.lastName || '';

        return `${ surname } ${ name } ${ lastName }`
    }

    const getDateLastSessions = (): string => {
        return dateOperation.getFormattedDateAndTime(statistics?.lastSessionDate || '', timeZone)
    }

    useEffect(() => init(), []);

    function init() {
        $getStatisticDashboard().then(res => {
            if (!res) return;

            setStatistics(res)
        })
    }

    return (
        <div className="dashboard__bg">
            <div className="dashboard__bg__info">
                <div className="dashboard__bg__caption">
                    <img src={userData?.image || '/img/logo/logo-2.png'} alt={''}/>

                    <div className="dashboard__bg__users">
                        <p>Сотрудник:</p>

                        <h4>{ getName() }</h4>
                    </div>
                </div>

                <div className="dashboard__bg__info__meta">
                    <span>{ roleUsers.getNameRoleUser(userData?.role || NaN) }</span>
                </div>
            </div>

            <div className="dashboard__bg__section">
                <div className="dashboard__bg__revenue__link">
                    <p>Выручка</p>

                    <span>{ statistics?.revenue || 0 } ₽</span>
                </div>

                <div className="dashboard__bg__revenue__link">
                    <p>Выручка картой</p>

                    <span>{ statistics?.revenueCard || 0 } ₽</span>
                </div>

                <div className="dashboard__bg__revenue__link">
                    <p>Наличных в кассе</p>

                    <span>{ statistics?.cash || 0 } ₽</span>
                </div>

                <div className="dashboard__bg__revenue__link">
                    <p>До начала смены</p>

                    <span>{ statistics?.cashBefore || 0 } ₽</span>
                </div>
            </div>

            <div className="line"></div>

            <div className="dashboard__bg__start-date">
                <p>Номер телефона</p>
                <span>{ `+ ${ userData?.phone }` }</span>
            </div>

            {statistics?.lastSessionDate && (
                <div className="dashboard__bg__start-date">
                    <p>Дата и время последней сессии</p>
                    <span>{ getDateLastSessions() }</span>
                </div>
            )}
        </div>
    )

}

export default DashboardContent;
