export default function MassageSecond({color= "white", width = 21, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 21 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.16797 7.5L10.3346 10.4167L14.5013 7.5"
                  stroke={color}
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
            <path d="M2 14.168V5.83464C2 5.39261 2.17559 4.96868 2.48816 4.65612C2.80072 4.34356 3.22464 4.16797 3.66667 4.16797H17C17.442 4.16797 17.8659 4.34356 18.1785 4.65612C18.4911 4.96868 18.6667 5.39261 18.6667 5.83464V14.168C18.6667 14.61 18.4911 15.0339 18.1785 15.3465C17.8659 15.659 17.442 15.8346 17 15.8346H3.66667C3.22464 15.8346 2.80072 15.659 2.48816 15.3465C2.17559 15.0339 2 14.61 2 14.168Z"
                  stroke={color}
                  strokeWidth="1.25"
            />
        </svg>
    );
}

