export default function Trash({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector"
                      d="M16.6667 7.50033L15.0042 16.9553C14.9363 17.3415 14.7346 17.6913 14.4343 17.9434C14.1341 18.1955 13.7545 18.3337 13.3625 18.3337H6.6375C6.24545 18.3337 5.86594 18.1955 5.56569 17.9434C5.26543 17.6913 5.06366 17.3415 4.99583 16.9553L3.33333 7.50033M17.5 5.00033H12.8125M12.8125 5.00033V3.33366C12.8125 2.89163 12.6369 2.46771 12.3243 2.15515C12.0118 1.84259 11.5879 1.66699 11.1458 1.66699H8.85417C8.41214 1.66699 7.98822 1.84259 7.67566 2.15515C7.36309 2.46771 7.1875 2.89163 7.1875 3.33366V5.00033M12.8125 5.00033H7.1875M2.5 5.00033H7.1875"
                      stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
