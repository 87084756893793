export default function SendIcon({ color = "white", width = 30, height = 30 }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30" fill="none">
			<path
				d="M27.5 15L3.75 25L8.20375 15L3.75 5L27.5 15ZM27.5 15H8.125"
				stroke={color}
				strokeWidth="1.875"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
