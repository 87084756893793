import SignInClub from "../../components/auth/SignInClub";

const Auth = () => {
    return (
        <div className="login-page">
            <div className="sign-bg">
                <SignInClub/>
            </div>
        </div>
    );
}

export default Auth;
