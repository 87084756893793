export default function Reload({color= "white", size = 22}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 22 22`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M18 11.2C17.4175 15.0488 14.078 18 10.0451 18C5.60177 18 2 14.4184 2 10C2 5.5816 5.60177 2 10.0451 2C13.3435 2 16.1794 3.9744 17.4208 6.8"
                      stroke={color}
                      strokeWidth="1.38"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2" d="M14 7H17.52C17.583 7 17.6455 6.98758 17.7037 6.96346C17.7619 6.93934 17.8148 6.90398 17.8594 6.85941C17.904 6.81484 17.9393 6.76192 17.9635 6.70369C17.9876 6.64545 18 6.58303 18 6.52V3"
                      stroke="white"
                      strokeWidth="1.38"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
