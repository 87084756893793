export default function Pen({color= "white", width = 20, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 13 14`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                  d="M10.8567 1.8C10.746 1.8 10.6363 1.82181 10.534 1.86419C10.4317 1.90657 10.3388 1.96869 10.2605 2.047L10.0162 2.29135L11.2086 3.48384L11.4529 3.23955C11.5312 3.16127 11.5934 3.06826 11.6358 2.96597C11.6782 2.86367 11.7 2.75403 11.7 2.64331C11.7 2.53258 11.6782 2.42294 11.6358 2.32065C11.5934 2.21836 11.5313 2.12542 11.453 2.04713C11.3747 1.96883 11.2816 1.90657 11.1793 1.86419C11.0771 1.82181 10.9674 1.8 10.8567 1.8ZM10.2894 4.40308L9.09692 3.21059L1.95163 10.3559C1.9516 10.3559 1.95166 10.3559 1.95163 10.3559C1.94476 10.3628 1.93981 10.3715 1.93745 10.3809L1.54345 11.9566L3.11861 11.5627C3.1281 11.5603 3.13721 11.5553 3.14412 11.5484L10.2894 4.40308ZM10.0365 0.663187C10.2965 0.555452 10.5752 0.5 10.8567 0.5C11.1382 0.5 11.4169 0.555452 11.6769 0.663187C11.9369 0.770898 12.1731 0.92876 12.3721 1.12776C12.5711 1.32673 12.7291 1.56309 12.8368 1.82307C12.9445 2.08311 13 2.36183 13 2.64331C13 2.92478 12.9445 3.2035 12.8368 3.46355C12.7291 3.72356 12.5712 3.9598 12.3722 4.15879C12.3722 4.15881 12.3722 4.15877 12.3722 4.15879L4.06323 12.4677C3.88955 12.6413 3.6718 12.7645 3.43354 12.8239L0.807701 13.4806C0.586187 13.536 0.351852 13.4711 0.190394 13.3096C0.0289368 13.1481 -0.0359623 12.9138 0.0194311 12.6923L0.676068 10.0665C0.735514 9.82837 0.858665 9.61046 1.03215 9.43689L9.34114 1.1279C9.34112 1.12792 9.34116 1.12787 9.34114 1.1279C9.54013 0.928865 9.77644 0.77091 10.0365 0.663187Z"
                  fill={color}
            />
        </svg>
    );
}
