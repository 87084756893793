import React, { FC, useState } from "react";
import Moon from "../../img/icon/Moon";
import Vision from "../../img/icon/Vision";
import Person from "../../img/icon/Person";
import PersonCrown from "../../img/icon/PersonCrown";
import { useLocation } from "react-router-dom";
import DateOperations from "../../mixins/date-operation";
import Percentage from "../../img/icon/Percentage";

interface TariffsEditPreviewProps {
    priceField: any
    prices: any,
    days: any,
    data: any,

}
export const TariffsEditPreview: FC<TariffsEditPreviewProps> = (
    {
        priceField,
        prices,
        days,
        data
    }
) => {
    const dateOperations = new DateOperations()

    priceField.forEach((item: any) => {
        const priceItems = prices.find((i: any) => i.id == item.id)

        if (priceItems) {
            priceItems[item.key] = item.value;
        }
    })

    const daysTitle: any = [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс' ]
    const tariffType: any[] = [
        {
            label: 'Фиксированный',
            value: 0
        },
        // {
        //     label: 'Поминутный',
        //     value: 1
        // },
        {
            label: 'Пакетный',
            value: 2
        },
        // {
        //     label: 'Несгораемый',
        //     value: 3
        // },
    ]
    const durationType = [
        {
            label: 'минут',
            value: 0
        },
        {
            label: 'часов',
            value: 1
        },
        // {
        //     label: 'суток',
        //     value: 2
        // },
        // {
        //     label: 'недель',
        //     value: 3
        // },
    ]

    const filteredDays = days ? days.sort((
        a: number,
        b: number
    ) => a - b) : ''
    const findItemType: any = (
        arr: any,
        obj: any
    ) => {
        const findTariffType: any = arr.find((item: any) => item.value === obj)

        return findTariffType?.label
    }

    return (
        <div className='tariffs-edit__preview'>
            <h3 className={ 'tariffsAdd__block-title' }>{ data.name === '' ? 'Название тарифа' : data.name }</h3>

            <div className='tariffsAdd__block-header'>
                <div className={`tariffsAdd__block-btn tariffsAdd__block-btn${data.type != 0 ? '-blue' : '-red'}`}>
                    { data.type || data.type === 0 ? findItemType(tariffType, data.type) : 'Свободен' }
                </div>

                { data.applyDiscount && (
                    <div className='tariffs__block-icon'><Percentage size={ 18 } color={ '#3582F6' } /></div>)
                }
            </div>

                <div className='tariffs__items'>
                        { prices.map((
                            item: any,
                            idx: number
                        ) => (
                            <div
                                key={ `tariffs__items-zones-${ idx }` }
                                className='tariffs__items-zones'>
                                <div className='tariffs__items-left'>
                                    <p className='tariffs__items-paragraph'>Зоны</p>

                                    <h1 className='tariffs__items-title'>{ item?.zoneId ? item.zoneId.name : '' }
                                    </h1>
                                </div>

                                <div className='tariffs__items-right tariffs__items-center'>
                                    <p className='tariffs__items-paragraph'>Цена</p>

                                    <div className='tariffs__items-prices'>
                                        { item.priceForAll ?
                                            <p><Person color={ '#3582F6' } width={ 25 }
                                                       height={ 25 } /> { item.priceForAll } ₽</p>
                                            :
                                            (<div className='tariffs__items-lines'><Person color={ '#3582F6' }
                                                                                           width={ 25 } height={ 25 } />
                                                    <span className='tariffs__items-line'></span>
                                                </div>
                                            ) }

                                        { item.priceForUsers ?
                                            <p style={ {color: 'grey'} }><PersonCrown
                                                color={ 'rgba(53, 130, 246, 0.40)' } width={ 32 }
                                                height={ 32 } /> { item.priceForUsers } ₽</p>
                                            :
                                            (<div className='tariffs__items-lines'><PersonCrown color={ '#A414FF' }
                                                                                                width={ 32 }
                                                                                                height={ 32 } />
                                                <span className='tariffs__items-line'></span>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        )) }
                </div>

                <div className={'separator'}></div>
                <div className='tariffs__items-duration'>
                    <div className='tariffs__items-desc'>
                        <p className='tariffs__items-paragraph'>Длительность</p>

                        { data.duration ?
                            <h1 className='tariffs__items-title'>{ data.duration } { findItemType(durationType, data.durationType) }</h1>
                            :
                            <div className='tariffs__items-line'></div>
                        }
                    </div>

                    <div className='tariffs__items-right tariffs__items-center'>
                        <p className='tariffs__items-paragraph'>Длительность</p>


                        { data.availableFrom || data.availableTo || days ?
                            (
                                <div>
                                    <div className='tariffs__items-days'>
                                        { days ? filteredDays.map((
                                                day: any,
                                                idx: number
                                            ) =>
                                                <span key={ idx }>
                                                            { daysTitle[day - 1] }{ (filteredDays.length - 1) !== idx ? ',' : '' } &nbsp;
                                        </span>
                                        ) : '' }
                                    </div>
                                    <p className='tariffs__items-time'>
                                        { dateOperations.getTimeFromSeconds(data.availableFrom) } - { dateOperations.getTimeFromSeconds(data.availableTo) }
                                    </p>
                                </div>
                            ) :
                            (
                                <div className='tariffs__items-line'></div>
                            )
                        }
                    </div>
                </div>
            </div>
    )
}
