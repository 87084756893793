import { FC, useEffect, useState } from "react";

import FilterSelect from "../FilterSelect";
import Button from "../form/Button";
import TextField from "../form/TextField";
import { DeviceModel } from "../../api/requests/device/interface";

interface DialogComputersAddProps {
    isType: number
    item?: any
    isEdit?: boolean
    onSave?: (item: any) => void
    deviceList?: DeviceModel[]
    isLoadingRequest?: boolean
    setIsLoadingRequest?: (is: boolean) => void
}

export const DialogComputersAdd: FC<DialogComputersAddProps> = (
    {
        isType,
        item,
        isEdit,
        onSave,
        deviceList,
        isLoadingRequest,
        setIsLoadingRequest
    }
) => {
    const [group, setGroup] = useState([{}]);
    const [selectGroup, setSelectGroup] = useState<any>(null);

    useEffect(() => {
        if (deviceList) {
            const mapDeviceList = deviceList.map(i => ({
                ...i,
                label: i.name,
                value: i.id
            }))

            setGroup(mapDeviceList);

            if (isEdit) {
                const findItem = mapDeviceList.find(i => i.id === item.id);

                findItem && setSelectGroup(findItem);
            }
        }
    }, [isEdit]);

    function onClickSave() {
        setIsLoadingRequest && setIsLoadingRequest(true);

        const requestData = {
            deviceId: selectGroup?.id,
            position: item.position,
            type: isType
        }

        onSave && onSave(requestData)
    }

    return (
        <div className='added__booking__dialog'>
            <div>
                <FilterSelect
                    value={selectGroup}
                    options={group}
                    onChange={setSelectGroup}
                    placeholder={'Выберите устройсво'}
                />
            </div>

            <Button
                loading={isLoadingRequest}
                className={'btn btn-primary map-center__saveBtn'}
                text={isEdit ? 'Изменить устройство' : 'Добавить устройство'}
                onClick={onClickSave}
            />
        </div>
    )
}
