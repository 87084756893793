import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";
import { UserModelLink } from "../../api/requests/staff/interface";

const initialState: any = {
    loading: false,
    data: null,
    error: null,
    success: false,
};

const userSlice = createSlice({
    name: 'person',
    initialState,
    reducers: {
        savePersonData: (state, action: PayloadAction<UserModelLink>) => {
            state.data = action.payload;
        },
    }
});
export const { savePersonData } = userSlice.actions;

export const getPersonData = (state: RootState) => state.person.data;

export default userSlice.reducer;
