export default function ElectroOff({color = "#FF5858", width = 20, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 24 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;"
               filter="url(#filter0_d_1024_16100)">
                <path id="Vector"
                      d="M4.66699 12.5V7.5C4.66699 6.17392 5.19378 4.90215 6.13146 3.96447C7.06914 3.02678 8.34091 2.5 9.66699 2.5H16.3337C17.6597 2.5 18.9315 3.02678 19.8692 3.96447C20.8069 4.90215 21.3337 6.17392 21.3337 7.5V12.5C21.3337 13.8261 20.8069 15.0979 19.8692 16.0355C18.9315 16.9732 17.6597 17.5 16.3337 17.5H9.66699C8.34091 17.5 7.06914 16.9732 6.13146 16.0355C5.19378 15.0979 4.66699 13.8261 4.66699 12.5Z"
                      stroke={color}
                      strokeWidth="1.25"
                />
                <path id="Vector_2" d="M12.7222 6.6665L11.333 9.99984H14.6663L13.2772 13.3332"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector 453" d="M5.5 2L21 18"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                />
            </g>
            <defs>
                <filter id="filter0_d_1024_16100" x="-1" y="0" width="28" height="28" filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB">
                    <feFlood
                        floodOpacity="0" result="BackgroundImageFix"
                    />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"
                    />
                    <feOffset dy="4"
                    />
                    <feGaussianBlur stdDeviation="2"
                    />
                    <feComposite in2="hardAlpha" operator="out"
                    />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1024_16100"
                    />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1024_16100" result="shape"
                    />
                </filter>
            </defs>
        </svg>

    );
}
