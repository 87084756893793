export default function Map({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 15.8333L3.15833 17.2808C3.08311 17.3059 3.00299 17.3128 2.92459 17.3008C2.84619 17.2889 2.77176 17.2584 2.70744 17.212C2.64313 17.1656 2.59077 17.1046 2.5547 17.034C2.51864 16.9633 2.49988 16.8851 2.5 16.8058V4.5275C2.50002 4.42262 2.53303 4.32039 2.59434 4.2353C2.65566 4.1502 2.74218 4.08655 2.84167 4.05333L7.5 2.5M7.5 15.8333L12.5 17.5M7.5 15.8333V2.5M7.5 2.5L12.5 4.16667M12.5 17.5L17.1583 15.9475C17.258 15.9142 17.3446 15.8505 17.4059 15.7652C17.4672 15.6799 17.5002 15.5775 17.5 15.4725V3.19333C17.5 3.1141 17.4811 3.036 17.445 2.96548C17.4089 2.89495 17.3565 2.83403 17.2922 2.78772C17.2279 2.74141 17.1535 2.71104 17.0752 2.69912C16.9969 2.68721 16.9168 2.69407 16.8417 2.71917L12.5 4.16667M12.5 17.5V4.16667"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
