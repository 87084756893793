export default function StarColor({ color = "white", width = 20, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 12 12`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M4.29313 4.11797L5.59213 1.50197C5.62994 1.42626 5.6881 1.36259 5.76008 1.31808C5.83205 1.27357 5.91501 1.25 5.99963 1.25C6.08426 1.25 6.16721 1.27357 6.23919 1.31808C6.31116 1.36259 6.36932 1.42626 6.40713 1.50197L7.70613 4.11797L10.6101 4.53997C10.6939 4.55159 10.7727 4.58652 10.8376 4.64078C10.9024 4.69504 10.9507 4.76644 10.977 4.84683C11.0032 4.92723 11.0063 5.01338 10.9859 5.09545C10.9655 5.17752 10.9224 5.2522 10.8616 5.31097L8.76063 7.34597L9.25663 10.221C9.32013 10.59 8.93013 10.871 8.59663 10.697L5.99963 9.33897L3.40213 10.697C3.06913 10.8715 2.67913 10.59 2.74263 10.2205L3.23863 7.34547L1.13763 5.31047C1.07712 5.25166 1.03433 5.17705 1.01411 5.09513C0.993896 5.01321 0.997067 4.92725 1.02327 4.84704C1.04946 4.76683 1.09764 4.69558 1.16232 4.64139C1.22699 4.58719 1.30557 4.55223 1.38913 4.54047L4.29313 4.11797Z"
                      fill={color}
                />
            </g>
        </svg>
    );
}



