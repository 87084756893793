import { FC, useState } from "react";
import { selectClub } from '../../redux/actions/userActions';
import cn from 'classnames';
import Spinner from "../Spinner";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import TextField from "../form/TextField";
import Search from "../../img/icon/Search";
import Button from "../form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useNavigate } from "react-router-dom";

const SignInClub: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector((state) => state.currentUser);

    const [ searchUser, setSearchUser ] = useState('');

    const [ clubList, setClubList ] = useState([
        {
            id: 1,
            title: 'Test',
            address: 'Мирабад 15',
            selected: true,
            typeList: [
                {
                    name: 'Нет смены',
                    active: false
                },
                {
                    name: 'Trial',
                    active: true
                },
            ]
        },
        {
            id: 2,
            title: 'Test',
            address: 'Мирабад 15',
            selected: false,
            typeList: [
                {
                    name: 'Нет смены',
                    active: false
                },
                {
                    name: 'Trial',
                    active: true
                },
            ]
        },
    ])

    const onSubmit = () => {
        const searchItem = clubList.find(i => i.selected);

        dispatch(selectClub(searchItem));
    };

    const isActive = false;

    function onClickSelectBlock(item: any) {
        const list = [ ...clubList ];

        const listUpdate = list.map(i => {
            i.selected = false;

            if (i.id === item.id) {
                return {
                    ...i,
                    selected: true
                }
            }

            return i
        })

        setClubList(listUpdate)
    }

    function onClickBack() {
        navigate('/')
    }

    return (
        <div className="sign-in-form">
            <div className="sign-in-form-back">
                <Button
                    text={ <ArrowLeft/> }
                    onClick={ onClickBack }
                    className={ 'btn btn-icon' }
                />
            </div>

            <h4>Выберите центр</h4>

            <form>
                <TextField
                    placeholder={ 'Поиск пользователя' }
                    value={ searchUser }
                    onChangeValue={ (value) => setSearchUser(value) }
                    imgLeft={ (<Search/>) }
                />

                <div className={ 'auth__club__list' }>
                    { clubList.map((item, idx) => (
                        <div
                            key={ `auth-club-item-${ idx }` }
                            className={ `auth__club__block ${ item.selected && 'active' }` }
                            onClick={ () => onClickSelectBlock(item) }
                        >
                            <div className="auth__club__block-name">
                                <p>{ item.title }</p>

                                <p className={ 'auth__club__block-address' }>{ item.address }</p>
                            </div>

                            <div className="line"></div>

                            <div className="auth__club__block-type">
                                { item.typeList.map((_item, _idx) => (
                                    <div
                                        key={ `auth-club-item-type-btn-${ _idx }` }
                                        className={ `auth__club__block-type__btn ${ _item.active && 'active' }` }
                                    >
                                        { _item.name }
                                    </div>
                                )) }
                            </div>
                        </div>
                    )) }
                </div>

                <button
                    // disabled={currentUser.loading || step !== 'password'}
                    type="button"
                    className={ cn('btn btn-primary btn-confirm', {'loading': true}) }
                    onClick={ onSubmit }
                >
                    { currentUser.loading ? (
                        <Spinner/>
                    ) : 'Войти' }
                </button>
            </form>
        </div>
    );
}

export default SignInClub;
