import { FC, useEffect, useState } from 'react';
import Button from "../../components/form/Button";
import RoleUsers from "../../mixins/role";
import EditDirection from "./EditDirection";

const DirectionPage: FC = () => {
    const roleUsers = new RoleUsers();

    const isUpdateByRole = roleUsers.getModelRoleByType('avatars', 'update'); //direction

    const [ selectItem, setSelectItem ] = useState<any>(null);

    const [ isShowCreateGroupsModal, setIsShowCreateGroupsModal ] = useState(false);

    const [ list, setList ] = useState<any[]>([]);

    useEffect(() => init(), [])

    function init() {
        // $getListDirection().then(res => {
        //     if (!res.length) return;

            const res = [
                {
                    name: 'Умник',
                    image: '/img/profile.png'
                },
            ]

            setList(res)
        // })
    }

    function onClickSelect(item?: any) {
        setIsShowCreateGroupsModal(true);

        setSelectItem(item);
    }

    return (
        <div className="col-12 col-md-9 settings__content">
            <div className={'settings__content__direction'}>
                <h4>Направление</h4>

                { !!list.length && list.map((item, idx) => (
                    <div className='settings__block'
                         key={ `game-item-list-pro-${ idx }` }
                    >
                        <div className='settings__block-left'>
                            <h1 className='settings__block-title'>{ item.name }</h1>
                        </div>

                        {isUpdateByRole && (
                            <div className='settings__block-right settings__block-right-btn'>
                                <Button
                                    className={ 'btn btn-primary' }
                                    text={ 'Редактировать' }
                                    onClick={ () => onClickSelect(item) }
                                />
                            </div>
                        )}
                    </div>
                )) }
            </div>

            <EditDirection
                selectItem={ selectItem }
                isShow={ isShowCreateGroupsModal }
                setIsShow={ () => {
                    setIsShowCreateGroupsModal(false);
                    setSelectItem(null);
                } }
                updateList={ () => init() }
            />
        </div>
    );
}

export default DirectionPage;
