export default function Comment({color = "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector" d="M14.167 10.4168C14.2775 10.4168 14.3835 10.3729 14.4616 10.2948C14.5398 10.2167 14.5837 10.1107 14.5837 10.0002C14.5837 9.88966 14.5398 9.78367 14.4616 9.70553C14.3835 9.62739 14.2775 9.5835 14.167 9.5835C14.0565 9.5835 13.9505 9.62739 13.8724 9.70553C13.7942 9.78367 13.7503 9.88966 13.7503 10.0002C13.7503 10.1107 13.7942 10.2167 13.8724 10.2948C13.9505 10.3729 14.0565 10.4168 14.167 10.4168ZM10.0003 10.4168C10.1108 10.4168 10.2168 10.3729 10.295 10.2948C10.3731 10.2167 10.417 10.1107 10.417 10.0002C10.417 9.88966 10.3731 9.78367 10.295 9.70553C10.2168 9.62739 10.1108 9.5835 10.0003 9.5835C9.88982 9.5835 9.78384 9.62739 9.7057 9.70553C9.62756 9.78367 9.58366 9.88966 9.58366 10.0002C9.58366 10.1107 9.62756 10.2167 9.7057 10.2948C9.78384 10.3729 9.88982 10.4168 10.0003 10.4168ZM5.83366 10.4168C5.94417 10.4168 6.05015 10.3729 6.12829 10.2948C6.20643 10.2167 6.25033 10.1107 6.25033 10.0002C6.25033 9.88966 6.20643 9.78367 6.12829 9.70553C6.05015 9.62739 5.94417 9.5835 5.83366 9.5835C5.72315 9.5835 5.61717 9.62739 5.53903 9.70553C5.46089 9.78367 5.41699 9.88966 5.41699 10.0002C5.41699 10.1107 5.46089 10.2167 5.53903 10.2948C5.61717 10.3729 5.72315 10.4168 5.83366 10.4168Z"
                      fill={color}
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2" d="M10.0003 18.3332C14.6028 18.3332 18.3337 14.6023 18.3337 9.99984C18.3337 5.39734 14.6028 1.6665 10.0003 1.6665C5.39783 1.6665 1.66699 5.39734 1.66699 9.99984C1.66699 11.5173 2.07283 12.9415 2.78199 14.1665L2.08366 17.9165L5.83366 17.2182C7.09994 17.9507 8.53742 18.3354 10.0003 18.3332Z"
                      stroke={color}
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
