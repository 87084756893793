import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { DashboardCreateGuest, DashboardGetGuest, DashboardListGuest } from "./interface";

export const $createGuest = (data: DashboardCreateGuest, handler?: IApiHandlerParams): Promise<null> => api
    .post('/guest', data, {
        handler
    })

export const $getListGuest = (params: DashboardGetGuest, handler?: IApiHandlerParams): Promise<DashboardListGuest[]> => api
    .get('/guest', {
        request: {params},
        handler
    })

export const $deleteGuest = (id: number, handler?: IApiHandlerParams): Promise<null> => api
    .delete('/guest/single/' + id, {
        // mock: require('./mocks/error.delete.json'),
        handler
    })

