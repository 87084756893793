import { FC, useState } from 'react';
import Button from "../form/Button";
import { useNavigate } from "react-router-dom";

const SidebarFooter: FC = () => {

    const navigate = useNavigate()

    function chatSupport() {
        navigate('/questions')
    }

    return (
        <>
            <div className="sidebar-footer">
                {/*<Button*/}
                {/*    className={'btn btn-primary'}*/}
                {/*    onClick={chatSupport}*/}
                {/*    text={'Чат с поддержкой'}*/}
                {/*    leftIcon={(*/}
                {/*        <>*/}
                {/*            <img src={'/img/icon/form/chat-support.svg'} alt=""/>*/}
                {/*        </>*/}
                {/*    )}*/}
                {/*/>*/}
            </div>
        </>
    );
}

export default SidebarFooter;
