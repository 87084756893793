import { FC, useEffect, useState } from "react";
import AddMedia from "../../img/icon/AddMedia";

interface FileFieldProps {
    isImage?: boolean
    upload: File | string | null
    setUpload: (file: File) => void
    label?: string
}

const FileField: FC<FileFieldProps> = (
    {
        isImage = true,
        upload,
        setUpload,
        label
    }
) => {
    const [ placeholder, setPlaceholder ] = useState('');
    const [ imageURL, setImageURL ] = useState<File | string | null>(null);

    const handleFile = ({target}: any) => {
        const file = target?.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);

            setImageURL(imageURL);

            setUpload(file);

            setPlaceholder(file?.name);
        }
    };

    useEffect(() => {
        if (upload && (typeof upload === 'string')) setImageURL(upload);
    }, [ upload ]);

    return (
        <div className={ 'form-file' }>
            <label htmlFor="file"></label>

            <input type='file' accept='.png, .jpg' onChange={ handleFile } id={ 'file' } hidden/>

            { (imageURL && (typeof imageURL === 'string'))
                ? (<img src={ imageURL }/>)
                : (<AddMedia/>)
            }

            <span>{ placeholder || label || 'Фото' }</span>
        </div>
    )
}

export default FileField;
