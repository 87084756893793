import { FC, useEffect, useState } from "react";
import Close from "../../img/icon/Close";
import DialogContainer from "../../components/dialog/DialogContainer";
import TextField from "../../components/form/TextField";
import Button from "../../components/form/Button";

interface EditDirectionProps {
    selectItem: any
    isShow: boolean
    setIsShow: () => void
    updateList: () => void
}

const EditDirection: FC<EditDirectionProps> = (
    {
        selectItem,
        isShow,
        setIsShow,
        updateList
    },
) => {
    const [ nameValue, setNameValue ] = useState<string>('');

    const getRequestObj = (): any => {
        return {
            name: nameValue
        }
    }

    const isValidForm = () => {
        return nameValue.length
    }

    useEffect(() => init(), [ selectItem ]);

    function init() {
        if (selectItem) {
            setNameValue(selectItem.name)
        }
    }

    function onClickUpdate() {
        // $updateDiscount(selectItem.id, getRequestObj()).then(res => {
        //     if (!res.name) return;

        resetForm();
        setIsShow();
        updateList();
        // })
    }

    function resetForm() {
        setNameValue('');
    }

    return (
        <DialogContainer
            isOpen={ isShow }
            closeModal={ () => {
                setIsShow()
                resetForm()
            }
            }
            label={ selectItem ? 'Редактировать направление' : 'Добавление направление' }
            closeIcon={ <Close/> }
        >
            <div className={ 'create__group__discounts' }>

                <div className="create__group__discounts__info">
                    <div className="create__group__discounts__info__modal">
                        <TextField
                            className={ 'mb-0' }
                            label={ 'Наименование' }
                            value={ nameValue }
                            onChangeValue={ setNameValue }
                        />
                    </div>
                </div>

                <div className="d-flex">
                    <Button
                        className={ `btn ${ isValidForm() ? 'btn-primary' : 'btn-secondary' }` }
                        text={ 'Обновить' }
                        onClick={ onClickUpdate }
                    />
                </div>
            </div>
        </DialogContainer>
    )
}

export default EditDirection;
