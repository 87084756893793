import { FC, useRef, useEffect, useState } from "react";
import { YMaps, Map, Placemark, FullscreenControl } from "@pbe/react-yandex-maps";

import TextField from "../form/TextField";
import Location from "../../img/icon/Location";

interface CardCoordsProps {
    setPlaceMarkCoords: any
    setPhone: any
    phone: string
    placeMarkCoords: any[]
}

export const CardCoords: FC<CardCoordsProps> = (
    {
        placeMarkCoords,
        setPlaceMarkCoords,
        phone,
        setPhone
    }
    ) => {
    const mapRef: any = useRef(null);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.events.add('click', (e: any) => {
                const coordinates = e.get('coords');

                setPlaceMarkCoords(coordinates);
            });
        }
    }, [placeMarkCoords]);

    const handleMapClick = (e: any) => {
        const coordinates = e.get('coords');

        setPlaceMarkCoords(coordinates);
    };

    return (
        <div className="club__add__right">
            <div className="club__header club__add__header">
                <h4>Местоположение центра</h4>
            </div>

            <div className="club__add__content">
                <div className="club__add__content-title">
                    <p>Данные центра</p>
                </div>

                <form>
                    <TextField
                        label={ 'Координаты (Долгота,Широта)' }
                        value={ placeMarkCoords }
                        onChangeValue={ setPlaceMarkCoords }
                        imgRight={ (<Location/>) }
                    />

                    <TextField
                        label={ 'Ваш телефон' }
                        value={ phone }
                        onChangeValue={ setPhone }
                    />
                </form>

                <div style={{ marginTop: 40 }} className="club__add__content-title">
                    <p>Координаты</p>
                </div>

                <YMaps
                    query={{
                        apikey: '54f3528b-2d53-4168-bec4-54e3313aadd8',
                        lang: "ru_RU"
                    }}
                >
                    <div>
                        <Map
                            instanceRef={mapRef}
                            onClick={handleMapClick}
                            width={'100%'}
                            defaultState={{ center: placeMarkCoords, zoom: 10 }}
                        >
                            {placeMarkCoords && (
                                <Placemark
                                    geometry={placeMarkCoords}
                                />
                            )}

                            <FullscreenControl
                                options={{float: 'right'}}
                            />
                        </Map>
                    </div>
                </YMaps>
            </div>
        </div>
    )
}
