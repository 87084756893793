import { FC } from "react";
import DateOperations from "../../mixins/date-operation";

interface ProblemsContentProps {
    item: any
    onClick: () => void
    selectedItem: any
}

export const ProblemsContent: FC<ProblemsContentProps> = (
    {
        item,
        onClick,
        selectedItem
    }
) => {
    const dateOperations = new DateOperations();

    return (
        <div
            onClick={ onClick }
            className={ `messenger__problems ${ selectedItem.id === item.id
                ? 'messenger__problems-active'
                : '' }`
            }
        >
            <div>
                <p>{ item?.user?.name || item?.name }</p>
                <span style={{fontSize: '12px'}}>{ item.title }</span>
            </div>

            <p>{ dateOperations.getFormattedDateByISO(dateOperations.getDate(item.date)) }</p>
        </div>
    )
}
