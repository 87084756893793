import { IApiHandlerParams } from "../../interface";
import api from "../../../api";
import { AvatarsModel, AvatarsModelUpdate } from "./interface"

export const $avatarsCreate = (data: AvatarsModel, handler?: IApiHandlerParams): Promise<AvatarsModelUpdate> => api
    .post('/avatars/single', data, {
        handler
    })

export const $updateAvatars = (id: string, data: AvatarsModel, handler?: IApiHandlerParams): Promise<AvatarsModelUpdate> => api
    .put('/avatars/single/' + id, data, {
        handler
    })

export const $deleteAvatars = (id: string, handler?: IApiHandlerParams): Promise<AvatarsModelUpdate> => api
    .delete('/avatars/single/' + id, {
        handler
    })

export const $getAvatars = (id: string, handler?: IApiHandlerParams): Promise<AvatarsModelUpdate> => api
    .get('/avatars/single' + id, {
        handler
    })

export const $getListAvatars = (handler?: IApiHandlerParams): Promise<AvatarsModelUpdate[]> => api
    .get('/avatars', {
        handler
    })
