export default function Download({color= "white", width=15,height = 14}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 15 14`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="icon">
                <path id="Rectangle 152 (Stroke)"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.2907 8.13983C1.84116 8.13983 1.47674 8.50425 1.47674 8.95378V12.2096C1.47674 12.6591 1.84116 13.0236 2.2907 13.0236H12.7093C13.1588 13.0236 13.5233 12.6591 13.5233 12.2096V8.95378C13.5233 8.50425 13.1588 8.13983 12.7093 8.13983H10.7341C10.4431 9.68344 8.85611 10.3375 7.5 10.3375C6.14389 10.3375 4.5569 9.68344 4.26592 8.13983H2.2907ZM0.5 8.95378C0.5 7.96481 1.30172 7.16309 2.2907 7.16309H4.38372C4.8513 7.16309 5.16016 7.53619 5.21628 7.90327C5.35214 8.79188 6.3062 9.36076 7.5 9.36076C8.6938 9.36076 9.64786 8.79188 9.78372 7.90327C9.83985 7.53619 10.1487 7.16309 10.6163 7.16309H12.7093C13.6983 7.16309 14.5 7.96481 14.5 8.95378V12.2096C14.5 13.1986 13.6983 14.0003 12.7093 14.0003H2.2907C1.30172 14.0003 0.5 13.1986 0.5 12.2096V8.95378Z"
                      fill={color}
                />
                <path id="Vector 383 (Stroke)"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.49991 6.83691C7.23019 6.83691 7.01154 6.61826 7.01154 6.34854L7.01154 0.488077C7.01154 0.218357 7.23019 -0.00029524 7.49991 -0.000295212C7.76963 -0.000295185 7.98828 0.218357 7.98828 0.488077L7.98828 6.34854C7.98828 6.61826 7.76963 6.83691 7.49991 6.83691Z"
                      fill={color}
                />
                <path id="Vector 384 (Stroke)"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.49952 6.1864L5.93673 4.10268C5.77489 3.8869 5.46878 3.84317 5.253 4.005C5.03723 4.16683 4.9935 4.47295 5.15533 4.68872L7.05673 7.22392C7.27812 7.51911 7.72091 7.51911 7.94231 7.22392L9.8437 4.68872C10.0055 4.47295 9.96181 4.16684 9.74603 4.005C9.53025 3.84317 9.22414 3.8869 9.06231 4.10268L7.49952 6.1864Z"
                      fill={color}
                />
            </g>
        </svg>
    );
}
