export default function Logs({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.0833 17.0833L18.3333 18.3333M12.5 15C12.5 15.663 12.7634 16.2989 13.2322 16.7678C13.7011 17.2366 14.337 17.5 15 17.5C15.663 17.5 16.2989 17.2366 16.7678 16.7678C17.2366 16.2989 17.5 15.663 17.5 15C17.5 14.337 17.2366 13.7011 16.7678 13.2322C16.2989 12.7634 15.663 12.5 15 12.5C14.337 12.5 13.7011 12.7634 13.2322 13.2322C12.7634 13.7011 12.5 14.337 12.5 15Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.6666 9.99999V4.79082C16.6666 4.72504 16.6537 4.6599 16.6285 4.59913C16.6034 4.53835 16.5665 4.48314 16.5199 4.43666L13.8966 1.81332C13.8029 1.71953 13.6758 1.66677 13.5433 1.66666H3.83325C3.70064 1.66666 3.57347 1.71933 3.4797 1.8131C3.38593 1.90687 3.33325 2.03405 3.33325 2.16666V17.8333C3.33325 17.9659 3.38593 18.0931 3.4797 18.1869C3.57347 18.2806 3.70064 18.3333 3.83325 18.3333H9.16658"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3333 1.66666V4.49999C13.3333 4.6326 13.3859 4.75977 13.4797 4.85354C13.5735 4.94731 13.7006 4.99999 13.8333 4.99999H16.6666"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
