import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../form/Button";
import { CenterModel, positionCenter } from "../../api/requests/center/interface";
import RoleUsers from "../../mixins/role";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

interface ClubItemInfoProps {
    data: CenterModel
    isActive: boolean
    isLoadingSelect: boolean
    setIsActive: (id: string) => void
}

const ClubItemInfo: FC<ClubItemInfoProps> = (
    {
        data,
        isActive,
        isLoadingSelect,
        setIsActive,
    }
) => {
    const navigate = useNavigate();

    const roleUsers = new RoleUsers();
    const isEditByRole = roleUsers.getModelRoleByType('center', 'update');

    const userData = useSelector(GetCurrentUserData);
    const checkStatusUser = true//(userData?.role === 1) ? true : (userData?.status === 2);

    const role = roleUsers.getNamePositionCenter(data.position, Object.keys(positionCenter)) || '';

    const [isActiveId, setIsActiveId] = useState('');

    useEffect(() => {
        if (!isLoadingSelect) setIsActiveId('');
    }, [isLoadingSelect])

    function onClickEdit() {
        navigate(`edit/${data.id}`, {
            state: {
                current: 'edit',
                data: data
            },
        });
    }

    function onClickSelectItem() {
        setIsActive(data.id);
        setIsActiveId(data.id);
    }

    return (
        <div className={`club__block ${isActive ? 'active' : ''}`}>
            <div className="club__block-header">
                <div className="club__block-header-title">{data?.name}</div>

                <span className="club__block-header-state">
                    { `${data?.country} ${data?.city} ${data?.street} ${data?.house}` }
                </span>
            </div>

            {role && (
                <div className="club__block-type">
                    <span className="">{ role }</span>
                </div>
            )}

            <div className="club__block__btn">
                { (isEditByRole && checkStatusUser) && (
                    <Button
                        className={'btn btn-primary w-100 justify-content-center'}
                        text={'Редактировать'}
                        onClick={onClickEdit}
                        disabled={!userData?.centerId}
                    />
                )}

                <Button
                    className={"btn btn-primary w-100 justify-content-center"}
                    text={isActive ? 'Выбран' : 'Выбрать'}
                    onClick={onClickSelectItem}
                    disabled={isActive}
                    loading={(isActiveId === data.id) && isLoadingSelect}
                />
            </div>
        </div>
    );
}

export default ClubItemInfo;

