import React, {FC, ReactNode, useState,} from "react";
import FilterSelect from "../../FilterSelect";
import EditPersonTariff from "./EditPersonTarrif";
import {TariffsAddFormTemplate} from "../../tariffs";
import Lock from "../../../img/icon/Lock";
import { ToggleBlock } from "../../form/ToggleBlock";
import AddMedia from "../../../img/icon/AddMedia";

interface EditPersonSelectProps {
    toggleValue: boolean
    setToggleValue: (is: boolean) => void
    fileImage: File | string | null
    setFileImage: (file: File) => void
}

const EditPersonSelect: FC<EditPersonSelectProps> = (
    {
        toggleValue,
        setToggleValue,
        fileImage,
        setFileImage
    }
) => {

    return (
        <div className="edit__person__select">
            {/*<EditPersonTariff/>*/}

            {/*<FilterSelect*/}
            {/*    placeholder={'Подробнее о контенте'}*/}
            {/*    value={valueZone}*/}
            {/*    options={zones}*/}
            {/*    onChange={(val) => setValueZone(val)}*/}
            {/*/>*/}

            {/*<FilterSelect*/}
            {/*    placeholder={'Комментарий о клиенте'}*/}
            {/*    value={valueZone}*/}
            {/*    options={zones}*/}
            {/*    onChange={(val) => setValueZone(val)}*/}
            {/*/>*/}

            {/*<FilterSelect*/}
            {/*    placeholder={'Несгораемый тариф'}*/}
            {/*    value={valueZone}*/}
            {/*    options={zones}*/}
            {/*    onChange={(val) => setValueZone(val)}*/}
            {/*/>*/}

            <div className="edit__person__select__switch">
                <p>Статус</p>

                <div className="edit__person__select__switch__inclusion">
                    <ToggleBlock
                        desc={ toggleValue ? 'Активен' : 'Не активен' }
                        isChecked={toggleValue}
                        handleChange={ () => setToggleValue(!toggleValue) }
                    />
                </div>
            </div>

            {/*<div className="edit__person__select__photo">*/}
            {/*    <ImageField*/}
            {/*        upload={fileImage}*/}
            {/*        setUpload={setFileImage}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
}

export default EditPersonSelect;
