import { FC, useEffect, useState } from "react";
import TableContainer from "../../components/form/TableContainer";
import Pagination from "../../components/form/Pagination";
import { $deleteGuest, $getListGuest } from "../../api/requests/guest";
import DateOperations from "../../mixins/date-operation";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

const GuestListPage: FC = () => {
    const dateOperation = new DateOperations();
    const userData = useSelector(GetCurrentUserData);
    const timeZone = userData?.staffCenter?.timezone || 0;

    const [headerTableList, setHeaderTableList] = useState([
        {
            key: 'id',
            title: 'ID',
        },
        // {
        //     key: 'userName',
        //     title: 'Создатель',
        // },
        {
            key: 'email',
            title: 'Почта',
        },
        {
            key: 'createdAt',
            title: 'Дата создание',
        },
    ]);
    const [contentDefaultTableList, setContentDefaultTableList] = useState<any[]>([]);
    const [contentTableList, setContentTableList] = useState<any[]>([]);

    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getChangeResultData = (item: any) => {
        return {
            ...item,
            createdAt: dateOperation.getFormattedDateAndTime(item.createdAt, timeZone),
        }
    }

    useEffect(() => init(), [activePage]);

    function init () {
        $getListGuest({ page: String(activePage) }).then(res => {
            if (!res) return;

            // const totalPages = Math.ceil(res.count / 15);
            //
            // setTotalPages(res.totalPages || totalPages || 1);

            const list = res.map(i => getChangeResultData(i))

            setContentTableList(list);
            setContentDefaultTableList(list);
        })
    }

    function onLeftClickTrContent(item: any) {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteGuest(item.id).then(res => {
            init();
        })
    }

    return (
        <>
            <div className="dashboard__header">
                <div className="dashboard__header__subject mt-3">
                    <h2>Список гостей</h2>
                </div>
            </div>

            <div className="computers__content users__content">
                <div className="computers__content__table guest__content__table">
                    <TableContainer
                        header={ headerTableList }
                        content={ contentTableList }
                        onLeftClickTrContent={ onLeftClickTrContent }
                        isLastRightText={ false }
                    />
                </div>
            </div>

            {(totalPages > 1) && (
                <div className='logs__carousel'>
                    <Pagination
                        activePage={activePage}
                        setActivePage={setActivePage}
                        pages={totalPages}
                        isLoading={isLoading}
                    />
                </div>
            )}
        </>
    );
}

export default GuestListPage;

