import { IApiHandlerParams } from "./interface";

// export const HOST_URL: any = 'https://api-phygital-dashboard.teamtim.tech';
export const HOST_URL: any = 'https://api-phygital-dashboard.myphygital.ru';
export const API_URL: any = HOST_URL + '';

export const API_DEF_CONFIG: IApiHandlerParams = {
    dontShowErrorMessage: false,
	disableLoader: false,
	dontUseApiUrl: false,
	returnData: true,
	dontAuth: false,
	delay: 200
};
