import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import { DashboardRatingCityCreate, RatingCityModel } from "./interface";

export const $createStatisticCity = (data: DashboardRatingCityCreate, handler?: IApiHandlerParams): Promise<RatingCityModel> => api
    .post('/rating-city/single', data, {
        handler
    })

export const $updateStatisticCity = (id: string, data: DashboardRatingCityCreate, handler?: IApiHandlerParams): Promise<RatingCityModel> => api
    .put('/rating-city/single/' + id, data, {
        handler
    })

export const $deleteStatisticCity = (id: string, handler?: IApiHandlerParams): Promise<RatingCityModel> => api
    .delete('/rating-city/single/' + id, {
        handler
    })

export const $getStatisticCity = (params: { cityId: string }, handler?: IApiHandlerParams): Promise<RatingCityModel[]> => api
    .get('/rating-city',{
        request: { params },
        handler
    })
