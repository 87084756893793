import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import {
    CenterPromotionDataModel,
    CenterPromotionRequestData,
    CenterPromotionResponseData,
    DashboardCreatePromotionCategory,
    PromotionCategoryList,
    PromotionCategoryModel
} from "./interface";
import { AchievementPageRequestData } from "../achievement/interface";

export const $getPromotionList = (params?: CenterPromotionRequestData, handler?: IApiHandlerParams): Promise<CenterPromotionResponseData> => api
    .get('/promotion', {
        request: {params},
        handler
    });

export const $createPromotion = (data: CenterPromotionDataModel, handler?: IApiHandlerParams): Promise<CenterPromotionDataModel> => api
    .post('/promotion/single', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $getPromotionListById = (id: string, handler?: IApiHandlerParams): Promise<CenterPromotionDataModel> => api
    .get('/promotion/single/' + id, {
        handler
    });

export const $updatePromotion = (id: string, data: CenterPromotionRequestData, handler?: IApiHandlerParams): Promise<CenterPromotionDataModel> => api
    .put('/promotion/single/' + id, data, {
        handler
    });

export const $deletePromotion = (id: string, handler?: IApiHandlerParams): Promise<CenterPromotionDataModel> => api
    .delete('/promotion/single/' + id, {
        handler
    });

export const $createCategoryPromotion = (data: DashboardCreatePromotionCategory, handler?: IApiHandlerParams): Promise<PromotionCategoryModel> => api
    .post('/promotion/category/single', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $getCategoryPromotionListById = (id: string, handler?: IApiHandlerParams): Promise<PromotionCategoryModel> => api
    .get('/promotion/category/single/' + id, {
        handler
    });

export const $updateCategoryPromotion = (id: string, data: DashboardCreatePromotionCategory, handler?: IApiHandlerParams): Promise<PromotionCategoryModel> => api
    .put('/promotion/category/single/' + id, data, {
        handler
    });

export const $deleteCategoryPromotion = (id: string, handler?: IApiHandlerParams): Promise<PromotionCategoryModel> => api
    .delete('/promotion/category/single/' + id, {
        handler
    });

export const $getCategoryPromotionList = (params?: AchievementPageRequestData, handler?: IApiHandlerParams): Promise<PromotionCategoryList> => api
    .get('/promotion/category', {
        request: {params},
        handler
    });
