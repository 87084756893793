export default function Printer({color= "white", size = 18}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="printer-alt 1">
                <path id="Vector"
                      d="M12.75 9.7575L12.7575 9.74925M5.25 12.75H12.75M4.5 7.5V2.7C4.5 2.58065 4.54741 2.46619 4.6318 2.3818C4.71619 2.29741 4.83065 2.25 4.95 2.25H13.05C13.1693 2.25 13.2838 2.29741 13.3682 2.3818C13.4526 2.46619 13.5 2.58065 13.5 2.7V7.5M15.75 15.3V10.5C15.75 9.70435 15.4339 8.94129 14.8713 8.37868C14.3087 7.81607 13.5456 7.5 12.75 7.5H5.25C4.45435 7.5 3.69129 7.81607 3.12868 8.37868C2.56607 8.94129 2.25 9.70435 2.25 10.5V15.3C2.25 15.4193 2.29741 15.5338 2.3818 15.6182C2.46619 15.7026 2.58065 15.75 2.7 15.75H15.3C15.4193 15.75 15.5338 15.7026 15.6182 15.6182C15.7026 15.5338 15.75 15.4193 15.75 15.3Z"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
