import { FC, useEffect, useState } from "react";
import FilterSelect from "../FilterSelect";
import { $getSelfStaffList, $selectCenterStaff } from "../../api/requests/staff";
import { UserModelLink } from "../../api/requests/staff/interface";
import { useSelector } from "react-redux";
import { GetCurrentUserData, changeCenter, changeReloadPage } from "../../redux/slices/userSlice";
import { useAppDispatch } from "../../redux/store";

interface DropdownCenterProps {
}

interface DropdownData extends UserModelLink {
    label: string
    value: string
}

const DropdownCenter: FC<DropdownCenterProps> = ({}) => {
    const [ valueCountry, setValueCountry ] = useState<DropdownData | null>(null);

    const [ countries, setCountries ] = useState<DropdownData[]>([]);

    const userData = useSelector(GetCurrentUserData);
    const dispatch = useAppDispatch();

    const isDisabled: boolean = userData ? [9, 10].includes(userData.role) : false

    useEffect(() => {
        if (userData && userData.id && !countries.length) init();

        if (userData && userData.id && !!countries.length) {
            const searchSelectCenter = countries.find(item => item.id === userData.centerId);

            if (searchSelectCenter) {
                setValueCountry(searchSelectCenter);
                dispatch(changeCenter(searchSelectCenter));
            }
        }
    }, [ userData, countries ]);

    function init() {
        $getSelfStaffList().then((r) => {
            if (!r || !r.length) return;

            setCountries(r.map((i: UserModelLink) => {
                return {
                    ...i,
                    label: i.name,
                    value: i.id
                }
            }))

            resetDropdown(r);
        })
    }

    function onClickChangeCenter(item: any) {
        if (valueCountry && (item.id === valueCountry.id)) return;

        $selectCenterStaff(item.id).then((i) => {
            if (!i) return resetDropdown(countries);

            const searchSelectCenter = countries.find(item => item.id === i.centerId);

            if (searchSelectCenter) {
                setValueCountry(searchSelectCenter);
                dispatch(changeReloadPage(true));
                dispatch(changeCenter(searchSelectCenter));
                window.location.reload();
            }
        })
    }

    function resetDropdown(arr: UserModelLink[]) {
        const searchSelectCenter = arr.find(i => i.id === userData?.centerId);

        searchSelectCenter ? setValueCountry({
            ...searchSelectCenter,
            label: searchSelectCenter.name,
            value: searchSelectCenter.id
        }) : (countries.length && setValueCountry({
            ...countries[0],
            label: countries[0].name,
            value: countries[0].id
        }))
    }

    return (
        <div className={ 'header__map' }>
            <FilterSelect
                placeholder={ 'Город' }
                value={ valueCountry }
                options={ countries }
                onChange={ onClickChangeCenter }
                className={ 'header__map-dropdown' }
                disabled={ isDisabled }
                imgLeft={ (
                    <>
                        <img src="/img/icon/location.svg" alt=""/>
                    </>
                ) }
            />
        </div>
    );
}

export default DropdownCenter;
