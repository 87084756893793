import { FC, useEffect, useState } from "react";
import TextField from "../form/TextField";
import DialogContainer from "../dialog/DialogContainer";
import Button from "../form/Button";
import Close from "../../img/icon/Close";
import Percentage from "../../img/icon/Percentage";
import { ToggleBlock } from "../form/ToggleBlock";
import { $createDiscount, $deleteDiscount, $updateDiscount } from "../../api/requests/discount";
import { CenterDiscountDataModel } from "../../api/requests/discount/interface";

interface CreateGroupDiscountsProps {
    selectItem: any
    isShow: boolean
    isPromo?: boolean
    setIsShow: () => void
    updateList: () => void
}

const CreateGroupDiscounts: FC<CreateGroupDiscountsProps> = (
    {
        selectItem,
        isShow,
        isPromo,
        setIsShow,
        updateList
    },
) => {
    const [ nameValue, setNameValue ] = useState<string>('');
    const [ percentValue, setPercentValue ] = useState<string>('');
    const [ limitValue, setLimitValue ] = useState<string>('');
    const [ isChecked, setIsChecked ] = useState<boolean>(false);

    const getRequestObj = (): CenterDiscountDataModel => {
        return {
            name: nameValue,
            percent: +percentValue,
            limit: +limitValue,
            isActive: isChecked,
            type: isPromo ? 2 : 1,
        }
    }

    const isValidForm = () => {
        return nameValue.length && String(percentValue).length
    }

    useEffect(() => init(), [ selectItem ]);

    function init() {
        if (selectItem) {
            setNameValue(selectItem.name)
            setPercentValue(selectItem.percent)
            setLimitValue(selectItem.limit)
            setIsChecked(selectItem.isActive)
        }
    }

    function onClickSave() {
        if (selectItem) return onClickUpdate();

        $createDiscount(getRequestObj()).then(res => {
            if (!res.name) return;

            resetForm();
            setIsShow();
            updateList();
        })
    }

    function onClickUpdate() {
        $updateDiscount(selectItem.id, getRequestObj()).then(res => {
            if (!res.name) return;

            resetForm();
            setIsShow();
            updateList();
        })
    }

    function onClickDelete() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteDiscount(selectItem.id).then(res => {
            resetForm();
            setIsShow();
            updateList();
        })
    }

    function resetForm() {
        setNameValue('');
        setPercentValue('');
        setIsChecked(false);
    }

    return (
        <DialogContainer
            isOpen={ isShow }
            closeModal={ () => {
                setIsShow()
                resetForm()
            }
            }
            label={ selectItem ? `Редактировать ${isPromo ? 'промокода' : 'скидки'}` : `Добавление ${isPromo ? 'промокода' : 'скидки'}` }
            closeIcon={ <Close /> }
        >
            <div className={ 'create__group__discounts' }>

                <div className="create__group__discounts__info">
                    <div className="create__group__discounts__info__modal">
                        <TextField
                            className={ 'mb-0' }
                            label={ 'Наименование' }
                            value={ nameValue }
                            onChangeValue={ setNameValue }
                        />
                    </div>

                    <div className="create__group__discounts__info__modal">
                        <TextField
                            type={ 'number' }
                            imgRight={ (
                                <Percentage
                                    size={ 15 }
                                />
                            ) }
                            className={ 'mb-0' }
                            label={ 'Значение' }
                            value={ percentValue }
                            onChangeValue={ setPercentValue }
                        />
                    </div>

                    { isPromo && (
                        <div style={{color: '#ffffff'}} className="create__group__discounts__info__modal">
                            <TextField
                                type={ 'number' }
                                className={ 'mb-0' }
                                imgRight={ 'шт' }
                                label={ 'Ограничение количества' }
                                value={ limitValue }
                                onChangeValue={ setLimitValue }
                            />
                        </div>
                    ) }

                    <div className="create__group__discounts__info__modal">
                        <ToggleBlock
                            isChecked={ isChecked }
                            handleChange={ setIsChecked }
                            desc={ 'Включен' }
                        />
                    </div>
                </div>

                <div className="d-flex">
                    { selectItem && (
                        <Button
                            className={ `btn btn-danger` }
                            text={ 'Удалить' }
                            onClick={ onClickDelete }
                        />
                    ) }

                    <Button
                        className={ `btn ${ isValidForm() ? 'btn-primary' : 'btn-secondary' }` }
                        text={ 'Сохранить' }
                        onClick={ onClickSave }
                    />
                </div>
            </div>
        </DialogContainer>
    )
}

export default CreateGroupDiscounts;
