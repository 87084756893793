import { FC } from "react";


interface EditPersonListProps {
    title: string
}


const EditPersonList: FC<EditPersonListProps> = (
    {
        title
    }
) => {


    return (
        <div className="edit__block__item__search__phone">
            <p>
                { title }
            </p>
        </div>
    );
}

export default EditPersonList;
