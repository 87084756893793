import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { SeasonsModel, SeasonsModelGet, SeasonsFrom, SeasonsMix, SeasonModel } from "./interface"

export const $getSeasons = (handler?: IApiHandlerParams): Promise<SeasonModel[]> => api
    .get('/season', {
        handler
    })

export const $getSeasonsById = (id: number, params: SeasonsModelGet, handler?: IApiHandlerParams): Promise<any> => api
    .get('/season/single/' + id, {
        request: { params },
        handler
    })

export const $updateSeasons = (id: number, data: SeasonsFrom, handler?: IApiHandlerParams): Promise<SeasonsModel> => api
    .put('/season/single/' + id, data, {
        handler
    })

export const $deleteSeasons = (id: number, handler?: IApiHandlerParams): Promise<SeasonsMix> => api
    .delete('/season/single/' + id, {
        handler
    })

export const $createSeasons = (data: SeasonsFrom, handler?: IApiHandlerParams): Promise<SeasonsMix> => api
    .post('/season/single', data, {
        handler
    })
