import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignInLayout from '../layouts/SignInLayout';
import AdminLayout from '../layouts/AdminLayout';
import Login from "../containers/login";
import NotFound from "../components/NotFound";
import ProtectedRoute from "../components/ProtectedRoute";
import Auth from "../containers/login/auth";
import BookingPage from "../containers/booking";
import DashboardPage from "../containers/dashboard";
import ComputersPage from "../containers/computers";
import IconsContainer from "../components/users/IconsContainer";
import AdminNonAsideLayout from "../layouts/AdminNonAsideLayout";
import ClubsPage from "../containers/clubs";
import ClubsAddPage from "../containers/clubs/ClubsAddPage";
import UsersPage from "../containers/users";
import EditTableUser from "../containers/users/EditTableUser";
import Person from "../containers/person";
import EditPerson from "../components/person/editing/EditPerson";
import { SettingsPage } from "../containers/settings";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../redux/slices/userSlice";
import Discounts from "../containers/discounts";
import MapPcCenterPage from "../containers/map-center";
import MapConsoleCenterPage from "../containers/map-center/MapConsoleCenterPage";
import ProgramPage from "../containers/programs";
import FormGameBlock from "../containers/programs/AddGamePage";
import FormProgramBlock from "../containers/programs/AddProgramPage";
import { AchievementsPage } from "../containers/achievements";
import { EditAchievements } from "../components/achievements/editing/EditAchievements";
import DisciplinePage from "../containers/discipline";
import DisciplineAddPage from "../containers/discipline/DisciplineAddPage";
import { Reports } from "../containers/reports";
import PromoCode from "../containers/promo-code";
import TournamentsGridPage from "../containers/tournaments/grid";
import { StocksPage } from "../containers/stocks";
import { EditStocks } from "../components/stocks/EditStocks";
import { EditNews } from "../components/news/EditNews";
import { NewsPage } from "../containers/news";
import GameTournament from "../containers/tournaments";
import EditTournamentGame from "../containers/tournaments/tournamentEditGame/EditTournaement";
import TournamentPage from "../containers/tournaments/AddTournamentPage";
import { Questions } from "../containers/questions";
import { LogsPage } from "../containers/logs";
import AvatarsPage from "../containers/avatars";
import AvatarsAddPage from "../containers/avatars/AvatarsAddPage";
import { Messenger } from "../containers/questions/Messenger";
import { NotificationPage } from "../containers/notification";
import NotificationAddPage from "../containers/notification/NotificationAddPage";
import { QuestionEdit } from "../components/questions/editing/questionEdit";
import SeasonsPage from "../containers/seasons";
import { Tariffs2 } from "../containers/tariffs2";
import AddTariffs from "../containers/tariffs2/AddTariffs";
import GuestPage from "../containers/guest";
import DirectionPage from "../containers/direction";
import RatingByCityClubs from "../containers/clubs/RatingByCityClubs";
import MapVrCenterPage from "../containers/map-center/MapVrCenterPage";
import QrPage from "../containers/qr";

const RoutesComponent = () => {
    const userData = useSelector(GetCurrentUserData);

    const redirectTo = (url: string): string => {
        if (window.location.pathname !== url) window.location.href = url;

        return url
    }

    const isRouterMain = (): string => {
        if (userData?.role === 9) return redirectTo('/guest')

        if (userData?.role === 10) return redirectTo('/qr')

        return userData?.centerId === null ? '/non/game' : '/dashboard'
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={ <SignInLayout/> }
                >
                    <Route path="/auth" element={ <Login/> }/>

                    <Route path="/verification" element={ <Auth/> }/>
                </Route>

                <Route
                    element={ <AdminLayout/> }
                >
                    <Route path="/" element={ <ProtectedRoute/> }>
                        <Route index element={<Navigate to={isRouterMain()} replace/>}/>

                        <Route path="icons" element={ <IconsContainer/> }/>

                        <Route path="dashboard" element={ <DashboardPage/> }/>

                        <Route path='map/center'>
                            <Route path="computers" element={ <MapPcCenterPage/> }/>
                            <Route path="console" element={ <MapConsoleCenterPage/> }/>
                            <Route path="vr" element={ <MapVrCenterPage/> }/>
                        </Route>

                        <Route path=''>
                            <Route path="computers" element={ <ComputersPage type={'comp'}/> }/>

                            <Route path="console" element={ <ComputersPage type={'console'}/> }/>

                            <Route path="vr" element={ <ComputersPage type={'vr'}/> }/>

                            <Route path="open-area" element={ <ComputersPage type={'open-area'}/> }/>
                        </Route>

                        <Route path="booking" element={ <BookingPage/> }/>

                        <Route path='seasons' element={ <SeasonsPage/> } />

                        <Route path='users'>
                            <Route path="" element={ <UsersPage/> }/>

                            <Route path="edit/:id" element={ <EditTableUser/> }/>
                            <Route path="create" element={ <EditTableUser/> }/>
                        </Route>

                        <Route path='tariffs'>
                            <Route path="" element={ <Tariffs2/> }/>

                            <Route path="add" element={ <AddTariffs/> }/>

                            <Route path="edit" element={ <AddTariffs/> }/>
                        </Route>

                        <Route path=''>
                            <Route path="discounts" element={ <Discounts/> }/>

                            <Route path="promo-code" element={ <PromoCode/> }/>
                        </Route>

                        <Route path=''>
                            <Route path='programsGame'>
                                <Route path="" element={ <ProgramPage/> }/>
                                <Route path="addGame" element={ <FormGameBlock/> }/>
                                <Route path="addPrograms" element={ <FormProgramBlock/> }/>
                            </Route>

                            <Route path='achievements'>
                                <Route path="" element={ <AchievementsPage/> }/>
                                <Route path="edit" element={ <EditAchievements/> }/>
                                <Route path="create" element={ <EditAchievements/> }/>
                            </Route>

                            <Route path='discipline'>
                                <Route path="" element={ <DisciplinePage/> }/>
                                <Route path="edit" element={ <DisciplineAddPage/> }/>
                                <Route path="create" element={ <DisciplineAddPage/> }/>
                            </Route>
                        </Route>

                        <Route path=''>
                            <Route path='news'>
                                <Route path="" element={ <NewsPage/> }/>
                                <Route path="edit" element={ <EditNews/> }/>
                                <Route path="create" element={ <EditNews/> }/>
                            </Route>

                            <Route path='stocks'>
                                <Route path="" element={ <StocksPage/> }/>
                                <Route path="edit" element={ <EditStocks/> }/>
                                <Route path="create" element={ <EditStocks/> }/>
                            </Route>
                        </Route>

                        <Route path='tournaments'>
                            <Route path="grid" element={ <TournamentsGridPage/> }/>

                            <Route path='list' element={<GameTournament/>}/>

                            <Route path='view/:id' element={<EditTournamentGame/>}/>

                            <Route path='edit' element={<TournamentPage/>}/>
                            <Route path='create' element={<TournamentPage/>}/>
                        </Route>

                        <Route path='questions' element={<Questions/>}/>

                        <Route path='reports'>
                            <Route path="shift" element={ <Reports/> }/>
                            <Route path="overview" element={ <Reports/> }/>
                            <Route path="user" element={ <Reports/> }/>
                            <Route path="unique" element={ <Reports/> }/>
                            <Route path="occupancy" element={ <Reports/> }/>
                            <Route path="collection" element={ <Reports/> }/>
                            <Route path="ratingCity" element={ <Reports/> }/>
                            <Route path="seasons" element={ <Reports/> }/>
                            <Route path='rating' element={ <Reports/> } />
                        </Route>

                        <Route path='logs' element={<LogsPage/>}/>

                        <Route path='avatars'>
                            <Route path="" element={ <AvatarsPage/> }/>

                            <Route path='edit' element={ <AvatarsAddPage/> }/>
                            <Route path='create' element={ <AvatarsAddPage/> }/>
                        </Route>

                        <Route path='direction'>
                            <Route path="" element={ <DirectionPage/> }/>
                        </Route>

                        <Route path='person'>
                            <Route path="" element={ <Person/> }/>

                            <Route path="edit/:id" element={ <EditPerson/> }/>
                            <Route path="create" element={ <EditPerson/> }/>
                        </Route>

                        <Route path='notifications'>
                            <Route path="" element={ <NotificationPage/> }/>

                            <Route path='view/:id' element={<EditTournamentGame/>}/>

                            <Route path='edit' element={<NotificationAddPage/>}/>
                            <Route path='create' element={<NotificationAddPage/>}/>
                        </Route>

                        <Route path='questions'>
                            <Route path="" element={ <Questions/> }/>

                            <Route path='add' element={<QuestionEdit/>}/>
                            <Route path='edit' element={<QuestionEdit/>}/>
                        </Route>

                        <Route path="feedback" element={ <Messenger/> }/>

                        <Route path="settings" element={ <SettingsPage/> }/>
                    </Route>
                </Route>

                <Route
                    element={ <AdminNonAsideLayout/> }
                >
                    <Route path="/non">
                        <Route path='game'>
                            <Route path="" element={ <ClubsPage/> }/>

                            <Route path="edit/:id" element={ <ClubsAddPage/> }/>
                            <Route path="create" element={ <ClubsAddPage/> }/>
                            <Route path="rating-city" element={ <RatingByCityClubs/> }/>
                        </Route>
                    </Route>

                    <Route path="/">
                        <Route path='guest'>
                            <Route path="" element={ <GuestPage/> }/>
                        </Route>

                        <Route path='qr'>
                            <Route path="" element={ <QrPage/> }/>
                        </Route>
                    </Route>
                </Route>

                <Route
                    path={ '*' }
                    element={ <NotFound/> }
                />
            </Routes>
        </BrowserRouter>
    );
};
export default RoutesComponent;
