export default function Moon({color= "white", size = 18}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 18 18`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector"
                      d="M2.25 8.63025C2.24896 10.2368 2.79132 11.7966 3.78895 13.0559C4.78657 14.3152 6.18079 15.2001 7.74499 15.5668C9.3092 15.9334 10.9514 15.7602 12.4047 15.0753C13.8581 14.3905 15.037 13.2342 15.75 11.7945C9.36975 11.7945 6.2055 8.6295 6.2055 2.25C5.01738 2.83955 4.01754 3.74924 3.31866 4.87653C2.61979 6.00381 2.24966 7.3039 2.25 8.63025Z"
                      stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                      strokeLinejoin="round"/>
            </g>
        </svg>
    );
}
