import { FC, useEffect, useState } from "react";
import TextField from "../../components/form/TextField";
import Search from "../../img/icon/Search";
import FilterSelect from "../../components/FilterSelect";
import { LogItem } from "../../components/logs/logItem";
import Pagination from "../../components/form/Pagination";
import DateOperations from "../../mixins/date-operation";
import { $getLogsList } from "../../api/requests/logs";

export const LogsPage: FC = () => {
    const dateOperations = new DateOperations();

    const [ logItems, setLogItems ] = useState<any[]>([]);
    const [ logFilterItems, setFilterLogItems ] = useState<any[]>([]);
    const [ searchValue, setSearchValue ] = useState<string>('');
    const [ activePage, setActivePage ] = useState(1);
    const [ totalPages, setTotalPages ] = useState(1);

    const actionType = [
        'Все',
        'Авторизация',
        'Смена',
        'Достижения',
        'Аватары',
        'Дисциплины',
        'FAQ',
        'Настройки',
        'Турнир',
        'Заявка на турнир',
        'Управление клиентом',
        'Управление сотрудником',
        'Управление играми',
        'Управление бронированием',
        'Управление устройствами',
        'Управление лояльностью',
        'Гостевой пропуск',
        'Новости',
        'Уведомление',
        'Акции',
        'Тарифы',
        'Статистика',
        'Зоны',
        'Центры',
        'Рейтинг центров',
        'Обратная связь',
        'Сезоны',
    ]

    const getStatusListChange = (list: any[]): any[] => {
        return [...list].map((i: string, idx) => ({
            label: i,
            value: idx
        }))
    }

    const [ statusList, setStatusList ] = useState<any[]>(getStatusListChange(actionType));
    const [ selectStatus, setSelectStatus ] = useState<any>(null);

    const groupByDate = (data: any[]) => {
        const groupedData: any[] = [];

        data.forEach((item) => {
            const existingGroup = groupedData.find((group) => group.date === item.date);

            if (existingGroup) {
                existingGroup.list.push({
                    text: item.text,
                    type: item.type,
                    date: item.date,
                    device: item.device,
                    user: item.user,
                    desc: item.desc,
                });
            } else {
                groupedData.push({
                    date: item.date,
                    list: [
                        {
                            text: item.text,
                            type: item.type,
                            date: item.date,
                            device: item.device,
                            user: item.user,
                            desc: item.desc,
                        },
                    ],
                });
            }
        });

        // Сортировка групп по дате, начиная с текущей даты
        // const currentDate = new Date().toLocaleDateString();

        // groupedData.sort((a, b) => {
        //     if (a.date === currentDate) return -1;
        //     if (b.date === currentDate) return 1;
        //
        //     return ((new Date(a.date) as any) - (new Date(b.date) as any));
        // });

        return groupedData;
    };

    const getOnChangeResponseData = (item: any): any => {
        return {
            ...item,
            date: item.updatedAt,
            device: item.center?.name,
            user: item.user?.name,
            desc: item.text,
            type: item.action,
            text: actionType[item.action + 1]
        }
    }

    useEffect(() => init(), [activePage]);

    function init() {
        $getLogsList({
            page: String(activePage)
        }).then(res => {
            if (!res.result) return;

            const totalPages = Math.ceil(res.count / 15);

            setTotalPages(res.totalPages || totalPages || 1);

            const listUpdate = groupByDate(res.result.map((i: any) => getOnChangeResponseData(i)));

            setLogItems(listUpdate);
            setFilterLogItems(listUpdate);
        })
    }

    function onChangeSearchValue(value: string) {
        setSearchValue(value);

        const listUpdate = [...logItems].filter(i => i.list.some((_i: any) => _i.text.toLowerCase().includes(value.toLowerCase())));

        setFilterLogItems(listUpdate);
    }

    function onChangeStatus(item: any) {
        setSelectStatus(item);

        const listUpdate = [...logItems].filter(i => i.list.some((_i: any) => _i.type === (item.value - 1)));

        setFilterLogItems(item.value === 0 ? logItems : listUpdate);
    }

    return (
        <div className='logs list-wrapper'>
            <div className="booking__header">
                <h1>Логи</h1>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 logs-style'>
                        <div className='logs__header'>
                            <div className='logs__header-search'>
                                <TextField
                                    imgLeft={ <Search/> }
                                    placeholder={ 'Поиск логи' }
                                    value={ searchValue }
                                    onChangeValue={ onChangeSearchValue }
                                />
                            </div>

                            <div className='logs__header-select'>
                                <FilterSelect
                                    value={ selectStatus }
                                    options={ statusList }
                                    onChange={ onChangeStatus }
                                    isSearchable
                                />
                            </div>
                        </div>

                        {!!logFilterItems.length && logFilterItems.map(({date, list}, idx) => (
                            <div
                                className='logs__content'
                                key={`logs-content-item-${idx}`}
                            >
                                <h1 className='logs-title'>{ dateOperations.getFormattedDateByISO(dateOperations.getDate(date)) }</h1>

                                <div className='logs__content-items'>
                                    { list.length && list.map((item: any, index: number) => (
                                        <LogItem
                                            key={`logs-item-${index}`}
                                            item={ item }
                                        />
                                    )) }
                                </div>
                            </div>
                        )) || null }

                        {totalPages > 1 && (
                            <div className='logs__carousel'>
                                <Pagination
                                    activePage={activePage}
                                    setActivePage={setActivePage}
                                    pages={totalPages}
                                />
                            </div>
                        )}

                        {!logFilterItems.length && (
                            <div className="program__block__item__appellation">
                                <p>
                                    Список журналов пуст
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
