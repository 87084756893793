import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/form/Button";
import cn from "classnames";
import Content from "../../img/icon/Content";
import { ShiftReport, UserReport, OverviewReport } from "../../components/reports";
import { CollectionReport } from "../../components/reports/CollectionReport";
import { OccupancyReport } from "../../components/reports/OccupancyReport";
import { RatingByCityReport } from "../../components/reports/RatingByCityReport";
import RoleUsers from "../../mixins/role";
import {SeasonsReport} from "../../components/reports/SeasonsReport";
import {RatingReport} from "../../components/reports/RatingReport";

export const Reports: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentLocationLink = location.pathname.replace('/reports', '');

    const roleUsers = new RoleUsers();

    const isCollectionByRole = roleUsers.getModelRoleByType('reportsCollection', 'read');
    const isOccupancyByRole = roleUsers.getModelRoleByType('reportsOccupancy', 'read');
    const isOverviewByRole = roleUsers.getModelRoleByType('reportsOverview', 'read');
    const isUsersByRole = roleUsers.getModelRoleByType('reportsUsers', 'read');
    const isUniqueByRole = roleUsers.getModelRoleByType('reportsUnique', 'read');
    const isShiftByRole = roleUsers.getModelRoleByType('reportsShift', 'read');

    const headerItems= [
        {
            title: 'Отчет по сменам',
            link: '/shift',
            icon: <Content/>,
            available: isShiftByRole
        },
        {
            title: 'Обзорный отчет',
            link: '/overview',
            icon: <Content/>,
            available: isOverviewByRole
        },
        {
            title: 'Отчёт по пользователям',
            link: '/user',
            icon: <Content/>,
            available: isUsersByRole
        },
        // {
        //     title: 'Уникальные посетители',
        //     link: '/unique',
        //     icon: <Content/>,
        //     available: ''
        // },
        {
            title: 'Занятость',
            link: '/occupancy',
            icon: <Content/>,
            available: isOccupancyByRole
        },
        {
            title: 'Инкассация',
            link: '/collection',
            icon: <Content/>,
            available: isCollectionByRole
        },
        {
            title: 'Рейтинг ФЦ',
            link: '/ratingCity',
            icon: <Content/>,
            available: true
        },
        {
            title: 'Рейтинг городов',
            link: '/rating',
            icon: <Content/>,
            available: true
        },
        {
            title: 'Сезоны',
            link: '/seasons',
            icon: <Content/>,
            available: true
        },
    ];

    const onNavigate = (link: any,) => {
        navigate(`/reports${ link }`);
    }

    const renderedItems = headerItems.map((item: any, index: number) => {
        const isActive = item.link === currentLocationLink;

        if (!item.available) return null;

        return (
            <Button
                className={ `reports__header-button ${isActive ? 'reports__header-active' : ''}` }
                key={ index }
                text={ item.title }
                onClick={ () => onNavigate(item.link) }
                leftIcon={ item.icon }
            />
        );
    })

    return (
        <div className='reports list-wrapper'>
            <div className="booking__header">
                <h1>Отчеты</h1>
            </div>
            <div className="reports-main">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 reports-style'>
                            <div className='reports__header'>
                                { renderedItems }
                            </div>

                            { ((currentLocationLink === '/shift') && isShiftByRole) && (
                                <ShiftReport />
                            ) }

                            { ((currentLocationLink === '/overview') && isOverviewByRole) && (
                                <OverviewReport />
                            ) }

                            { ((currentLocationLink === '/user') && isUsersByRole) && (
                                <UserReport />
                            ) }

                            {((currentLocationLink === '/collection') && isCollectionByRole) && (
                                <CollectionReport />
                            )}

                            {((currentLocationLink === '/occupancy') && isOccupancyByRole) && (
                                <OccupancyReport />
                            )}

                            {((currentLocationLink === '/ratingCity')) && (
                                <RatingByCityReport />
                            )}

                            {((currentLocationLink === '/rating')) && (
                                <RatingReport />
                            )}

                            {((currentLocationLink === '/seasons')) && (
                                <SeasonsReport />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
