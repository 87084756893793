import { FC, useEffect } from "react";

import { getCurrentUser } from "./redux/actions/userActions";
import { useAppDispatch, useAppSelector } from "./redux/store";

import Cookies from "js-cookie";
import RoutesComponent from "./routes";
import { selectLoading } from "./redux/reducers/loading/reducer";
import Spinner from "./components/Spinner";

const App: FC = () => {
    const dispatch = useAppDispatch();

    const loadingIsActive = useAppSelector(selectLoading);

    useEffect(() => {
        if (Cookies.get('token')) {
            dispatch(getCurrentUser());
        }
    }, [ dispatch ]);

    return (
        <>
            <RoutesComponent/>

            { loadingIsActive && (
                <div className="spinner__wrapper">
                    <div className="spinner-block">
                        <Spinner />
                    </div>
                </div>
            ) }
        </>
    )
}
export default App;
