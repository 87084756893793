import { FC, useState } from "react";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useNavigate } from "react-router-dom";
import TextField from "../../components/form/TextField";
import CheckBox from "../../components/form/CheckBox";
import { $createGuest } from "../../api/requests/guest";

const GuestPage: FC = () => {
    const navigate = useNavigate();

    const [ email, setEmail ] = useState('');
    const [ isChecked1, setIsChecked1 ] = useState(true);
    const [ isChecked2, setIsChecked2 ] = useState(false);
    const [ isChecked3, setIsChecked3 ] = useState(false);
    const [ isChecked4, setIsChecked4 ] = useState(false);

    const [ isValidEmail, setIsValidEmail ] = useState(true);

    const validateEmail = (email: string) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailPattern.test(email);
    };

    function onClickBack() {
        navigate("/users");
    }

    function onClickNext() {
        setIsValidEmail(validateEmail(email));

        if (!isChecked1 || !isChecked2 || !isChecked3 || !isChecked4 || !validateEmail(email)) return;

        const requestObj = { email };

        $createGuest(requestObj).then(res => {
            if (!res) return;

            // onClickBack();
            onClickReset();
        })
    }

    function onClickReset() {
        setEmail('');
        setIsChecked1(false);
        setIsChecked2(false);
        setIsChecked3(false);
        setIsChecked4(false);
    }

    return (
        <div className="guest list-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="guest-container">
                            <div className="sign-in-form">
                                {/*<div className="sign-in-form-back">*/}
                                {/*    <Button*/}
                                {/*        text={ <ArrowLeft/> }*/}
                                {/*        onClick={ onClickBack }*/}
                                {/*        className={ 'btn btn-icon' }*/}
                                {/*    />*/}
                                {/*</div>*/}

                                <h4>Гостевой профиль</h4>

                                <form>
                                    <p>Заполните форму, прочитайте и примите условия для получения гостевого доступа</p>

                                    <TextField
                                        type={ 'email' }
                                        label={ 'Введите email' }
                                        value={ email }
                                        onChangeValue={ setEmail }
                                        className={ 'mt-3 mb-1' }
                                    />

                                    { !isValidEmail && (
                                        <span style={ {color: 'red'} }>Неверный адрес электронной почты</span>
                                    )}

                                    {/*<CheckBox*/}
                                    {/*    isChecked={ isChecked1 }*/}
                                    {/*    text={*/}
                                    {/*        <a href={ 'https://myphygital.ru/docs/public_offer.pdf' } target={ '_blank' }>*/}
                                    {/*            Согласен с публичной офертой*/}
                                    {/*        </a>*/}
                                    {/*    }*/}
                                    {/*    onChange={ setIsChecked1 }*/}
                                    {/*    className={'mt-3'}*/}
                                    {/*/>*/}

                                    <CheckBox
                                        isChecked={ isChecked2 }
                                        text={
                                            <p>
                                                Настоящим соглашаюсь на рассылку на e-mail
                                            </p>
                                        }
                                        onChange={ setIsChecked2 }
                                        className={'mt-3'}
                                    />

                                    <CheckBox
                                        isChecked={ isChecked3 }
                                        text={
                                            <a href={ 'https://myphygital.ru/docs/user_agreement.pdf' } target={ '_blank' }>
                                                Правила использования сервиса
                                            </a>
                                        }
                                        onChange={ setIsChecked3 }
                                    />

                                    <CheckBox
                                        isChecked={ isChecked4 }
                                        text={
                                            <a href={ 'https://myphygital.ru/docs/visiting_rules.pdf' } target={ '_blank' }>
                                                Правила посещения фиджитал-центра
                                            </a>
                                        }
                                        onChange={ setIsChecked4 }
                                    />

                                    <Button
                                        text={ 'Получить доступ' }
                                        className={ 'btn btn-primary w-100 justify-content-center mt-5' }
                                        onClick={ onClickNext }
                                        disabled={!isChecked1 || !isChecked2 || !isChecked3 || !isChecked4 || !validateEmail(email)}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GuestPage;

