export default function Seasons({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.3335 15.8333V4.16667C3.3335 3.72464 3.50909 3.30072 3.82165 2.98816C4.13421 2.67559 4.55814 2.5 5.00016 2.5H16.1668C16.2994 2.5 16.4266 2.55268 16.5204 2.64645C16.6142 2.74021 16.6668 2.86739 16.6668 3V13.9283M5.00016 14.1667H16.6668M5.00016 17.5H16.6668"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
			/>

			<path
				d="M5.00016 17.5001C4.55814 17.5001 4.13421 17.3245 3.82165 17.0119C3.50909 16.6994 3.3335 16.2754 3.3335 15.8334C3.3335 15.3914 3.50909 14.9675 3.82165 14.6549C4.13421 14.3423 4.55814 14.1667 5.00016 14.1667"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<path d="M7.5 5.83325H12.5"
				  stroke={color}
				  strokeWidth="1.5"
				  strokeLinecap="round"
			/>
		</svg>
	);
}
