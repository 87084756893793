export default class LocationCurrent {

    getTypeLocation = (name: string) => {
        const parts = name.split('/');

        const actionIndex = parts.indexOf('edit') !== -1 ? 'edit' : 'create';

        const idIndex = parts.indexOf(actionIndex) + 1;

        const id = parts[idIndex];

        return {
            type: actionIndex,
            id
        }
    }

}
