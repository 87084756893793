import {FC, useEffect, useState,} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import TextField from "../../components/form/TextField";
import Info from "../../img/icon/Info";
import FilterSelect from "../../components/FilterSelect";
import {ToggleBlock} from "../../components/form/ToggleBlock";
import {selectOption} from "../../types/users";
import TransformObjArray from "../../mixins/transform-obj-array";
import ImageField from "../../components/form/ImageField";

const AddProgramPage: FC = () => {

    const [listGroupAccounts, setListGroupAccounts] = useState<selectOption[]>([
        {
            label: '',
            value: ''
        },
    ]);

    useEffect(() => init(), []);

    const state: any = useLocation().state;
    const editedData: any = state?.data;
    const isEditPage = state?.current === 'edit';

    const [ image, setImage ] = useState<any>(null);

    const transformObjArray = new TransformObjArray();

    const [list, setList] = useState<any[]>([
        {
            key: 'name',
            title: 'Название программы',
            placeholder: 'Введите название программы',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'path',
            title: 'Путь к программе',
            placeholder: 'Введите путь до программы',
            value: '',
            info: {
                title: '',
                text: ''
            },
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'path2',
            placeholder: 'Введите путь до программы',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'path3',
            placeholder: 'Введите путь до программы',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'icon',
            title: 'Путь к иконке',
            placeholder: 'Введите путь к иконке',
            value: '',
            info: {
                title: '',
                text: ''
            },
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'commandLineArgument',
            title: 'Аргумент командной строки',
            placeholder: 'Введите аргумент командной строки',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
        },
        {
            key: 'workDirectory',
            title: 'Рабочая директория',
            placeholder: 'Укажите рабочую директорию',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),

        },
        {
            key: 'groupAccounts',
            title: 'Группа аккаунтов',
            placeholder: 'Нет доступных групп',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            select: true,
            list: listGroupAccounts,
            disabled: true
        },
        {
            key: 'startupScript',
            title: 'Скрипт запуска',
            placeholder: 'Введите скрипт запуска',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            disabled: true
        },
        {
            key: 'startupPassword',
            title: 'Пароль запуска',
            placeholder: 'Введите новый пароль',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            disabled: true
        },
        {
            key: 'openAsAdmin',
            title: 'Дополнительно',
            value: '',
            setValue: (value: string, key: string) => onChangeSetValue(value, key),
            checkbox: true,
            toggleText: 'Запускать от имени администратора'
        },
    ]);

    const navigate = useNavigate();

    function init() {
        const listUpdate = [...list].map(i => {
            if (editedData && Object.keys(editedData).includes(i.key)) i.value = editedData[i.key];

            return i;
        });

        setList(listUpdate)
    }

    function onClickBack() {
        navigate("/programsGame");
    }

    function onChangeSetValue(value: string, key: string) {
        const listUpdate = [...list].map(i => {
            if (i.key === key) i.value = value;

            return i;
        });

        setList(listUpdate)
    }

    function onClickSave() {
        onClickBack();
    }

    function onClickEdit() {
        onClickBack();
    }

    function onClickDelete() {
        if (isEditPage) return onClickEdit()

        onClickBack();
    }

    return (
        <div className="game__list__header">
            <div className="row">
                <div className="col-12">
                    <div className="game__list__header__form">
                        <div className="game__list__header__back">
                            <Button
                                className={'btn'}
                                leftIcon={(
                                    <ArrowLeft color={'#3582F6'}/>
                                )}

                                text={'Назад'}
                                onClick={onClickBack}
                            />
                        </div>

                        <div className="game__list__header__tariffs">
                            <h3>{ isEditPage ? 'Редактирование' : 'Создание'} программы</h3>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <form className="game__list__header__form__items">
                                    {list.map((item, idx) => {

                                        return (
                                            <div className="game__list__header__block"
                                                 key={`game-list__header-block${idx}`}
                                            >
                                                <div className="game__list__header__block__inner">
                                                    {item.title && (
                                                        <div className={'game__list__header__block__inner__title'}>
                                                            <h5>{item.title}</h5>

                                                            {/*{item.info && (*/}
                                                            {/*    <>*/}
                                                            {/*        <Info color={'#3582F6'}/>*/}

                                                            {/*        <div></div>*/}
                                                            {/*    </>*/}
                                                            {/*)}*/}
                                                        </div>
                                                    )}

                                                    {item.select && (
                                                        <FilterSelect
                                                            placeholder={item.placeholder}
                                                            value={item.value}
                                                            options={item.list}
                                                            onChange={(i) => item.setValue(i, item.key)}
                                                        />
                                                    )}

                                                    {item.checkbox && (
                                                        <ToggleBlock
                                                            desc={item.toggleText}
                                                            isChecked={item.value}
                                                            handleChange={(i) => item.setValue(i, item.key)}
                                                            imgLeft={item.imgLeft}
                                                        />
                                                    )}

                                                    {item.bottomText && (
                                                        <div className={'bottom-block'}>{item.bottomText}</div>
                                                    )}

                                                    {(!item.select && !item.checkbox) && (
                                                        <TextField
                                                            placeholder={item.placeholder}
                                                            value={item.value}
                                                            onChangeValue={(value) => item.setValue(value, item.key)}
                                                        />
                                                    )}

                                                </div>
                                            </div>
                                        )
                                    })}
                                </form>
                                <Button
                                    className={'btn btn-primary w-100 justify-content-center'}
                                    text={isEditPage ? 'Обновить' : 'Сохранить'}
                                    onClick={onClickSave}
                                />
                            </div>

                            <div className="col-12 col-md-4 mt-4">
                                <div className="edit__news__right-content">
                                    <div className="edit__news__header__item__cover">
                                        <h2>Обложка Программы</h2>

                                        {/*<Info*/}
                                        {/*    color={'#3582F6'}*/}
                                        {/*/>*/}
                                    </div>

                                    <ImageField
                                        label={ '512х512' }
                                        upload={ image }
                                        setUpload={ setImage }
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="game__list__header__button">
                            {isEditPage && (
                                <Button
                                    className={'btn btn-danger'}
                                    text={'Сохранить'}
                                    onClick={onClickDelete}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddProgramPage;
