import React, {useEffect, FC, useState, useRef} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {requiredPattern} from "../../functions";
import { login } from '../../redux/actions/userActions';
import Cookies from "js-cookie";
import cn from 'classnames';
import Spinner from "../Spinner";
import { useAppDispatch } from "../../redux/store";
import { useAppSelector } from "../../redux/store";
import { AuthSignBodyResultData, AuthSignData } from "../../api/requests/auth/interface";
import {IMask} from 'react-imask';
import { $authGetSms, $authSign } from "../../api/requests/auth";
import Eye from "../../img/icon/Eye";
import MailSecond from "../../img/icon/MailSecond";
import Timer from "../form/Timer";
import EyeClose from "../../img/icon/EyeClose";
import { failureNotify, successNotify } from "../../notifications";
import { changeUser } from "../../redux/slices/userSlice";

const SignInForm: FC = () => {
    const isMobile = window.innerWidth < 465
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentUser = useAppSelector((state) => state.currentUser);

    const { register, handleSubmit, formState: { errors },  } = useForm<AuthSignData>({});

    const [step, setStep] = useState('start');
    const [errorValidPhone, setErrorValidPhone] = useState('');
    const [valuePhone, setValuePhone] = useState('');
    const [loadingCheckSms, setLoadingCheckSms] = useState(false);
    const [isShowPassword, setShowPassword] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        if (currentUser.data && Cookies.get('token')) {
            navigate('/auth');
        }
    }, [currentUser.success]);

    useEffect(() => {
        if (inputRef.current) {
            const mask = IMask(inputRef.current, {
                mask: '+0 000 000-00-00'
            });
        }
    }, []);

    useEffect(() => {
        if (valuePhone.length === 16) setErrorValidPhone('');
    }, [valuePhone])

    const onSubmit = (data: AuthSignData) => {
        const cleanedPhoneNumber = valuePhone.replace(/[\s+\-]/g, '');

        const dataUpdate = {
            password: data.password,
            phone: cleanedPhoneNumber
        }

        $authSign(dataUpdate, {returnData: false}).then(response => {
            if (!response) return;

            if (!response?.result?.access_token) {
                console.log(response.message, 'error profile');

                if (response && response.message) {
                    failureNotify(response.message);
                }

                return;
            }

            Cookies.set('token', response.result.access_token);

            successNotify('Вы успешно авторизованы');

            navigate('/dashboard');

            dispatch(changeUser(response.result))
        })
    };

    const getTextByCode = () => {
        if (step === 'end') return 'Отправить повторно'

        return 'Отправить код';
    }

    function handleTimerEnd () {
        setStep('end')
    }

    function onClickSmsCode(value: string) {
        if (value.length < 16) return setErrorValidPhone('Заполните правильно поле');

        const phone = value.replace(/[\s\-+]/g, '');
        setLoadingCheckSms(true)

        $authGetSms({ phone }).then((r) => {
            setLoadingCheckSms(false);

            if (r && r.response) {
                setErrorValidPhone(r.response.message);

                return;
            }

            setStep('smsCode')
        })
    }

    return (
        <div className="sign-in-form">
            <h4>Вход в панель управления</h4>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <div className={'position-relative w-100'}>
                        <input
                            placeholder="Введите номер телефона"
                            className="form-control"
                            ref={inputRef}
                            value={valuePhone}
                            onChange={(e) => {
                                setValuePhone(e.target.value)
                            }}
                            onPaste={(e) => {
                                setValuePhone(e.clipboardData.getData('Text'))
                            }}
                            type="text"
                            name="logins"
                        />
                    </div>
                </div>

                {errors.password && <span className="error-message">{errors.password.message}</span>}

                <div className="form-group">
                    <div className="position-relative w-100">
                        <input
                            placeholder="Введите пароль"
                            className="form-control right"
                            type={isShowPassword ? 'text' : 'password'}
                            {...register("password", {
                                required: requiredPattern
                            })}
                            autoComplete="new-password"
                        />

                        <button
                            type="button"
                            className={cn('btn btn-icon btn-right', { 'loading': true })}
                            onClick={() => setShowPassword(!isShowPassword)}
                        >
                            {(!isShowPassword ? <Eye/> : <EyeClose/>)}
                        </button>
                    </div>
                </div>

                {errors.password && <span className="error-message">{errors.password.message}</span>}

                {/*<div className="form-group">*/}
                {/*    <button*/}
                {/*        type="button"*/}
                {/*        className={cn('btn btn-icon btn-left', { 'loading': true })}*/}
                {/*        onClick={() => setShowPassword(!isShowPassword)}*/}
                {/*    >*/}
                {/*        {(<MailSecond />)}*/}
                {/*    </button>*/}

                {/*    <input*/}
                {/*        placeholder="SMS"*/}
                {/*        className="form-control left"*/}
                {/*        type={'text'}*/}
                {/*        {...register("code", {*/}
                {/*            required: requiredPattern*/}
                {/*        })}*/}
                {/*        autoComplete={'off'}*/}
                {/*    />*/}

                {/*    <button*/}
                {/*        disabled={loadingCheckSms || valuePhone.length < 11}*/}
                {/*        type="button"*/}
                {/*        className={cn('btn btn-icon btn-right', { 'loading': true })}*/}
                {/*        onClick={() => onClickSmsCode((inputRef.current as any).value)}*/}
                {/*    >*/}
                {/*        {step === 'smsCode' ? (*/}
                {/*            <Timer*/}
                {/*                minutes={5}*/}
                {/*                seconds={0}*/}
                {/*                onTimerEnd={handleTimerEnd}*/}
                {/*            />*/}
                {/*        ) : (*/}
                {/*            getTextByCode()*/}
                {/*        )}*/}
                {/*    </button>*/}
                {/*</div>*/}

                {!isMobile && (
                    <button
                        // disabled={currentUser.loading || step !== 'password'}
                        type="submit"
                        className={cn('btn btn-primary btn-confirm', { 'loading': true })}
                    >
                        {currentUser.loading ? (
                            <Spinner />
                        ) : 'Войти'}
                    </button>
                )}

                {isMobile && (
                    <div className='game__header-right'>
                        <button
                            // disabled={currentUser.loading || step !== 'password'}
                            type="submit"
                            className={cn('btn btn-primary btn-confirm', { 'loading': true })}
                        >
                            {currentUser.loading ? (
                                <Spinner />
                            ) : 'Войти'}
                        </button>
                    </div>
                )}
            </form>

        </div>
    );
}

export default SignInForm;
