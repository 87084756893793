export default function Unavailable({color= "white", size = 20}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <circle id="Ellipse 1242" cx="10" cy="10" r="8.25" stroke={color}
                        strokeWidth="1.5"
                />
                <line id="Line 13" x1="4.53033" y1="4.46967" x2="15.5303" y2="15.4697" stroke={color}
                      strokeWidth="1.5"
                />
            </g>
        </svg>
    );
}
