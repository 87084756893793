import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import { ShiftCloseData, ShiftOpenData, ShiftCenterOpen} from "./interface";

// начало Shift
export const $shiftOpen = (data: ShiftOpenData, handler?: IApiHandlerParams): Promise<ShiftCenterOpen> => api
    .post('/center/shift/open', data, {
        handler
    });
export const $shiftClose = (data: ShiftCloseData, handler?: IApiHandlerParams): Promise<ShiftCenterOpen> => api
    .post('/center/shift/close', data, {
        handler
    });

// Конец Shift
