export default function PiggyBank({color= "white", size = 22}) {
    return (
        <svg width={size} height={size} viewBox={`0 0 22 22`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="piggy-bank 1">
                <path id="Vector"
                      d="M17.416 14.7816C16.954 15.6891 16.1858 16.4783 15.2078 17.0742L15.6523 18.5408C15.6772 18.623 15.6826 18.7098 15.6679 18.7944C15.6532 18.879 15.6189 18.959 15.5678 19.0279C15.5166 19.0969 15.45 19.1529 15.3734 19.1916C15.2967 19.2302 15.212 19.2503 15.1262 19.2503H13.5092C13.3902 19.2503 13.2744 19.2117 13.1792 19.1403L11.9004 18.1806H9.66651L8.38777 19.1403C8.29256 19.2117 8.17677 19.2503 8.05777 19.2503H6.44077C6.35491 19.2503 6.27026 19.2302 6.19358 19.1916C6.1169 19.1529 6.05033 19.0969 5.99919 19.0279C5.94805 18.959 5.91376 18.879 5.89907 18.7944C5.88438 18.7098 5.8897 18.623 5.9146 18.5408L6.35918 17.0742C4.71835 16.0741 3.66602 14.5286 3.66602 12.7952C3.66602 9.77933 6.85235 7.33366 10.7839 7.33366C11.5805 7.33366 12.576 7.60683 13.291 7.79199L17.416 6.41699L17.339 9.74266L19.2493 10.542V13.7503L17.4838 14.667"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2"
                      d="M14.2083 11.9167C14.0868 11.9167 13.9702 11.8684 13.8842 11.7824C13.7983 11.6965 13.75 11.5799 13.75 11.4583C13.75 11.3368 13.7983 11.2202 13.8842 11.1342C13.9702 11.0483 14.0868 11 14.2083 11C14.3299 11 14.4465 11.0483 14.5324 11.1342C14.6184 11.2202 14.6667 11.3368 14.6667 11.4583C14.6667 11.5799 14.6184 11.6965 14.5324 11.7824C14.4465 11.8684 14.3299 11.9167 14.2083 11.9167Z"
                      fill={color} stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_3" d="M1.83398 9.16699C1.83398 9.16699 1.83398 11.367 3.66732 11.917" stroke={color}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_4"
                      d="M11.7333 7.10692C11.8525 6.76592 11.9167 6.39925 11.9167 6.01792C11.9167 4.213 10.4802 2.75 8.70833 2.75C6.93642 2.75 5.5 4.213 5.5 6.01792C5.5 6.8805 5.82817 7.66608 6.36533 8.25"
                      stroke={color}
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
