import { io } from 'socket.io-client';

let URL = window.location.protocol.toLowerCase() === "https:"
    ? "wss://api-phygital-dashboard.teamtim.tech/"
    : "wss://api-phygital-dashboard.teamtim.tech/";

export const socket = io(URL, {
    autoConnect: false,
    reconnection: true,
    transports: ['websocket']
});