export default function StarsFull({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none"
			 xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_3400_6583"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width={size}
				height={size}
			>
				<path d="M0 0H20V20H0V0Z" fill={color}/>
			</mask>

			<g>
				<path
					d="M18.334 9.9987H19.1673M10.0007 1.66536V0.832031M10.0007 19.1654V18.332M16.6673 16.6654L15.834 15.832M16.6673 3.33203L15.834 4.16536M3.33398 16.6654L4.16732 15.832M3.33398 3.33203L4.16732 4.16536M0.833984 9.9987H1.66732M8.29398 8.01786L9.59315 5.2637C9.62815 5.18484 9.68528 5.11783 9.75761 5.07079C9.82995 5.02376 9.91437 4.99873 10.0007 4.99873C10.0869 4.99873 10.1714 5.02376 10.2437 5.07079C10.316 5.11783 10.3731 5.18484 10.4082 5.2637L11.7073 8.01786L14.6115 8.46203C14.984 8.5187 15.1323 8.9987 14.8631 9.2737L12.7615 11.4154L13.2573 14.4412C13.3215 14.8304 12.9315 15.1262 12.5981 14.9429L10.0007 13.5137L7.40315 14.9429C7.06982 15.1262 6.67982 14.8304 6.74398 14.4412L7.23982 11.4162L5.13815 9.2737C4.86898 8.9987 5.01732 8.5187 5.38982 8.46203L8.29398 8.01786Z"
					stroke={color}
					strokeWidth="1.25"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}
