import axios from "axios";

class MyUploadAdapter {
    private loader: any;

    constructor(loader: any) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file
            .then((file: File) => new Promise((resolve, reject) => {

                const toBase64 = (file: File) => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });

                return toBase64(file).then((cFile: any) => {
                    const updateFile = cFile.replace(/data:image\/[a-zA-Z]*;base64,/, '')

                    return axios.post("/upload", {
                        imageBinary: updateFile
                    }).then((d: any) => {
                        if (d?.data?.statusCode) {
                            this.loader.uploaded = true;
                            resolve({
                                default: d?.data?.result
                            });
                        } else {
                            reject(`не удалось загрузить файл: ${ file.name }.`)
                        }
                    });
                })

            }));
    }
}

export default function MyCustomUploadAdapterPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return new MyUploadAdapter(loader);
    };
}
