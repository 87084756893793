import React, { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import ArrowLeft from "../../img/icon/ArrowLeft";
import { useLocation, useNavigate } from "react-router-dom";
import { TariffsEditPreview } from "../../components/tariffs2/TariffsEditPreview";
import TransformObjArray from "../../mixins/transform-obj-array";
import TariffsListForm from "../../components/tariffs2/TariffsListForm";
import Trash from "../../img/icon/Trash";
import { ListUsersRequestRate } from "../../api/requests/rate/interface";
import { $deleteRate, $getListRate, $updateRate } from "../../api/requests/rate";
import { $getCurrentNoDeviceZone } from "../../api/requests/zone";

const AddTariffs: FC = () => {
    const isMobile = window.innerWidth < 465
    const location = useLocation();
    const navigate = useNavigate();

    const transformObjArray = new TransformObjArray()

    const state: any = location.state;
    const isEditPage = state?.current === 'edit';
    const editedData: any = state?.data || {};

    const daysAction = [
        {
            label: 'Пн',
            value: 1
        },
        {
            label: 'Вт',
            value: 2
        },
        {
            label: 'Ср',
            value: 3
        },
        {
            label: 'Чт',
            value: 4
        },
        {
            label: 'Пт',
            value: 5
        },
        {
            label: 'Сб',
            value: 6
        },
        {
            label: 'Вс',
            value: 7
        },
    ]

    const [ zones, setZones ] = useState([])
    const [ days, setDays ] = useState<any[]>()

    const formPriceFieldList = [
        {delete: 'x', id: 0},
        {
            key: 'zoneId',
            id: 0,
            placeholder: 'Выберите группу оборудования',
            title: 'Прайслист',
            value: '',
            setValue: (
                value: string,
                key: string,
                id: any,
                list: any
            ) => onChangePriceSetValue(
                value,
                key,
                id,
                list
            ),
            select: true,
            options: zones,
            top: isMobile ? -10 : -10
        },
        // {
        //     key: 'priceForAll',
        //     id: 0,
        //     placeholder: 'Для всех',
        //     type: 'number',
        //     value: '',
        //     setValue: (
        //         value: string,
        //         key: string,
        //         id: any,
        //         list: any
        //     ) => onChangePriceSetValue(
        //         value,
        //         key,
        //         id,
        //         list
        //     ),
        //     field: true,
        //     isMulti: true,
        //     className: 'tariffs-edit__form-multi',
        //     itemRight: '₽'
        // },
        {
            key: 'priceForUsers',
            id: 0,
            placeholder: 'Введите стоимость',
            type: 'number',
            value: '',
            setValue: (
                value: string,
                key: string,
                id: any,
                list: any
            ) => onChangePriceSetValue(
                value,
                key,
                id,
                list
            ),
            field: true,
            // isMulti: true,
            // className: 'tariffs-edit__form-multi',
            itemRight: '₽'
        },
    ];
    const [ formFieldList, setFormFieldList ] = useState([
        {
            key: 'name',
            placeholder: 'Название тарифа',
            title: 'Название тарифа',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formFieldList,
                setFormFieldList
            ),
            field: true,
            top: 16
        },
        {
            key: 'isActive',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formFieldList,
                setFormFieldList
            ),
            checkbox: true,
        },
        {
            key: 'type',
            placeholder: 'Выберите тип тарифа',
            title: 'Тип тарифа',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formFieldList,
                setFormFieldList
            ),
            select: true,
            options: [
                {
                    label: 'Фиксированный',
                    value: 0
                },
                // {
                //     label: 'Поминутный',
                //     value: 1
                // },
                {
                    label: 'Пакетный',
                    value: 2
                },
                // {
                //     label: 'Несгораемый',
                //     value: 3
                // },
            ],
            space: true,
            top: isMobile ? 10 : 65,
            disabled: false,
        },
        {
            key: 'duration',
            placeholder: 'Введите',
            title: 'Длительность',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formFieldList,
                setFormFieldList
            ),
            field: true,
            isMulti: true,
            className: 'tariffs-edit__form-multi',
            top: isMobile  ? 0 : 30,
            type: 'number',
            disabled: false,
        },
        {
            key: 'durationType',
            placeholder: `Выберите ${!isMobile ? 'время' : ''}`,
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formFieldList,
                setFormFieldList
            ),
            select: true,
            options: [
                {
                    label: 'Поминутно',
                    value: 0
                },
                {
                    label: 'Почасово',
                    value: 1
                },
                // {
                //     label: 'Посуточно',
                //     value: 2
                // },
                // {
                //     label: 'По неделям',
                //     value: 3
                // },
            ],
            isMulti: true,
            className: 'tariffs-edit__form-multi'
        }
    ]);
    const [ formExtraFieldList, setFormExtraFieldList ] = useState([
        {
            key: 'button',
            text: 'Добавить',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formPriceFieldList,
                setFormExtraFieldList
            ),
            button: true,
        },
        {
            key: 'availableDays',
            placeholder: 'Дни действия',
            title: 'График действия',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formExtraFieldList,
                setFormExtraFieldList
            ),
            select: true,
            options: [
                {
                    label: 'Пн',
                    value: 1
                },
                {
                    label: 'Вт',
                    value: 2
                },
                {
                    label: 'Ср',
                    value: 3
                },
                {
                    label: 'Чт',
                    value: 4
                },
                {
                    label: 'Пт',
                    value: 5
                },
                {
                    label: 'Сб',
                    value: 6
                },
                {
                    label: 'Вс',
                    value: 7
                },
            ],
            space: true,
            top: isMobile ? 20 : -5
        },
        {
            key: 'availableFrom',
            placeholder: '00:00',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formExtraFieldList,
                setFormExtraFieldList
            ),
            time: true,
            isMulti: true,
            className: 'tariffs-edit__form-multi'
        },
        {
            key: 'availableTo',
            placeholder: '00:00',
            value: '',
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formExtraFieldList,
                setFormExtraFieldList
            ),
            time: true,
            isMulti: true,
            className: 'tariffs-edit__form-multi'
        },
        // {
        //     key: 'barcode',
        //     placeholder: 'Штрих код',
        //     value: '',
        //     setValue: (
        //         value: string,
        //         key: string
        //     ) => onChangeSetValue(
        //         value,
        //         key,
        //         formExtraFieldList,
        //         setFormExtraFieldList
        //     ),
        //     field: true,
        //     isMulti: true,
        //     space: true
        // },
        {
            key: 'applyDiscount',
            placeholder: 'График действия',
            // title: 'Дополнительно',
            value: false,
            setValue: (
                value: string,
                key: string
            ) => onChangeSetValue(
                value,
                key,
                formExtraFieldList,
                setFormExtraFieldList
            ),
            toggle: true,
            className: '',
            space: true,
            hide: true,
        }
    ])

    const [ previewPriceFieldList, setPreviewFieldList ] = useState<any[]>([
        {
            id: 0,
            zoneId: '',
            priceForAll: '',
            priceForUsers: ''
        }
    ])
    const [ duplicatedPriceFieldList, setDuplicatedPriceFieldList ] = useState<any[]>([ ...formPriceFieldList ])

    useEffect(() => init(), []);

    const init = () => {
        if (editedData) {
            handleForm(formFieldList, setFormFieldList);
            handleForm(formExtraFieldList, setFormExtraFieldList);
        }

        handleFormPrices(duplicatedPriceFieldList, setDuplicatedPriceFieldList);

    }

    const handleForm = (
        list: any,
        setList: any
    ) => {
        const defaultValues: any = isEditPage && daysAction.filter(option => editedData.availableDays?.includes(option.value));
        const listUpdate: any = [ ...list ].map(i => {

            if (Object.keys(editedData).includes(i.key)) {
                i.value = editedData[i.key];

                if (i.options) i.value = i.options.find((_i: any) => _i.value === editedData[i.key]);
            }

            if (i.key === 'availableDays') i.value = defaultValues;

            if (i.key === 'duration') return changeFixedDropdown('duration', i);

            if (i.key === 'durationType') return changeFixedDropdown('durationType', i);

            return i;
        });

        isEditPage && setDays(defaultValues.map((item: any) => item.value))
        setList(listUpdate)
    }

    const addPriceField = () => {
        const updatedRoundsList: any = [ ...duplicatedPriceFieldList ];

        const searchLastId = duplicatedPriceFieldList[duplicatedPriceFieldList.length - 1].id;

        updatedRoundsList.push(...formPriceFieldList.map((item: any) => {
            return {
                ...item,
                id: searchLastId + 1,
                title: '',
                options: zones
            }
        }));

        setDuplicatedPriceFieldList(updatedRoundsList)

        setPreviewFieldList([
            ...previewPriceFieldList,
            {
                id: searchLastId + 1,
                zoneId: '',
                priceForAll: '',
                priceForUsers: '',
            }
        ])
    };

    const handleDeletePriceField = (itemToRemove: any) => {
        const listUpdate = ((item: any) => item.id != itemToRemove.id)

        setDuplicatedPriceFieldList(duplicatedPriceFieldList.filter(listUpdate))
        setPreviewFieldList(previewPriceFieldList.filter(listUpdate))
    }

    const getPriceItems = () => {
        const combinedItems: any = []

        previewPriceFieldList.forEach((item: any) => {
            const combinedItem = {
                zones: [ item.zoneId.id ],
                priceForAll: +item.priceForUsers,
                priceForUsers: +item.priceForUsers
            }

            combinedItems.push(combinedItem)
        })

        return combinedItems
    }

    function handleFormPrices(
        list: any,
        setList: any
    ) {
        $getCurrentNoDeviceZone().then(response => {
            if (!response.length) return;

            const priceOptions: any = response.map((i) => ({
                ...i,
                label: i.name,
                value: i.id
            }))

            const listUpdate = [ ...list ].map(i => {
                if (editedData && Object.keys(editedData).includes(i.key)) i.value = editedData[i.key];

                if (i.options) i.options = priceOptions

                return i;
            });

            const updateList: any[] = []

            isEditPage && editedData?.prices?.forEach((
                i: any,
                idx: number
            ) => {
                const list = [ ...formPriceFieldList ].map((item: any) => {
                    const valueItem = priceOptions.find((_i: any) => _i.value === i[item.key]);

                    return {
                        ...item,
                        id: idx,
                        options: priceOptions,
                        title: item.key === 'zoneId' && idx < 1 ? 'Прайслист' : '',
                        value: item.key === 'zoneId' ? valueItem : i[item.key],
                        // disabled: item.key === 'zoneId' ? false : (valueItem?.zoneId.type === 0)
                    }
                })

                updateList.push(...list)
            })

            setZones(priceOptions)
            isEditPage && setPreviewFieldList(editedData.prices)
            setList(isEditPage ? updateList : listUpdate)
        })
    }

    function onClickBack() {
        navigate('/tariffs')
    }

    function onChangeSetValue(
        value: any,
        key: string,
        list: any[],
        setList: any
    ) {
        const findDays: any = formExtraFieldList.find((item) => item.key === 'availableDays')

        const listUpdates: any = [ ...list ].map(i => {
            const daysArr = findDays.value && findDays?.value.map((item: any) => item.value)
            const filteredDays: any = daysArr ? daysArr.sort((a: number, b: number) => a - b) : []

            if (i.key === key) i.value = value;

            if (i.key === 'duration') return changeFixedDropdown('duration', i);

            if (i.key === 'durationType') return changeFixedDropdown('durationType', i);

            setDays(filteredDays);

            return i;
        });

        setList(listUpdates);
    }

    function changeFixedDropdown(typeKey: string, item: any) {
        const findType: any = formFieldList.find((item: any) => item.key === 'type');
        const fixedType: any = findType.value.value;
        const searchItemByMinute = item.options ? item.options.find((item: any) => item.value === 0) : undefined;

        fixedType === 0 && (item.value = typeKey === 'duration' ? 30 : searchItemByMinute);
        fixedType === 2 && (item.value = typeKey === 'duration' ? item.value : item.value);
        item.disabled = fixedType === 0 && true;

        return item
    }

    function onChangePriceSetValue(
        value: string,
        key: string,
        id: any,
        list: any
    ) {
        const listUpdates: any = [ ...list ].map(i => {
            if (i.key === key && i.id === id) i.value = value;

            return i;
        });

        setDuplicatedPriceFieldList(listUpdates)
    }

    function onClickSave() {
        const objKey = transformObjArray.getRequestObj([ ...formFieldList, ...duplicatedPriceFieldList, ...formExtraFieldList ]);

        const requestData: ListUsersRequestRate = {
            ...objKey,
            availableDays: days,
            durationType: +objKey.durationType,
            type: +objKey.type,
            tariffType: +objKey.type,
            availableFrom: objKey.availableFrom,
            availableTo: objKey.availableTo,
            duration: +objKey.duration,
            prices: getPriceItems(),
            applyDiscount: objKey.applyDiscount === '' ? false : objKey.applyDiscount
        }

        if (isEditPage) return onEditSave(requestData);

        onCreateSave(requestData);
    }

    function onEditSave(data: ListUsersRequestRate) {
        $updateRate(editedData.id, data).then(res => {
            if (res && !res.id) return;

            onClickBack()
        })
    }

    function onCreateSave(data: ListUsersRequestRate) {
        $getListRate(data).then(res => {
            if (res && !res.id) return;

            onClickBack()
        })
    }

    function onClickDelete() {
        if (!confirm('Вы подтверждаете удаление?')) return;

        $deleteRate(editedData.id).then(() => {
            navigate('/tariffs')
        })
    }

    return (
        <div className='tariffs-edit list-wrapper'>
            <div className="tariffs-inner">
                <div className='container-fluid p-0'>
                    <div className="row">
                        <div className='col-12'>
                            <div className="tournament__edit__back">
                                <Button
                                    text={!isMobile && 'Назад' }
                                    leftIcon={ (
                                        <ArrowLeft
                                            color={ '#3582F6' }
                                        />
                                    ) }
                                    onClick={ onClickBack }
                                    className={ 'btn btn-icon' }
                                />

                                {isMobile && (
                                    <h2 className={ 'tariffs-edit__addTitle' }>
                                        { isEditPage ? 'Редактирование' : 'Создание' } тарифа
                                    </h2>
                                )}
                            </div>

                            <div className='tariffs-edit__content'>
                                <div className='col-12 col-md-8 col-sm-12 tariffs-edit__left'>
                                    {!isMobile && (
                                        <h2 className={ 'tariffs-edit__addTitle' }>
                                            { isEditPage ? 'Редактирование' : 'Создание' } тарифа
                                        </h2>
                                    )}

                                    <TariffsListForm
                                        formList={ formFieldList }
                                    />

                                    <TariffsListForm
                                        onDelete={ handleDeletePriceField }
                                        priceFields={ duplicatedPriceFieldList }
                                        formList={ duplicatedPriceFieldList }
                                    />

                                    <TariffsListForm
                                        addPriceField={ addPriceField }
                                        formList={ formExtraFieldList }
                                    />
                                </div>

                                <div className='col-12 col-md-4 col-sm-12 tariffs-edit-previewBlock'>
                                    <h2>Превью</h2>

                                    <TariffsEditPreview
                                        priceField={ duplicatedPriceFieldList }
                                        prices={ previewPriceFieldList }
                                        days={ days }
                                        data={ transformObjArray.getRequestObj([
                                            ...formFieldList,
                                            ...duplicatedPriceFieldList,
                                            ...formExtraFieldList,
                                        ]) }
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-8 col-sm-12 tariffs-edit__left-content tariffs-style tariffs-edit__btns'>
                                <div className={ 'tariffs-edit__titles col-4' }>

                                </div>
                                <div className='tariffs-edit__btns-btn'>
                                    { isEditPage && (
                                        <div
                                            className='tariffsAdd__template-delete'
                                            onClick={ () => onClickDelete() }
                                        >
                                            <Trash color={ 'red' }/>
                                        </div>
                                    ) }
                                    <Button
                                        className='tariffs-edit-createBtn btn btn-primary '
                                        text={ isEditPage ? 'Обновить' : 'Создать' }
                                        onClick={ onClickSave }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTariffs;
