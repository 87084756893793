import React, { FC, useState } from "react";
import Button from "../../components/form/Button";
import TextField from "../../components/form/TextField";
import FilterSelect from "../../components/FilterSelect";
import { ToggleBlock } from "../form/ToggleBlock";
import PlusBorder from "../../img/icon/PlusBorder";
import cn from "classnames";
import DatePickerTimeField from "../../components/form/DatePickerTimeField";
import DateOperations from "../../mixins/date-operation";
import CheckBox from "../form/CheckBox";

interface TariffsListFormProps {
    onDelete?: any,
    addPriceField?: () => void
    formList: any[],
    priceFields?: any
}

type DateInterface = Date | null;

const TariffsListForm: FC<TariffsListFormProps> = (
    {
        addPriceField,
        formList = [],
        priceFields,
        onDelete
    }
) => {
    const isMobile = window.innerWidth < 465
    const dateOperations: any = new DateOperations();

    return (
        <div className='tariffs-edit__left-content'>
            <div className='col-4 tariffs-edit__titles'>
                { formList.map((
                    item: any,
                    idx: number
                ) => (
                    <h3
                        key={ `tariffs-edit-titles-${ idx }` }
                        style={ {marginTop: item.top} }
                    >
                        { item.title }
                    </h3>
                )) }
            </div>

            <form className="tournament__edit-content tariffs-edit__inputs">
                { formList.map((
                    item: any,
                    idx
                ) => (
                    <React.Fragment key={ `tournament-edit-item-${ idx }` }>
                        { item.title && (
                            <div
                                className={`tariffs-edit-content__text`}
                                key={ `tournament-edit-title-${ idx }` }
                                style={ {marginTop: isMobile && item.top} }
                            >
                                <p>{ item.title }</p>

                                { item.imgRight }
                            </div>
                        ) }

                        { item.field && (
                            <>
                                <TextField
                                    disabled={item.disabled}
                                    type={item.type}
                                    className={ cn(item.className, item.space && 'tariffs-edit-space') }
                                    placeholder={ item.placeholder }
                                    value={ item.value }
                                    onChangeValue={ (value) => item.setValue(value, item.key, item.id, priceFields) }
                                    imgRight={ item.itemRight }
                                />
                            </>
                        ) }

                        {item.delete ? (
                            <div className='tariffs-edit-priceField'>
                                { item.select && (
                                    <FilterSelect
                                        className={ item.className  }
                                        placeholder={ item.placeholder }
                                        value={ item.value }
                                        options={ item.options }
                                        onChange={ (value) => item.setValue(value, item.key, item.id, priceFields) }
                                    />
                                ) }
                                {item.delete && item.id != 0 && (
                                    <div
                                        onClick={() => onDelete(item)}
                                        className={`tariffs-edit-delete${priceFields.length > 4 ? '' : '__none'}`}>
                                        {item.delete}
                                    </div>
                                )}
                            </div>
                        ): (
                            <>
                                { item.select && (
                                    <FilterSelect
                                        disabled={item.disabled}
                                        isMulti={item.key === 'availableDays'}
                                        className={ `${ item.className } ${ item.space && 'tariffs-edit-space' }` }
                                        placeholder={ item.placeholder }
                                        value={ item.value }
                                        options={ item.options }
                                        onChange={ (value) => item.setValue(value, item.key, item.id, priceFields) }
                                    />
                                ) }
                            </>
                        )}

                        { (item.toggle && !item.hide) && (
                            <div
                                className={ `tariffs-edit__toggle ${ item.space ? 'tariffs-edit-space' : '' }` }
                            >
                                <ToggleBlock
                                    desc={ 'Применять скидку центра или промокод' }
                                    isChecked={item.value === '' || !item.value ? false : item.value}
                                    handleChange={ (value) => item.setValue(value, item.key) }
                                />
                            </div>
                        ) }

                        { item.time && (
                            <div className={ item.className }>
                                <DatePickerTimeField
                                    startDate={new Date(`2023-10-08T${dateOperations.getTimeFromSeconds(item.value)}:00`)}
                                    onChangeDate={ (date) =>
                                        item.setValue(dateOperations.getTimeInSeconds(dateOperations.getFormattedTime(date)),
                                            item.key
                                        ) }
                                />
                            </div>
                        ) }

                        { item.checkbox && (
                            <CheckBox
                                isChecked={item.value}
                                text={ 'Активный' }
                                onChange={ (value) => item.setValue(value, item.key) }
                            />
                        ) }

                        { item.button && (
                            <div
                                onClick={ addPriceField }
                                className={ `${ item.space && 'tariffs-edit-space' }` }
                            >
                                <Button
                                    className={ 'tariffs-edit-addBtn' }
                                    leftIcon={ <PlusBorder color={ '#3582F6' } /> }
                                    text={ 'Добавить' }
                                />
                            </div>
                        ) }
                    </React.Fragment>
                )) }
            </form>
        </div>
    )
}

export default TariffsListForm;
