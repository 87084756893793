import api from '../../../api';
import { IApiHandlerParams } from "../../interface";
import { RateCenterData, CenterDataRateRespons, rateModel } from "../rate/interface";
import { CreateUserRate, ListUsersRequestRate, ListUsersRequestRateSecond} from "./interface";

export const $getCurrentRate = (params?: CreateUserRate, handler?: IApiHandlerParams): Promise<RateCenterData> => api
  .get('/center/rate', {
    request: { params },
    handler
  })

export const $getListRate = (data: ListUsersRequestRate, handler?: IApiHandlerParams): Promise<rateModel> => api
  .post('/center/rate', data, {
    handler
  })

export const $getListRateSecond = (data: ListUsersRequestRateSecond, handler?: IApiHandlerParams): Promise<Array<CenterDataRateRespons>> => api
    .post('/center/rate/available', data, {
        handler
    })

export const $getRate = (id: number, params: CreateUserRate, handler?: IApiHandlerParams): Promise<Array<CenterDataRateRespons>> => api
  .get('/center/rate' + id, {
    request: { params },
    handler
  })

export const $updateRate = (id: number, data: ListUsersRequestRate, handler?: IApiHandlerParams): Promise<any> => api
  .put('/center/rate/single/' + id, data, {
    // mock: require('./mocks/complete.form.calculate.json'),
    handler
  })
export const $deleteRate = (id: number, handler?: IApiHandlerParams): Promise<CenterDataRateRespons> => api
  .delete('/center/rate/single/' + id, {
    // mock: require('./mocks/error.delete.json'),
    handler
  })
