import { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { ru } from "date-fns/locale/ru";
import Calendar from "../../img/icon/Calendar";

type DateInterface = Date | null;

interface DatePickerProps {
	startDate?: DateInterface
	onChangeDate: (date: Date) => void
	isIcon?: boolean
	placeholder?: string
	isRightIcon?: boolean
	isTimeSelect?: boolean
	isMonthSelect?: boolean
	className?: string
	disabled?: boolean
	minDate?: Date
	maxDate?: Date
}

const DatePickerField: FC<DatePickerProps> = (
	{
		startDate,
		onChangeDate,
		isIcon = false,
		placeholder = '',
		isRightIcon = false,
		isTimeSelect = false,
		isMonthSelect = false,
		className,
		disabled,
		minDate,
		maxDate
	}
) => {
	const dateStart = (date: any): DateInterface => {
		if (typeof date === 'string') return new Date(date);

		return startDate || null
	};

	const [ startDateLocal, setStartDate ] = useState<DateInterface>(dateStart(startDate));
	const [ endDate, setEndDate ] = useState<DateInterface>(null);

	useEffect(() => {
		// if (startDateLocal === null && startDate) setStartDate(dateStart(startDate))
		if (dateStart(startDate) !== startDateLocal) setStartDate(dateStart(startDate))
	}, [ startDate ]);

	const onDateRange = (value: Date | null) => {
		onChangeDate(value || new Date())
	};

	const getDateFormatText = (): string => {
		if (isTimeSelect) return 'dd.MM.yyyy HH:mm';

		if (isMonthSelect) return 'MM/yyyy';

		return 'dd.MM.yyyy'
	};

	const renderMonthContent = (monthIndex: number, shortMonthText: string, fullMonthText: string) => {
		const tooltipText = `Подсказка: ${ fullMonthText }`;

		return <span title={ tooltipText }>{ shortMonthText }</span>;
	};

	return (
		<div className={ `custom-calendar${ isRightIcon ? ' right' : '' } ${ className }` }>
			{ isIcon && (
				<div className="icon-wrapper">
					<Calendar color={ '#3582F6' }/>
				</div>
			) }

			<div className={ `picker-wrapper ${ isIcon ? 'iconLeft' : '' }` }>
				<DatePicker
					locale={ ru }
					selected={ startDateLocal }
					dateFormat={ getDateFormatText() }
					onChange={ (date) => {
						onDateRange(date);

						setStartDate(date);
					} }
					selectsStart
					startDate={ startDateLocal }
					endDate={ endDate }
					placeholderText={ placeholder }
					minDate={ minDate }
					maxDate={ maxDate }

					showTimeSelect={ isTimeSelect }
					timeCaption="Время"
					timeFormat="HH:mm"

					renderMonthContent={ renderMonthContent }
					showMonthYearPicker={ isMonthSelect }
					disabled={ disabled }
					showYearDropdown
					showMonthDropdown
					yearDropdownItemNumber={ 50 }
					scrollableYearDropdown
				/>
			</div>
		</div>
	)
}

export default DatePickerField;
