export default function GameZone({ color = "white", size = 20 }) {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 20 20`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.5835 14.5833C16.6669 17.4999 19.9577 15.3458 19.1669 12.4999C17.9794 8.22575 17.3335 5.84659 16.9977 4.58992C16.9017 4.23008 16.6897 3.91194 16.3945 3.68476C16.0994 3.45758 15.7376 3.33404 15.3652 3.33325H4.6352C3.8702 3.33325 3.20354 3.85409 3.0177 4.59575C2.31687 7.38575 1.69437 9.83492 0.953535 12.4999C0.163536 15.3458 3.45354 17.4999 5.53687 14.5833M15.0002 7.08325L15.0094 7.09159M13.7419 5.83325L13.751 5.84159M13.7419 8.33325L13.751 8.34159M12.5002 7.08325L12.5094 7.09159M5.83353 5.83325V8.33325M4.58354 7.08325H7.08353"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.66667 13.3333C7.10869 13.3333 7.53262 13.1577 7.84518 12.8452C8.15774 12.5326 8.33333 12.1087 8.33333 11.6667C8.33333 11.2246 8.15774 10.8007 7.84518 10.4882C7.53262 10.1756 7.10869 10 6.66667 10C6.22464 10 5.80072 10.1756 5.48816 10.4882C5.17559 10.8007 5 11.2246 5 11.6667C5 12.1087 5.17559 12.5326 5.48816 12.8452C5.80072 13.1577 6.22464 13.3333 6.66667 13.3333ZM13.3333 13.3333C13.7754 13.3333 14.1993 13.1577 14.5118 12.8452C14.8244 12.5326 15 12.1087 15 11.6667C15 11.2246 14.8244 10.8007 14.5118 10.4882C14.1993 10.1756 13.7754 10 13.3333 10C12.8913 10 12.4674 10.1756 12.1548 10.4882C11.8423 10.8007 11.6667 11.2246 11.6667 11.6667C11.6667 12.1087 11.8423 12.5326 12.1548 12.8452C12.4674 13.1577 12.8913 13.3333 13.3333 13.3333Z"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
