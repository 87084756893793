import { FC, useEffect, useState } from "react";
import DialogRightByContent from "../../components/dialog/DialogRightByContent";
import { $getCurrentZone } from "../../api/requests/zone";
import ComputersHeaderBtn from "../../components/computers/ComputersHeaderBtn";
import ComputersTableHeader from "../../components/computers/ComputersTableHeader";
import ComputersTableContent from "../../components/computers/ComputersTableContent";
import DateOperations from "../../mixins/date-operation";
import { useSelector } from "react-redux";
import { GetCurrentUserData } from "../../redux/slices/userSlice";

interface ComputersPageProps {
    type?: string
}

const ComputersPage: FC<ComputersPageProps> = (
    {
        type
    }
) => {
    let intervalId: NodeJS.Timeout | null = null;

    const dateOperation = new DateOperations();
    const userData = useSelector(GetCurrentUserData);
    const timeZone = userData?.staffCenter?.timezone || 0;

    const statusList: { [key: string]: string } = {
        '1': 'Активен',
        '0': 'Занят',
        '-1': 'Забронирован',
        '-2': 'Режим обслуживания',
        '-3': 'Не активен',
    };

    const btnDefaultList = [
        {
            name: 'Все группы',
            isActive: false
        }
    ]

    const [btnList, setBtnList] = useState(btnDefaultList);

    const [infoPcData, setInfoPcData] = useState({
        title: '',
        status: NaN
    });
    const [infoPcList, setInfoPcList] = useState([
        {
            title: 'Процессор:',
            value: 'Нет информации',
            key: 'cpu'
        },
        {
            title: 'Оперативная память:',
            value: 'Нет информации',
            key: 'ram'
        },
        {
            title: 'Видеокарта:',
            value: 'Нет информации',
            key: 'videoCard'
        },
        {
            title: 'Диски:',
            value: 'Нет информации',
            key: 'disk_status'
        },
        {
            title: 'MAC',
            value: 'Нет информации',
            key: 'mac'
        },
        {
            title: 'Активное приложение:',
            value: 'Нет информации',
            key: 'activeApp'
        },
        {
            title: 'Комментарий к ПК:',
            value: 'Нет информации',
            key: 'comments'
        },
    ]);

    const [tableContentList, setTableContentList] = useState<any[]>([]);
    const [localTableContentList, setLocalTableContentList] = useState<any[]>([]);

    const [isShowModal, setIsShowModal] = useState(false);

    const headerTable = [
        {
            key: 'position',
            title: '№',
        },
        {
            key: 'name',
            title: 'Название',
        },
        {
            key: 'user',
            title: 'Пользователь',
        },
        {
            key: 'session',
            title: 'Сеанс',
        },
        {
            key: 'dateStart',
            title: 'Старт',
        },
        {
            key: 'dateEnd',
            title: 'Окончание',
        },
        {
            key: 'dateRemains',
            title: 'Остаток',
        },
        {
            key: 'application',
            title: 'Приложение',
        }
    ];
    const contentTable = [
        {
            number: 1,
            name: (
                <div className={'computers-table-name-work'}>
                    <p>pc1</p>

                    <div className={'btn btn-green'}>Работает</div>

                    <div className={'btn btn-red'}>Забронирован</div>
                </div>
            ),
            user: (
                <div className={'computers-table-name-work'}>
                    <div className={'btn btn-link'}>#2134a2</div>
                </div>
            ),
            session: 21312,
            dateStart: '21:30',
            dateEnd: '22:30',
            dateRemains: '01:00',
            application: (
                <div>Warface</div>
            ),
            version: '1.00123',
        }
    ];

    const typeList = [
        {
            key: 0,
            value: 'comp',
        },
        {
            key: 1,
            value: 'console'
        },
        {
            key: 2,
            value: 'vr'
        },
        {
            key: 3,
            value: 'open-area'
        }
    ]

    const getDeviceContentTable = (arr: any[]): any[] => {
        return [...arr].map(i => getDeviceContentTableData(i))
    }

    const getDeviceData = (key: any): string => {
        return typeof key === 'string' && key || '-'
    }

    const getDateFormat = (date: string) => {
        if (!date) return '-';

        return getDeviceData(dateOperation.getFormattedDateAndTime(date, timeZone))
    }

    const getDeviceContentTableData = (item: any): any => {
        return {
            ...item,
            number: getDeviceData(item.position),
            title: item.name,
            name: (
                <div className={'computers-table-name-work'}>
                    <p>{getDeviceData(item.name)}</p>

                    <div className={`btn btn-${(item.isOnline) ? 'green' : 'red'}`}>{ item.isOnline ? 'В сети' : 'Не в сети' }</div>

                    <div className={`btn btn-${(item.status > 0) ? 'green' : 'red'}`}>{ getDeviceStatusData(String(item.status)) }</div>
                </div>
            ),
            user: (
                <div className={'computers-table-name-work'}>
                    <div className={`btn btn-link ${item.session ? '' : (item.session?.user ? '' : 'no-user')}`}>
                        { getDeviceData(item.session
                            ? (item.session?.user?.name || 'Гость')
                            : '-') }
                    </div>
                </div>
            ),
            session: calculateTimeDifference(
                item.session?.startDate,
                item.session?.endDate
            ),
            dateStart: getDateFormat(item.session?.startDate),
            dateEnd: getDateFormat(item.session?.endDate),
            dateRemains: calculateTimeDifference(
                item.session?.startDate,
                item.session?.endDate,
                new Date()
            ),
            application: getDeviceData(item.activeApp)
        }
    }

    const getDeviceStatusData = (key: keyof typeof statusList): string => {
        return statusList[key];
    };

    const calculateTimeDifference = (startTime: string, endTime: string, currentTime?: Date): string => {
        if (!startTime || !endTime) return '-';

        const start = new Date(startTime);
        const end = new Date(endTime);
        let timeDifference: number;

        if (currentTime) {
            timeDifference = (end.getTime() - currentTime.getTime()) / 60000;
        } else {
            timeDifference = (end.getTime() - start.getTime()) / 60000;
        }

        if (timeDifference < 0) {
            return '-';
        }

        // Calculate hours and minutes of the time difference
        const diffHours = Math.floor(timeDifference / 60);
        const diffMinutes = (timeDifference % 60).toFixed(0);

        // Format the result as hh:mm
        return `${diffHours.toString().padStart(2, "0")}:${diffMinutes.toString().padStart(2, "0")}`;
    }

    useEffect(() => {
        init();

        const intervalId = setInterval(init, 15000);

        return () => clearInterval(intervalId as NodeJS.Timeout);
    }, [type]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });

    }, [isShowModal])

    function init() {
        const findKey = typeList.find(i => i.value === type);

        $getCurrentZone(
            { type: findKey ? findKey.key : 0 },
            { disableLoader: true }
        ).then((i) => {
            if (!i || !i.result) return clearInterval(intervalId as NodeJS.Timeout);

            const list = [...btnDefaultList];

            const getList = i.result.map(_i => ({
                ..._i,
                devices: _i.devices && getDeviceContentTable(_i.devices),
                isActive: false
            }))

            list.push(...getList)

            setBtnList(list)
            setTableContentList(getList)
            setLocalTableContentList(getList)
        })
    }

    function onLeftClickTrContent(item: any) {
        setInfoPcList(list => {
            return [ ...list ].map(i => {
                if (Object.keys(item).includes(i.key) && item[i.key]) {
                    i.value = item[i.key]
                }

                return i
            })
        })

        setInfoPcData({
            title: item.title,
            status: item.status,
        })

        setIsShowModal(true);
    }

    return (
        <div className="computers list-wrapper pages-comp">
            <div className="booking__header">
                <h1>Зоны</h1>
            </div>

            <div className="computers-mains">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 computers-page">
                            <ComputersHeaderBtn
                                btnList={btnList}
                                setUpdateList={() => init()}
                                setSelectFilterGroup={(list) => {
                                    const listUpdate = list.length ? [...localTableContentList].filter(i => list.includes(i.id)) : localTableContentList;

                                    setTableContentList(listUpdate)
                                }}
                                type={type || ''}
                            />

                            {(tableContentList && tableContentList.length) ? tableContentList.map((i, idx) => (
                                <div
                                    key={`computers-content-item-${idx}`}
                                    className="computers__content"
                                >
                                    <ComputersTableHeader
                                        title={i.name}
                                        item={i}
                                        type={type || ''}
                                        setUpdateList={() => init()}
                                        userData={userData}
                                    />

                                    <ComputersTableContent
                                        headerTable={headerTable}
                                        contentTable={i.devices}
                                        onLeftClick={onLeftClickTrContent}
                                        setUpdateList={() => init()}
                                    />
                                </div>
                            )) : null}

                            {!tableContentList.length && (
                                <div className="program__block__item__appellation">
                                    <p>
                                        Список пк пуст
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <DialogRightByContent
                isOpen={isShowModal}
                closeModal={() => setIsShowModal(false)}
                label={infoPcData.title}
            >
                <div className={'info-pc'}>
                    <div className={'info-pc__header'}>
                        <span className={`btn btn-${(infoPcData.status > 0) ? 'green' : 'red'}`}>{getDeviceStatusData(infoPcData.status)}</span>
                    </div>

                    <div className="info-pc__content">
                        {infoPcList.map((i, idx) => (
                            <div
                                key={`info-pc-item-${idx}`}
                                className="info-pc__block"
                            >
                                <p>{i.title}</p>

                                <p>{i.value}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </DialogRightByContent>
        </div>
    );
}

export default ComputersPage;

