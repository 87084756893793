export default function Collector({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.66732 8.33301H13.334M5.83398 11.6663H6.66732M13.334 11.6663H14.1673"
				stroke={color}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.5 14.9997V9.50801C2.50017 9.28251 2.5461 9.0594 2.635 8.85217L4.5675 4.34384C4.69586 4.04395 4.90941 3.78831 5.18168 3.60863C5.45394 3.42895 5.77295 3.33311 6.09917 3.33301H13.9008C14.227 3.33304 14.5459 3.42875 14.8181 3.60828C15.0904 3.78781 15.304 4.04327 15.4325 4.34301L17.3658 8.85134C17.4547 9.05914 17.5003 9.28284 17.5 9.50884V14.9997M2.5 14.9997V16.9997C2.5 17.1323 2.55268 17.2595 2.64645 17.3532C2.74021 17.447 2.86739 17.4997 3 17.4997H5.33333C5.46594 17.4997 5.59312 17.447 5.68689 17.3532C5.78065 17.2595 5.83333 17.1323 5.83333 16.9997V14.9997M2.5 14.9997H5.83333M17.5 14.9997V16.9997C17.5 17.1323 17.4473 17.2595 17.3536 17.3532C17.2598 17.447 17.1326 17.4997 17 17.4997H14.6667C14.601 17.4997 14.536 17.4867 14.4753 17.4616C14.4147 17.4365 14.3595 17.3997 14.3131 17.3532C14.2667 17.3068 14.2299 17.2517 14.2047 17.191C14.1796 17.1304 14.1667 17.0653 14.1667 16.9997V14.9997M17.5 14.9997H14.1667M5.83333 14.9997H14.1667"
				stroke={color}
				strokeWidth="1.25"
			/>
		</svg>
	);
}
