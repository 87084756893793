export default function Ruble({color= "white", width = 9, height = 12}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 9 12`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.80563 7.2V8.56H6.24563V9.76H2.80563V12H1.52563V9.76H0.085625V8.56H1.52563V7.2H0.085625V6H1.52563V0.799999H4.80563C5.52029 0.799999 6.14963 0.938666 6.69363 1.216C7.24829 1.49333 7.67496 1.87733 7.97363 2.368C8.28296 2.848 8.43763 3.392 8.43763 4C8.43763 4.608 8.28296 5.15733 7.97363 5.648C7.67496 6.128 7.24829 6.50667 6.69363 6.784C6.14963 7.06133 5.52029 7.2 4.80563 7.2H2.80563ZM2.80563 2V6H4.80563C5.50963 6 6.06963 5.81867 6.48563 5.456C6.90163 5.09333 7.10963 4.608 7.10963 4C7.10963 3.392 6.90163 2.90667 6.48563 2.544C6.06963 2.18133 5.50963 2 4.80563 2H2.80563Z"
                fill={color}
            />
        </svg>
    );
}
