export default function PersonCrown({color= "white", width = 25, height = 20}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 25 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#184;">
                <path id="Vector"
                      d="M8.68075 9.2929C9.76573 9.2929 10.8063 8.8619 11.5735 8.0947C12.3407 7.32751 12.7717 6.28697 12.7717 5.20199C12.7717 4.11702 12.3407 3.07648 11.5735 2.30928C10.8063 1.54209 9.76573 1.11108 8.68075 1.11108C7.59578 1.11108 6.55524 1.54209 5.78804 2.30928C5.02085 3.07648 4.58984 4.11702 4.58984 5.20199C4.58984 6.28697 5.02085 7.32751 5.78804 8.0947C6.55524 8.8619 7.59578 9.2929 8.68075 9.2929Z"
                      stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2"
                      d="M1.86328 17.2928V16.2929C1.86305 15.1121 2.15045 13.9503 2.69873 12.9159C3.24701 11.8815 4.03834 11.0079 4.99907 10.3766C5.95979 9.74533 7.05866 9.37679 8.1934 9.30532C9.32813 9.23384 10.4618 9.46175 11.4889 9.96783M17.2644 19.2925L18.2269 13.2933L14.8579 15.0931L13.4141 13.2933L11.9702 15.0931L8.60125 13.2933L9.56382 19.2925H17.2644Z"
                      stroke={color}
                      strokeWidth="1.375"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}