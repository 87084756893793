import { FC, useEffect, useState } from "react";
import Button from "../../components/form/Button";
import PopupDialog from "../../components/dialog/PopupDialog";
import PlusBorder from "../../img/icon/PlusBorder";
import { DialogComputersAdd } from "../../components/dialog/DialogComputersAdd";
import DialogContainer from "../../components/dialog/DialogContainer";
import { ListData } from "../../components/calendar";
import cn from "classnames";
import Warning from "../../img/icon/Warning";
import ElectroOn from "../../img/icon/ElectroOn";
import ElectroOff from "../../img/icon/ElectroOff";
import { $getMapDeviceList, $installPositionDevice } from "../../api/requests/device";
import { DeviceModel } from "../../api/requests/device/interface";
import RoleUsers from "../../mixins/role";

const MapPcCenterPage: FC = () => {
    const roleUsers = new RoleUsers();

    const isCreateByRole = roleUsers.getModelRoleByType('mapCenter', 'create');
    const isUpdateByRole = roleUsers.getModelRoleByType('mapCenter', 'update');
    const isDeleteByRole = roleUsers.getModelRoleByType('mapCenter', 'delete');

    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [eventPopup, setEventPopup] = useState<MouseEvent | null>(null);
    const [selectPositionData, setSelectPositionData] = useState<any>(null);
    const [isEditBlock, setIsEditBlock] = useState<boolean>(false);
    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [deviceList, setDeviceList] = useState<DeviceModel[]>([]);

    const defaultList = [
        {
            id: 1,
            name: '',
            position: 1,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 2,
            name: '',
            position: 2,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 3,
            name: '',
            position: 3,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 4,
            name: '',
            position: 4,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 5,
            name: '',
            position: 5,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 6,
            name: '',
            position: 6,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 7,
            name: '',
            position: 7,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 8,
            name: '',
            position: 8,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 9,
            name: '',
            position: 9,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 10,
            name: '',
            position: 10,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 11,
            name: '',
            position: 11,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 12,
            name: '',
            position: 12,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 13,
            name: '',
            position: 13,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 14,
            name: '',
            position: 14,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 15,
            name: '',
            position: 15,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 16,
            name: '',
            position: 16,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 17,
            name: '',
            position: 17,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 18,
            name: '',
            position: 18,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 19,
            name: '',
            position: 19,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
        {
            id: 20,
            name: '',
            position: 20,
            warning: false,
            turnOn: false,
            turnOff: false,
            zone: ''
        },
    ];
    const [list, setList] = useState<any[]>([])

    const handleClick = (event: React.MouseEvent) => {
        const target = event.target as HTMLElement;

        if (!target.classList.contains('btn')) {
            setEventPopup(null);
        }
    };

    const renderItems = () => {
        const rows: JSX.Element[] = [];

        for (let i = 0; i < list.length; i += 10) {
            const leftBlock: JSX.Element[] = [];
            const rightBlock: JSX.Element[] = [];

            for (let j = 0; j < 10; j++) {
                const item = list[i + j];

                if (item) {
                    const block = j < 5 ? leftBlock : rightBlock;

                    block.push(
                        <div className="map-center__item" key={j}>
                            <div className={cn(item.name && !item.warning ? "map-center__block map-center__selected" : 'map-center__block')}
                            >
                                <Button
                                    className={'btn btn-icon'}
                                    text={item.warning ? <Warning color={'#FF5858'} size={24}/>
                                        : (item.name || <PlusBorder size={22}/> )}
                                    onClick={(event) => {
                                        if (!isCreateByRole || !isUpdateByRole || !isDeleteByRole) return;

                                        onClickOpenPopup(event, item, true)
                                    }}
                                />
                            </div>
                        </div>
                    );
                }
            }

            rows.push(
                <div className="map-center__items" key={i}>
                    <div className="map-center__border">{leftBlock}</div>
                    <div className="map-center__border">{rightBlock}</div>
                </div>
            );
        }

        return rows;
    };

    useEffect(() => init(), []);

    function init() {
        $getMapDeviceList({ type: 0 }).then(res => {
            if (!res.length) return setList(defaultList);

            setDeviceList(res);

            setList(() => {
                return [ ...defaultList ].map(i => {
                    const item = res.find(_i => _i.position === i.position);

                    if (item) return {
                        ...i,
                        ...item,
                        turnOn: item.isOnline,
                        turnOff: item.isOnline
                    }

                    return {
                        ...i
                    }
                });
            })
        })
    }

    function onClickOpenPopup(event: any, item: any, is: boolean) {
        if (!item.name.length) {
            setEventPopup(null);
            return openDialog(is, item)
        }

        setSelectPositionData(item);

        setEventPopup(event);
    }

    function openDialog (is?: boolean, item?: ListData, isEdit?: boolean) {
        setIsShowDialog(is || false)

        setSelectPositionData(!is ? null : item);
    }

    function onClickEditBlock() {
        setIsEditBlock(true);
        setIsShowDialog(true);
        setEventPopup(null);
    }

    function onClickDeleteBlock() {
        const requestData = {
            deviceId: selectPositionData.id || '',
            position: 0,
            type: 0
        }

        $installPositionDevice(requestData).then(res => {
            if (!res.id) return;

            resetData();
        })
    }

    function onSaveNewDevicePosition(item: any) {
        $installPositionDevice(item).then(res => {
            setIsLoadingRequest(false);

            if (!res.id) return;

            resetData();
        })
    }

    function resetData() {
        setEventPopup(null);
        setIsShowDialog(false);
        setIsEditBlock(false);
        setDeviceList([]);

        setIsLoadingRequest(false);

        init();
    }

    return (
        <>
            <div className="map-center dashboard" onClick={handleClick}>
                <div className="map-center-main">
                    <div className="container-fluid">
                        <div className="map-center-header dashboard__header">
                            <div className="dashboard__header__subject">
                                <h2>Карта ПК</h2>
                            </div>
                        </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="map-center__content">
                                {renderItems()}
                            </div>
                        </div>
                    </div>

                    {eventPopup && (
                        <PopupDialog
                            eventProps={eventPopup}
                            onClose={() => {}}
                        >
                            <div className="map-center__popup__block">
                                <div>
                                    <div className={'map-center__popup__item'}>
                                        <Button
                                            text={'Редактировать'}
                                            onClick={onClickEditBlock}
                                            className={'btn btn-primary map-center__popup__btn'}
                                        />
                                    </div>

                                    <div className={'map-center__popup__item'}>
                                        <Button
                                            text={'Удалить'}
                                            onClick={onClickDeleteBlock}
                                            className={'btn btn-danger map-center__popup__btn'}
                                        />
                                    </div>
                                </div>

                                <div className='map-center__popup__header'>
                                    {selectPositionData.warning && (
                                        <>
                                            <Warning color={'#FF5858'} size={20}/>
                                            <p>Неполадки</p>
                                        </>
                                    )}

                                    {selectPositionData.turnOn && (
                                        <>
                                            <ElectroOn color={'#70D584'} size={20}/>
                                            <p>Включен</p>
                                        </>
                                    )}

                                    {selectPositionData.turnOff && (
                                        <>
                                            <ElectroOff
                                                color={'#FF5858'}
                                                width={20}
                                                height={20}
                                            />

                                            <p>Выключен</p>
                                        </>
                                    )}
                                </div>

                                <div className='map-center__popup__content'>
                                    {selectPositionData.zone && (
                                        <p> {selectPositionData.zone},</p>
                                    )}

                                    <p>{selectPositionData.name}</p>
                                </div>
                            </div>
                        </PopupDialog>
                    )}

                        <DialogContainer
                            isOpen={isShowDialog}
                            closeModal={openDialog}
                            // label={'Добавить устройство'}
                            label={`Позиция ${selectPositionData?.position || ''}`}
                            widthProps={346}
                        >
                            <DialogComputersAdd
                                isType={0}
                                deviceList={deviceList}
                                item={selectPositionData}
                                isEdit={isEditBlock}
                                onSave={onSaveNewDevicePosition}
                                isLoadingRequest={isLoadingRequest}
                                setIsLoadingRequest={setIsLoadingRequest}
                            />
                        </DialogContainer>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MapPcCenterPage;

