import {UserModel} from "../zone/interface";

export interface CreateUserDataDevice {
    page: string // номер страницы
}

export interface DeviceCenterData {
    totalPages: number
    count: number
    hasNextPage: boolean
    hasPrevPage: boolean
    limit: number
    result: DeviceModel[]
}

export interface DevicePostDataCenter {
    statusCode: number
    message: string
    result?: DeviceModel
}

export interface DeviceModel {
    id: string // UUID устройства
    centerId: string
    smartShellId?: number
    name: string // Название устройства
    position: number // Позиция
    type: number // Тип
    status: StatusDevice // Статус
    isOnline: boolean
    session?: {
        id: string // UUID сессии
        centerId: string // UUID центра
        amount: number // Стоимость
        startDate: string // Дата начала сессии
        endDate: string // Дата конца сессии
        isActive: boolean // Активна ли сессия
        paymentType: 1 | 2 | 3 // Тип оплаты
        createdAt: string // Дата создания
        updatedAt: string // Дата обновления
    }
    zones: ZonesKeys
}

export enum StatusDevice {
    'Активен' = 1,
    'Занят',
    'Забронирован',
    'Режим',
}

export interface ActionDeviceCommand {
    deviceId: string
    action: CommandDevice
}

export interface ActionDeviceStart {
    deviceId: string
    inService: boolean
}

export interface ActiveDeviceSession {
    paymentMethod: 1 | 2 | 3,
    userId?: null | string,
    discountId?: null | string,
    promoCode?: null | string,
    rates: ActiveDeviceSessionRates[]
}

export interface ActiveDeviceSessionRates {
    rateId: string,
    amount: number
}

export enum CommandDevice {
    ALLOW_ADMIN_MODE = "ALLOW_ADMIN_MODE", // включить админ права
    DISABLE_SHELL = "DISABLE_SHELL", // Отключить шелл
    LOGOFF = "LOGOFF", // Выйти из системы
    REBOOT = "REBOOT", // перезагрузка
    RUN = "RUN", // Включить
    // SEND_LOGS = "SEND_LOGS", // получить логи
    // SHELL_UNINSTALL = "SHELL_UNINSTALL", // удалить шел
    // SHELL_UPDATE = "SHELL_UPDATE", // Редактировать шел
    // SHOW_MESSAGE = "SHOW_MESSAGE", // отправить смс
    SHUTDOWN = "SHUTDOWN", // выкл
}

export interface ZonesKeys {
    id: string // UUID группы
    name: string // Название группы
    devices?: boolean // Список устройств
    bookings?: BookingModels[] // Список бронирований
}

export interface BookingModels {
    id: string // UUID бронирования
    isActive: boolean // Активное бронирование
    date: string // Дата бронирования
    from_time: string // Время начала бронирования
    to_time: string // Время окончания бронирования
    createdAt: string // Дата создания
    updatedAt: string // Дата обновления
    devices: boolean // Список устройств
    users?: UserModel[] // Список пользователей учавствующих в бронировании
    user?: UserModel // Владелец бронирования
    rate?: rateModel // Тариф
}

export interface DashboardDeviceAvailableDevices {
    date: string // Дата бронирования
    from_time: string // Время начала бронирования
    to_time: string // Время окончания бронирования
    zoneId: string // UUID группы
}

export interface rateModel extends UserModel {
    prices: RatePriceModel[] // Прайслист
}

export interface RatePriceModel {
    zones: RatePriceZoneModel[] // Группы
    priceForAll: number // Стоимость для всех
    priceForUsers: number // Стоимость для клиентов
}

export interface RatePriceZoneModel {
    zoneId: string // UUID группы
}

export interface ListUsersRequestParamsDevice {
    zoneId: string // UUID группы
    name: string // Название устройства
    position?: number // Позиция
    status: number // Статус
    type: number // Тип устройства
    capacity: number // Вместимость
}

export interface ActionDeviceCommand {
    deviceId: string
    action: CommandDevice
}

export interface ActionDeviceStart {
    deviceId: string
    inService: boolean
}

export interface ActiveDeviceSession {
    paymentMethod: 1 | 2 | 3,
    userId?: null | string,
    discountId?: null | string,
    promoCode?: null | string,
    rates: ActiveDeviceSessionRates[]
}

export interface ActiveDeviceSessionRates {
    rateId: string,
    amount: number
}

export interface DeviceInstallPositionData extends GetDeviceMapListData {
    deviceId: string // ID устройства
    position: number // Позиция
}

export interface GetDeviceMapListData {
    type: number // тип дивайсов
}
