export default function Shift({color = "white", width = 16, height = 17}) {
    return (
        <svg width={width} height={height} viewBox={`0 0 16 17`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 731">
                <path id="Vector" d="M1.11523 4.46154H14.3845M14.3845 4.46154L11.1152 1M14.3845 4.46154L11.1152 7.92308"
                      stroke={color}
                      strokeWidth="1.38"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
                <path id="Vector_2" d="M14.3848 12.5385L1.11554 12.5385M1.11554 12.5385L4.38477 16M1.11554 12.5385L4.38477 9.07692"
                      stroke={color}
                      strokeWidth="1.38"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
