export default function CompleteDouble({ color = "white", size = 20 }) {
	return (
		<svg width={size} height={size} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Frame">
				<path id="Vector"
					  d="M1.5 12.5L5.576 16.576C5.6885 16.6884 5.841 16.7515 6 16.7515C6.159 16.7515 6.3115 16.6884 6.424 16.576L9 14M16 7L12 11"
					  stroke={color}
					  strokeWidth="2"
					  strokeLinecap="round"
				/>
				<path id="Vector_2"
					  d="M7 12L11.576 16.576C11.6885 16.6884 11.841 16.7515 12 16.7515C12.159 16.7515 12.3115 16.6884 12.424 16.576L22 7"
					  stroke={color}
					  strokeWidth="2"
					  strokeLinecap="round"
				/>
			</g>
		</svg>
	);
}
